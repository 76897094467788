import { FormikHelpers, FormikProps } from 'formik';
import { useCallback, useRef, useState } from 'react';
import {
  FETCH_SPOT_ACCOUNT_CACHE_KEY,
  useCurrentUserWalletsBalances,
  useModal,
  useWalletConnector,
} from '../../hooks';
import { spotAccountResource } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { redeemSpotAccountFundsFailure } from '../../redux/spot-account/actions';
import { web3Service } from '../../services/blockchain';
import { useMutation } from 'react-query';
import {
  showConfirmedTransactionToast,
  showRedeemSpotAccountProcessToast,
} from '../../redux/toast/actions';
import { reactQueryService } from '../../services';
import { getCurrentNetwork } from '../../redux/blockchain/selectors';
import { getRoundedBalance } from '../../helpers';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';
import { useTranslation } from 'react-i18next';
import { routes } from '../../constants';

export const useForm = (walletConnected: boolean) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<IWithdrawSpotAccountFormData> | null>(null);
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search.slice(1));
  const network = useSelector(getCurrentNetwork);
  const { t } = useTranslation(['spot-account']);
  const { connectedAddress: selectedWallet } = useWalletConnector();
  const { walletsBalances } = useCurrentUserWalletsBalances();
  const transactionId =
    (params as unknown as IWithdrawSpotAccountScreenNavigationParams)?.transactionId ?? '';

  const initialValues: IWithdrawSpotAccountFormData = {
    wallet: '',
    walletType: walletConnected ? 'connected' : 'external',
  };

  const {
    modalVisible: confirmModalVisible,
    closeModal: closeConfirmModal,
    toggleModalVisibility: toggleConfirmModalVisibility,
  } = useModal();

  const [confirmModalValues, setConfirmModalValues] =
    useState<Maybe<IConfirmSpotWithdrawModalData>>(null);

  const { mutate: redeemRequest } = useMutation<
    IRedeemSpotAccountFundsResponse | undefined,
    Error,
    IRedeemSpotAccountFundsParams
  >(spotAccountResource.redeemSpotAccountFunds);

  const wallet = walletsBalances?.find(
    (wallet) => wallet.address?.toLowerCase() === selectedWallet?.toLowerCase(),
  );

  const onSubmitForm = useCallback(
    async (
      values: IWithdrawSpotAccountFormData,
      { setSubmitting }: FormikHelpers<IWithdrawSpotAccountFormData>,
    ) => {
      if (selectedWallet || values.wallet) {
        const walletAddress =
          (values.walletType === 'connected' ? selectedWallet : values.wallet) ?? '';

        try {
          const estimation = await spotAccountResource.estimateGasForRedeemSpotAccount({
            transactionId: transactionId,
            wallet: walletAddress,
          });

          if (!estimation) {
            return;
          }
          const finalAmount = web3Service.roundBalance(estimation.finalAmount).toFixed(2);
          const fee = `${getRoundedBalance(estimation.epanFee, 'EPAN')} EPAN`;
          const amount = web3Service.roundBalance(estimation.amount).toFixed(2);

          const confirmTransferData: IConfirmSpotWithdrawModalData = {
            amount: String(amount),
            finalAmount: String(finalAmount),
            address: walletAddress,
            currency: 'EPAN',
            fee,
            onProceedPress: () => {
              closeConfirmModal();

              redeemRequest(
                {
                  wallet: walletAddress,
                  transactionId: transactionId,
                },
                {
                  onSuccess: async (resp) => {
                    await reactQueryService.invalidateQueries(FETCH_SPOT_ACCOUNT_CACHE_KEY);
                    setSubmitting(false);
                    dispatch(
                      showRedeemSpotAccountProcessToast({
                        message: t('redeeming-process', {
                          value: `${resp?.amount} EPAN ...`,
                        }),
                        type: 'transactionProcess',
                        txHash: resp?.transactionHash,
                        amount: `${resp?.amount} EPAN`,
                        wallet,
                        onReceipt: () => {
                          dispatch(
                            showConfirmedTransactionToast({
                              message: t('redeem-success', {
                                value: `${resp?.amount} EPAN`,
                              }),
                              withAction: true,
                            }),
                          );
                          history.push(routes.SPOT_ACCOUNT);
                        },
                      }),
                    );
                  },
                  onError: (err) => {
                    dispatch(redeemSpotAccountFundsFailure(err));
                    setSubmitting(false);
                  },
                },
              );
            },
          };

          setConfirmModalValues(confirmTransferData);
          toggleConfirmModalVisibility();
        } catch (e) {
          dispatch(redeemSpotAccountFundsFailure(e as Error));
        } finally {
          setSubmitting(false);
        }
      }
    },
    [
      selectedWallet,
      transactionId,
      toggleConfirmModalVisibility,
      closeConfirmModal,
      redeemRequest,
      dispatch,
      history,
      network,
    ],
  );

  return {
    initialValues,
    onSubmitForm,
    confirmModalValues,
    confirmModalVisible,
    closeConfirmModal,
    formRef,
  };
};
