import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, CommonButton } from '../../../../components';
import './styles.scss';
import { useUserInfo } from '../../../../hooks';
import cx from 'classnames';
import { IProps } from './types';
import Webcam from 'react-webcam';
import { editProfilePictureRequest } from '../../../../redux/users/actions';
import { useDispatch } from 'react-redux';
import { dataURLtoFile } from '../../../../helpers';
import ResetIcon from '../../../../assets/images/icons/reset.svg';
import Jimp from 'jimp';

export const TakeShotOnCamera = (props: IProps) => {
  const { t } = useTranslation(['settings']);
  const webcamRef = useRef<Webcam>(null);
  const dispatch = useDispatch();
  const { firstName, lastName } = useUserInfo();
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const takePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current!.getScreenshot();
    if (imageSrc) {
      const jimpImage = await Jimp.read(Buffer.from(imageSrc.substring(22), 'base64'));
      jimpImage.crop(jimpImage.bitmap.width / 2 - 90, jimpImage.bitmap.height / 2 - 90, 180, 180);
      const jimpImgB64 = await jimpImage.getBase64Async(Jimp.MIME_JPEG);
      setImageSrc(jimpImgB64);
    }
  }, [webcamRef]);

  const savePhoto = () => {
    if (imageSrc) {
      const transformedImg = dataURLtoFile(
        imageSrc,
        `profile-photo-${firstName}-${lastName}-${Date.now()}`,
      );
      dispatch(editProfilePictureRequest({ picture: transformedImg as unknown as IFile }));
      setImageSrc(null);
      props.onDismiss();
    }
  };

  useEffect(() => {
    if (!props.isVisible) {
      setImageSrc(null);
    }
  }, [props.isVisible]);

  return (
    <BaseModal
      title={imageSrc ? t('your-profile-pic') : t('take-a-shot')}
      visible={props.isVisible}
      onDismiss={() => props.onDismiss(true)}
      hideCloseButton
    >
      <div className="edit-profile-webcam">
        {!imageSrc ? (
          <React.Fragment>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              mirrored={true}
              forceScreenshotSourceSize
              videoConstraints={{
                facingMode: 'user',
              }}
            />
            <div className="edit-profile-webcam-crop"></div>
          </React.Fragment>
        ) : (
          <div className="edit-profile-webcam-result">
            <img src={imageSrc} />
            <div onClick={() => setImageSrc(null)} className="edit-profile-webcam-result-remove">
              <img src={ResetIcon} />
            </div>
          </div>
        )}
      </div>
      <div className="edit-profile-webcam-btns">
        <CommonButton
          onClick={() => props.onDismiss(true)}
          className="edit-profile-webcam-btns-outlined"
          labelClassName={cx(
            'edit-profile-webcam-btns-label',
            'edit-profile-webcam-btns-label-outlined',
          )}
          label={t('cancel')}
        />
        <CommonButton
          onClick={imageSrc ? savePhoto : takePhoto}
          labelClassName="edit-profile-webcam-btns-label"
          label={imageSrc ? t('save-photo') : t('take-a-shot')}
        />
      </div>
    </BaseModal>
  );
};
