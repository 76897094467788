import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import './style.scss';
import { snackbarService } from '../../services';

interface IDismissActionProps {
  snackbarKey: string;
  callback?: () => void;
}

export const DismissAction: React.FC<IDismissActionProps> = ({ snackbarKey, callback }) => {
  const { t } = useTranslation('common');

  const handleClickDismiss = useCallback(() => {
    snackbarService.closeSnackbar(snackbarKey);
    callback && callback();
  }, [snackbarKey, callback]);

  return (
    <Button onClick={handleClickDismiss} className={'snackbar-actions-dismiss'}>
      {t('hide')}
    </Button>
  );
};
