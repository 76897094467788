import { shallowEqual, useSelector } from 'react-redux';
import { getApiUser, getCurrentUser } from '../redux/users/selectors';

export const useUserInfo = () => {
  const currentUser = useSelector(getCurrentUser, shallowEqual);
  const apiUser = useSelector(getApiUser, shallowEqual);
  const firstName = currentUser?.user_metadata?.firstName || apiUser?.firstName || '';
  const lastName = currentUser?.user_metadata?.lastName || apiUser?.lastName || '';
  const photo = currentUser?.user_metadata?.pictureUrl || apiUser?.photo || '';
  const userId = currentUser?.user_id;
  const email = currentUser?.email ?? '';
  const emailVerified = currentUser?.email_verified;
  const phoneNumber = currentUser?.user_metadata?.phoneNumber ?? '';
  const userName = `${firstName} ${lastName}`;

  return {
    email,
    photo,
    userId,
    lastName,
    userName,
    firstName,
    phoneNumber,
    emailVerified,
  };
};
