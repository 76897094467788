import { createAction } from 'typesafe-actions';

export const FETCH_BTC_USD_TO_ETH_RATE_FAILURE =
  '@EXCHANGE_RATES/FETCH_BTC_USD_TO_ETH_RATE_FAILURE';
export const FETCH_CURRENCIES_QUOTES_FAILURE = '@EXCHANGE_RATES/FETCH_CURRENCIES_QUOTES_FAILURE';

export const fetchBtcUsdToEthRateFailure = createAction(FETCH_BTC_USD_TO_ETH_RATE_FAILURE)<Error>();
export const fetchCurrenciesQuotesFailure = createAction(FETCH_CURRENCIES_QUOTES_FAILURE)<Error>();

export const exchangeRatesActions = {
  fetchBtcUsdToEthRateFailure,
  fetchCurrenciesQuotesFailure,
} as const;
