export const primaryColor = '#D95ACE';
export const accentColor = '#F2AB3C';
export const textColor = '#000000';
export const textSecondaryColor = '#B3A3B3';
export const backgroundColor = '#FCF0FC';
export const errorColor = '#E91818';
export const inputColor = '#F1F2F4';
export const placeholderColor = '#D3B3CF';
export const paginationText = '#83828F';
export const twitterColor = '#10A0F5';
export const telegramColor = '#3E8DCF';
export const metamaskColor = '#F09536';
export const ethColor = '#637AEA';
export const notificationColor = 'rgba(219, 71, 124, 0.9)';

export const whiteColor = '#FFFFFF';
export const blackColor = '#000000';
export const activeColor = '#38BB40';

export const defaultWalletColor: Record<IWalletType, string> = {
  coinbase: '#EF8D34',
  metamaskExtension: '#EA3675',
  walletConnect: '#4396F7',
  paypolitan: primaryColor,
};
