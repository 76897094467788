import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseModal,
  CommonButton,
  FormPhoneNumberField,
  FormTextField,
} from '../../../../components';
import './styles.scss';
import { AvatarUser } from '../../../../components/user-avatar';
import { useModal, useUserInfo } from '../../../../hooks';
import cx from 'classnames';
import { IProps, IUpdateProfileData } from './types';
import DropdownIcon from '../../../../assets/images/icons/dropdown.svg';
import { ClickAwayListener } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useForm } from './use-form';
import { TakeShotOnCamera } from '../camera';
import { useWebcamForm } from './use-webcam-form';
import { VerifyModal } from '../verify';
import { ConfirmType } from '../verify/types';
import { getResendConfirmationCodeTimeoutTimestamp } from '../../../../helpers';
import { verifyTypeMapper } from './helpers';

export const EditProfileModal = (props: IProps) => {
  const { t } = useTranslation(['settings']);
  const { firstName, lastName, photo } = useUserInfo();
  const [isUploadBtnActive, setUploadBtnActive] = useState(false);
  const [activeField, setActiveField] = useState<null | keyof IUpdateProfileData>(null);
  const userData = useUserInfo();
  const uploadRef = useRef<HTMLInputElement>(null);
  const {
    modalVisible: webcamModalVisible,
    toggleModalVisibility: toggleWebcamModalVisibility,
    closeModal: closeWebcamModal,
  } = useModal();
  const {
    modalVisible: verifyModalVisible,
    toggleModalVisibility: toggleVerifyModalVisibility,
    closeModal: closeVerifyModal,
  } = useModal();
  const [confirmationCodeTimeoutTimestamp, setConfirmationCodeTimeoutTimestamp] = useState<
    null | number
  >(null);
  const { initialValues, validationSchema, onSubmitForm, ActionButtons } = useForm(activeField!);
  const { handleImgRemove, handleImgUpload, hasWebcam } = useWebcamForm(() =>
    setUploadBtnActive(false),
  );
  const formik = useFormik({
    initialValues,
    onSubmit: (data, helpers) =>
      onSubmitForm(
        data,
        () => {
          if (activeField === 'email' || activeField === 'phoneNumber') {
            props.onDismiss();
            setConfirmationCodeTimeoutTimestamp(getResendConfirmationCodeTimeoutTimestamp());
            toggleVerifyModalVisibility();
          } else {
            setActiveField(null);
          }
        },
        helpers,
      ),
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema,
  });

  useEffect(() => {
    if (!props.isVisible) {
      setUploadBtnActive(false);
    }
  }, [props.isVisible]);

  const handleInputChangeCapture = (currentField: keyof IUpdateProfileData) => {
    if (activeField !== currentField) {
      setActiveField(currentField);
    }
    formik.resetForm();
  };

  const renderActionButtons = (fieldName: keyof IUpdateProfileData) => {
    return ActionButtons(activeField, fieldName, formik.isSubmitting, formik.isValid, () => {
      setActiveField(null);
      formik.resetForm();
    });
  };

  return (
    <React.Fragment>
      <BaseModal
        title={t('edit-profile-normal')}
        visible={props.isVisible}
        onDismiss={props.onDismiss}
      >
        <div className="edit-profile-form">
          <div className="edit-profile-form-photo">
            <AvatarUser
              customClass="edit-profile-form-photo-img"
              userInfo={{
                firstName: firstName,
                lastName: lastName,
                photoUrl: photo,
              }}
            />
            <ClickAwayListener onClickAway={() => setUploadBtnActive(false)}>
              <div className="edit-profile-form-photo-upload-container">
                <CommonButton
                  onClick={() => setUploadBtnActive((prev) => !prev)}
                  className="edit-profile-form-photo-upload"
                >
                  <img
                    className={cx({
                      'edit-profile-form-photo-upload-img-rotated': isUploadBtnActive,
                    })}
                    src={DropdownIcon}
                  />
                  <span className="edit-profile-form-photo-upload-text">
                    {t('upload-new-photo')}
                  </span>
                </CommonButton>
                <div
                  className={cx('edit-profile-form-photo-upload-options', {
                    'edit-profile-form-photo-upload-options-active': isUploadBtnActive,
                  })}
                >
                  <div
                    onClick={() => uploadRef.current!.click()}
                    className={cx('edit-profile-form-photo-upload-options-item', {
                      'edit-profile-form-photo-upload-options-item-only': !hasWebcam,
                    })}
                  >
                    <span>{t('upload-from-computer')}</span>
                    <input
                      accept="image/png, image/jpeg"
                      multiple={false}
                      onChange={handleImgUpload}
                      className="edit-profile-invisible-input"
                      type="file"
                      ref={uploadRef}
                    />
                  </div>
                  {hasWebcam && (
                    <div
                      onClick={() => {
                        props.onDismiss();
                        toggleWebcamModalVisibility();
                      }}
                      className="edit-profile-form-photo-upload-options-item"
                    >
                      <span>{t('take-a-shot-on-cam')}</span>
                    </div>
                  )}
                </div>
              </div>
            </ClickAwayListener>
            <div onClick={handleImgRemove} className="edit-profile-form-photo-remove">
              <span>{t('remove-photo')}</span>
            </div>
          </div>
          <div>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <div className="edit-profile-input-container">
                  <span className={cx('edit-profile-input-label')}>{t('first-name')}</span>
                  <FormTextField<IUpdateProfileData>
                    name={'firstName'}
                    className="edit-profile-input"
                    onChangeCapture={() => handleInputChangeCapture('firstName')}
                    highlightOnFocus
                  />
                  {renderActionButtons('firstName')}
                </div>
                <div className="edit-profile-input-container">
                  <span className={cx('edit-profile-input-label')}>{t('last-name')}</span>
                  <FormTextField<IUpdateProfileData>
                    className="edit-profile-input"
                    name={'lastName'}
                    onChangeCapture={() => handleInputChangeCapture('lastName')}
                    highlightOnFocus
                  />
                  {renderActionButtons('lastName')}
                </div>
                <div className="edit-profile-input-container">
                  <span className={cx('edit-profile-input-label')}>{t('phone-number')}</span>
                  <FormPhoneNumberField<IUpdateProfileData>
                    containerClass="edit-profile-phone-input"
                    name={'phoneNumber'}
                    onChangeCapture={() => handleInputChangeCapture('phoneNumber')}
                    highlightOnFocus
                  />
                  {renderActionButtons('phoneNumber')}
                </div>
                <div className="edit-profile-input-container">
                  <span className={cx('edit-profile-input-label')}>{t('email')}</span>
                  <FormTextField<IUpdateProfileData>
                    className="edit-profile-input"
                    name={'email'}
                    onChangeCapture={() => handleInputChangeCapture('email')}
                    highlightOnFocus
                  />
                  {renderActionButtons('email')}
                </div>
              </form>
            </FormikProvider>
          </div>
        </div>
      </BaseModal>
      <TakeShotOnCamera
        isVisible={webcamModalVisible}
        onDismiss={(openEditModal) => {
          closeWebcamModal();
          if (openEditModal) {
            props.toggleVisibility();
          }
        }}
      />
      <VerifyModal
        type={
          activeField === 'email' || activeField === 'phoneNumber'
            ? (verifyTypeMapper[activeField] as ConfirmType)
            : null
        }
        isVisible={verifyModalVisible}
        onDismiss={() => {
          closeVerifyModal();
          setActiveField(null);
          formik.setFieldValue('phoneNumber', userData.phoneNumber);
          formik.setFieldValue('email', userData.email);
        }}
        updatedKey={activeField === 'email' ? formik.values.email : formik.values.phoneNumber}
        confirmationCodeTimeoutTimestamp={confirmationCodeTimeoutTimestamp}
        setConfirmationCodeTimeoutTimestamp={setConfirmationCodeTimeoutTimestamp}
      />
    </React.Fragment>
  );
};
