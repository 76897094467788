import { FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GET_BRIDGE_SWAP_ALLOWANCE_CACHE_KEY } from './use-bridge-swap-allowance';
import { isBscNetwork, isEthNetwork } from '../../../helpers';
import { useModal, WALLETS_BALANCES_CACHE_KEY } from '../../../hooks';
import { getCurrentNetwork } from '../../../redux/blockchain/selectors';
import {
  resetBridgeSwapProcessToast,
  showBridgeSwapProcessToast,
  showSuccessBridgeSwapToast,
} from '../../../redux/toast/actions';
import { getValidationSchema } from '../validation-schema';
import {
  EAbortControllers,
  ethBscBridgeService,
  IEthBscBridgeTransactionParams,
  web3Service,
} from '../../../services/blockchain';
import {
  bridgeSwapBlockchainError,
  getBridgeSwapAllowanceBlockchainError,
} from '../../../redux/blockchain/actions';
import { reactQueryService, snackbarService } from '../../../services';

export const useForm = ({ tokenBalance, selectedWallet }: ICrossChainBridgeUseFormParams) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cross-chain-bridge']);
  const network = useSelector(getCurrentNetwork);
  const [confirmModalValues, setConfirmModalValues] =
    useState<Maybe<ICrossChainBridgeConfirmModalValues>>(null);
  const {
    modalVisible: confirmModalVisible,
    closeModal: closeConfirmModal,
    toggleModalVisibility: toggleConfirmModalVisibility,
  } = useModal();
  const [approveLoading, setApproveLoading] = useState(false);
  const initialValues: ICrossChainBridgeFormData = { amount: '' };
  const validationSchema = useMemo(() => getValidationSchema(Number(tokenBalance)), [tokenBalance]);

  const onDismissConfirmModal = useCallback(() => {
    closeConfirmModal();
    setConfirmModalValues(null);
  }, [closeConfirmModal]);

  const onSubmitForm = useCallback(
    async (
      values: ICrossChainBridgeFormData,
      { resetForm, setSubmitting, validateForm }: FormikHelpers<ICrossChainBridgeFormData>,
    ) => {
      const amount = values.amount.toString();

      const onReceipt = () => {
        setTimeout(async () => {
          resetForm();
          validateForm();
          dispatch(resetBridgeSwapProcessToast());
          dispatch(
            showSuccessBridgeSwapToast({
              message: t('transfer-success', {
                amount: `${amount} ${isEthNetwork(network) ? 'EPAN' : 'BEPAN'}`,
                amountReceive: `${amount} ${isEthNetwork(network) ? 'BEPAN' : 'EPAN'}`,
              }),
              withAction: true,
            }),
          );
          if (isEthNetwork(network)) {
            await reactQueryService.invalidateQueries(GET_BRIDGE_SWAP_ALLOWANCE_CACHE_KEY);
          }
          await reactQueryService.invalidateQueries(WALLETS_BALANCES_CACHE_KEY);
        }, 5 * 1000);
      };

      const modalValues: ICrossChainBridgeConfirmModalValues = {
        title: t('confirm-transfer'),
        fields: [
          {
            label: 'amount-send',
            value: `${amount} ${isEthNetwork(network) ? 'EPAN' : 'BEPAN'}`,
          },
          {
            label: 'amount-receive',
            value: `${amount} ${isEthNetwork(network) ? 'BEPAN' : 'EPAN'}`,
          },
        ],
        onConfirm: async () => {
          onDismissConfirmModal();

          try {
            if (selectedWallet) {
              setSubmitting(true);
              const data: IEthBscBridgeTransactionParams = {
                onReceipt,
                network,
                value: amount,
                fromWallet: selectedWallet,
                type: 'EPAN_BEPAN',
              };
              const bridgeSwapProcessData = {
                message: t('receive-after-swap', {
                  amount,
                  currency: isEthNetwork(network) ? 'BEPAN' : 'EPAN',
                  network: t(`${isEthNetwork(network) ? 'smart-chain' : 'ethereum'}`),
                }),
                amount: `${amount} ${isBscNetwork(network) ? 'BEPAN' : 'EPAN'}`,
                wallet: selectedWallet,
                onReceipt,
              };

              snackbarService.showWatingForApprovalTxSnackbar(
                t('common:waiting-for-approval'),
                'CROSS_CHAIN_BRIDGE',
                () => {
                  web3Service.executeAbortToAbortController(EAbortControllers.CROSS_CHAIN_BRIDGE);
                  web3Service.clearAbortController(EAbortControllers.CROSS_CHAIN_BRIDGE);
                  snackbarService.closeSnackbar('CROSS_CHAIN_BRIDGE');
                },
              );

              const txHash = await ethBscBridgeService.bridgeSwap(data);

              if (txHash) {
                dispatch(
                  showBridgeSwapProcessToast({
                    txHash,
                    ...bridgeSwapProcessData,
                  }),
                );
              }

              setSubmitting(true);
            }
          } catch (error) {
            dispatch(resetBridgeSwapProcessToast());
            dispatch(bridgeSwapBlockchainError(error as Error));
          } finally {
            web3Service.clearAbortController(EAbortControllers.CROSS_CHAIN_BRIDGE);
            snackbarService.closeSnackbar('CROSS_CHAIN_BRIDGE');
            setSubmitting(false);
          }
        },
      };

      setConfirmModalValues(modalValues);
      toggleConfirmModalVisibility();
    },
    [t, onDismissConfirmModal, network, toggleConfirmModalVisibility, dispatch, selectedWallet],
  );

  const onApprovalReceipt = () => {
    const interval = setInterval(async () => {
      try {
        if (selectedWallet) {
          const allowance = await ethBscBridgeService.getBridgeSwapAllowance(
            'EPAN_BEPAN',
            selectedWallet,
            network,
          );
          if (Number(allowance)) {
            clearInterval(interval);
            await reactQueryService.invalidateQueries(GET_BRIDGE_SWAP_ALLOWANCE_CACHE_KEY);
            await reactQueryService.invalidateQueries(WALLETS_BALANCES_CACHE_KEY);
            dispatch(resetBridgeSwapProcessToast());
            dispatch(
              showSuccessBridgeSwapToast({
                message: t('common:successfully-approved', {
                  value: `${isEthNetwork(network) ? 'EPAN' : 'BEPAN'}`,
                }),
                withAction: true,
              }),
            );
          }
        }
      } catch (err) {
        clearInterval(interval);
        dispatch(resetBridgeSwapProcessToast());
        dispatch(getBridgeSwapAllowanceBlockchainError(err as Error));
      }
    }, 5 * 1000);
  };

  const handleApprove = useCallback(
    () => async () => {
      try {
        if (selectedWallet) {
          setApproveLoading(true);

          const bridgeSwapProcessData = {
            message: `${t('common:approve')} ${isEthNetwork(network) ? 'EPAN' : 'BEPAN'}`,
            wallet: selectedWallet,
            onReceipt: onApprovalReceipt,
          };

          snackbarService.showWatingForApprovalTxSnackbar(
            t('common:waiting-for-approval'),
            'CROSS_CHAIN_BRIDGE',
            () => {
              web3Service.executeAbortToAbortController(EAbortControllers.CROSS_CHAIN_BRIDGE);
              web3Service.clearAbortController(EAbortControllers.CROSS_CHAIN_BRIDGE);
              snackbarService.closeSnackbar('CROSS_CHAIN_BRIDGE');
            },
          );

          const txHash = await ethBscBridgeService.approveBridgeSwapTransaction({
            fromWallet: selectedWallet,
            network,
            onReceipt: onApprovalReceipt,
            type: 'EPAN_BEPAN',
          });

          if (txHash) {
            dispatch(
              showBridgeSwapProcessToast({
                txHash,
                type: 'approveBridgeSwap',
                ...bridgeSwapProcessData,
              }),
            );
          }
        }
      } catch (error) {
        dispatch(resetBridgeSwapProcessToast());
        dispatch(bridgeSwapBlockchainError(error as Error));
      } finally {
        web3Service.clearAbortController(EAbortControllers.CROSS_CHAIN_BRIDGE);
        snackbarService.closeSnackbar('CROSS_CHAIN_BRIDGE');
        setApproveLoading(false);
      }
    },
    [selectedWallet, network, dispatch, setApproveLoading, onApprovalReceipt],
  );

  return {
    confirmModalVisible,
    initialValues,
    validationSchema,
    onSubmitForm,
    handleApprove,
    approveLoading,
    confirmModalValues,
    onDismissConfirmModal,
  };
};
