import Decimal from 'decimal.js';

export const getInitAnchorElState = (filtersData: ITopUpSpotAccountListHeaderFiltersData[]) =>
  filtersData.reduce((obj, cur) => ({ ...obj, [cur.type]: null }), {} as Record<string, null>);

export const getFiatAmount = (value: string, rate?: number) => {
  if (!rate) {
    return 'n/a';
  }

  return new Decimal(value).mul(rate).toDecimalPlaces(2).toString();
};

export const getDestinationAddress = (
  values: ITopUpSpotAccountFormData,
  addresses?: IGetTopUpWalletAddressesResponse,
) => {
  if (!addresses) {
    return null;
  }

  if (values.payWithCurrency === 'ETH') {
    return addresses.ethAddress;
  } else if (values.payWithCurrency === 'USDT') {
    return addresses.usdtAddress;
  }

  return null;
};

export const payWithGasMap: Record<PayWithCurrencyType, number> = {
  ETH: 22000,
  USDT: 70000,
};
