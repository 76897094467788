import { useEffect, useRef } from 'react';
import { FormikProps } from 'formik/dist/types';
import { useWalletConnector } from './use-wallet-connector';

export const useResetFormOnWalletDisconnection = <T>() => {
  const { walletConnected } = useWalletConnector();
  const formRef = useRef<Maybe<FormikProps<T>>>(null);

  useEffect(() => {
    const atLeastOneValueFilled = !!Object.values(formRef.current?.values ?? {}).length;
    const atLeastOneErrorExist = !!Object.values(formRef.current?.errors ?? {}).length;
    if (!walletConnected && (atLeastOneValueFilled || atLeastOneErrorExist)) {
      formRef.current?.resetForm();
    }
  }, [formRef, walletConnected]);

  return {
    formRef,
  };
};
