import React from 'react';
import './style.scss';
import UniswapIcon from '../../assets/images/icons/uniswap-icon.png';
import PancakeSwapIcon from '../../assets/images/icons/pancakeswap-icon.svg';
import SpotAccountIcon from '../../assets/images/icons/spot-account.svg';
import CompoundIcon from '../../assets/images/icons/compound-icon.svg';
import ClubDealIcon from '../../assets/images/icons/club-deal-icon.svg';
import CrossChainBridgeIcon from '../../assets/images/icons/cross-chain-bridge-icon.svg';
import PolvenGovernanceIcon from '../../assets/images/icons/governance-icon.svg';
import TransferIcon from '../../assets/images/icons/transfer-icon.svg';
import ETHIcon from '../../assets/images/icons/eth-icon.svg';
import BNBIcon from '../../assets/images/icons/bnb-icon.svg';
import { TokenIconRounded } from '../token-icon-rounded';

export const ContractIconRounded: React.FC<IContractIconRoundedProps> = ({ contract }) => {
  switch (contract) {
    case 'UNISWAP':
      return <img src={UniswapIcon} alt="uniswap" />;
    case 'PANCAKE_SWAP':
      return <img src={PancakeSwapIcon} alt="pancake swap" />;
    case 'COMPOUND':
      return <img src={CompoundIcon} alt="compound" />;
    case 'BSC_TO_ETH':
    case 'ETH_TO_BSC':
      return <img src={CrossChainBridgeIcon} alt="cross-chain-bridge" />;
    case 'BEPAN_STAKING':
      return (
        <div className="contract-icon-rounded-bnb-wrapper">
          <img src={BNBIcon} alt="cross-chain-bridge" />
        </div>
      );
    case 'POLVEN_GOVERNANCE':
      return <img src={PolvenGovernanceIcon} alt="polven governance" />;
    case 'CLUB_DEAL':
      return <img src={ClubDealIcon} alt="club deal" />;
    case 'SPOT_ACCOUNT':
      return <img src={SpotAccountIcon} alt="spot account" />;
    case 'TRANSFER':
    case 'REQUEST_TRANSFER':
      return <img src={TransferIcon} alt="transfer" />;
    case 'POLVEN_STAKING':
      return <TokenIconRounded tokenName="POLVEN" />;
    case 'EPAN_STAKING':
      return <TokenIconRounded tokenName="EPAN" />;
    case 'ETH':
      return <TokenIconRounded tokenName="ETH" />;
    case 'BNB':
      return <TokenIconRounded tokenName="BNB" />;
    default:
      return <img src={ETHIcon} alt="fee" />;
  }
};
