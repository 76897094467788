import { protectedApi } from '../decorators';
import { httpService } from '../../../services';

export const CURRENCIES = '/currencies';
export const UNISWAP_DEFAULT_TOKEN_LIST = 'https://tokens.uniswap.org';
export const COMPOUND_TOKEN_LIST =
  'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json';
export const PANCAKE_SWAP_TOP100_TOKEN_LIST =
  'https://tokens.pancakeswap.finance/pancakeswap-top-100.json';

export class TokensResource {
  cacheKey = 'defaultErc20tokes';
  uniswapDefaultListTokenListCacheKey = 'uniswapDefaultListTokenListCacheKey';
  compoundTokenListCacheKey = 'compoundTokenListCacheKey';
  pancakeSwapTop100TokenListCacheKey = 'pancakeSwapTop100TokenListCacheKey';

  @protectedApi()
  async getDefaultErc20Tokens() {
    return httpService.get<IGetDefaultErc20TokesResponse>({
      url: CURRENCIES,
    });
  }

  async getUniswapDefaultTokenList() {
    return httpService.get<IGetTokenListResponse>({
      url: UNISWAP_DEFAULT_TOKEN_LIST,
    });
  }

  async getCompoundTokenList() {
    return httpService.get<IGetTokenListResponse>({
      baseURL: COMPOUND_TOKEN_LIST,
      url: '',
      headers: {
        Authorization: undefined,
      },
    });
  }

  async getPancakeSwapTop100TokenList() {
    return httpService.get<IGetTokenListResponse>({
      url: PANCAKE_SWAP_TOP100_TOKEN_LIST,
    });
  }
}

export const tokensResource = new TokensResource();
