import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { translate } from '../../../../api';
import { PASSWORD_MAX_LENGTH, passwordPolicy } from '../../../../constants';
import { isEmail } from 'class-validator';

export const getValidationSchema = (): SchemaOf<ISignUpEmailFormData> =>
  Yup.object({
    email: Yup.string()
      .trim()
      .email(translate('incorrect-email', 'errors'))
      .required(translate('required', 'errors'))
      .test('additional-email-validations', translate('incorrect-email', 'errors'), (value) => {
        return isEmail(value);
      }),
    password: Yup.string()
      .required(translate('required', 'errors'))
      .test('passwords-policy', translate('password-regex', 'errors'), function (value) {
        return passwordPolicy.check(value);
      })
      .max(PASSWORD_MAX_LENGTH, translate('password-max-length', 'errors')),
    confirmPassword: Yup.string()
      .required(translate('required', 'errors'))
      .test('passwords-match', translate('passwords-match', 'errors'), function (value) {
        return this.parent.password === value;
      }),
  });
