import React from 'react';
import { CommonButton } from '../../../../components';
import { AvatarUser } from '../../../../components/user-avatar';
import { IProps } from './types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { EditProfileModal } from '../edit-profile';
import { useModal, useUserInfo } from '../../../../hooks';
import { shortenString } from '../../../../helpers';

export const Header = (props: IProps) => {
  const { t } = useTranslation(['settings']);
  const { modalVisible, toggleModalVisibility, closeModal } = useModal();
  const { email, firstName, lastName, photo } = useUserInfo();

  return (
    <div className="settings-header">
      <div className="settings-header-first-part">
        <div className="settings-header-user-avatar">
          <AvatarUser
            userInfo={{
              firstName: firstName,
              lastName: lastName,
              photoUrl: photo,
            }}
          />
        </div>
        <div className="settings-header-user">
          <span className="settings-header-user-name">
            {shortenString(`${firstName} ${lastName}`, 30)}
          </span>
          <span className="settings-header-user-email">{email}</span>
        </div>
      </div>
      <div className="settings-header-second-part">
        <CommonButton
          onClick={toggleModalVisibility}
          label={t('edit-profile')}
          containerClassName="settings-header-edit-profile-container"
          labelClassName="settings-header-edit-profile-label"
          className="settings-header-edit-profile-button"
        />
      </div>
      <EditProfileModal
        isVisible={modalVisible}
        onDismiss={closeModal}
        toggleVisibility={toggleModalVisibility}
      />
    </div>
  );
};
