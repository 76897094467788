import { createReducer } from 'typesafe-actions';
import {
  updateCustomErc20Tokens,
  updateErc20Tokens,
  setCurrentChainId,
  deleteCustomErc20Token,
  setCurrentChainIdLoading,
  setCustomErc20Tokens,
  toggleNetworkSelector,
  toggleCustomTokensModal,
  setActiveTokenList,
  removeActiveTokenList,
  setExternalWalletData,
  updateExternalWalletData,
  supportedChain,
  notSupportedChain,
  setTryAutoConnectWallet,
  setWalletConnectQrCodeUrl,
  resetWalletConnectQrCodeUrl,
  setWalletConnectQrModalError,
  resetWalletConnectQrModalError,
} from './actions';
import { resetStore } from '../app/actions';
import { envService } from '../../services/env.service';
import { DEFAULT_ACTIVE_TOKEN_LISTS } from '../../constants';

const initialState: IBlockchainState = {
  externalWalletData: {
    type: 'metamaskExtension',
    chainId: 1,
    addresses: [],
  },
  tryAutoConnectWallet: true,
  currentChainId: envService.chain.chain_id,
  chainIdSupported: true,
  currentChainIdLoading: false,
  erc20Tokens: [],
  customErc20Tokens: [],
  networkSelectorVisible: false,
  customTokensModalVisible: false,
  walletConnectQrCodeUrl: '',
  walletConnectQrModalError: '',
  activeTokenLists: DEFAULT_ACTIVE_TOKEN_LISTS,
};

export const blockChain = createReducer(initialState)
  .handleAction(setCurrentChainId, (state, { payload }) => ({
    ...state,
    currentChainId: payload,
  }))
  .handleAction(supportedChain, (state) => ({
    ...state,
    chainIdSupported: true,
  }))
  .handleAction(notSupportedChain, (state) => ({
    ...state,
    chainIdSupported: false,
  }))
  .handleAction(setCurrentChainIdLoading, (state, { payload }) => ({
    ...state,
    currentChainIdLoading: payload,
  }))
  .handleAction(updateErc20Tokens, (state, { payload }) => ({
    ...state,
    erc20Tokens: payload,
  }))
  .handleAction(updateCustomErc20Tokens, (state, { payload }) => ({
    ...state,
    customErc20Tokens: [
      ...state.customErc20Tokens,
      ...payload.filter(
        (token) =>
          !state.customErc20Tokens.find((storedToken) => storedToken.address === token.address),
      ),
    ],
  }))
  .handleAction(setCustomErc20Tokens, (state, { payload }) => ({
    ...state,
    customErc20Tokens: payload,
  }))
  .handleAction(deleteCustomErc20Token, (state, { payload: { id } }) => ({
    ...state,
    customErc20Tokens: state.customErc20Tokens.filter((item) => item._id !== id),
  }))
  .handleAction(toggleNetworkSelector, (state) => ({
    ...state,
    networkSelectorVisible: !state.networkSelectorVisible,
  }))
  .handleAction(toggleCustomTokensModal, (state) => ({
    ...state,
    customTokensModalVisible: !state.customTokensModalVisible,
  }))
  .handleAction(setActiveTokenList, (state, { payload }) => ({
    ...state,
    activeTokenLists: Array.isArray(payload) ? payload : [...state.activeTokenLists, payload],
  }))
  .handleAction(removeActiveTokenList, (state, { payload }) => ({
    ...state,
    activeTokenLists: state.activeTokenLists.filter((name) => name !== payload),
  }))
  .handleAction(setExternalWalletData, (state, { payload }) => ({
    ...state,
    externalWalletData: payload,
  }))
  .handleAction(updateExternalWalletData, (state, { payload }) => ({
    ...state,
    externalWalletData: {
      ...state.externalWalletData,
      ...payload,
    },
  }))
  .handleAction(setTryAutoConnectWallet, (state, { payload }) => ({
    ...state,
    tryAutoConnectWallet: payload,
  }))
  .handleAction(setWalletConnectQrCodeUrl, (state, { payload }) => ({
    ...state,
    walletConnectQrCodeUrl: payload,
  }))
  .handleAction(resetWalletConnectQrCodeUrl, (state) => ({
    ...state,
    walletConnectQrCodeUrl: initialState.walletConnectQrCodeUrl,
  }))
  .handleAction(setWalletConnectQrModalError, (state, { payload }) => ({
    ...state,
    walletConnectQrModalError: payload,
  }))
  .handleAction(resetWalletConnectQrModalError, (state) => ({
    ...state,
    walletConnectQrModalError: initialState.walletConnectQrModalError,
  }))
  .handleAction(resetStore, () => initialState);
