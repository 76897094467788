import { protectedApi } from '../decorators';
import { httpService } from '../../../services';
import { __DEV__ } from '../../../constants';
import { envService } from '../../../services/env.service';

export const USERS_URL = '/users';
export const PROFILE_PICTURE_URL = `${USERS_URL}/picture`;
export const REFER_FRIEND = `${USERS_URL}/invite/email`;
export const LOGOUT = '/auth/logout';
export const SEND_VERIFICATION_SMS = '/users/send-verification-sms';
export const CHECK_VERIFICATION_CODE = '/users/check-verification-code';
export const CHECK_EMAIL_VERIFICATION_CODE = '/users/verify-email-update';
export const SEND_VERIFICATION_EMAIL = '/users/send-email-update-request';
export const CHANGE_EMAIL = '/users/email';
export const CHANGE_PASSWORD = `${USERS_URL}/password`;
export const CUSTOM_TOKENS = `${USERS_URL}/custom-tokens`;
export const PREFERENCES = `${USERS_URL}/preferences`;
export const SEND_MANAGE_KEYS_VERIFICATION_SMS = `${USERS_URL}/send-manage-keys-verification-sms`;
export const CHECK_MANAGE_KEYS_VERIFICATION_CODE = `${USERS_URL}/check-manage-keys-verification-code`;
export const NOTIFICATIONS_TOKENS = `${USERS_URL}/notifications-tokens`;
export const PROMO_CODES = `${USERS_URL}/promo-codes`;

export class UsersResource {
  @protectedApi()
  async getCurrentUser() {
    return httpService.get<IApiUser>({
      url: USERS_URL,
    });
  }

  @protectedApi()
  async createUser() {
    return httpService.post<ICreateUserResponse>({
      baseURL: `${envService.apiBaseUrl}`,
      url: USERS_URL,
    });
  }

  @protectedApi()
  async editUser(data: EditUserParams) {
    return httpService.patch<EditUserResponse>({
      url: USERS_URL,
      data,
    });
  }

  @protectedApi()
  async uploadProfilePicture({ picture }: IUploadProfilePictureParams) {
    return httpService.sendFormData<IUploadProfilePictureResponse>({
      file: picture,
      method: 'post',
      fileApiKey: 'file',
      url: PROFILE_PICTURE_URL,
    });
  }

  @protectedApi()
  async referFriend(data: IReferFriendParams) {
    return httpService.post<IReferFriendResponse>({
      url: REFER_FRIEND,
      data,
    });
  }

  @protectedApi()
  async deleteAccount() {
    return httpService.delete<IDeleteAccountResponse>({
      url: USERS_URL,
    });
  }

  @protectedApi()
  async logout(data?: ILogoutParams) {
    return httpService
      .get<IDeleteAccountResponse>({
        url: LOGOUT,
        ...(data?.deviceId ? { params: { deviceId: data?.deviceId } } : {}),
      })
      .catch((err) => {
        // not needed to process this error - logout any way
        __DEV__ && console.log('api logout error', err);
      });
  }

  async sendVerificationSms(data: ISendVerificationSmsParams) {
    return httpService.post<ISendVerificationSmsResponse>({
      url: SEND_VERIFICATION_SMS,
      data,
    });
  }

  async checkVerificationCode(data: ICheckVerificationCodeParams) {
    return httpService.post<ICheckVerificationCodeResponse>({
      url: CHECK_VERIFICATION_CODE,
      data,
    });
  }

  @protectedApi()
  async changeEmail(data: IChangeEmailParams) {
    return httpService.patch<IChangeEmailResponse>({
      url: CHANGE_EMAIL,
      data,
    });
  }

  @protectedApi()
  async changePassword(data: IChangePasswordParams) {
    return httpService.patch<void>({
      url: CHANGE_PASSWORD,
      data,
    });
  }

  async deleteProfilePicture() {
    return httpService.delete<void>({
      url: PROFILE_PICTURE_URL,
    });
  }

  @protectedApi()
  async addCustomErc20Token(data: IAddCustomErc20TokenParams) {
    return httpService.post<void>({
      url: CUSTOM_TOKENS,
      data,
    });
  }

  @protectedApi()
  async deleteCustomToken(data: IDeleteCustomTokenParams) {
    return httpService.delete<void>({
      url: `${CUSTOM_TOKENS}/${data.id}`,
    });
  }

  @protectedApi()
  async setPreferences(data: IPreferencesParams) {
    return httpService.patch<void>({
      url: PREFERENCES,
      data,
    });
  }

  @protectedApi()
  async sendManageKeysVerificationSms(data: ISendManageKeysVerificationSms) {
    return httpService.post<void>({
      url: SEND_MANAGE_KEYS_VERIFICATION_SMS,
      data,
    });
  }

  @protectedApi()
  async checkManageKeysVerificationCode(data: ICheckManageKeysVerificationCode) {
    return httpService.post<void>({
      url: CHECK_MANAGE_KEYS_VERIFICATION_CODE,
      data,
    });
  }

  @protectedApi()
  async sendNotificationsToken(data: ISendNotificationsTokenParams) {
    return httpService.post<void>({
      url: NOTIFICATIONS_TOKENS,
      data,
    });
  }

  @protectedApi()
  async updateNotificationTokenById(data: IUpdateNotificationsTokenParams) {
    return httpService.patch<void>({
      url: `${NOTIFICATIONS_TOKENS}/${data.id}`,
      data: { token: data.token },
    });
  }

  @protectedApi()
  async activatePromoCode(data: IActivatePromoCodeParams) {
    return httpService.post<void>({
      url: PROMO_CODES,
      data,
    });
  }

  @protectedApi()
  async checkEmailVerificationCode(data: ICheckEmailVerificationCodeParams) {
    return httpService.post<ICheckEmailVerificationCodeResponse>({
      url: CHECK_EMAIL_VERIFICATION_CODE,
      data,
    });
  }

  @protectedApi()
  async sendVerificationEmail(data: ISendVerificationEmailParams) {
    return httpService.post<ISendVerificationEmailResponse>({
      url: SEND_VERIFICATION_EMAIL,
      data,
    });
  }
}

export const usersResource = new UsersResource();
