import * as Yup from 'yup';
import { translate } from '../api';
import {
  MIN_SWAPPING_AMOUNT,
  MIN_TRANSFER_AMOUNT,
  TRANSACTION_ID_MAX_LENGTH,
  TRANSACTION_ID_MIN_LENGTH,
  TRANSACTION_ID_REGEXP,
} from '../constants';
import parseMobile from 'libphonenumber-js/mobile';
import { getMinInputAmountByCurrency } from './common';

type AmountWithCurrencyYupValidationProps = {
  maxValue?: number | string;
  currency?: string;
  walletConnected?: boolean;
};

export const getAmountWithCurrencyYupValidation = ({
  maxValue,
  currency,
  walletConnected,
}: AmountWithCurrencyYupValidationProps) =>
  Yup.number()
    .required(translate('required', 'errors'))
    .test('wallet not connected', translate('wallet-not-connected', 'errors'), function () {
      return !!walletConnected;
    })
    .test('amount valid', '', (originalValue) => {
      const withComma = `${originalValue}`.replace(/\./g, ',');
      if ((withComma.match(/,/g) || []).length > 1) {
        return false;
      }

      const str = withComma.replace(/,/g, '.');

      return !isNaN(Number(str));
    })
    .typeError(translate('only-number', 'errors'))
    .min(
      getMinInputAmountByCurrency(currency),
      translate('min-transfer-amount', 'errors', { number: getMinInputAmountByCurrency(currency) }),
    )
    .test(
      'max amount',
      translate('max-transfer-your-wallet-balance', 'errors', { number: maxValue }),
      function (value) {
        if (!value || maxValue === undefined) {
          return true;
        }
        return value <= maxValue;
      },
    );

export const getAmountYupValidation = (maxValue?: number | string, min?: number) =>
  Yup.number()
    .required(translate('required', 'errors'))
    .test('amount valid', '', (originalValue) => {
      const withComma = `${originalValue}`.replace(/\./g, ',');
      if ((withComma.match(/,/g) || []).length > 1) {
        return false;
      }

      const str = withComma.replace(/,/g, '.');

      return !isNaN(Number(str));
    })
    .typeError(translate('only-number', 'errors'))
    .min(
      min ?? MIN_TRANSFER_AMOUNT,
      translate('min-transfer-amount', 'errors', { number: min ?? MIN_TRANSFER_AMOUNT }),
    )
    .test(
      'max amount',
      translate('max-transfer-amount', 'errors', { number: maxValue }),
      function (value) {
        if (!value || maxValue === undefined) {
          return true;
        }
        return value <= maxValue;
      },
    );

export const getAmountSwappingYupValidation = (maxValue?: number | string) =>
  Yup.number()
    .required(translate('required', 'errors'))
    .test('amount valid', '', (originalValue) => {
      const withComma = `${originalValue}`.replace(/\./g, ',');
      if ((withComma.match(/,/g) || []).length > 1) {
        return false;
      }

      const str = withComma.replace(/,/g, '.');

      return !isNaN(Number(str));
    })
    .typeError(translate('only-number', 'errors'))
    .min(
      MIN_SWAPPING_AMOUNT,
      translate('min-transfer-amount', 'errors', { number: MIN_SWAPPING_AMOUNT }),
    )
    .test(
      'max amount',
      translate('max-swapping-amount', 'errors', { number: maxValue }),
      function (value) {
        if (!value || !maxValue) {
          return true;
        }

        return value <= maxValue;
      },
    );

export const getAmountRequestTransferYupValidation = () =>
  Yup.number()
    .required(translate('required', 'errors'))
    .test('amount valid', '', (originalValue) => {
      const withComma = `${originalValue}`.replace(/\./g, ',');
      if ((withComma.match(/,/g) || []).length > 1) {
        return false;
      }

      const str = withComma.replace(/,/g, '.');

      return !isNaN(Number(str));
    })
    .typeError(translate('only-number', 'errors'))
    .min(
      MIN_TRANSFER_AMOUNT,
      translate('min-transfer-amount', 'errors', { number: MIN_TRANSFER_AMOUNT }),
    );

export const getSpotBalanceRedeemYupValidation = (maxValue?: number | string) =>
  Yup.number()
    .required(translate('required', 'errors'))
    .test('amount valid', '', (originalValue) => {
      const withComma = `${originalValue}`.replace(/\./g, ',');
      if ((withComma.match(/,/g) || []).length > 1) {
        return false;
      }

      const str = withComma.replace(/,/g, '.');

      return !isNaN(Number(str));
    })
    .typeError(translate('only-number', 'errors'))
    .test(
      'max amount',
      translate('max-spot-balance-redeem', 'errors', { number: maxValue }),
      function (value) {
        if (!value || maxValue === undefined) {
          return true;
        }
        return value <= maxValue;
      },
    );

export const getUniswapAmountYupValidation = (maxValue: number | string, error: string) =>
  Yup.number()
    .required(translate('required', 'errors'))
    .test('amount valid', '', (originalValue) => {
      const withComma = `${originalValue}`.replace(/\./g, ',');
      if ((withComma.match(/,/g) || []).length > 1) {
        return false;
      }

      const str = withComma.replace(/,/g, '.');

      return !isNaN(Number(str));
    })
    .typeError(translate('only-number', 'errors'))
    .min(
      MIN_SWAPPING_AMOUNT,
      translate('min-transfer-amount', 'errors', { number: MIN_SWAPPING_AMOUNT }),
    )
    .test('max amount', translate(error, 'errors', { number: maxValue }), function (value) {
      if (!value || maxValue === undefined) {
        return true;
      }
      return value <= maxValue;
    });

export const getCalculateInterestAmountYupValidation = () =>
  Yup.number()
    .required(translate('required', 'errors'))
    .test('amount valid', '', (originalValue) => {
      const withComma = `${originalValue}`.replace(/\./g, ',');
      if ((withComma.match(/,/g) || []).length > 1) {
        return false;
      }

      const str = withComma.replace(/,/g, '.');

      return !isNaN(Number(str));
    })
    .typeError(translate('only-number', 'errors'));

export const getSpotAccountTransactionIDValidationSchema = () =>
  Yup.object({
    transactionId: Yup.string()
      .required(translate('required', 'errors'))
      .matches(TRANSACTION_ID_REGEXP, translate('invalid-transaction-id', 'errors'))
      .min(TRANSACTION_ID_MIN_LENGTH, translate('invalid-transaction-id', 'errors'))
      .max(TRANSACTION_ID_MAX_LENGTH, translate('invalid-transaction-id', 'errors')),
  });

// export enum PhoneNumbersTypes {
//   MOBILE = 'MOBILE',
// }

/** current BE implementation */
export const isValidPhoneNumber = (_phoneNumber: string) => {
  return true;
  // const parsedPhoneNumber = parseMobile(phoneNumber, {
  //   extract: false,
  // });
  //
  // if (!parsedPhoneNumber) {
  //   return false;
  // }
  //
  // return parsedPhoneNumber.isValid() && parsedPhoneNumber.getType() === PhoneNumbersTypes.MOBILE;
};
