export enum SignUpStep {
  EmailPassword = 1,
  OTPConfirmationEmail,
  FullNamePhoneNumber,
  OTPConfirmationPhone,
}

export interface ISignUpState {
  step: SignUpStep;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  profilePicture: Maybe<IFile>;
  areTermsAccepted: boolean;
  isPhoneNumberVerified: boolean;
  confirmationCodeTimeoutTimestamp: Maybe<number>;
}

export interface ISignInPasswordPayload {
  email: string;
  password: string;
}

export interface ISignUpPasswordPayload {
  email: string;
  password: string;
  onCreated: () => void;
}
