import React from 'react';
import './style.scss';
import { Header, Block } from './components';
import { useBlocks } from './use-blocks';

export const SettingsPage = () => {
  const [blocks, ActionProvider] = useBlocks();
  return (
    <>
      <div className="settings">
        <Header />
        <div className="setting-blocks">
          {blocks.map((val, idx) => {
            return <Block key={idx} {...val} />;
          })}
        </div>
        {ActionProvider}
      </div>
    </>
  );
};
