import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { exchangeRatesResource } from '../api';
import { fetchCurrenciesQuotesFailure } from '../redux/exchange-rates/actions';
import { getCurrentFiatCurrency } from '../redux/users/selectors';
import get from 'lodash.get';
import { useMemo } from 'react';

export const useExchangeRates = (selectedCurrency?: DefaultWalletCurrencies) => {
  const dispatch = useDispatch();
  const { currency: fiatCurrency } = useSelector(getCurrentFiatCurrency, shallowEqual);

  const {
    data: currenciesQuotes,
    isLoading: isLoadingCurrenciesQuotes,
    refetch: refetchCurrenciesQuotes,
  } = useQuery<IFetchCurrenciesQuotesResponse | undefined, Error>(
    [exchangeRatesResource.fetchCurrenciesQuotesCacheKey],
    () => exchangeRatesResource.fetchCurrenciesQuotes(),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000,
      retry: 2,
      onError: (e) => {
        dispatch(fetchCurrenciesQuotesFailure(e));
      },
    },
  );

  const getSelectedCurrencyPrice = () => {
    return get(
      currenciesQuotes ?? {},
      `quotes[${selectedCurrency}][${fiatCurrency}].price`,
      0,
    ) as number;
  };

  const quotes = useMemo<IFetchCurrenciesQuotesResponse | undefined>(() => {
    if (!currenciesQuotes) {
      return undefined;
    }

    return {
      ...currenciesQuotes,
      quotes: {
        ...currenciesQuotes.quotes,
        BEPAN: currenciesQuotes.quotes.EPAN,
      },
    };
  }, [currenciesQuotes]);

  return {
    currenciesQuotes: quotes,
    isLoadingCurrenciesQuotes,
    refetchCurrenciesQuotes,
    selectedCurrencyPrice: getSelectedCurrencyPrice(),
  };
};
