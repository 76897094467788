declare const process: { env: IProcessEnvConfig };

export const Config: IEnvConfig = {
  ENV: process.env.REACT_APP_SPA_ENV,
  API_URL: process.env.REACT_APP_SPA_API_URL,
  AUTH0_API_AUDIENCE: process.env.REACT_APP_SPA_AUTH0_API_AUDIENCE,
  AUTH0_CLIENT_ID: process.env.REACT_APP_SPA_AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: process.env.REACT_APP_SPA_AUTH0_DOMAIN,
  AUTH0_MANAGEMENT_API_AUDIENCE: process.env.REACT_APP_SPA_AUTH0_MANAGEMENT_API_AUDIENCE,
  BSCSCAN_API_KEY: process.env.REACT_APP_SPA_BSCSCAN_API_KEY,
  BSCSCAN_BASE_URL: process.env.REACT_APP_SPA_BSCSCAN_BASE_URL,
  ETHERSCAN_API_KEY: process.env.REACT_APP_SPA_ETHERSCAN_API_KEY,
  ETHERSCAN_BASE_URL: process.env.REACT_APP_SPA_ETHERSCAN_BASE_URL,
  INFURA_ID: process.env.REACT_APP_SPA_INFURA_ID,
  WALLET_CONNECT_BRIDGE: process.env.REACT_APP_SPA_WALLET_CONNECT_BRIDGE,
};
