import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, CommonButton, CommonOtpInput, Timer } from '../../../../components';
import './styles.scss';
import { IProps } from './types';
import { useForm } from './use-form';
import { inputColor } from '../../../../theme';
import cx from 'classnames';

export const VerifyModal = (props: IProps) => {
  const { t } = useTranslation(['settings']);
  const {
    code,
    setCode,
    getTimerLabel,
    submitting,
    resendCodeClick,
    resendingCode,
    onSubmitForm,
    appLoading,
  } = useForm(
    props.setConfirmationCodeTimeoutTimestamp,
    props.onDismiss,
    props.updatedKey,
    props.type,
  );

  useEffect(() => {
    if (!props.isVisible) {
      setCode('');
    }
  }, [props.isVisible]);

  const getResendingCodeTimer = useCallback(() => {
    if (props.confirmationCodeTimeoutTimestamp === null) {
      return;
    }
    return (
      <div className={cx('resend-timer', 'edit-profile-verify-resend-timer')}>
        {props.confirmationCodeTimeoutTimestamp ? (
          <Timer
            timeoutTimestamp={props.confirmationCodeTimeoutTimestamp}
            getLabel={getTimerLabel}
          />
        ) : (
          <button disabled={resendingCode} onClick={resendCodeClick}>
            <span className="edit-profile-verify-resend-timer-text">{t('resend-code')}</span>
          </button>
        )}
      </div>
    );
  }, [t, resendingCode, getTimerLabel, props.confirmationCodeTimeoutTimestamp, resendCodeClick]);

  return (
    <BaseModal
      title={props.type === 'phone' ? t('verify-phone') : t('verify-email')}
      visible={props.isVisible}
      onDismiss={props.onDismiss}
      hideCloseButton
    >
      <div className="edit-profile-verification">
        <div className="edit-profile-description">
          <span>
            {props.type === 'phone'
              ? t('verify-phone-description', { phoneNumber: props.updatedKey })
              : t('verify-email-description', { email: props.updatedKey })}
          </span>
        </div>
        <div className="edit-profile-verify-otp-input-container">
          <CommonOtpInput
            onlyNumbers
            code={code}
            onChange={setCode}
            disabled={submitting}
            placeholder="____"
            inputStyles={{ backgroundColor: inputColor, border: 'none' }}
          />
        </div>
        {getResendingCodeTimer()}
        <div className="edit-profile-verify-action-buttons">
          <CommonButton
            size="large"
            type="submit"
            label={t('cancel')}
            className={cx('edit-profile-verify-action-button-cancel')}
            labelClassName={cx('edit-profile-verify-action-button-cancel-label')}
            onClick={props.onDismiss}
            disabled={submitting || appLoading}
          />
          <CommonButton
            size="large"
            type="submit"
            label={t('verify')}
            labelClassName="edit-profile-verify-action-button-label"
            loading={submitting || appLoading}
            disabled={submitting || appLoading || code.length !== 4}
            onClick={onSubmitForm}
          />
        </div>
      </div>
    </BaseModal>
  );
};
