import { createReducer } from 'typesafe-actions';
import {
  finishAppLoading,
  resetStore,
  setApiConfig,
  setAppInitializing,
  setAuthenticated,
  setConnectionWalletModalVisible,
  startAppLoading,
} from './actions';
import { IAppState } from './types';

const initialState: IAppState = {
  loading: false,
  authenticated: false,
  initializing: true,
  connectionWalletModalVisible: false,
  config: null,
};

export const app = createReducer(initialState)
  .handleAction(startAppLoading, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(finishAppLoading, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(setAuthenticated, (state, { payload }) => ({
    ...state,
    authenticated: payload,
  }))
  .handleAction(setAppInitializing, (state, { payload }) => ({
    ...state,
    initializing: payload,
  }))
  .handleAction(setConnectionWalletModalVisible, (state, { payload }) => ({
    ...state,
    connectionWalletModalVisible: payload,
  }))
  .handleAction(setApiConfig, (state, { payload }) => ({
    ...state,
    config: payload,
  }))
  .handleAction(resetStore, (state) => ({
    ...state,
    authenticated: false,
  }));
