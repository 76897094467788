import React from 'react';
import cx from 'classnames';
import './style.scss';
import { ICurrentStakingTableProps } from './types';
import { CurrentStakingItem } from '../current-staking-item';
import { Divider } from '@mui/material';
import { providersIconMap } from '../connect-wallet-modal/use-modal';

export const CurrentStakingTable: React.FC<ICurrentStakingTableProps> = ({
  className,
  externalWalletData,
  data = [],
}) => {
  if (!data?.length) {
    return null;
  }

  return (
    <div className={cx(className)}>
      <Divider className={cx('staking-table-divider')}>
        {!!externalWalletData && (
          <div className={cx('staking-table-row-centered')}>
            <img src={providersIconMap[externalWalletData?.type] ?? ''} alt="wallet" width={16} />
            <span className={cx('staking-table-wallet-name')}>{externalWalletData?.name}</span>
          </div>
        )}
      </Divider>
      {data.map((item, idx, arr) => {
        const isLastItem = idx === arr.length - 1;
        return (
          <CurrentStakingItem
            className={cx('staking-table-item', { 'staking-table-item-margin': !isLastItem })}
            key={`${idx}${item?.secondColumnValue}${item?.thirdColumnValue}`}
            isLongTerm={Boolean(item.isLongTerm)}
            apy={item.secondColumnValue}
            initialAmount={item.thirdColumnValue}
            earnings={item.fourthColumnValue}
            totalAmount={item.fifthColumnValue}
            tokenName={item.type as PaypolitanStakingType} // TODO refactor
            bottomButtons={item.bottomButtons}
            stakingPeriodEnd={item.endsStackingDate ?? ''}
          />
        );
      })}
    </div>
  );
};
