import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useForm } from './use-form';
import {
  CommonButton,
  FormCheckBoxField,
  FormPhoneNumberField,
  FormTextField,
} from '../../../../components';
import './style.scss';
import cx from 'classnames';

export const FullNameStep: React.FC = () => {
  const { t } = useTranslation(['common', 'sign-up']);
  const {
    onSubmitForm,
    initialValues,
    validationSchema,
    appLoading,
    isPhoneNumberVerified,
    lastStep,
  } = useForm();

  return (
    <Formik<ISignUpFullNameFormData>
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <span className={cx('sign-up-main-text', 'sign-up-input-title')}>
            {t('sign-up:first-name')}
          </span>
          <FormTextField<ISignUpFullNameFormData>
            autoFocus
            disabled={isPhoneNumberVerified}
            inputContainerClassName={cx('full-name-and-phone-input')}
            name={'firstName'}
            label={t('sign-up:first-name')}
          />
          <span className={cx('sign-up-main-text', 'sign-up-input-title')}>
            {t('sign-up:last-name')}
          </span>
          <FormTextField<ISignUpFullNameFormData>
            disabled={isPhoneNumberVerified}
            inputContainerClassName={cx('full-name-and-phone-input')}
            name={'lastName'}
            label={t('sign-up:last-name')}
          />
          <span className={cx('sign-up-main-text', 'sign-up-input-title')}>
            {t('sign-up:phone-number')}
          </span>
          <FormPhoneNumberField<ISignUpFullNameFormData>
            name={'phoneNumber'}
            disabled={isPhoneNumberVerified}
            containerClass={cx('full-name-and-phone-input-phone')}
            placeholder={t('sign-up:phone-number-placeholder')}
          />
          <div>
            <FormCheckBoxField<ISignUpFullNameFormData>
              name="areTermsAccepted"
              label={
                <span className={cx('full-name-and-phone-terms-text')}>
                  {`${t('sign-up:i-accept-terms')} `}
                  <a
                    className={cx('full-name-and-phone-link')}
                    href="https://paypolitan.io/terms"
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {t('sign-up:terms')}
                  </a>
                  {` ${t('common:and')} `}
                  <a
                    className={cx('full-name-and-phone-link')}
                    href="https://paypolitan.io/policy"
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {t('sign-up:privacy')}
                  </a>
                </span>
              }
            />
          </div>
          <CommonButton
            fill
            fullWidth
            type="submit"
            size="large"
            label={t(isPhoneNumberVerified || lastStep ? 'sign-up:finish-registration' : 'next')}
            loading={isSubmitting || appLoading}
            className="sign-up-submit-button"
            containerClassName={cx('sign-up-next-button')}
            disabled={isSubmitting || !isValid || appLoading}
          />
        </Form>
      )}
    </Formik>
  );
};
