import * as Yup from 'yup';
import { translate } from '../../api';
import { web3Service } from '../../services/blockchain';

export const getValidationSchema = (shouldValidate: boolean) => {
  if (shouldValidate) {
    return Yup.object({
      wallet: Yup.string()
        .trim()
        .required(translate('required', 'errors'))
        .test('isValid address', translate('invalid-wallet-address', 'errors'), function (value) {
          if (!value) {
            return false;
          }

          return web3Service.isValidAddress(value);
        }),
      walletType: Yup.string().optional(),
    });
  }

  return Yup.object({
    wallet: Yup.string().optional(),
    walletType: Yup.string().optional(),
  });
};
