import { httpService } from '../../../services';
import { protectedApi } from '../decorators';

export const TRANSFER_REQUEST = '/transfer-requests';

class TransferRequestResource {
  @protectedApi()
  async transferRequest(data: ITransferRequestParams) {
    return httpService.post<ITransferRequestResponse>({
      url: TRANSFER_REQUEST,
      data,
    });
  }

  @protectedApi()
  async deleteTransferRequest({ id, data }: IDeleteTransferParams) {
    return httpService.delete<void>({
      url: `${TRANSFER_REQUEST}/${id}`,
      data,
    });
  }
}

export const transferRequestResource = new TransferRequestResource();
