import React, { useCallback } from 'react';
import './style.scss';
import { Checkbox, FormControlLabel, FormHelperText, FormControl } from '@material-ui/core';
import { CheckboxProps, fieldToCheckbox } from 'formik-material-ui';
import { Check } from '@material-ui/icons';
import cx from 'classnames';

export interface IFormFormCheckBoxProps extends CheckboxProps {
  label: string | React.ReactNode;
  labelClassName?: string;
  onChangeState?: (value: boolean) => void;
}

export const CheckBox: React.FC<IFormFormCheckBoxProps> = (props) => {
  const {
    label,
    labelClassName,
    form: { setFieldValue, errors, touched },
    field: { name },
    onChangeState,
  } = props;

  const onChange = useCallback(
    (event) => {
      const { checked } = event.target;
      setFieldValue(name, checked);

      if (onChangeState) onChangeState(checked);
    },
    [setFieldValue, name, onChangeState],
  );

  const error = touched[name] ? errors[name] : undefined;

  return (
    <FormControl error={Boolean(error)}>
      <FormControlLabel
        label={label}
        classes={{ label: cx('checkbox-input-label', labelClassName) }}
        value="center"
        labelPlacement="end"
        control={
          <Checkbox
            disableRipple
            className={'checkbox-input-root'}
            {...fieldToCheckbox(props)}
            checkedIcon={
              <span className="checkbox-input-icon">
                <Check className={'checkbox-input-checkedIcon'} />
              </span>
            }
            color={'primary'}
            icon={<span className="checkbox-input-icon" />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            onChange={onChange}
            aria-describedby="component-error-text"
          />
        }
      />
      {Boolean(error) && (
        <FormHelperText className={'checkbox-input-error'} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
