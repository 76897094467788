import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BaseModal } from '../base-modal';
import { CommonButton } from '../common-button';
import './style.scss';
import { logout } from '../../redux/auth/actions';

export const UnauthenticatedModal = (props: IUnauthenticatedModalParams) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const redirect = () => {
    dispatch(logout());
  };

  return (
    <BaseModal
      visible={props.isOpen}
      onDismiss={() => {
        props.onCloseModal();
        redirect();
      }}
    >
      <div className="unauthenticated-modal-text-container">
        <span className="unauthenticated-modal-text">{t('session-expired')}</span>
      </div>
      <div>
        <CommonButton onClick={redirect} label={t('okay')} />
      </div>
    </BaseModal>
  );
};
