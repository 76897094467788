export const PERCENT_RATES: Record<PaypolitanEarningType, { timestamp: number; rate: number }> = {
  bepanStakingFlexible: {
    rate: 2572270000000000000,
    timestamp: 1626875135 * 1000,
  },
  bepanStakingLong: { rate: 4951440000000000000, timestamp: 1626875135 * 1000 },
  epanStakingFlexible: {
    rate: 2572270000000000000,
    timestamp: 1618897600 * 1000,
  },
  epanStakingLong: { rate: 4951440000000000000, timestamp: 1618897600 * 1000 },
  polvenStakingFlexible: {
    rate: 2586884420913935573,
    timestamp: 1625201003 * 1000,
  },
  polvenStakingLong: {
    rate: 5728480000000000000,
    timestamp: 1625201003 * 1000,
  },
};

export const getPaypolitanEarningType = (
  currency: PaypolitanStakingType,
  isLongTerm: boolean,
): PaypolitanEarningType => {
  switch (currency) {
    case 'EPAN':
      return isLongTerm ? 'epanStakingLong' : 'epanStakingFlexible';
    case 'POLVEN':
      return isLongTerm ? 'polvenStakingLong' : 'polvenStakingFlexible';
    case 'BEPAN':
      return isLongTerm ? 'bepanStakingLong' : 'bepanStakingFlexible';
  }
};
