import { useEarningsBalances } from './use-earnings-balances';
import { useMemo } from 'react';
// import { useWalletConnect } from '@walletconnect/react-native-dapp';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentNetwork, getExternalWalletAddresses } from '../redux/blockchain/selectors';
import { /*getWalletConnectInfo,*/ isEthNetwork } from '../helpers';
import { earningTypeToCurrencyMap } from '../constants';

interface IUseCurrentUserEarningsBalancesParams {
  currency?: EarningCurrencies;
  walletType?: IWalletType;
  selectedWallet?: IWallet;
}

const ethEarningTypes: EthEarningType[] = [
  'epanStakingLong',
  'epanStakingFlexible',
  'polvenStakingLong',
  'polvenStakingFlexible',
];

const bscEarningTypes: BscEarningType[] = ['bepanStakingLong', 'bepanStakingFlexible'];

const getEarnings = (network: Network, address?: string) => {
  if (!address) {
    return [];
  }

  return [...(isEthNetwork(network) ? ethEarningTypes : bscEarningTypes)].map<IEarning>((type) => ({
    address,
    type,
    currency: earningTypeToCurrencyMap[type],
    walletType: 'metamaskExtension',
  }));
};

export const useCurrentUserEarningsBalances = ({
  currency,
  selectedWallet,
  walletType,
}: IUseCurrentUserEarningsBalancesParams = {}) => {
  // const connector = useWalletConnect();
  const network = useSelector(getCurrentNetwork);
  const externalWalletAddresses = useSelector(getExternalWalletAddresses, shallowEqual);

  const earnings = useMemo<IEarning[]>(() => {
    return [
      ...externalWalletAddresses
        .map<IEarning[]>((acc) => {
          return getEarnings(network, acc) /*.map((earning) => ({
              ...earning,
              name: walletConnectInfo.name,
              icon: walletConnectInfo.icon,
              walletType: walletConnectInfo.type,
            }))*/;
        })
        .flat(),
    ]
      .filter((item) => (selectedWallet ? item.address === selectedWallet.address : true))
      .filter((item) => (currency ? item.currency === currency : true))
      .filter((item) => (walletType ? item.walletType === walletType : true));
  }, [
    externalWalletAddresses,
    // connector,
    network,
    selectedWallet,
    currency,
    walletType,
  ]);

  return useEarningsBalances(earnings);
};
