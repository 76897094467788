import { RootStateType } from '../store';

export const getToast = (state: RootStateType) => state.toast;
export const getTransactionProcessToast = (state: RootStateType) => state.toast.transactionProcess;
export const getRedeemSpotAccountProcessToast = (state: RootStateType) =>
  state.toast.redeemSpotAccountProcess;
export const getSpotTopUpProcessToast = (state: RootStateType) => state.toast.spotTopUpProcess;
export const getBridgeSwapProcessToast = (state: RootStateType) => state.toast.bridgeSwapProcess;
export const getCompoundProcessToast = (state: RootStateType) => state.toast.compoundProcess;
export const getEpanStakingProcessToast = (state: RootStateType) => state.toast.epanStakingProcess;
export const getSwappingTokenProcessToast = (state: RootStateType) => state.toast.swappingToken;
export const getCustomToast = (state: RootStateType) => state.toast.custom;
export const getUniswapLiquidityApprovalToast = (state: RootStateType) =>
  state.toast.uniswapLiquidityApprovalProcess;
export const getUniswapLiquiditySupplyingToast = (state: RootStateType) =>
  state.toast.uniswapLiquiditySupplyingProcess;
export const getUniswapRemoveLiquidityToast = (state: RootStateType) =>
  state.toast.uniswapRemoveLiquidityProcess;
export const getRemovingLiquidityApprovalToast = (state: RootStateType) =>
  state.toast.removingLiquidityApprovalProcess;
export const getClubDealSwapApprovalToast = (state: RootStateType) =>
  state.toast.clubDealSwapApprovalProcess;
export const getClubDealSwappingToast = (state: RootStateType) =>
  state.toast.clubDealSwappingProcess;
