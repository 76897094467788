import React from 'react';
import { Field } from 'formik';
import { IFormFormCheckBoxProps, CheckBox } from './CheckBox';

interface IFormTextFieldProps<FormFields> extends Partial<IFormFormCheckBoxProps> {
  name: keyof FormFields;
  onChangeState?: (value: boolean) => void;
}

export const FormCheckBoxField = <FormFields extends object>({
  name,
  label,
  ...rest
}: IFormTextFieldProps<FormFields>) => (
  <Field name={name} label={label} type="checkbox" component={CheckBox} {...rest} />
);
