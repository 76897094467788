import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './style.scss';
import cx from 'classnames';
import EpanTokenIconGreyish from '../../../../assets/images/icons/epan-token-icon-greyish.svg';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { TokenIconRounded } from '../../../../components';
import { isNull } from '../../../../utils/isNull';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getCurrentNetwork } from '../../../../redux/blockchain/selectors';
import { isEthNetwork } from '../../../../helpers';

export const CurrencySelector: React.FC<IStakingCurrencySelectorProps> = ({
  currency,
  disabled,
  onCurrencyChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const network = useSelector(getCurrentNetwork);
  const tokens = useMemo<PaypolitanStakingType[]>(() => {
    return isEthNetwork(network) ? ['EPAN', 'POLVEN'] : ['BEPAN'];
  }, [network]);

  useEffect(() => {
    setAnchorEl(null);
    onCurrencyChange(isEthNetwork(network) ? 'EPAN' : 'BEPAN');
  }, [network, onCurrencyChange]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleChangeCurrency = useCallback(
    (value: PaypolitanStakingType) => () => {
      handleClose();
      onCurrencyChange(value);
    },
    [handleClose, onCurrencyChange],
  );

  return (
    <div>
      <Button
        aria-haspopup="true"
        onClick={handleClick}
        aria-controls="simple-menu"
        disabled={disabled}
      >
        {disabled ? (
          <img src={EpanTokenIconGreyish} alt="EPAN" />
        ) : (
          <TokenIconRounded tokenName={currency} raw />
        )}
        <span
          className={cx('staking-currency-selector-currency', {
            'staking-currency-selector-currency-disabled': disabled,
          })}
        >
          {currency}
        </span>
        <KeyboardArrowDown
          className={cx(
            'staking-currency-selector-arrow',
            `${!isNull(anchorEl) ? 'rotate180' : 'rotate0'}`,
            { 'staking-currency-selector-arrow-disabled': disabled },
          )}
        />
      </Button>
      <Menu
        keepMounted
        id="simple-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {tokens.map((token, idx) => (
          <MenuItem key={`${token}${idx}`} onClick={handleChangeCurrency(token)}>
            <TokenIconRounded tokenName={token} />
            <span className={cx('staking-currency-selector-currency')}>{token}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
