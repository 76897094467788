import { MenuItem } from '@material-ui/core';
import React from 'react';
import cx from 'classnames';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { useConnectionWalletModal, useWalletConnector } from '../../hooks';
import { BaseModal } from '../base-modal';
import { CommonButton } from '../common-button';
import { useModal, providers } from './use-modal';
import { ConnectedWalletModalAppearance } from './components/connected-wallet-modal-appearance';

export const ConnectWalletModal = () => {
  const { t } = useTranslation(['common']);
  const { type, visible, closeModal, handleTypeClick, isChangingProvider, toggleChangingProvider } =
    useConnectionWalletModal();
  const { walletConnected, connectWallet, walletConnecting, connectedAddress } =
    useWalletConnector();
  const {
    connectionHandler,
    externalWallet,
    viewOnEtherscanRef,
    isConnectionButtonDisabled,
    onClipboardCopied,
  } = useModal({
    type,
    connectWallet,
    walletConnected,
    connectedAddress,
    isChangingProvider,
    walletConnecting,
    onCloseModal: closeModal,
  });

  return (
    <BaseModal visible={visible} onDismiss={closeModal} title={t('connect-wallet')}>
      {walletConnected && !isChangingProvider ? (
        <ConnectedWalletModalAppearance
          externalWallet={externalWallet}
          connectedAddress={connectedAddress}
          onClipboardCopied={onClipboardCopied}
          viewOnEtherscanRef={viewOnEtherscanRef}
          toggleChangingProvider={toggleChangingProvider}
        />
      ) : (
        <div className="connection-wallet-modal-providers">
          {providers.map((item, idx, arr) => {
            const isLastItem = idx === arr.length - 1;
            const info = item?.info;
            return (
              <MenuItem
                key={item.type}
                onClick={handleTypeClick(item.type)}
                selected={type === item.type}
                disabled={item.disabled}
                className={cx('connection-wallet-modal-item', {
                  'connection-wallet-modal-item-margin': !isLastItem,
                })}
              >
                <div className="connection-wallet-modal-row">
                  <div className="connection-wallet-modal-icon-rounded">
                    <img src={item.icon} alt="icon" width={14} />
                  </div>
                  <span className={cx('connection-wallet-modal-label')}>{item.label}</span>
                </div>
                {info && <span className={cx('connection-wallet-modal-info')}>{t(info)}</span>}
              </MenuItem>
            );
          })}
        </div>
      )}
      <CommonButton
        disabled={isConnectionButtonDisabled}
        onClick={connectionHandler}
        loading={walletConnecting}
        size="large"
        className="connection-wallet-modal-connect-button"
        containerClassName="connection-wallet-modal-connect-button-container"
        label={t(walletConnected && !isChangingProvider ? 'disconnect' : 'connect')}
      />
    </BaseModal>
  );
};
