import * as Yup from 'yup';
import { translate } from '../../../../api';
import { uniswapTransactionSettingsValidationValues } from '../../../../constants';

export const getValidationSchema = () =>
  Yup.object({
    slippageTolerance: Yup.number()
      .typeError(translate('numbers-only', 'errors'))
      .test('valid', translate('invalid-slippage-tolerance', 'errors'), (value) => {
        return !(
          !value ||
          Number(value) < 0 ||
          Number(value) > uniswapTransactionSettingsValidationValues.slippage.valid
        );
      })
      .optional(),
    deadline: Yup.number()
      .typeError(translate('numbers-only', 'errors'))
      .test('deadlineValid', translate('invalid-deadline', 'errors'), (value) => {
        return !(
          !value ||
          Number(value) < 0 ||
          Number(value) > uniswapTransactionSettingsValidationValues.deadline.max
        );
      })
      .optional(),
  });
