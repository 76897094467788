import React, { useState } from 'react';
import { ReactComponent as ReferFriend } from '../../assets/images/icons/refer-friend.svg';
import { ReactComponent as TermsAndConditions } from '../../assets/images/icons/terms-and-conditions.svg';
import { ReactComponent as HelpAndSupport } from '../../assets/images/icons/help-and-support.svg';
import { ReactComponent as AppSettings } from '../../assets/images/icons/app-settings.svg';
import { ReactComponent as PrivacyPolicy } from '../../assets/images/icons/privacy-policy.svg';
import { ReactComponent as Promo } from '../../assets/images/icons/promo.svg';
import { ReactComponent as Security } from '../../assets/images/icons/security.svg';
import Telegram from '../../assets/images/icons/telegram.svg';
import Twitter from '../../assets/images/icons/twitter.svg';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { Privacy, Terms } from './components';

const CommunityIcon = () => {
  return (
    <div className="settings-block-community-icon">
      <img className="settings-block-community-icon-telegram" src={Telegram} />
      <img className="settings-block-community-icon-twitter" src={Twitter} />
    </div>
  );
};

export const useBlocks = (): [
  Array<{ action: (() => void) | null; text: string; icon: any }>,
  JSX.Element,
] => {
  const { t } = useTranslation(['settings']);
  const [visibleModal, setVisibleModal] = useState<'terms' | 'privacy' | null>(null);
  const blocks = [
    {
      action: null,
      text: t('app-settings'),
      icon: AppSettings,
    },
    {
      action: null,
      text: t('security'),
      icon: Security,
    },
    {
      action: null,
      text: t('promo-code'),
      icon: Promo,
    },
    {
      action: null,
      text: t('help-and-support'),
      icon: HelpAndSupport,
    },
    {
      action: () => setVisibleModal('terms'),
      text: t('terms-and-conditions'),
      icon: TermsAndConditions,
    },
    {
      action: () => setVisibleModal('privacy'),
      text: t('privacy-policy'),
      icon: PrivacyPolicy,
    },
    {
      action: null,
      text: t('refer-a-friend'),
      icon: ReferFriend,
    },
    {
      action: null,
      text: t('join-the-community'),
      icon: CommunityIcon,
    },
  ];

  const ActionProvider = (
    <React.Fragment>
      <Terms isVisible={visibleModal === 'terms'} onDismiss={() => setVisibleModal(null)} />
      <Privacy isVisible={visibleModal === 'privacy'} onDismiss={() => setVisibleModal(null)} />
    </React.Fragment>
  );

  return [blocks, ActionProvider];
};
