import React from 'react';
import './style.scss';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { ILoaderProps } from './types';
import cx from 'classnames';

export const DefaultLoader = (props: ILoaderProps) => {
  return (
    <div className={cx('default-loader-container', props.containerClass)}>
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: '#E9E9EB',
          }}
          size={70}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: '#CF46B9',
            animationDuration: '650ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={70}
          thickness={4}
        />
      </Box>
    </div>
  );
};
