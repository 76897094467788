import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, CommonButton, FormTextField } from '../../../../components';
import cx from 'classnames';
import './style.scss';
import { Form, Formik } from 'formik';
import { useForm } from './use-form';

export const SettingsModal = ({
  onSubmit,
  initialValues,
  closeModal,
  isVisible,
}: ITopUpSpotAccountSettingsModalData & {
  isVisible: boolean;
  closeModal: () => void;
}) => {
  const { t } = useTranslation(['spot-account', 'common']);
  const { formRef, onSubmitForm, validationSchema } = useForm({ onSubmit, closeModal });
  return (
    <BaseModal onDismiss={closeModal} title={t('transaction-settings')} visible={isVisible}>
      <div className="spot-account-top-up-modal">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitForm}
        >
          {({ setFieldValue, errors }) => (
            <Form>
              <div>
                <div className="top-up-spot-account-input-container top-up-spot-account-settings-input-wrapper">
                  <div className="top-up-spot-account-input-wrapper">
                    <span className={cx('ccb-secondary-text', 'top-up-spot-account-input-label')}>
                      {t('slippage-tolerance')}
                    </span>
                    <FormTextField<ITopUpSpotAccountSettingsData>
                      className={cx('ccb-input')}
                      placeholder={'0.1'}
                      name="slippageTolerance"
                      rightComponent={<span>%</span>}
                    />
                  </div>
                  <CommonButton
                    className="top-up-spot-account-settings-auto-button"
                    labelClassName="top-up-spot-account-settings-auto-button-label"
                    label={t('auto', { ns: 'common' })}
                    onClick={() => {
                      setFieldValue('slippageTolerance', 0.1);
                    }}
                  />
                </div>
                <div className="top-up-spot-account-input-wrapper">
                  <span className={cx('ccb-secondary-text', 'top-up-spot-account-input-label')}>
                    {t('transaction-deadline')}
                  </span>
                  <FormTextField<ITopUpSpotAccountSettingsData>
                    className={cx('ccb-input')}
                    placeholder={'20'}
                    name="deadline"
                    rightComponent={<span>{t('minutes', { ns: 'common' })}</span>}
                  />
                </div>
                <div className={cx('redeem-spot-account-action-buttons')}>
                  <CommonButton
                    containerClassName={cx('redeem-spot-account-action-button-cancel-container')}
                    labelClassName={cx(
                      'redeem-spot-account-action-button-label',
                      'redeem-spot-account-action-button-cancel-label',
                    )}
                    className={cx(
                      'redeem-spot-account-action-button',
                      'redeem-spot-account-action-button-cancel',
                    )}
                    label={t('cancel', { ns: 'common' })}
                    onClick={closeModal}
                  />
                  <CommonButton
                    labelClassName="redeem-spot-account-action-button-label"
                    className="redeem-spot-account-action-button"
                    label={t('save', { ns: 'common' })}
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BaseModal>
  );
};
