import { createAction } from 'typesafe-actions';

export const GET_WALLETS_BALANCES_BC_ERROR = '@BLOCKCHAIN/GET_WALLETS_BALANCES_BC_ERROR';
export const WALLET_CONNECT_BC_ERROR = '@BLOCKCHAIN/WALLET_CONNECT_BC_ERROR';
export const SEND_TRANSACTION_BC_ERROR = '@BLOCKCHAIN/SEND_TRANSACTION_BC_ERROR';
export const SET_EXTERNAL_WALLET_DATA = '@BLOCKCHAIN/SET_EXTERNAL_WALLET_DATA';
export const UPDATE_EXTERNAL_WALLET_DATA = '@BLOCKCHAIN/UPDATE_EXTERNAL_WALLET_DATA';
export const SET_TRY_AUTO_CONNECT_WALLET = '@BLOCKCHAIN/SET_TRY_AUTO_CONNECT_WALLET';
export const GET_EARNINGS_BALANCES_BC_ERROR = '@BLOCKCHAIN/GET_EARNINGS_BALANCES_BC_ERROR';
export const GET_ESTIMATED_GAS_BC_ERROR = '@BLOCKCHAIN/GET_ESTIMATED_GAS_BC_ERROR';
export const GET_BRIDGE_SWAP_CONTRACT_FEE_BC_ERROR =
  '@BLOCKCHAIN/GET_BRIDGE_SWAP_CONTRACT_FEE_BC_ERROR';
export const GET_COMPOUND_SUPPLY_APY_BC_ERROR = '@BLOCKCHAIN/GET_COMPOUND_SUPPLY_APY_BC_ERROR';
export const GET_EPAN_STAKING_APY_BC_ERROR = '@BLOCKCHAIN/GET_EPAN_STAKING_APY_BC_ERROR';
export const GET_EPAN_STAKING_ALLOWANCE_BC_ERROR =
  '@BLOCKCHAIN/GET_EPAN_STAKING_ALLOWANCE_BC_ERROR';
export const GET_BRIDGE_SWAP_ALLOWANCE_BC_ERROR = '@BLOCKCHAIN/GET_BRIDGE_SWAP_ALLOWANCE_BC_ERROR';
export const GET_POLVEN_STAKING_APY_BC_ERROR = '@BLOCKCHAIN/GET_POLVEN_STAKING_APY_BC_ERROR';
export const GET_POLVEN_STAKING_ALLOWANCE_BC_ERROR =
  '@BLOCKCHAIN/GET_POLVEN_STAKING_ALLOWANCE_BC_ERROR';
export const GET_POLVEN_STAKING_BALANCE_ERROR = '@BLOCKCHAIN/GET_POLVEN_STAKING_BALANCE_ERROR';

export const MINT_COMPOUND_BC_ERROR = '@BLOCKCHAIN/MINT_COMPOUND_BC_ERROR';
export const REDEEM_UNDERLINE_COMPOUND_BC_ERROR = '@BLOCKCHAIN/REDEEM_UNDERLINE_COMPOUND_BC_ERROR';

export const CONFIGURE_CURRENT_CHAIN_ID = '@BLOCKCHAIN/CONFIGURE_CURRENT_CHAIN_ID';
export const SET_CURRENT_CHAIN_ID = '@BLOCKCHAIN/SET_CURRENT_CHAIN_ID';
export const SUPPORTED_CHAIN = '@BLOCKCHAIN/SUPPORTED_CHAIN';
export const NOT_SUPPORTED_CHAIN = '@BLOCKCHAIN/NOT_SUPPORTED_CHAIN';
export const SET_CURRENT_CHAIN_ID_BC_ERROR = '@BLOCKCHAIN/SET_CURRENT_CHAIN_ID_BC_ERROR';
export const SET_CURRENT_CHAIN_ID_LOADING = '@BLOCKCHAIN/SET_CURRENT_CHAIN_ID_LOADING';

export const DELETE_CUSTOM_ERC20_TOKEN = '@USERS/DELETE_CUSTOM_ERC20_TOKEN';

export const STAKE_EPAN_BC_ERROR = '@BLOCKCHAIN/STAKE_EPAN_BC_ERROR';
export const WITHDRAW_EPAN_BC_ERROR = '@BLOCKCHAIN/WITHDRAW_EPAN_BC_ERROR';

export const BRIDGE_SWAP_BC_ERROR = '@BLOCKCHAIN/BRIDGE_SWAP_BC_ERROR';

export const UPDATE_ERC20_TOKENS = '@BLOCKCHAIN/UPDATE_ERC20_TOKENS';
export const UPDATE_CUSTOM_ERC20_TOKENS = '@BLOCKCHAIN/UPDATE_CUSTOM_ERC20_TOKENS';
export const SET_CUSTOM_ERC20_TOKENS = '@BLOCKCHAIN/SET_CUSTOM_ERC20_TOKENS';

export const TOGGLE_NETWORK_SELECTOR = '@TOAST/TOGGLE_NETWORK_SELECTOR';
export const TOGGLE_CUSTOM_TOKENS_MODAL = '@TOAST/TOGGLE_CUSTOM_TOKENS_MODAL';

export const SET_ACTIVE_TOKEN_LIST = '@BLOCKCHAIN/SET_ACTIVE_TOKEN_LIST';
export const REMOVE_ACTIVE_TOKEN_LIST = '@BLOCKCHAIN/REMOVE_ACTIVE_TOKEN_LIST';

export const SET_SWAPPING_CUSTOM_TOKEN = '@BLOCKCHAIN/SET_SWAPPING_CUSTOM_TOKEN';
export const REMOVE_SWAPPING_CUSTOM_TOKEN = '@BLOCKCHAIN/REMOVE_SWAPPING_CUSTOM_TOKEN';

export const UNISWAP_POOLS_ERROR = '@BLOCKCHAIN/UNISWAP_POOLS_FAILURE';
export const UNISWAP_TOKEN_PAIR_DATA_ERROR = '@BLOCKCHAIN/UNISWAP_TOKEN_PAIR_DATA_ERROR';
export const UNISWAP_REMOVE_LIQUIDITY_ERROR = '@BLOCKCHAIN/UNISWAP_REMOVE_LIQUIDITY_ERROR';

export const POLVEN_GOVERNANCE_ERROR = '@BLOCKCHAIN/POLVEN_GOVERNANCE_ERROR';

export const SET_WALLET_CONNECT_QR_CODE_URL = '@BLOCKCHAIN/SET_WALLET_CONNECT_QR_CODE_URL';
export const RESET_WALLET_CONNECT_QR_CODE_URL = '@BLOCKCHAIN/RESET_WALLET_CONNECT_QR_CODE_URL';
export const SET_WALLET_CONNECT_QR_MODAL_ERROR = '@BLOCKCHAIN/SET_WALLET_CONNECT_QR_MODAL_ERROR';
export const RESET_WALLET_CONNECT_QR_MODAL_ERROR =
  '@BLOCKCHAIN/RESET_WALLET_CONNECT_QR_MODAL_ERROR';

export const getWalletsBalancesBlockchainError = createAction(
  GET_WALLETS_BALANCES_BC_ERROR,
)<Error>();
export const walletConnectBlockchainError = createAction(WALLET_CONNECT_BC_ERROR)<Error>();
export const sendTransactionBlockchainError = createAction(SEND_TRANSACTION_BC_ERROR)<Error>();
export const setExternalWalletData = createAction(SET_EXTERNAL_WALLET_DATA)<IExternalWalletData>();
export const updateExternalWalletData = createAction(UPDATE_EXTERNAL_WALLET_DATA)<
  Partial<IExternalWalletData>
>();
export const setTryAutoConnectWallet = createAction(SET_TRY_AUTO_CONNECT_WALLET)<boolean>();
export const getEarningsBalancesBlockchainError = createAction(
  GET_EARNINGS_BALANCES_BC_ERROR,
)<Error>();
export const getEstimatedGasBlockchainError = createAction(GET_ESTIMATED_GAS_BC_ERROR)<Error>();
export const getBridgeSwapContractFeeBlockchainError = createAction(
  GET_BRIDGE_SWAP_CONTRACT_FEE_BC_ERROR,
)<Error>();
export const getCompoundSupplyApyBlockchainError = createAction(
  GET_COMPOUND_SUPPLY_APY_BC_ERROR,
)<Error>();
export const getEpanStakingApyBlockchainError = createAction(
  GET_EPAN_STAKING_APY_BC_ERROR,
)<Error>();
export const getEpanStakingAllowanceBlockchainError = createAction(
  GET_EPAN_STAKING_ALLOWANCE_BC_ERROR,
)<Error>();
export const getBridgeSwapAllowanceBlockchainError = createAction(
  GET_BRIDGE_SWAP_ALLOWANCE_BC_ERROR,
)<Error>();
export const getPolvenStakingApyBlockchainError = createAction(
  GET_POLVEN_STAKING_APY_BC_ERROR,
)<Error>();
export const getPolvenStakingAllowanceBlockchainError = createAction(
  GET_POLVEN_STAKING_ALLOWANCE_BC_ERROR,
)<Error>();

export const deleteCustomErc20Token = createAction(DELETE_CUSTOM_ERC20_TOKEN)<{ id: string }>();

export const mintCompoundBlockchainError = createAction(MINT_COMPOUND_BC_ERROR)<Error>();
export const redeemUnderlineCompoundBlockchainError = createAction(
  REDEEM_UNDERLINE_COMPOUND_BC_ERROR,
)<Error>();

export const stakeEpanBlockchainError = createAction(STAKE_EPAN_BC_ERROR)<Error>();
export const withdrawEpanBlockchainError = createAction(WITHDRAW_EPAN_BC_ERROR)<Error>();

export const bridgeSwapBlockchainError = createAction(BRIDGE_SWAP_BC_ERROR)<Error>();

export const configureCurrentChainId = createAction(
  CONFIGURE_CURRENT_CHAIN_ID,
)<IConfigureCurrentChainIdActionParams>();
export const setCurrentChainId = createAction(SET_CURRENT_CHAIN_ID)<SupportedChainId>();
export const supportedChain = createAction(SUPPORTED_CHAIN)();
export const notSupportedChain = createAction(NOT_SUPPORTED_CHAIN)();
export const setCurrentChainIdBlockchainError = createAction(
  SET_CURRENT_CHAIN_ID_BC_ERROR,
)<Error>();
export const setCurrentChainIdLoading = createAction(SET_CURRENT_CHAIN_ID_LOADING)<boolean>();
export const updateErc20Tokens = createAction(UPDATE_ERC20_TOKENS)<IApiErc20Token[]>();
export const updateCustomErc20Tokens = createAction(UPDATE_CUSTOM_ERC20_TOKENS)<ICustomToken[]>();
export const setCustomErc20Tokens = createAction(SET_CUSTOM_ERC20_TOKENS)<ICustomToken[]>();

export const toggleNetworkSelector = createAction(TOGGLE_NETWORK_SELECTOR)();
export const toggleCustomTokensModal = createAction(TOGGLE_CUSTOM_TOKENS_MODAL)();

export const setActiveTokenList = createAction(SET_ACTIVE_TOKEN_LIST)<string | string[]>();
export const removeActiveTokenList = createAction(REMOVE_ACTIVE_TOKEN_LIST)<string>();

export const uniswapPoolsFailure = createAction(UNISWAP_POOLS_ERROR)<Error>();
export const uniswapTokenPairDataError = createAction(UNISWAP_TOKEN_PAIR_DATA_ERROR)<Error>();
export const uniswapRemoveLiquidityError = createAction(UNISWAP_REMOVE_LIQUIDITY_ERROR)<Error>();

export const getPolvenStakingBalanceError = createAction(GET_POLVEN_STAKING_BALANCE_ERROR)<Error>();
export const polvenGovernanceError = createAction(POLVEN_GOVERNANCE_ERROR)<Error>();

export const setWalletConnectQrCodeUrl = createAction(SET_WALLET_CONNECT_QR_CODE_URL)<string>();
export const resetWalletConnectQrCodeUrl = createAction(RESET_WALLET_CONNECT_QR_CODE_URL)();
export const setWalletConnectQrModalError = createAction(
  SET_WALLET_CONNECT_QR_MODAL_ERROR,
)<string>();
export const resetWalletConnectQrModalError = createAction(RESET_WALLET_CONNECT_QR_MODAL_ERROR)();

export const blockchainActions = {
  getWalletsBalancesBlockchainError,
  walletConnectBlockchainError,
  sendTransactionBlockchainError,
  deleteCustomErc20Token,
  getEarningsBalancesBlockchainError,
  getCompoundSupplyApyBlockchainError,
  mintCompoundBlockchainError,
  redeemUnderlineCompoundBlockchainError,
  getEstimatedGasBlockchainError,
  stakeEpanBlockchainError,
  withdrawEpanBlockchainError,
  setCurrentChainId,
  updateErc20Tokens,
  updateCustomErc20Tokens,
  getEpanStakingApyBlockchainError,
  getPolvenStakingApyBlockchainError,
  setCurrentChainIdBlockchainError,
  setCurrentChainIdLoading,
  configureCurrentChainId,
  setCustomErc20Tokens,
  getEpanStakingAllowanceBlockchainError,
  getPolvenStakingAllowanceBlockchainError,
  toggleNetworkSelector,
  toggleCustomTokensModal,
  setActiveTokenList,
  removeActiveTokenList,
  getBridgeSwapContractFeeBlockchainError,
  bridgeSwapBlockchainError,
  getBridgeSwapAllowanceBlockchainError,
  setExternalWalletData,
  uniswapPoolsFailure,
  uniswapTokenPairDataError,
  uniswapRemoveLiquidityError,
  getPolvenStakingBalanceError,
  polvenGovernanceError,
  updateExternalWalletData,
  supportedChain,
  notSupportedChain,
  setTryAutoConnectWallet,
  setWalletConnectQrCodeUrl,
  resetWalletConnectQrCodeUrl,
  setWalletConnectQrModalError,
  resetWalletConnectQrModalError,
} as const;
