import { httpService } from '../../../services';
import { protectedApi } from '../decorators';

const NOTIFICATIONS = '/notifications';

class NotificationsResource {
  notificationsCacheKey = 'notificationsCacheKey';
  notificationsCountCacheKey = 'notificationsCountCacheKey';

  @protectedApi()
  async getNotifications(params?: IGetNotificationsParams) {
    return httpService.get<IGetNotificationsResponse>({
      url: NOTIFICATIONS,
      params,
    });
  }
}

export const notificationsResource = new NotificationsResource();
