import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Switch, Route, useLocation } from 'react-router-dom';
import { __DEV__, routes } from '../constants';
import {
  CrossChainBridgePage,
  DashboardPage,
  NotFoundPage,
  StakingPage,
  // TransactionHistoryPage,
  SettingsPage,
  // SpotAccountPage,
  RedeemSpotAccountPage,
  TopUpSpotAccountPage,
  SmartCreditPage,
} from '../pages';
import { DrawerComponent } from '../components/drawer';
import { web3Service } from '../services/blockchain';
import { useDispatch, useSelector } from 'react-redux';
import { configureCurrentChainId, updateExternalWalletData } from '../redux/blockchain/actions';
import { populateCurrentUserData } from '../redux/users/actions';
import { DashboardHeader } from '../components/dashboard-header';
import { ConnectWalletModal, CustomWalletConnectQrModal } from '../components';
import { getTryAutoConnectWallet } from '../redux/blockchain/selectors';
import { walletTypeToName } from '../hooks';
import { asyncStorageService } from '../services/local-storage.service';

export const AppRouter: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const tryAutoConnectWallet = useSelector(getTryAutoConnectWallet);

  useEffect(() => {
    dispatch(populateCurrentUserData());
  }, [dispatch]);

  useEffect(() => {
    const onAccountsChanged: IEthProviderSubscriptionParams['onAccountsChanged'] = (accounts) => {
      dispatch(updateExternalWalletData({ addresses: accounts ?? [] }));
    };
    const onChainChanged: IEthProviderSubscriptionParams['onChainChanged'] = (chainId) => {
      dispatch(updateExternalWalletData({ chainId }));
      dispatch(configureCurrentChainId({ chainId }));
    };
    const onDisconnected: IEthProviderSubscriptionParams['onDisconnected'] = async () => {
      __DEV__ && console.log('-------- disconnected');
      await asyncStorageService.removeItem('latestWalletType');
      dispatch(updateExternalWalletData({ addresses: [] }));
    };
    const onConnected: IEthProviderSubscriptionParams['onConnected'] = (info) => {
      __DEV__ && console.info('-------- connected', info);
    };

    web3Service.subscribeToEvents({
      onAccountsChanged,
      onChainChanged,
      onDisconnected,
      onConnected,
    });

    return () =>
      web3Service.unSubscribeFromEvents({
        onAccountsChanged,
        onChainChanged,
        onDisconnected,
        onConnected,
      });
  }, [dispatch]);

  useEffect(() => {
    if (tryAutoConnectWallet) {
      web3Service.tryToGetConnectedData({
        onAccountsChanged: (accounts) => {
          dispatch(updateExternalWalletData({ addresses: accounts ?? [] }));
        },
        onChainChanged: (chainId) => {
          dispatch(updateExternalWalletData({ chainId }));
          dispatch(configureCurrentChainId({ chainId }));
        },
        onDisconnected: async () => {
          await asyncStorageService.removeItem('latestWalletType');
          dispatch(updateExternalWalletData({ addresses: [] }));
        },
        onConnected: (_, walletName, walletType) => {
          if (walletType) {
            dispatch(
              updateExternalWalletData({
                type: walletType ?? 'metamaskExtension',
                name:
                  ['walletConnect', 'paypolitan'].includes(walletType) && walletName
                    ? walletName
                    : walletTypeToName[walletType ?? 'metamaskExtension'],
              }),
            );
          }
        },
      });
    }
  }, [dispatch, tryAutoConnectWallet]);

  const pagesWithDashboardHeader = [
    routes.DASHBOARD,
    routes.STAKING,
    routes.CROSS_CHAIN_BRIDGE,
    routes.SPOT_ACCOUNT,
    routes.REDEEM_SPOT_ACCOUNT,
    routes.TRANSACTION_HISTORY,
    routes.TOP_UP_SPOT_ACCOUNT,
    routes.SMART_CREDIT,
  ];

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <DrawerComponent />
      <ConnectWalletModal />
      <CustomWalletConnectQrModal />
      <div className="page-wrapper">
        <div className="page-wrapper-content">
          {pagesWithDashboardHeader.includes(
            location.pathname[location.pathname.length - 1] === '/'
              ? location.pathname.slice(0, location.pathname.length - 1)
              : (location.pathname as any),
          ) && <DashboardHeader />}
          <Switch>
            <Route exact path={routes.DASHBOARD} component={DashboardPage} />
            <Route exact path={routes.STAKING} component={StakingPage} />
            <Route exact path={routes.CROSS_CHAIN_BRIDGE} component={CrossChainBridgePage} />
            {/*<Route exact path={routes.SPOT_ACCOUNT} component={SpotAccountPage} />*/}
            {/*<Route exact path={routes.TRANSACTION_HISTORY} component={TransactionHistoryPage} />*/}
            <Route exact path={routes.SETTINGS} component={SettingsPage} />
            <Route exact path={routes.REDEEM_SPOT_ACCOUNT} component={RedeemSpotAccountPage} />
            <Route exact path={routes.TOP_UP_SPOT_ACCOUNT} component={TopUpSpotAccountPage} />
            <Route exact path={routes.SMART_CREDIT} component={SmartCreditPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    </Box>
  );
};
