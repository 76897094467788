import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from './redux';
import { historyService, reactQueryService, snackbarService } from './services';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';

const store = configureStore();

ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <QueryClientProvider client={reactQueryService.queryClient}>
        <Router history={historyService.history}>
          <SnackbarProvider
            maxSnack={snackbarService.maxSnack}
            ref={snackbarService.assignRef}
            preventDuplicate
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </SnackbarProvider>
        </Router>
      </QueryClientProvider>
    </Provider>
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
