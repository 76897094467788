import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import './style.scss';
import { transactionsResource } from '../../api';
import { isEthNetwork } from '../../helpers';
import { DismissAction } from './DismissAction';
import { useSelector } from 'react-redux';
import { getCurrentNetwork } from '../../redux/blockchain/selectors';

interface IPendingTransactionActionProps {
  snackbarKey: string;
  txHash: string;
}

export const PendingTransactionAction: React.FC<IPendingTransactionActionProps> = ({
  snackbarKey,
  txHash,
}) => {
  const { t } = useTranslation('common');
  const network = useSelector(getCurrentNetwork);

  return (
    <div className={'snackbar-actions-pending-transaction-container'}>
      <CircularProgress size={'1rem'} className={'snackbar-actions-spinner'} />
      <a
        className={'snackbar-actions-explorer-link'}
        target={'_blank'}
        rel={'noreferrer'}
        href={transactionsResource.getBlockExplorerUrl({ value: txHash, network, type: 'txHash' })}
      >
        {t(
          isEthNetwork(network)
            ? 'transactions-history:view-on-eth-scan'
            : 'transactions-history:view-on-bsc-scan',
        )}
      </a>
      <DismissAction snackbarKey={snackbarKey} />
    </div>
  );
};
