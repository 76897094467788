import React from 'react';

export const EpanGraph: React.FC = () => {
  return (
    //@ts-ignore
    <coingecko-coin-price-chart-widget
      coin-id="paypolitan-token"
      currency="usd"
      height="300"
      locale="en"
      background-color="#f6f8fa"
    >
      {/*@ts-ignore*/}
    </coingecko-coin-price-chart-widget>
  );
};
