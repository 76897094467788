import {
  CLIENT_ERROR,
  SERVER_ERROR,
  CONNECTION_ERROR,
  CANCEL_ERROR,
  NETWORK_ERROR,
  TIMEOUT_ERROR,
  UNKNOWN_ERROR,
} from 'apisauce';

export class HttpError extends Error {
  name = 'HttpError';
}

export class HttpClientError extends HttpError {
  name: string = CLIENT_ERROR;
}

export class HttpClientValidationError extends HttpClientError {
  name = '400 - client validation';
}

export class HttpClientUnauthorizedError extends HttpClientError {
  name = '401 - client Unauthorized';
}

export class HttpServerError extends HttpError {
  name = SERVER_ERROR;
}

export class HttpConnectionError extends HttpError {
  name = CONNECTION_ERROR;
}

export class HttpNetworkError extends HttpError {
  name = NETWORK_ERROR;
}

export class HttpTimeoutError extends HttpError {
  name = TIMEOUT_ERROR;
}

export class HttpCancelError extends HttpError {
  name = CANCEL_ERROR;
}

export class HttpUnknownError extends HttpError {
  name = UNKNOWN_ERROR;
}
