import i18n, { StringMap } from 'i18next';

export const resources = {
  en: require('../assets/translations/en.json'),
} as const;

export const translate = (
  key: string,
  ns: keyof typeof resources.en & string = 'common',
  options: StringMap = {},
) => i18n.t(key, { ns, ...options });

export const changeLanguageI18n = async (language: AvailableLanguageTags) => {
  await i18n.changeLanguage(language);
};
