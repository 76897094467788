import { FormikProps } from 'formik';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getValidationSchema } from './validation-schema';

export const useForm = ({
  onSubmit,
  closeModal,
}: {
  onSubmit: (values: ITopUpSpotAccountSettingsData) => void;
  closeModal: () => void;
}) => {
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const formRef = useRef<FormikProps<ITopUpSpotAccountSettingsData> | null>(null);
  const { t } = useTranslation(['spot-account']);
  const onSubmitForm = useCallback(
    (values: ITopUpSpotAccountSettingsData) => {
      onSubmit(values);
      closeModal();
    },
    [onSubmit, closeModal],
  );

  return {
    onSubmitForm,
    formRef,
    validationSchema,
  };
};
