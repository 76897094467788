import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { web3Service } from '../services/blockchain';
import {
  configureCurrentChainId,
  updateExternalWalletData,
  walletConnectBlockchainError,
} from '../redux/blockchain/actions';
import {
  getChainIdSupported,
  getExternalWalletAddresses,
  getExternalWalletChainId,
  getExternalWalletType,
} from '../redux/blockchain/selectors';
import { __DEV__, supportedChainIds } from '../constants';
import { useConnectionWalletModal } from './use-connection-wallet-modal';
import { asyncStorageService } from '../services/local-storage.service';

export const walletTypeToName: Record<IWalletType, string> = {
  coinbase: 'Coinbase',
  metamaskExtension: 'Metamask',
  walletConnect: 'WalletConnect',
  paypolitan: 'Paypolitan',
};

export const useWalletConnector = () => {
  const [walletConnecting, setWalletConnecting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const chainId = useSelector(getExternalWalletChainId) as SupportedChainId;
  const { closeModal: closeConnectionWalletModal } = useConnectionWalletModal();
  const addresses: string[] = useSelector(getExternalWalletAddresses, shallowEqual);
  const chainIdSupported = useSelector(getChainIdSupported);
  const externalWalletType = useSelector(getExternalWalletType);
  const isChainSupported = supportedChainIds.includes(chainId);

  const walletConnected = Boolean(addresses?.length);
  const connectedAddress: Maybe<string> = addresses?.length ? addresses[0] : null;

  const walletEnabled = walletConnected && chainIdSupported;

  const onAccountsChanged: IEthProviderSubscriptionParams['onAccountsChanged'] = useCallback(
    (accounts) => {
      dispatch(updateExternalWalletData({ addresses: accounts ?? [] }));
    },
    [dispatch],
  );

  const onDisconnected: IEthProviderSubscriptionParams['onDisconnected'] = useCallback(async () => {
    __DEV__ && console.info('-------- disconnected');
    await asyncStorageService.removeItem('latestWalletType');
    dispatch(updateExternalWalletData({ addresses: [] }));
  }, [dispatch]);

  const onChainChanged: IEthProviderSubscriptionParams['onChainChanged'] = useCallback(
    (chainId) => {
      dispatch(updateExternalWalletData({ chainId }));
      dispatch(configureCurrentChainId({ chainId }));
      closeConnectionWalletModal();
    },
    [dispatch, closeConnectionWalletModal],
  );

  const initProvider = useCallback(
    async (walletType: IWalletType) => {
      await web3Service.initProvider({
        walletType,
        onAccountsChanged,
        onChainChanged,
        onDisconnected,
        onConnected: (info, walletName?: string) => {
          dispatch(
            updateExternalWalletData({
              type: walletType,
              name:
                ['walletConnect', 'paypolitan'].includes(walletType) && walletName
                  ? walletName
                  : walletTypeToName[walletType],
            }),
          );
          closeConnectionWalletModal();
          __DEV__ && console.info('-------connected', info);
        },
      });
    },
    [dispatch, onAccountsChanged, onChainChanged, onDisconnected, closeConnectionWalletModal],
  );

  const connectWallet = useCallback(
    async (walletType: IWalletType) => {
      try {
        switch (walletType) {
          case 'metamaskExtension':
          case 'coinbase':
            setWalletConnecting(true);
            await initProvider(walletType);
            break;
          case 'walletConnect':
          case 'paypolitan':
            await initProvider(walletType);
            break;
          default:
            break;
        }
      } catch (e) {
        dispatch(walletConnectBlockchainError(e as Error));
      } finally {
        setWalletConnecting(false);
      }
    },
    [initProvider, dispatch],
  );

  return {
    connectWallet,
    walletConnecting,
    walletConnected,
    connectedAddress,
    chainIdSupported,
    walletEnabled,
    externalWalletType,
    isChainSupported,
  };
};
