import { ISpotAccountInvestmentsItem } from './components/spot-account-investments/types';
import { __DEV__ } from '../../constants';
import { isSpotInvestmentRedeemable, roundToDecimals } from '../../helpers';

interface ICalculateTotalBalanceParams {
  walletsBalances: IWalletWithBalance[];
  earningsBalances: IEarningWithBalance[];
  currenciesQuotes?: IFetchCurrenciesQuotesResponse;
  inCurrency?: SupportedTotalBalanceCurrency;
  totalBalanceCurrencySign: string;
  spotAccountDetails: IGetSpotAccountDetailsResponse | undefined;
}

interface ICalculateSpotInvestments {
  currenciesQuotes?: IFetchCurrenciesQuotesResponse;
  fiatCurrency: TSupportedFiatCurrencies;
  spotAccountDetails: IGetSpotAccountDetailsResponse;
}

export const calculateTotalBalance = ({
  // earningsBalances,
  walletsBalances,
  currenciesQuotes,
  inCurrency = 'USD',
  spotAccountDetails,
  totalBalanceCurrencySign = '$',
}: ICalculateTotalBalanceParams) => {
  if (!currenciesQuotes) {
    return {
      totalBalance: 0,
      totalBalanceCurrencySign,
    };
  }

  try {
    const walletsTotalBalance = walletsBalances.reduce((acc, curr) => {
      const balance = Number(curr.balance ?? 0);
      const currencyRate = currenciesQuotes.quotes[curr.currency]
        ? currenciesQuotes.quotes[curr.currency][inCurrency]?.price ?? 0
        : 0;

      if (!Number.isNaN(balance) && currencyRate) {
        return acc + balance * currencyRate;
      } else {
        return acc;
      }
    }, 0);

    const spotAccountBalance =
      (spotAccountDetails?.balance ?? 0) *
      (currenciesQuotes.quotes.EPAN ? currenciesQuotes.quotes.EPAN[inCurrency]?.price ?? 0 : 0);

    // const earningsTotalBalance = earningsBalances.reduce((acc, curr) => {
    //   const balance = Number(curr.balance ?? 0);
    //   const currencyRate =
    //     curr.currency !== 'DAI' ? currenciesQuotes.quotes[curr.currency][inCurrency] : 0; //TODO temp until currency supported
    //
    //   if (!Number.isNaN(balance) && currencyRate) {
    //     return acc + balance * currencyRate;
    //   } else {
    //     return acc;
    //   }
    // }, 0);

    const totalBalance = walletsTotalBalance + spotAccountBalance;

    return {
      totalBalance,
      totalBalanceCurrencySign,
    };
  } catch (e) {
    __DEV__ && console.log('-------- e', e);
    return {
      totalBalance: 0,
      totalBalanceCurrencySign,
    };
  }
};

export const calculateSpotInvestments = ({
  spotAccountDetails,
  currenciesQuotes,
  fiatCurrency,
}: ICalculateSpotInvestments) =>
  spotAccountDetails.investments?.map<ISpotAccountInvestmentsItem>((spot) => {
    const quote = currenciesQuotes?.quotes[spot?.currency];
    const valueInFiat = roundToDecimals((quote?.[fiatCurrency].price ?? 0) * (spot.value ?? 0));
    return {
      ...spot,
      isRedeemable: isSpotInvestmentRedeemable(spot.redeemableDate),
      fiatEquivalent: quote ? `${valueInFiat} ${fiatCurrency}` : '',
    };
  });
