import { SignUpStep } from '../../redux/auth/types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSignUpStep } from '../../redux/auth/selectors';
import { useCallback } from 'react';
import { cancelSignUpProcess, setSignUpState } from '../../redux/auth/actions';
import { getApiConfig } from '../../redux/app/selectors';

export const useSignUpScreen = () => {
  const config = useSelector(getApiConfig, shallowEqual);
  const shouldVerifyPhone = config?.isPhoneEnabled ?? true;
  const stepCount = shouldVerifyPhone
    ? Object.keys(SignUpStep).length / 2
    : Object.keys(SignUpStep).length / 2 - 1;
  const step = useSelector(getSignUpStep);
  const dispatch = useDispatch();

  const handleClickBack = useCallback(() => {
    if (step !== SignUpStep.EmailPassword) {
      dispatch(setSignUpState({ step: step - 1 }));
    }
  }, [dispatch, step]);

  const handleClickCancel = useCallback(() => {
    dispatch(cancelSignUpProcess());
  }, [dispatch]);

  return {
    step,
    stepCount,
    handleClickBack,
    handleClickCancel,
    config,
  };
};
