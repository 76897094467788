import cn from 'classnames';
import React, { FC, ReactNode } from 'react';

import styles from './Typography.module.scss';

type Props = {
  isCapitalize?: boolean;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'p' | 'span';
  paragraph?: boolean;
  uppercase?: boolean;
  className?: string;
  children: ReactNode | string | number;
  variant?: 'inherit' | 'body' | 'bodySmall' | 'h1' | 'h2' | 'h3' | 'h4';
  display?: 'initial' | 'block' | 'inline';
  noWrap?: boolean;
  color?: 'textSecondary' | 'textPrimary';
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  font?: 'Futura';
  weight?: 'inherit' | 400 | 500 | 600 | 700;
};

export const Typography: FC<Props> = ({
  component,
  paragraph,
  uppercase,
  className,
  children,
  variant,
  display = 'initial',
  noWrap,
  color = 'textPrimary',
  align = 'left',
  font = 'Futura',
  weight = 'inherit',
}) => {
  const tagMapping = {
    inherit: 'p',
    body: 'p',
    bodySmall: 'p',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
  };

  const Tag = (component ||
    (paragraph ? 'p' : variant && tagMapping[variant]) ||
    'span') as React.ElementType;

  let modifiedChildren: ReactNode | string | number;

  return (
    <Tag
      className={cn(
        styles.typography,
        {
          [styles[`typography_display_${display}`]]: display,
          [styles[`typography_variant_${variant}`]]: variant,
          [styles[`typography_color_${color}`]]: color,
          [styles[`typography_align_${align}`]]: align,
          [styles[`typography_font_${font}`]]: font,
          [styles[`typography_weight_${weight}`]]: weight,
          [styles.typography_uppercase]: uppercase,
          [styles.typography_nowrap]: noWrap,
        },
        className,
      )}
    >
      {modifiedChildren || children}
    </Tag>
  );
};
