import React from 'react';
import { CommonButton, CommonTileContainer } from '../';
import { TokenIconRounded } from '../token-icon-rounded';
import { ICurrentStakingItemProps } from './types';
import cx from 'classnames';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { AmountField } from '../amount-field';

export const CurrentStakingItem: React.FC<ICurrentStakingItemProps> = ({
  bottomButtons,
  className,
  tokenName,
  stakingPeriodEnd,
  apy,
  initialAmount,
  earnings,
  totalAmount,
  isLongTerm,
}) => {
  const { t } = useTranslation(['staking']);

  return (
    <CommonTileContainer className={cx('staking-item', className)}>
      <div className={cx('staking-item-content')}>
        <div className={cx('staking-item-header', 'staking-item-row-centered')}>
          <div className={cx('staking-item-row-centered')}>
            <TokenIconRounded tokenName={tokenName} />
            <span className={cx('staking-item-token-name')}>{tokenName}</span>
          </div>
          {!!stakingPeriodEnd && (
            <span className={cx('staking-item-main-text', 'staking-item-period-end')}>
              {isLongTerm ? t('staking-period-ends', { value: stakingPeriodEnd }) : ' '}
            </span>
          )}
        </div>
        <div>
          <AmountField
            label={t(isLongTerm ? 'apy-long-term' : 'apy-flexible')}
            amountClassName="staking-item-value"
            amount={String(apy)}
          />
          <AmountField
            label={t('initial-amount')}
            amountClassName="staking-item-value"
            amount={String(initialAmount)}
          />
          <AmountField
            label={t('earnings')}
            amountClassName="staking-item-value"
            amount={String(earnings)}
          />
          <AmountField
            label={t('total-amount')}
            amountClassName="staking-item-value"
            amount={String(totalAmount)}
          />
        </div>
        <div className={cx('staking-item-row-centered', 'staking-item-buttons')}>
          {bottomButtons.map((button, index) => (
            <CommonButton
              onClick={button.onPress}
              fullWidth
              key={`${totalAmount}${index}`}
              label={button.label}
              disabled={button.disabled}
              design={index ? 'secondary' : undefined}
              containerClassName={cx({
                'staking-item-bottom-button-margin': !index,
              })}
            />
          ))}
        </div>
        <div className={cx('staking-item-footer')}>
          <span className={cx('staking-item-main-text', 'staking-item-footer-label')}>
            {t(isLongTerm ? 'long-term' : 'flexible')}
          </span>
        </div>
      </div>
    </CommonTileContainer>
  );
};
