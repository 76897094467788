import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes } from '../../constants';
import { getAuthenticated } from '../../redux/app/selectors';

export const AuthRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const authenticated = useSelector(getAuthenticated);
  const history = useHistory();
  useEffect(() => {
    if (authenticated) {
      history.push(routes.DASHBOARD);
    }
  }, [authenticated]);
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          // @ts-ignore
          <Component {...props} />
        );
      }}
    />
  );
};
