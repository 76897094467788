import { useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import {
  paypolitanStakingService,
  IPaypolitanStakingTransactionParams,
  web3Service,
  EAbortControllers,
} from '../../services/blockchain';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEpanStakingAllowanceBlockchainError,
  stakeEpanBlockchainError,
  withdrawEpanBlockchainError,
} from '../../redux/blockchain/actions';
import {
  GET_PAYPOLITAN_STAKING_ALLOWANCE_CACHE_KEY,
  useModal,
  useUserInfo,
  WALLETS_BALANCES_CACHE_KEY,
} from '../../hooks';
import { useTranslation } from 'react-i18next';
import { getCurrentNetwork } from '../../redux/blockchain/selectors';
import { EARNING_BALANCES_CACHE_KEY } from '../../hooks/use-earnings-balances';
import {
  resetEpanStakingProcessToast,
  showEpanStakingProcessToast,
  showSuccessApproveEpanToast,
  showSuccessStakeEpanToast,
  showSuccessWithdrawEpanToast,
} from '../../redux/toast/actions';
import { reactQueryService, snackbarService } from '../../services';
import { isBscNetwork } from '../../helpers';

export const useStakingForm = ({
  currency,
  defiStakingAction,
  epanStakingPeriod,
  selectedWallet,
}: IUseStakingFormParams) => {
  const [approveModalValues, setApproveModalValues] =
    useState<Maybe<IApproveDefiStakingActionModalValues>>(null);
  const {
    modalVisible: approveModalVisible,
    closeModal: closeApproveModal,
    toggleModalVisibility: toggleApproveModalVisibility,
  } = useModal();
  const initialValues: IStakingFormData = { amount: '' };
  const dispatch = useDispatch();
  const { t } = useTranslation('epan-staking');
  const network = useSelector(getCurrentNetwork);
  const [approveLoading, setApproveLoading] = useState(false);
  const { userId } = useUserInfo();

  const onSubmitForm = useCallback(
    async (
      values: IStakingFormData,
      { resetForm, setSubmitting, validateForm }: FormikHelpers<IStakingFormData>,
    ) => {
      const amount = values.amount.toString();

      const onReceipt = () => {
        setTimeout(async () => {
          resetForm();
          validateForm();

          dispatch(resetEpanStakingProcessToast());
          if (defiStakingAction === 'stake') {
            await reactQueryService.invalidateQueries(GET_PAYPOLITAN_STAKING_ALLOWANCE_CACHE_KEY);
          }

          await reactQueryService.invalidateQueries(WALLETS_BALANCES_CACHE_KEY);
          await reactQueryService.invalidateQueries(EARNING_BALANCES_CACHE_KEY);

          if (defiStakingAction === 'stake') {
            dispatch(
              showSuccessStakeEpanToast({
                message: t('staking-success', { amount: `${amount} ${currency}` }),
                withAction: true,
              }),
            );
          } else {
            dispatch(
              showSuccessWithdrawEpanToast({
                message: t('withdraw-success', { amount: `${amount} ${currency}` }),
                withAction: true,
              }),
            );
          }
        }, 5 * 1000);
      };

      const modalInfo: IApproveDefiStakingActionModalValues = {
        amount,
        action: defiStakingAction,
        period: epanStakingPeriod,
        currency,
        onApprove: async () => {
          closeApproveModal();

          try {
            if (selectedWallet && userId) {
              setSubmitting(true);
              const data: IPaypolitanStakingTransactionParams = {
                onReceipt,
                network,
                value: amount,
                gas: isBscNetwork(network) ? 500000 : undefined,
                fromWallet: selectedWallet,
                isTimeFrame: epanStakingPeriod === 'long',
                type: currency,
              };

              const epanStakingProcessData = {
                message: t(defiStakingAction === 'stake' ? 'staking:staking' : 'staking:withdraw', {
                  value: `${amount} ${currency} ...`,
                }),
                amount: `${amount} ${currency}`,
                wallet: selectedWallet,
                onReceipt,
              };

              snackbarService.showWatingForApprovalTxSnackbar(
                t('common:waiting-for-approval'),
                'STAKING',
                () => {
                  web3Service.executeAbortToAbortController(EAbortControllers.STAKING);
                  web3Service.clearAbortController(EAbortControllers.STAKING);
                  snackbarService.closeSnackbar('STAKING');
                },
              );

              // if (selectedWallet.type !== 'metamaskExtension') {
              //   dispatch(
              //     showEpanStakingProcessToast({
              //       ...epanStakingProcessData,
              //       type: 'epanStakingProcess',
              //       timeoutTimestamp: getTransactionTimeoutTimestamp(),
              //     }),
              //   );
              // }

              const txHash =
                defiStakingAction === 'stake'
                  ? await paypolitanStakingService.stake(data)
                  : await paypolitanStakingService.withdraw(data);

              if (txHash) {
                dispatch(
                  showEpanStakingProcessToast({
                    txHash,
                    type: 'epanStakingProcess',
                    ...epanStakingProcessData,
                  }),
                );
              }

              setSubmitting(false);
            }
          } catch (e) {
            setSubmitting(false);
            dispatch(resetEpanStakingProcessToast());

            if (defiStakingAction === 'stake') {
              dispatch(stakeEpanBlockchainError(e as Error));
            } else {
              dispatch(withdrawEpanBlockchainError(e as Error));
            }
          } finally {
            web3Service.clearAbortController(EAbortControllers.STAKING);
            snackbarService.closeSnackbar('STAKING');
          }
        },
      };
      setApproveModalValues(modalInfo);
      toggleApproveModalVisibility();
    },
    [
      network,
      selectedWallet,
      defiStakingAction,
      epanStakingPeriod,
      currency,
      toggleApproveModalVisibility,
      dispatch,
      t,
      closeApproveModal,
      userId,
    ],
  );

  const onApprovalReceipt = async () => {
    const interval = setInterval(async () => {
      try {
        if (selectedWallet) {
          const allowance = await paypolitanStakingService.getStakeAllowance(
            currency,
            selectedWallet,
            network,
          );
          if (Number(allowance)) {
            clearInterval(interval);
            await reactQueryService.invalidateQueries(GET_PAYPOLITAN_STAKING_ALLOWANCE_CACHE_KEY);
            await reactQueryService.invalidateQueries(WALLETS_BALANCES_CACHE_KEY);
            await reactQueryService.invalidateQueries(EARNING_BALANCES_CACHE_KEY);
            dispatch(resetEpanStakingProcessToast());
            dispatch(
              showSuccessApproveEpanToast({
                message: t('approving-success', {
                  amount: `${currency}`,
                }),
                withAction: true,
              }),
            );
          }
        }
      } catch (err) {
        clearInterval(interval);
        dispatch(resetEpanStakingProcessToast());
        dispatch(getEpanStakingAllowanceBlockchainError(err as Error));
      }
    }, 5 * 1000);
  };

  const handleErc20Approve = useCallback(
    () => async () => {
      try {
        setApproveLoading(true);

        if (selectedWallet && userId) {
          const epanStakingProcessData = {
            message: t('staking:approve', { value: `${currency} ...` }),
            amount: `${currency}`,
            wallet: selectedWallet,
            onReceipt: onApprovalReceipt,
          };

          // if (selectedWallet.type !== 'metamaskExtension') {
          //   dispatch(
          //     showEpanStakingProcessToast({
          //       ...epanStakingProcessData,
          //       type: 'epanStakingProcess',
          //       timeoutTimestamp: getTransactionTimeoutTimestamp(),
          //     }),
          //   );
          // }

          snackbarService.showWatingForApprovalTxSnackbar(
            t('common:waiting-for-approval'),
            'STAKING',
            () => {
              web3Service.executeAbortToAbortController(EAbortControllers.STAKING);
              web3Service.clearAbortController(EAbortControllers.STAKING);
              snackbarService.closeSnackbar('STAKING');
            },
          );

          const txHash = await paypolitanStakingService.approveStakeTransaction({
            fromWallet: selectedWallet,
            userId,
            network,
            onReceipt: onApprovalReceipt,
            type: currency,
          });

          if (txHash) {
            dispatch(
              showEpanStakingProcessToast({
                txHash,
                type: 'epanStakingProcess',
                ...epanStakingProcessData,
              }),
            );
          }
        }
      } catch (e) {
        dispatch(stakeEpanBlockchainError(e as Error));
        dispatch(resetEpanStakingProcessToast());
      } finally {
        web3Service.clearAbortController(EAbortControllers.STAKING);
        snackbarService.closeSnackbar('STAKING');
        setApproveLoading(false);
      }
    },
    [network, selectedWallet, userId, dispatch, currency, onApprovalReceipt],
  );

  return {
    initialValues,
    onSubmitForm,
    approveModalVisible,
    approveModalValues,
    closeApproveModal,
    defiStakingAction,
    handleErc20Approve,
    approveLoading,
  };
};
