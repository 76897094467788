import { RootStateType } from '../store';

export const getCurrentUser = (state: RootStateType) => state.users.current;
export const getCurrentFiatCurrency = (state: RootStateType) => state.users.currentFiatCurrency;
export const getApiUser = (state: RootStateType) => state.users.apiUser;
export const getNotificationsSettings = (state: RootStateType): Partial<INotificationSettings> =>
  state.users.notificationsSettings;
export const getUserApiWallets = (state: RootStateType) => state.users.apiWallets;
export const getCurrentPaypolitanWallet = (state: RootStateType) => {
  const wallets = state.users.apiWallets;
  return wallets[wallets.length - 1];
};
