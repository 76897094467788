import { protectedApi } from '../decorators';
import { httpService } from '../../../services';

export const CONTACTS = '/contacts';
export const CONTACTS_IMPORT = '/contacts/import';

export class ContactsResource {
  cacheKey = 'transferContactsList'; // TODO check compatibility with contacts screen
  recipientCacheKey = 'transferRecipientList';

  @protectedApi()
  async getContacts(params?: IGetContactsListParams) {
    return httpService.get<IGetContactsListResponse>({
      url: CONTACTS,
      params,
    });
  }

  @protectedApi()
  async addContact(data: IAddContactParams) {
    return httpService.post<IApiContact>({
      url: CONTACTS,
      data,
    });
  }

  @protectedApi()
  async importContacts(data: IImportContactsParams) {
    return httpService.post<void>({
      url: CONTACTS_IMPORT,
      data,
    });
  }

  @protectedApi()
  async editContact({ id, data }: IEditContactParams) {
    return httpService.patch<void>({
      url: `${CONTACTS}/${id}`,
      data,
    });
  }

  @protectedApi()
  async deleteContact(data: IDeleteContactParams) {
    return httpService.delete<void>({
      url: `${CONTACTS}/${data.id}`,
      data,
    });
  }
}

export const contactsResource = new ContactsResource();
