import { createBrowserHistory, History } from 'history';

class HistoryService {
  constructor() {
    this.historyInstance = createBrowserHistory();
  }

  private readonly historyInstance: History;

  get history(): History {
    return this.historyInstance;
  }
}

export const historyService = new HistoryService();
