import React from 'react';
import { Field, FieldProps } from 'formik';
import { IPhoneNumberInputProps, PhoneNumberInput } from './PhoneNumberInput';

interface IFormTextFieldProps<FormFields> extends Partial<IPhoneNumberInputProps> {
  name: keyof FormFields;
  disabled?: boolean;
}

export const FormPhoneNumberField = <FormFields extends object>({
  containerClass,
  disabled,
  name,
  valid,
  setValid,
  onChangeCapture,
  highlightOnFocus,
  ...rest
}: IFormTextFieldProps<FormFields>) => (
  <Field name={name} {...rest}>
    {({ field, form, ...props }: FieldProps) => (
      <PhoneNumberInput
        containerClass={containerClass}
        disabled={disabled}
        field={field}
        onChangeCapture={onChangeCapture}
        form={form}
        valid={valid}
        setValid={setValid}
        highlightOnFocus={highlightOnFocus}
        {...props}
      />
    )}
  </Field>
);
