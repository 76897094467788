import { RootStateType } from '../store';
import { chainIdToNetworkMap } from '../../constants';

export const getExternalWalletData = (state: RootStateType) => state.blockChain.externalWalletData;
export const getExternalWalletType = (state: RootStateType) =>
  state.blockChain.externalWalletData.type;
export const getExternalWalletAddresses = (state: RootStateType) => {
  return state.blockChain.chainIdSupported ? state.blockChain.externalWalletData.addresses : [];
};
export const getExternalWalletChainId = (state: RootStateType) =>
  state.blockChain.externalWalletData.chainId;
export const getChainIdSupported = (state: RootStateType) => state.blockChain.chainIdSupported;
export const getCustomTokes = (state: RootStateType) => {
  const currentChainId = state.blockChain.currentChainId;

  return state.blockChain.customErc20Tokens.filter((token) => token.chainId === currentChainId);
};
export const getErc20Tokes = (state: RootStateType) => {
  const currentChainId = state.blockChain.currentChainId;

  return state.blockChain.erc20Tokens.filter((token) => token.chainId === currentChainId);
};
export const getAllCustomTokens = (state: RootStateType) => state.blockChain.customErc20Tokens;
export const getCurrentChainId = (state: RootStateType) => state.blockChain.currentChainId;
export const getCurrentNetwork = (state: RootStateType) =>
  chainIdToNetworkMap[state.blockChain.currentChainId] ?? 'mainnet';
export const getCurrentNetworkLoading = (state: RootStateType) =>
  state.blockChain.currentChainIdLoading;
export const getNetworkSelectorVisible = (state: RootStateType) =>
  state.blockChain.networkSelectorVisible;
export const getCustomTokensModalVisible = (state: RootStateType) =>
  state.blockChain.customTokensModalVisible;
export const getActiveTokenLists = (state: RootStateType) => state.blockChain.activeTokenLists;
export const getTryAutoConnectWallet = (state: RootStateType) =>
  state.blockChain.tryAutoConnectWallet;
export const getWalletConnectQrCodeUrl = (state: RootStateType) =>
  state.blockChain.walletConnectQrCodeUrl;
export const getWalletConnectQrModalError = (state: RootStateType) =>
  state.blockChain.walletConnectQrModalError;
