import { shallowEqual, useSelector } from 'react-redux';
import { useWalletsBalances } from './use-wallets-balances';
import { useMemo } from 'react';
import { getCurrentNetwork, getExternalWalletAddresses } from '../redux/blockchain/selectors';
import { DEFAULT_BSC_WALLET_CURRENCIES, DEFAULT_ETH_WALLET_CURRENCIES } from '../constants';
// import { useWalletConnect } from '@walletconnect/react-native-dapp';
import { /*getWalletConnectInfo,*/ isEthNetwork } from '../helpers';
import { envService } from '../services/env.service';

interface useCurrentUserWalletsBalancesParams {
  currency?: DefaultWalletCurrencies;
  walletType?: IWalletType;
  onSuccess?: () => void;
}

interface ISupportedCurrenciesData {
  currency: DefaultWalletCurrencies;
  decimals?: number;
  tokenAddress?: string;
}

const getSupportedCurrenciesDataMap = (
  network: Network,
): Record<DefaultWalletCurrencies, ISupportedCurrenciesData> => ({
  ETH: {
    currency: 'ETH',
  },
  BNB: {
    currency: 'BNB',
  },
  EPAN: {
    currency: 'EPAN',
    decimals: isEthNetwork(network)
      ? envService.getEthTokens(network ?? 'ropsten').EPAN.decimals
      : undefined,
    tokenAddress: isEthNetwork(network)
      ? envService.getEthTokens(network ?? 'ropsten').EPAN.address
      : undefined,
  },
  BEPAN: {
    currency: 'BEPAN',
    decimals: !isEthNetwork(network)
      ? envService.getBscTokens(network ?? 'bscTestnet').BEPAN.decimals
      : undefined,
    tokenAddress: !isEthNetwork(network)
      ? envService.getBscTokens(network ?? 'bscTestnet').BEPAN.address
      : undefined,
  },
  POLVEN: {
    currency: 'POLVEN',
    decimals: isEthNetwork(network)
      ? envService.getEthTokens(network ?? 'ropsten').POLVEN.decimals
      : undefined,
    tokenAddress: isEthNetwork(network)
      ? envService.getEthTokens(network ?? 'ropsten').POLVEN.address
      : undefined,
  },
  USDT: {
    currency: 'USDT',
    decimals: isEthNetwork(network)
      ? envService.getEthTokens(network ?? 'ropsten').USDT.decimals
      : undefined,
    tokenAddress: isEthNetwork(network)
      ? envService.getEthTokens(network ?? 'ropsten').USDT.address
      : undefined,
  },
});

const getDefaultTokens = (address: string, network: Network) =>
  isEthNetwork(network)
    ? DEFAULT_ETH_WALLET_CURRENCIES.map((cur) => ({
        address,
        type: 'metamaskExtension' as const,
        ...getSupportedCurrenciesDataMap(network)[cur],
      }))
    : DEFAULT_BSC_WALLET_CURRENCIES.map((cur) => ({
        address,
        type: 'metamaskExtension' as const,
        ...getSupportedCurrenciesDataMap(network)[cur],
      }));

// const excludeDefaultTokensFilter = (token: ICustomToken) =>
//   !DEFAULT_WALLET_CURRENCIES.includes(token.symbol as DefaultWalletCurrencies);
//
// const getTokenData = (token: ICustomToken) => ({
//   currency: token.symbol as DefaultEthWalletCurrencies,
//   tokenAddress: token.address,
//   decimals: token.decimals,
// });

export const useCurrentUserWalletsBalances = ({
  currency,
  walletType,
  onSuccess,
}: useCurrentUserWalletsBalancesParams = {}) => {
  // const connector = useWalletConnect();
  const externalWalletAddresses = useSelector(getExternalWalletAddresses, shallowEqual);
  // const customErc20Tokes = useSelector(getCustomTokes, shallowEqual);
  const currentNetwork = useSelector(getCurrentNetwork);
  const wallets: IWallet[] = useMemo(() => {
    return [
      ...externalWalletAddresses
        .map<IWallet[]>((acc) => [
          ...getDefaultTokens(acc, currentNetwork) /*.map((item) => ({
              ...item,
              ...getWalletConnectInfo(connector),
            }))*/,
          // ...customErc20Tokes.filter(excludeDefaultTokensFilter).map((token) => ({
          //   address: acc,
          //   ...getTokenData(token),
          //   ...getWalletConnectInfo(connector),
          // })),
        ])
        .flat(),
    ]
      .filter((item) => (currency ? item.currency === currency : true))
      .filter((item) => (walletType ? item.type === walletType : true));
  }, [externalWalletAddresses, currentNetwork, currency, walletType]);

  return useWalletsBalances({
    wallets,
    onSuccess,
  });
};
