import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { transactionsResource } from '../../api';
import MetamaskIcon from '../../assets/images/icons/metamask-icon.svg';
import WalletConnectIcon from '../../assets/images/icons/wallet-connect-icon.svg';
import MainIcon from '../../assets/images/icons/main.svg';
import CoinBaseIcon from '../../assets/images/icons/coinbase.svg';
import { getCurrentNetwork, getExternalWalletData } from '../../redux/blockchain/selectors';
import { snackbarService } from '../../services';
import { updateExternalWalletData } from '../../redux/blockchain/actions';
import { web3Service } from '../../services/blockchain';
import { noop } from '../../helpers';
import { asyncStorageService } from '../../services/local-storage.service';

interface IProviderItem {
  icon: string;
  label: string;
  type: IWalletType;
  disabled?: boolean;
  info?: string;
}

export const providers: IProviderItem[] = [
  { icon: MainIcon, label: 'Paypolitan wallet', type: 'paypolitan' },
  { icon: MetamaskIcon, label: 'Metamask', type: 'metamaskExtension' },
  {
    icon: WalletConnectIcon,
    label: 'WalletConnect',
    type: 'walletConnect',
    info: 'wallet-connect-info',
  },
  { icon: CoinBaseIcon, label: 'Coinbase wallet', type: 'coinbase', disabled: false },
];

export const providersIconMap: Record<IWalletType, string> = {
  metamaskExtension: MetamaskIcon,
  walletConnect: WalletConnectIcon,
  paypolitan: MainIcon,
  coinbase: CoinBaseIcon,
};

export const useModal = ({
  type,
  connectWallet,
  walletConnected,
  connectedAddress,
  isChangingProvider,
  walletConnecting,
  onCloseModal,
}: IUseConnectWalletModalParams) => {
  const { t } = useTranslation(['common']);
  const network = useSelector(getCurrentNetwork);
  const dispatch = useDispatch();
  const externalWallet = useSelector(getExternalWalletData, shallowEqual);

  const viewOnEtherscanRef = transactionsResource.getBlockExplorerUrl({
    value: connectedAddress ?? '',
    network,
    type: 'walletAddress',
  });

  const connectWalletByType = useCallback(() => {
    switch (type) {
      case 'metamaskExtension':
        return () => connectWallet('metamaskExtension');
      case 'walletConnect':
        return () => connectWallet('walletConnect');
      case 'paypolitan':
        return () => connectWallet('paypolitan');
      case 'coinbase':
        return () => connectWallet('coinbase');
      default:
        return noop;
    }
  }, [type, connectWallet]);

  const disconnectWallet = useCallback(
    () => async () => {
      dispatch(
        updateExternalWalletData({
          addresses: [],
        }),
      );
      onCloseModal();
      await asyncStorageService.removeItem('latestWalletType');
      await web3Service.disconnect();
    },
    [dispatch],
  );

  const changeProvider = useCallback(
    () => () => {
      connectWalletByType()();
      onCloseModal();
    },
    [dispatch, connectWalletByType],
  );

  const connectionHandler = useMemo(() => {
    if (isChangingProvider) {
      return changeProvider();
    }
    if (walletConnected) {
      return disconnectWallet();
    }
    return connectWalletByType();
  }, [walletConnected, disconnectWallet, connectWalletByType, changeProvider, isChangingProvider]);

  const isConnectionButtonDisabled = useMemo(() => {
    if (walletConnected) {
      if (isChangingProvider && !type) {
        return true;
      }
      return walletConnecting;
    }
    return !type || walletConnecting;
  }, [walletConnected, isChangingProvider, type, walletConnecting]);

  const onClipboardCopied = useCallback(() => {
    snackbarService.showSuccessSnackbar(t('copied'));
  }, [t]);

  return {
    externalWallet,
    connectionHandler,
    viewOnEtherscanRef,
    onClipboardCopied,
    isConnectionButtonDisabled,
  };
};
