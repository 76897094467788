import { protectedApi } from '../decorators';
import { httpService } from '../../../services';

export const WALLETS_URL = '/wallets';
export const IMPORT_WALLET_URL = `${WALLETS_URL}/import`;

export class WalletsResource {
  @protectedApi()
  async addWallet(data: IAddWalletsParams) {
    return httpService.post<IApiWallet>({
      url: WALLETS_URL,
      data,
    });
  }

  @protectedApi()
  async importWallet(data: IImportWalletsParams) {
    return httpService.post<void>({
      url: IMPORT_WALLET_URL,
      data,
    });
  }

  @protectedApi()
  async editWallet({ walletId, ...data }: IEditWalletsParams) {
    return httpService.patch({
      url: `${WALLETS_URL}/${walletId}`,
      data,
    });
  }

  @protectedApi()
  async getWallets() {
    return httpService.get<IGetApiWalletsResponse>({
      url: WALLETS_URL,
    });
  }
}

export const walletsResource = new WalletsResource();
