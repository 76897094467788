import { useCallback, useState } from 'react';

export const usePasswordVisibility = (initialVisible = false): [boolean, () => void] => {
  const [visible, setVisible] = useState<boolean>(initialVisible);
  const toggleVisibility = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  return [visible, toggleVisibility];
};
