import { bscPaypolitanStakingAbi, ethPaypolitanStakingAbi } from './abi';
import { Contract } from 'web3-eth-contract';
import { web3Service, Web3Service } from './web3.service';
import {
  EARNING_AMOUNT_PRECISION,
  EPAN_BALANCE_PRECISION,
  networkToChainIdMap,
} from '../../constants';
import { EAbortControllers, IPaypolitanStakingTransactionParams } from './importable.types';
import { isBscNetwork, isEthNetwork } from '../../helpers';
import { envService } from '../env.service';
import moment from 'moment';
import { getPaypolitanEarningType, PERCENT_RATES } from './helpers';
import Decimal from 'decimal.js';

class PaypolitanStakingService {
  constructor(web3Svc: Web3Service) {
    this._web3service = web3Svc;
    this.stakingContract = {
      EPAN: new this._web3service.web3.eth.Contract(
        ethPaypolitanStakingAbi,
        envService.getEthNetworks('mainnet').epanStakingAddress,
      ),
      POLVEN: new this._web3service.web3.eth.Contract(
        ethPaypolitanStakingAbi,
        envService.getEthNetworks('mainnet').polvenStakingAddress,
      ),
      BEPAN: null,
    };
  }

  private _web3service: Web3Service;
  private stakingContract: Record<PaypolitanStakingType, Maybe<Contract>>;

  getStakingAddress = (type: PaypolitanStakingType, network: Network) => {
    if (type === 'EPAN' && isEthNetwork(network)) {
      return envService.getEthNetworks(network).epanStakingAddress;
    } else if (type === 'POLVEN' && isEthNetwork(network)) {
      return envService.getEthNetworks(network).polvenStakingAddress;
    } else if (type === 'BEPAN' && isBscNetwork(network)) {
      return envService.getBscNetworks(network).bepanStakingAddress;
    }

    return '';
  };

  init(web3service: Web3Service, network: Network) {
    this._web3service = web3service;
    this.stakingContract = {
      EPAN: isEthNetwork(network)
        ? new this._web3service.web3.eth.Contract(
            ethPaypolitanStakingAbi,
            this.getStakingAddress('EPAN', network),
          )
        : null,
      POLVEN: isEthNetwork(network)
        ? new this._web3service.web3.eth.Contract(
            ethPaypolitanStakingAbi,
            this.getStakingAddress('POLVEN', network),
          )
        : null,
      BEPAN: isBscNetwork(network)
        ? new this._web3service.web3.eth.Contract(
            bscPaypolitanStakingAbi,
            this.getStakingAddress('BEPAN', network),
          )
        : null,
    };
  }

  stake = async ({
    gas,
    fromWallet,
    // connector,
    network,
    isTimeFrame,
    value,
    // userId,
    onReceipt,
    type,
  }: IPaypolitanStakingTransactionParams) => {
    const trxData = await this.prepareStakeTrxData({
      fromWallet,
      value,
      network,
      isTimeFrame,
      type,
    });

    if (fromWallet.type === 'metamaskExtension' || fromWallet.type === 'coinbase') {
      return this._web3service.sendMetamaskExtensionTransaction({
        executionAbortControllerName: EAbortControllers.STAKING,
        // userId,
        onReceipt,
        gas,
        ...trxData,
      });
    } /*else if (connector && connector.connected) {
      return this._web3service.sendWalletConnectTransaction({
        connector,
        ...trxData,
      });
    }*/
  };

  private prepareStakeTrxData = async ({
    fromWallet,
    network,
    value,
    isTimeFrame,
    type,
  }: Pick<
    IPaypolitanStakingTransactionParams,
    'fromWallet' | 'network' | 'value' | 'gasPrice' | 'isTimeFrame' | 'type'
  >) => {
    const trxData: ITransactionConfigBase = {
      from: fromWallet.address,
      to: this.getStakingAddress(type, network),
      value: '0',
      chainId: networkToChainIdMap[network],
    };

    const tokenAmount = this._web3service.web3.utils.toHex(
      this._web3service.web3.utils.toWei(value?.toString(), 'ether'),
    );

    const data = await this.stakingContract[type]?.methods
      .updateCompoundAndStake(tokenAmount, isTimeFrame)
      .encodeABI();

    return {
      data,
      ...trxData,
    };
  };

  getStakeBalance = async (type: PaypolitanStakingType, isTimeframe: boolean, from?: string) => {
    const balance: Maybe<string> = await this.stakingContract[type]?.methods
      .getBalance(isTimeframe)
      .call({ from: from ?? this._web3service.web3.defaultAccount });

    return balance
      ? this._web3service.formatBalanceFromWei(balance, 'ether', EPAN_BALANCE_PRECISION)
      : null;
  };

  getStakeAllowance = async (
    type: PaypolitanStakingType,
    fromWallet: IWallet,
    network: Network,
  ) => {
    const from = fromWallet.address;
    const spender = this.getStakingAddress(type, network);
    const tokenAddress = this.getStakingToken(type, network)?.address;

    if (!tokenAddress) {
      return null;
    }

    const contract = isEthNetwork(network)
      ? this._web3service.getErc20Contract(tokenAddress)
      : this._web3service.getBep20Contract(tokenAddress);

    return tokenAddress ? await contract.methods.allowance(from, spender).call() : null;
  };

  getStakesInfo = async (
    type: PaypolitanStakingType,
    from?: string,
    isTimeFrame = true,
  ): Promise<IPaypolitanStakeTimeframeResponse> => {
    if (isTimeFrame) {
      return await this.stakingContract[type]?.methods
        .userStakesTimeframe(from ?? this._web3service.web3.defaultAccount)
        .call({ from: from ?? this._web3service.web3.defaultAccount });
    }

    return await this.stakingContract[type]?.methods
      .userStakes(from ?? this._web3service.web3.defaultAccount)
      .call({ from: from ?? this._web3service.web3.defaultAccount });
  };

  getRewardAmount = async (type: PaypolitanStakingType, isTimeframe: boolean, from?: string) => {
    const rewardAmount: Maybe<string> = await this.stakingContract[type]?.methods
      .getRewardAmount(isTimeframe)
      .call({ from: from ?? this._web3service.web3.defaultAccount });

    return rewardAmount
      ? this._web3service.formatBalanceFromWei(rewardAmount, 'ether', EPAN_BALANCE_PRECISION)
      : null;
  };

  withdraw = async ({
    gas,
    fromWallet,
    // connector,
    onReceipt,
    // userId,
    value,
    network,
    isTimeFrame,
    type,
  }: IPaypolitanStakingTransactionParams) => {
    const trxData = await this.prepareWithdrawTrxData({
      fromWallet,
      value,
      network,
      isTimeFrame,
      type,
    });

    if (fromWallet.type === 'metamaskExtension' || fromWallet.type === 'coinbase') {
      return this._web3service.sendMetamaskExtensionTransaction({
        executionAbortControllerName: EAbortControllers.STAKING,
        gas,
        // userId,
        onReceipt,
        ...trxData,
      });
    } /*else if (connector && connector.connected) {
      return this._web3service.sendWalletConnectTransaction({
        connector,
        ...trxData,
        gasPrice: this._web3service.web3.utils.toHex(gasPrice),
      });
    }*/
  };

  private prepareWithdrawTrxData = async ({
    fromWallet,
    network,
    value,
    isTimeFrame,
    type,
  }: Pick<
    IPaypolitanStakingTransactionParams,
    'fromWallet' | 'network' | 'value' | 'gasPrice' | 'isTimeFrame' | 'type'
  >) => {
    const trxData: ITransactionConfigBase = {
      from: fromWallet.address,
      to: this.getStakingAddress(type, network),
      value: '0',
      chainId: networkToChainIdMap[network],
    };

    const tokenAmount = this._web3service.web3.utils.toHex(
      this._web3service.web3.utils.toWei(value?.toString(), 'ether'),
    );

    const data = await this.stakingContract[type]?.methods
      .updateCompoundAndWithdraw(tokenAmount, isTimeFrame)
      .encodeABI();

    return {
      data,
      ...trxData,
    };
  };

  getApy = async (type: PaypolitanStakingType, isTimeframe: boolean, from?: string) => {
    const interestRate: Maybe<string> = isTimeframe
      ? await this.stakingContract[type]?.methods
          .interestRateTimeframe()
          .call({ from: from ?? this._web3service.web3.defaultAccount })
      : await this.stakingContract[type]?.methods
          .interestRate()
          .call({ from: from ?? this._web3service.web3.defaultAccount });
    // (((1 + x)^1/31536000) - 1) * 10^27 = interestRate
    // const percentRate = interestRate ? (Number(interestRate) / 10 ** 27 + 1) ** 31536000 - 1 : 0;
    const percentRate = new Decimal(interestRate ?? 0)
      .dividedBy(new Decimal(10).pow(27))
      .plus(1)
      .pow(31536000)
      .sub(1);
    // return Math.round(percentRate * 10000) / 100;
    return percentRate.mul(100).toDecimalPlaces(2).toNumber();
  };

  estimateApproveStakeGas = async ({
    fromWallet,
    value,
    network,
    type,
  }: Pick<
    IPaypolitanStakingTransactionParams,
    'fromWallet' | 'value' | 'gasPriceInEth' | 'network' | 'type'
  >) => {
    const amount = this._web3service.web3.utils.toWei(value);
    const tokenAddress = this.getStakingToken(type, network)?.address;

    if (!tokenAddress) {
      return 0;
    }

    const contract = isEthNetwork(network)
      ? this._web3service.getErc20Contract(tokenAddress)
      : this._web3service.getBep20Contract(tokenAddress);

    return contract.methods.approve(this.getStakingAddress(type, network), amount).estimateGas({
      from: fromWallet.address,
    });
  };

  approveStakeTransaction = async ({
    fromWallet,
    network,
    // connector,
    onReceipt,
    // userId,
    type,
  }: Omit<IPaypolitanStakingTransactionParams, 'gasPrice' | 'isTimeFrame' | 'value'>) => {
    const stakingToken = this.getStakingToken(type, network);

    if (!stakingToken) {
      return null;
    }

    return this._web3service.approveTokenTransaction({
      executionAbortControllerName: EAbortControllers.STAKING,
      customToken: stakingToken,
      fromWallet,
      // connector,
      spenderAddress: this.getStakingAddress(type, network),
      // userId,
      onReceipt,
      chainId: networkToChainIdMap[network],
    });
  };

  isEthStaking = (
    type: EthPaypolitanStakingType | BscPaypolitanStakingType,
  ): type is EthPaypolitanStakingType => {
    return !type || type === 'EPAN' || type === 'POLVEN';
  };

  getStakingToken = (type: PaypolitanStakingType, network: Network) => {
    if (isEthNetwork(network) && this.isEthStaking(type)) {
      return envService.getEthTokens(network)[type];
    } else if (isBscNetwork(network) && !this.isEthStaking(type)) {
      return envService.getBscTokens(network)[type];
    }

    return null;
  };

  estimateEarnings = ({
    startAmount,
    type,
    startedAt,
    endedAt,
  }: IEstimatePaypolitanEarningsParams) => {
    const baseRate = new Decimal(PERCENT_RATES[type].rate).div(new Decimal(10).pow(27));
    const durationFromRatesDeploymentToStake = moment(startedAt).diff(
      moment(PERCENT_RATES[type].timestamp),
      'seconds',
    );
    const compoundRateOnStakeTime = baseRate.add(1).pow(durationFromRatesDeploymentToStake);
    const normalizedAmount = new Decimal(startAmount).div(compoundRateOnStakeTime);
    const durationFromStakeToLastUpdate = moment(endedAt).diff(moment(startedAt), 'seconds');
    const compoundRateOnLastUpdate = baseRate.add(1).pow(durationFromStakeToLastUpdate);
    const estimatedBalance = normalizedAmount
      .mul(compoundRateOnStakeTime.sub(1).add(compoundRateOnLastUpdate))
      .minus(startAmount);

    return estimatedBalance
      .toDecimalPlaces(EARNING_AMOUNT_PRECISION, Decimal.ROUND_CEIL)
      .toString();
  };

  estimateCalculatorAmounts = ({
    startedAt,
    startAmount,
    isLongTerm,
    currency,
  }: IEstimateCalculatorAmountsParams): IRewardCalculatorAmounts => {
    const type = getPaypolitanEarningType(currency, isLongTerm);

    return {
      amountIn1d: isLongTerm
        ? ''
        : this.estimateEarnings({
            startAmount,
            type,
            startedAt: moment(startedAt).valueOf(),
            endedAt: moment(startedAt).add(1, 'days').valueOf(),
          }),
      amountIn7d: isLongTerm
        ? ''
        : this.estimateEarnings({
            startAmount,
            type,
            startedAt: moment(startedAt).valueOf(),
            endedAt: moment(startedAt).add(7, 'days').valueOf(),
          }),
      amountIn30d: isLongTerm
        ? ''
        : this.estimateEarnings({
            startAmount,
            type,
            startedAt: moment(startedAt).valueOf(),
            endedAt: moment(startedAt).add(30, 'days').valueOf(),
          }),
      amountIn180d: this.estimateEarnings({
        startAmount,
        type,
        startedAt: moment(startedAt).valueOf(),
        endedAt: moment(startedAt).add(180, 'days').valueOf(),
      }),
      amountIn365d: this.estimateEarnings({
        startAmount,
        type,
        startedAt: moment(startedAt).valueOf(),
        endedAt: moment(startedAt).add(365, 'days').valueOf(),
      }),
    };
  };
}

export const paypolitanStakingService = new PaypolitanStakingService(web3Service);
