import React, { useCallback, useState } from 'react';
import './style.scss';
import { styled } from '@mui/material/styles';
import { Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import blackArrow from '../../assets/images/icons/blackArrow.svg';

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// @ts-ignore
export const CommonCollapsedBlock = ({ children, label }) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  return (
    <div className="collapsed-block">
      <div className="collapsed-block-header">
        <p>{label}</p>
        <ExpandMore
          // @ts-ignore
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <img src={blackArrow} alt="icon" className={` ${!expanded ? 'rotate0' : 'rotate180'}`} />
        </ExpandMore>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};
