import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { makeStyles } from '@mui/styles';
import warningIcon from './../../assets/images/icons/warning-icon.svg';
import blackArrow from './../../assets/images/icons/blackArrow.svg';
import { TokenIconRounded } from '../token-icon-rounded';
import ReactTooltip from 'react-tooltip';
import {
  chainIdToNetworkMap,
  networkNativeCurrency,
  networkTitles,
  supportedChainIds,
} from '../../constants';
import { useTranslation } from 'react-i18next';
import { web3Service } from '../../services/blockchain';
import { useDispatch, useSelector } from 'react-redux';
import {
  configureCurrentChainId,
  setCurrentChainIdBlockchainError,
  updateExternalWalletData,
} from '../../redux/blockchain/actions';
import { isNull } from '../../utils/isNull';
import { getExternalWalletChainId, getExternalWalletType } from '../../redux/blockchain/selectors';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { CommonButton } from '../common-button';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

export const WalletSelect = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const chainId = useSelector(getExternalWalletChainId) as SupportedChainId;
  const externalWalletType = useSelector(getExternalWalletType);
  const dispatch = useDispatch();
  const isChainSupported = supportedChainIds.includes(chainId);
  const isMetamaskorCoinbase =
    externalWalletType === 'metamaskExtension' || externalWalletType === 'coinbase';

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeNetwork = useCallback(
    (chainId: SupportedChainId) => async () => {
      try {
        await web3Service.requestMetamaskNetworkChange(chainId);
        handleClose();
      } catch (e) {
        dispatch(setCurrentChainIdBlockchainError(e as Error));
      }
    },
    [dispatch, handleClose],
  );

  const handleDisconnectWallet = useCallback(async () => {
    dispatch(updateExternalWalletData({ addresses: [], chainId: 1 }));
    dispatch(configureCurrentChainId({ chainId: 1 }));
    await web3Service.disconnect();
  }, [dispatch]);

  const renderCurrentNetwork = useCallback(() => {
    return (
      <div className="dashboard-header-user-wallet-button-current">
        <TokenIconRounded tokenName={networkNativeCurrency[chainIdToNetworkMap[chainId]]} />
        <span>{t(networkTitles[chainId])}</span>
      </div>
    );
  }, [t, chainId]);

  useEffect(() => {
    // rebuild tool tip after changing external wallet type or chain
    ReactTooltip.rebuild();
  }, [externalWalletType, chainId]);

  return (
    <>
      <div className={classes.root}>
        {isChainSupported ? (
          <>
            <Button
              disabled={!isMetamaskorCoinbase}
              aria-haspopup="true"
              onClick={handleClick}
              aria-controls="simple-menu"
              className="dashboard-header-user-wallet-button"
            >
              {renderCurrentNetwork()}
              <div className="dashboard-header-user-wallet-button-arrow">
                {isMetamaskorCoinbase && (
                  <img
                    alt="icon"
                    src={blackArrow}
                    className={`${!isNull(anchorEl) ? 'rotate180' : 'rotate0'}`}
                  />
                )}
              </div>
            </Button>
            {!isMetamaskorCoinbase && (
              <div
                className="dashboard-header-user-wallet-warning"
                data-tip="wallet-connect"
                data-for="wallet-connect"
              >
                <span>i</span>
              </div>
            )}
          </>
        ) : (
          <div className="dashboard-header-user-wallet-row">
            <CommonButton
              containerClassName="dashboard-header-user-wallet-disconnect-button-container"
              className="dashboard-header-user-wallet-disconnect-button"
              onClick={handleDisconnectWallet}
              size="large"
              label={t('disconnect')}
            />
            <div>
              <span className="dashboard-header-user-wallet-unsupported-label">
                {t('networks:unsupported')}
              </span>
              <div
                data-tip="unsupported-network"
                data-for="unsupported-network"
                className="dashboard-header-user-wallet-unsupported-network"
              >
                <img src={warningIcon} alt="icon" />
                <span className="dashboard-header-user-wallet-detail-label">
                  {t('common:details')}
                </span>
              </div>
            </div>
          </div>
        )}
        <Menu
          keepMounted
          id="simple-menu"
          anchorEl={anchorEl}
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          {supportedChainIds.map((id) => (
            <MenuItem
              key={id}
              onClick={handleChangeNetwork(id)}
              className="dashboard-header-user-wallet-modal-item"
            >
              <TokenIconRounded tokenName={networkNativeCurrency[chainIdToNetworkMap[id]]} />
              <span>{t(networkTitles[id])}</span>
            </MenuItem>
          ))}
        </Menu>
      </div>
      <ReactTooltip place={'bottom'} backgroundColor={'black'} id="unsupported-network" type="dark">
        <span className="networks-bridge-tooltip-text">
          {t('common:unsupported-network-tooltip')}
        </span>
      </ReactTooltip>
      <ReactTooltip
        wrapper="div"
        className="dashboard-header-user-wallet-network-tooltip"
        place={'bottom'}
        backgroundColor={'black'}
        id="wallet-connect"
        type="dark"
      >
        <span className="networks-bridge-tooltip-text">
          {t('wallet-connect-changing-network-info')}
        </span>
      </ReactTooltip>
    </>
  );
};
