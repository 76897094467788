import React from 'react';
import EPANTokenIconWhite from '../../assets/images/icons/epan-token-icon-white.svg';
import EPANTokenIconPrimary from '../../assets/images/icons/epan-token-icon-primary.svg';
import POLVENTokenIcon from '../../assets/images/icons/polven-token-icon.svg';
import usdtTokenIcon from '../../assets/images/icons/usdt-token-icon.svg';
import ETHIcon from '../../assets/images/icons/eth-icon.svg';
import CustomTokenIcon from '../../assets/images/icons/custom-token.svg';
import BnbIcon from '../../assets/images/icons/bnb-icon.svg';
import { ITokenIconRoundedProps } from './types';
import cx from 'classnames';
import { Image } from '@material-ui/icons';
import './style.scss';

export const TokenIconRounded: React.FC<ITokenIconRoundedProps> = ({ tokenName, raw }) => {
  switch (tokenName) {
    case 'EPAN':
    case 'BEPAN':
      return (
        <div
          className={cx('token-icon-rounded', 'token-icon-rounded-epan', {
            'token-icon-rounded-raw': raw,
          })}
        >
          <img
            src={raw ? EPANTokenIconPrimary : EPANTokenIconWhite}
            alt="EPAN"
            className="token-icon-rounded-epan-raw"
          />
        </div>
      );
    case 'POLVEN':
      return <img src={POLVENTokenIcon} alt="POLVEN" className={cx('token-icon-rounded')} />;
    case 'USDT':
      return (
        <div
          className={cx('token-icon-rounded', 'token-icon-rounded-epan', {
            'token-icon-rounded-raw': raw,
          })}
        >
          <img src={usdtTokenIcon} alt="USDT" className="token-icon-rounded-epan-raw" />
        </div>
      );
    case 'ETH':
      return <img src={ETHIcon} alt="ETH" className={cx('token-icon-rounded')} />;
    case 'BNB':
      return <img src={BnbIcon} alt="BNB" className={cx('token-icon-rounded')} />;
    case 'CUSTOM':
      return <img src={CustomTokenIcon} alt="Custom Tokens" className={cx('token-icon-rounded')} />;
    default:
      return (
        <div className={cx('token-icon-rounded-default-icon-wrapper')}>
          <Image className={cx('token-icon-rounded-default-icon')} />
        </div>
      );
  }
};
