import { call, put, takeLatest, delay } from 'redux-saga/effects';
import {
  resetCompoundProcessToast,
  resetEpanStakingProcessToast,
  resetTransactionProcessToast,
  SHOW_COMPOUND_PROCESS_TOAST,
  SHOW_EPAN_STAKING_PROCESS_TOAST,
  SHOW_TRANSACTION_PROCESS_TOAST,
  showCompoundProcessToast,
  showEpanStakingProcessToast,
  showTransactionProcessToast,
  showSwappingTokenProcessToast,
  resetSwappingTokenProcessToast,
  SHOW_SWAPPING_TOKEN_PROCESS_TOAST,
  showCustomProcessToast,
  resetCustomProcessToast,
  SHOW_CUSTOM_PROCESS_TOAST,
  resetUniswapLiquidityApprovalProcessToast,
  showUniswapLiquidityApprovalProcessToast,
  SHOW_UNISWAP_LIQUIDITY_APPROVAL_PROCESS_TOAST,
  showUniswapLiquiditySupplyingProcessToast,
  resetUniswapLiquiditySupplyingProcessToast,
  SHOW_UNISWAP_LIQUIDITY_SUPPLYING_PROCESS_TOAST,
  showUniswapRemoveLiquidityProcessToast,
  resetUniswapRemoveLiquidityProcessToast,
  SHOW_UNISWAP_REMOVE_LIQUIDITY_PROCESS_TOAST,
  showRemovingLiquidityApprovalProcessToast,
  resetRemovingLiquidityApprovalProcessToast,
  SHOW_REMOVING_LIQUIDITY_APPROVAL_PROCESS_TOAST,
  showClubDealSwapApprovalProcessToast,
  resetClubDealSwapApprovalProcessToast,
  SHOW_CLUB_DEAL_SWAP_APPROVAL_PROCESS_TOAST,
  showClubDealSwappingProcessToast,
  resetClubDealSwappingProcessToast,
  SHOW_CLUB_DEAL_SWAPPING_PROCESS_TOAST,
  SHOW_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST,
  showRedeemSpotAccountProcessToast,
  resetRedeemSpotAccountProcessToast,
  RESET_EPAN_STAKING_PROCESS_TOAST,
  SHOW_SUCCESS_STAKE_EPAN_TOAST,
  SHOW_SUCCESS_WITHDRAW_EPAN_TOAST,
  SHOW_SUCCESS_APPROVE_EPAN_TOAST,
  showBridgeSwapProcessToast,
  resetBridgeSwapProcessToast,
  SHOW_BRIDGE_SWAP_PROCESS_TOAST,
  RESET_BRIDGE_SWAP_PROCESS_TOAST,
  SHOW_SUCCESS_BRIDGE_SWAP_TOAST,
  SHOW_SUCCESS_REDEEM_TOAST,
  showSpotTopUpProcessToast,
  resetSpotTopUpProcessToast,
  SHOW_SPOT_TOP_UP_PROCESS_TOAST,
  RESET_SPOT_TOP_UP_PROCESS_TOAST,
  SHOW_CONFIRMED_TRANSACTION_TOAST,
  RESET_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST,
} from './actions';
import { web3Service } from '../../services/blockchain';
import { IShowToastPayload, IShowTransactionProcessToastPayload } from './types';
import { TransactionReceipt } from 'web3-core';
import { __DEV__ } from '../../constants';
import { snackbarService } from '../../services';
import { PayloadAction } from 'typesafe-actions';

function* trxPendingSaga(payload: IShowTransactionProcessToastPayload) {
  if (payload.txHash) {
    try {
      let trx: Maybe<TransactionReceipt> = null;

      while (!trx) {
        trx = yield call([web3Service, web3Service.getTransactionReceipt], payload.txHash);

        if (!trx) {
          yield delay(10 * 1000);
        }
      }

      payload.onReceipt && payload.onReceipt(trx);
    } catch (e) {
      __DEV__ && console.log('-------- e', e);
    }
  }
}

function* transferPendingSaga({ payload }: ReturnType<typeof showTransactionProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetTransactionProcessToast());
  }
}

function* compoundPendingSaga({ payload }: ReturnType<typeof showCompoundProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetCompoundProcessToast());
  }
}

function* epanStakingPendingSaga({
  payload,
  type,
}: ReturnType<typeof showEpanStakingProcessToast>) {
  if (payload.txHash) {
    if (payload.wallet?.type !== 'metamaskExtension' && payload.wallet?.type !== 'coinbase') {
      yield call(trxPendingSaga, payload);
      yield put(resetEpanStakingProcessToast());
    } else {
      snackbarService.showTransactionPendingSnackbar(payload.message, type, payload.txHash);
    }
  }
}

function* resetEpanStakingPendingSaga() {
  yield snackbarService.closeSnackbar(SHOW_EPAN_STAKING_PROCESS_TOAST);
}

function* resetSpotTopUpPendingSaga() {
  yield snackbarService.closeSnackbar(SHOW_SPOT_TOP_UP_PROCESS_TOAST);
}

function* showConfirmedTransactionToast({ payload }: PayloadAction<string, IShowToastPayload>) {
  yield snackbarService.showSuccessSnackbar(payload.message);
}

function* showSuccessEpanStakingSaga({ payload }: PayloadAction<string, IShowToastPayload>) {
  yield snackbarService.showSuccessSnackbar(payload.message);
}

function* showSuccessRedeemSuccessSaga({ payload }: PayloadAction<string, IShowToastPayload>) {
  yield snackbarService.showSuccessSnackbar(payload.message);
}

function* bridgeSwapPendingSaga({ payload, type }: ReturnType<typeof showBridgeSwapProcessToast>) {
  if (payload.txHash) {
    if (payload.wallet?.type !== 'metamaskExtension' && payload.wallet?.type !== 'coinbase') {
      yield call(trxPendingSaga, payload);
      yield put(resetBridgeSwapProcessToast());
    } else {
      snackbarService.showTransactionPendingSnackbar(payload.message, type, payload.txHash);
    }
  }
}

function* resetBridgeSwapPendingSaga() {
  yield snackbarService.closeSnackbar(SHOW_BRIDGE_SWAP_PROCESS_TOAST);
}

function* showSuccessBridgeSwapSaga({ payload }: PayloadAction<string, IShowToastPayload>) {
  yield snackbarService.showSuccessSnackbar(payload.message);
}

function* swappingTokenPendingSaga({ payload }: ReturnType<typeof showSwappingTokenProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetSwappingTokenProcessToast());
  }
}

function* customToastPendingSaga({ payload }: ReturnType<typeof showCustomProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetCustomProcessToast());
  }
}

function* uniswapLiquidityApprovalToastPendingSaga({
  payload,
}: ReturnType<typeof showUniswapLiquidityApprovalProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetUniswapLiquidityApprovalProcessToast());
  }
}

function* uniswapLiquiditySupplyingToastPendingSaga({
  payload,
}: ReturnType<typeof showUniswapLiquiditySupplyingProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetUniswapLiquiditySupplyingProcessToast());
  }
}

function* uniswapRemoveLiquidityToastPendingSaga({
  payload,
}: ReturnType<typeof showUniswapRemoveLiquidityProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetUniswapRemoveLiquidityProcessToast());
  }
}

function* removingLiquidityApprovalToastPendingSaga({
  payload,
}: ReturnType<typeof showRemovingLiquidityApprovalProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetRemovingLiquidityApprovalProcessToast());
  }
}

function* clubDealSwapApprovalToastPendingSaga({
  payload,
}: ReturnType<typeof showClubDealSwapApprovalProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetClubDealSwapApprovalProcessToast());
  }
}

function* clubDealSwappingToastPendingSaga({
  payload,
}: ReturnType<typeof showClubDealSwappingProcessToast>) {
  if (
    payload.wallet?.type !== 'metamaskExtension' &&
    payload.wallet?.type !== 'coinbase' &&
    payload.txHash
  ) {
    yield call(trxPendingSaga, payload);
    yield put(resetClubDealSwappingProcessToast());
  }
}

function* redeemSpotAccountToastPendingSaga({
  type,
  payload,
}: ReturnType<typeof showRedeemSpotAccountProcessToast>) {
  if (payload.txHash) {
    snackbarService.showTransactionPendingSnackbar(payload.message, type, payload.txHash);
    yield call(trxPendingSaga, payload);
    yield put(resetRedeemSpotAccountProcessToast());
  }
}

function* resetRedeemSpotAccountProcessToastSaga() {
  yield snackbarService.closeSnackbar(SHOW_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST);
}

function* spotTopUpPendingSaga({ payload, type }: ReturnType<typeof showSpotTopUpProcessToast>) {
  if (payload.txHash) {
    if (payload.wallet?.type !== 'metamaskExtension' && payload.wallet?.type !== 'coinbase') {
      yield call(trxPendingSaga, payload);
      yield put(resetSpotTopUpProcessToast());
    } else {
      snackbarService.showTransactionPendingSnackbar(payload.message, type, payload.txHash);
    }
  }
}

function* showSuccessSpotTopUpSaga({ payload }: PayloadAction<string, IShowToastPayload>) {
  yield snackbarService.showSuccessSnackbar(payload.message);
}

export function* watchToastSaga() {
  yield takeLatest(SHOW_TRANSACTION_PROCESS_TOAST, transferPendingSaga);
  yield takeLatest(SHOW_COMPOUND_PROCESS_TOAST, compoundPendingSaga);
  yield takeLatest(SHOW_EPAN_STAKING_PROCESS_TOAST, epanStakingPendingSaga);
  yield takeLatest(SHOW_BRIDGE_SWAP_PROCESS_TOAST, bridgeSwapPendingSaga);
  yield takeLatest(SHOW_SWAPPING_TOKEN_PROCESS_TOAST, swappingTokenPendingSaga);
  yield takeLatest(SHOW_CUSTOM_PROCESS_TOAST, customToastPendingSaga);
  yield takeLatest(
    SHOW_UNISWAP_LIQUIDITY_APPROVAL_PROCESS_TOAST,
    uniswapLiquidityApprovalToastPendingSaga,
  );
  yield takeLatest(
    SHOW_UNISWAP_LIQUIDITY_SUPPLYING_PROCESS_TOAST,
    uniswapLiquiditySupplyingToastPendingSaga,
  );
  yield takeLatest(
    SHOW_UNISWAP_REMOVE_LIQUIDITY_PROCESS_TOAST,
    uniswapRemoveLiquidityToastPendingSaga,
  );
  yield takeLatest(
    SHOW_REMOVING_LIQUIDITY_APPROVAL_PROCESS_TOAST,
    removingLiquidityApprovalToastPendingSaga,
  );
  yield takeLatest(
    SHOW_CLUB_DEAL_SWAP_APPROVAL_PROCESS_TOAST,
    clubDealSwapApprovalToastPendingSaga,
  );
  yield takeLatest(SHOW_CLUB_DEAL_SWAPPING_PROCESS_TOAST, clubDealSwappingToastPendingSaga);

  yield takeLatest(SHOW_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST, redeemSpotAccountToastPendingSaga);
  yield takeLatest(RESET_SPOT_TOP_UP_PROCESS_TOAST, resetSpotTopUpPendingSaga);
  yield takeLatest(SHOW_CONFIRMED_TRANSACTION_TOAST, showConfirmedTransactionToast);
  yield takeLatest(SHOW_SPOT_TOP_UP_PROCESS_TOAST, spotTopUpPendingSaga);
  yield takeLatest(RESET_EPAN_STAKING_PROCESS_TOAST, resetEpanStakingPendingSaga);
  yield takeLatest(RESET_SPOT_TOP_UP_PROCESS_TOAST, resetSpotTopUpPendingSaga);
  yield takeLatest(SHOW_CONFIRMED_TRANSACTION_TOAST, showSuccessSpotTopUpSaga);
  yield takeLatest(
    [
      SHOW_SUCCESS_STAKE_EPAN_TOAST,
      SHOW_SUCCESS_WITHDRAW_EPAN_TOAST,
      SHOW_SUCCESS_APPROVE_EPAN_TOAST,
    ],
    showSuccessEpanStakingSaga,
  );
  yield takeLatest(SHOW_SUCCESS_REDEEM_TOAST, showSuccessRedeemSuccessSaga);
  yield takeLatest(RESET_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST, resetRedeemSpotAccountProcessToastSaga);
  yield takeLatest(RESET_BRIDGE_SWAP_PROCESS_TOAST, resetBridgeSwapPendingSaga);
  yield takeLatest([SHOW_SUCCESS_BRIDGE_SWAP_TOAST], showSuccessBridgeSwapSaga);
}
