import { useCallback, useMemo, useState } from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { getValidationSchema } from './validation-schema';
import { useDispatch } from 'react-redux';
import { Auth0Error, authService } from '../../services';
import { resetPasswordAuth0Error } from '../../redux/auth/actions';

export const useForm = () => {
  const initialValues: IResetPasswordFormData = { email: '' };
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const dispatch = useDispatch();
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const onSubmitForm = useCallback(
    async (
      { email }: IResetPasswordFormData,
      { setSubmitting }: FormikHelpers<IResetPasswordFormData>,
    ) => {
      try {
        await authService.resetPassword(email);
        setSubmitting(false);
        setEmailSent(true);
      } catch (e) {
        dispatch(resetPasswordAuth0Error(e as Auth0Error));
      }
    },
    [dispatch],
  );

  return {
    initialValues,
    onSubmitForm,
    validationSchema,
    emailSent,
  };
};
