import { __DEV__ } from '../constants';

class LocalStorageService {
  activeTokenListKey = 'activeTokenList';

  saveStringsArrayToStorage = async (key: string, name: string) => {
    try {
      const storedItem = localStorage.getItem(key);

      if (!storedItem) {
        await localStorage.setItem(key, JSON.stringify([name]));
        return;
      }
      const names: string[] = JSON.parse(String(storedItem));
      const isNameExist = names.includes(name);

      if (!isNameExist) {
        await localStorage.setItem(key, JSON.stringify([...names, name]));
      }
    } catch (err) {
      __DEV__ && console.log({ err });
    }
  };

  deleteStringsArrayToStorage = async (key: string, name: string) => {
    try {
      const storedItem = await localStorage.getItem(key);

      if (storedItem) {
        const names: string[] = JSON.parse(String(storedItem));
        const filteredNames = names.filter((item) => item !== name);
        await localStorage.setItem(key, JSON.stringify(filteredNames));
      }
    } catch (err) {
      __DEV__ && console.log({ err });
    }
  };

  setItem = async (key: string, value: string) => {
    try {
      await localStorage.setItem(key, value);
    } catch (err) {
      __DEV__ && console.log({ err });
    }
  };

  getItem = async (key: string) => {
    try {
      return await localStorage.getItem(key);
    } catch (err) {
      __DEV__ && console.log({ err });
    }
  };

  removeItem = async (key: string) => {
    try {
      await localStorage.removeItem(key);
    } catch (err) {
      __DEV__ && console.log({ err });
    }
  };
}

export const asyncStorageService = new LocalStorageService();
