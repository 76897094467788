import React from 'react';
import './style.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { AmountField, BaseModal, CommonButton } from '../../../../components';
import { networkTitles, networkToChainIdMap } from '../../../../constants';
import { mapNetworkToSwitchChainId } from '../../helper';
import { useSelector } from 'react-redux';
import { getCurrentNetwork } from '../../../../redux/blockchain/selectors';

export const ConfirmModal: React.FC<ICrossChainBridgeConfirmModalProps> = ({
  visible,
  title,
  onDismiss,
  onConfirm,
  fields = [],
}) => {
  const network = useSelector(getCurrentNetwork);
  const { t } = useTranslation(['cross-chain-bridge']);

  return (
    <BaseModal visible={visible} onDismiss={onDismiss} title={title}>
      <span className={cx('ccb-main-text', 'ccb-networks-transfer', 'ccb-confirm-modal-subtitle')}>
        {t('transfer-between-networks', {
          networkFrom: t(networkTitles[networkToChainIdMap[network]]),
          networkTo: t(networkTitles[mapNetworkToSwitchChainId[network]]),
        })}
      </span>
      {fields.map((item, idx) => (
        <AmountField
          key={`${item.value}${item.label}${idx}`}
          label={t(item.label)}
          amount={item.value}
        />
      ))}
      <CommonButton
        className="ccb-confirm-modal-button"
        label={t('common:ok')}
        onClick={onConfirm}
      />
    </BaseModal>
  );
};
