import React from 'react';
import './style.scss';
import { DismissAction } from './DismissAction';
import { CircularProgress } from '@material-ui/core';

interface ApprovalTransactionActionProps {
  key: string;
  onCancelPress: () => void;
}

export const ApprovalTransactionAction: React.FC<ApprovalTransactionActionProps> = ({
  key,
  onCancelPress,
}) => {
  return (
    <>
      <CircularProgress size={'1rem'} className={'snackbar-actions-spinner'} />
      <DismissAction snackbarKey={key} callback={onCancelPress} />
    </>
  );
};
