import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BaseModal } from '../../../../components';
import { blocks } from './helpers';
import './styles.scss';
import { IProps } from './types';

export const Privacy = (props: IProps) => {
  const { t } = useTranslation(['privacy']);

  return (
    <BaseModal title={t('title')} visible={props.isVisible} onDismiss={props.onDismiss}>
      <div>
        {blocks.map((val) => {
          return (
            <div className="privacy-block">
              <span className="privacy-block-title">{t(val.title)}</span>
              <div className="privacy-block-description">
                <span>
                  <Trans
                    t={t}
                    components={{
                      br: <br />,
                      circleUl: <ul className="privacy-circle-list" />,
                      alphaUl: <ul className="privacy-alpha-list" />,
                      li: <li />,
                    }}
                    i18nKey={val.description}
                  />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </BaseModal>
  );
};
