import {
  CRYPTO_BALANCE_PRECISION,
  CURRENCIES_TO_ROUND_MAP,
  DEFAULT_CURRENCY_AMOUNT_PRECISION,
  DEFAULT_PRECISION,
  MIN_DEFAULT_AMOUNT,
  MIN_NATIVE_TOKEN_AMOUNT,
} from '../constants';
import { thousandSeparator } from './thousandSeparator';

export const noop = () => {};

export const capitalize = (s: string) => {
  if (!s.length) {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getEllipsizeString = (value = '', factor = 5) => {
  return value ? `${value.substring(0, factor)}...${value.substring(value.length - factor)}` : '';
};

export const shortenString = (value = '', factor: number) => {
  return `${value.length > factor ? `${value.substring(0, factor)}...` : value}`;
};

export const getMaxNumberInArray = (arr: number[]) => Math.max.apply(null, arr);

export const roundToDecimals = (value?: number | string, decimals = DEFAULT_PRECISION) => {
  if (!value) {
    return '0.00';
  }
  return Number(value).toFixed(decimals);
};

export const getRoundedBalance = (value?: string | number, currency?: string) => {
  if (Number.isNaN(Number(value)) || !value) {
    return '0.00';
  }

  const isInteger = Number.isInteger(Number(value ?? 0));
  const isNativeToken = CURRENCIES_TO_ROUND_MAP.includes(currency ?? '');

  if (isInteger || isNativeToken) {
    return thousandSeparator(Number(value ?? 0).toFixed(DEFAULT_CURRENCY_AMOUNT_PRECISION));
  }

  const parsedValue = parseFloat(Number(value ?? 0).toFixed(CRYPTO_BALANCE_PRECISION));
  const splitValueStr = String(parsedValue).split('.');
  const isOneNumberAfterDot = splitValueStr[1]?.length === 1;

  return thousandSeparator(
    String(
      isOneNumberAfterDot ? parsedValue.toFixed(DEFAULT_CURRENCY_AMOUNT_PRECISION) : parsedValue,
    ),
  );
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const percIncrease = (initial: number, current: number) => {
  let percent;
  if (current !== 0) {
    if (initial !== 0) {
      percent = ((current - initial) / initial) * 100;
    } else {
      percent = current * 100;
    }
  } else {
    percent = -initial * 100;
  }
  return Math.floor(percent);
};

export const getMinInputAmountByCurrency = (currency?: string) => {
  return CURRENCIES_TO_ROUND_MAP.includes(currency ?? '')
    ? MIN_NATIVE_TOKEN_AMOUNT
    : MIN_DEFAULT_AMOUNT;
};
