import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEthNetwork, roundToDecimals } from '../../../helpers';
import { getCurrentNetwork, getExternalWalletType } from '../../../redux/blockchain/selectors';
import { web3Service } from '../../../services/blockchain';
import { setCurrentChainIdBlockchainError } from '../../../redux/blockchain/actions';
import { mapNetworkToSwitchChainId } from '../helper';

export const useScreen = ({
  connectedAddress,
  walletsBalances,
}: ICrossChainBridgeUseScreenParams) => {
  const externalWalletType = useSelector(getExternalWalletType);
  const dispatch = useDispatch();
  const network = useSelector(getCurrentNetwork);
  const tokenBalance = useMemo(() => {
    const balances = {
      EPAN: walletsBalances?.find((wallet) => wallet.currency === 'EPAN')?.balance ?? 0,
      BEPAN: walletsBalances?.find((wallet) => wallet.currency === 'BEPAN')?.balance ?? 0,
    };
    return isEthNetwork(network)
      ? roundToDecimals(balances?.EPAN ?? 0)
      : roundToDecimals(balances.BEPAN ?? 0);
  }, [walletsBalances]);
  const selectedWallet = walletsBalances?.find(
    (wallet) => wallet.address?.toLowerCase() === connectedAddress?.toLowerCase(),
  );

  const handleChangeNetwork = useCallback(async () => {
    try {
      if (externalWalletType === 'metamaskExtension' || externalWalletType === 'coinbase') {
        await web3Service.requestMetamaskNetworkChange(mapNetworkToSwitchChainId[network]);
      }
    } catch (e) {
      dispatch(setCurrentChainIdBlockchainError(e as Error));
    }
  }, [dispatch, network, externalWalletType]);

  return {
    tokenBalance,
    selectedWallet,
    handleChangeNetwork,
  };
};
