import { httpService } from '../../../services';

export const CONFIGURATION_URL = '/configuration';

export class ConfigurationResource {
  async getConfiguration() {
    return httpService.get<IApiConfiguration>({
      url: CONFIGURATION_URL,
    });
  }
}

export const configurationResource = new ConfigurationResource();
