import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as EyeIcon } from '../../assets/images/icons/eye-icon.svg';
import { ReactComponent as CrossedOutEyeIcon } from '../../assets/images/icons/crossed-out-eye-icon.svg';
import { getValidationSchema } from './validation-schema';
import { CommonButton, FormTextField } from '../../components';
import './style.scss';
import cx from 'classnames';
import { useForm } from './use-form';
import { useAppLoading } from '../../hooks';
import { routes } from '../../constants';
import { UserAuthHeader } from '../../components';

export const SignInPage: React.FC = () => {
  const { t } = useTranslation(['common', 'sign-in']);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const { initialValues, onSubmitForm } = useForm();
  const appLoading = useAppLoading();

  return (
    <div className="signIn">
      <UserAuthHeader containerClassName="signIn-auth-header" title={t('common:welcome')} />
      <Formik<ISignInFormData>
        validateOnMount
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onSubmit={onSubmitForm}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <span className={cx('signIn-main-text', 'signIn-input-title')}>{t('email')}</span>
            <FormTextField<ISignInFormData>
              inputContainerClassName={cx('signIn-email-input')}
              name="email"
              type="email"
              label={t('email')}
            />
            <span className={cx('signIn-main-text', 'signIn-input-title')}>{t('password')}</span>
            <FormTextField<ISignInFormData>
              name="password"
              inputContainerClassName={cx('signIn-password-input')}
              type={passwordShown ? 'text' : 'password'}
              label={t('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordShown((prev) => !prev)}
                      edge="end"
                    >
                      {passwordShown ? <EyeIcon /> : <CrossedOutEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={cx('signIn-remember-me')}>
              <Link className={cx('signIn-main-text', 'signIn-link')} to={routes.RESET_PASSWORD}>
                {t('sign-in:forgot-password')}
              </Link>
            </div>
            <CommonButton
              size="large"
              type="submit"
              label={t('sign-in')}
              className="signIn-submit-button"
              containerClassName="signIn-submit-button-container"
              loading={isSubmitting || appLoading}
              disabled={isSubmitting || appLoading || !isValid}
            />
            <div className={cx('signIn-sign-up')}>
              <span className={cx('signIn-main-text', 'signIn-have-account-question')}>
                {t('sign-in:have-account-question')}
              </span>
              <Link className={cx('signIn-main-text', 'signIn-link')} to={routes.SIGN_UP}>
                {t('sign-up')}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
