import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { usersResource } from '../../../../api';
import {
  checkVerificationCodeFailure,
  finishSignUp,
  resendConfirmationCodeFailure,
  setConfirmationCodeTimeoutTimestamp,
  setSignUpState,
} from '../../../../redux/auth/actions';
import debounce from 'lodash.debounce';
import { getSignUpData } from '../../../../redux/auth/selectors';
import { getAppLoading } from '../../../../redux/app/selectors';
import { SignUpStep } from '../../../../redux/auth/types';
import { authService } from '../../../../services';
import { useTranslation } from 'react-i18next';
import {
  formatResendCodeTimer,
  getResendConfirmationCodeTimeoutTimestamp,
} from '../../../../helpers';

export const useForm = (type: OTPConfirmationType) => {
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation(['sign-up']);
  const appLoading = useSelector(getAppLoading);
  const { phoneNumber, email, firstName, lastName } = useSelector(getSignUpData, shallowEqual);

  const { mutateAsync: checkVerificationCode, isLoading: submitting } = useMutation<
    ICheckVerificationCodeResponse | undefined,
    Error,
    ICheckVerificationCodeParams
  >(usersResource.checkVerificationCode);

  const { mutateAsync: editUserRequest, isLoading: isLoadingEditUser } = useMutation<
    EditUserResponse | undefined,
    Error,
    EditUserParams
  >(usersResource.editUser);

  const {
    mutateAsync: checkEmailVerificationCode,
    isLoading: isLoadingCheckEmailVerificationCode,
  } = useMutation<
    ICheckEmailVerificationCodeResponse | undefined,
    Error,
    ICheckEmailVerificationCodeParams
  >(usersResource.checkEmailVerificationCode);

  const { mutateAsync: sendVerificationEmail, isLoading: isLoadingSendVerificationEmail } =
    useMutation<ISendVerificationEmailResponse | undefined, Error, ISendVerificationEmailParams>(
      usersResource.sendVerificationEmail,
    );

  const { mutateAsync: sendVerificationSms, isLoading: isLoadingSendVerificationSms } = useMutation<
    ISendVerificationSmsResponse | undefined,
    Error,
    ISendVerificationSmsParams
  >(usersResource.sendVerificationSms);

  const onSubmitForm = useCallback(
    debounce(async () => {
      try {
        if (type === 'email') {
          await checkEmailVerificationCode({ code });
          dispatch(setSignUpState({ step: SignUpStep.FullNamePhoneNumber }));
        } else if (type === 'phone') {
          await checkVerificationCode({ phoneNumber, code });
          await editUserRequest({ phoneNumber, firstName, lastName, areTermsAccepted: true });
          await authService.updateUser({ phoneNumber, firstName, lastName });
          dispatch(finishSignUp());
        }
        dispatch(setConfirmationCodeTimeoutTimestamp(null));
      } catch (e) {
        dispatch(checkVerificationCodeFailure(e as Error));
      }
    }, 300),
    [dispatch, checkVerificationCode, code, phoneNumber],
  );

  const resendCodeClick = useCallback(
    debounce(async () => {
      try {
        switch (type) {
          case 'email':
            await sendVerificationEmail({ email });
            break;
          case 'phone':
            await sendVerificationSms({ phoneNumber });
            break;
          default:
            break;
        }
        dispatch(setConfirmationCodeTimeoutTimestamp(getResendConfirmationCodeTimeoutTimestamp()));
      } catch (e) {
        dispatch(resendConfirmationCodeFailure(e as Error));
      }
    }, 300),
    [email, type, phoneNumber, dispatch, sendVerificationEmail, sendVerificationSms],
  );

  const getTimerLabel = useCallback(
    (value: number) => {
      if (!value) {
        setTimeout(() => {
          dispatch(setConfirmationCodeTimeoutTimestamp(0));
        }, 1000);
      }
      return t('resend-code-timer', { timer: formatResendCodeTimer(value) });
    },
    [t, dispatch],
  );

  return {
    code,
    setCode,
    onSubmitForm,
    getTimerLabel,
    resendCodeClick,
    phoneNumber,
    email,
    resendingCode: isLoadingSendVerificationEmail || isLoadingSendVerificationSms,
    submitting: submitting || isLoadingCheckEmailVerificationCode || isLoadingEditUser,
    appLoading,
  };
};
