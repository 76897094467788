import React from 'react';
import './styles.scss';
import logo from './../assets/images/paypolitan-logo.svg';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from '../constants';
import { NotFoundPage, SignInPage, SignUpPage, ResetPasswordPage } from '../pages';

export const AuthRouter: React.FC = () => (
  <div className="userAuth">
    <div className="userAuth-image">
      <img className="userAuth-image-logo" src={logo} alt="logo" />
    </div>
    <div className="userAuth-form">
      <Switch>
        <Route path={routes.SIGN_IN} component={SignInPage} />
        <Route path={routes.SIGN_UP} component={SignUpPage} />
        <Route path={routes.RESET_PASSWORD} component={ResetPasswordPage} />
        <Redirect exact from="/auth" to={routes.SIGN_IN} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </div>
  </div>
);
