import React from 'react';
import cx from 'classnames';
import './style.scss';
import { ICommonTileContainerProps } from './types';

export const CommonTileContainer: React.FC<ICommonTileContainerProps> = ({
  children,
  className,
}) => {
  return <div className={cx('common-tile-container', className)}>{children}</div>;
};
