import React, { useCallback, useState } from 'react';
import { paypolitanStakingService } from '../../services/blockchain';
import { getPaypolitanStakingTimeframeDaysLeft } from '../../helpers';
import { usePaypolitanStakingApy } from '../../hooks';
import { shallowEqual, useSelector } from 'react-redux';
import { getEpanStakingProcessToast } from '../../redux/toast/selectors';

export const useScreen = (
  currency: PaypolitanStakingType,
  amountFieldRef: React.RefObject<HTMLInputElement>,
) => {
  const { visible: processingToastVisible } = useSelector(getEpanStakingProcessToast, shallowEqual);
  const [defiStakingAction, setDefiStakingAction] = useState<DefiStakingActions>('stake');
  const [epanStakingPeriod, setEpanStakingPeriod] = useState<EpanStakingPeriod>('long');
  const [longPeriodWithdrawDaysLeft, setLongPeriodWithdrawDaysLeft] = useState<number>(0);
  const longTermSelected = epanStakingPeriod === 'long';
  const [longTermLocked, setLongTermLocked] = useState<boolean>(false);

  const closeStackingPeriodModal = useCallback(() => {
    setLongPeriodWithdrawDaysLeft(0);
  }, []);

  const handleSelectDefiStakingAction = useCallback(
    (action: DefiStakingActions, fromWallet: Maybe<IWallet>) =>
      (period: EpanStakingPeriod) =>
      async () => {
        if (action === 'withdraw') {
          const resp = await paypolitanStakingService.getStakesInfo(currency, fromWallet?.address);

          const days = getPaypolitanStakingTimeframeDaysLeft(resp.lastStakeTime);

          if (days > 0) {
            if (period === 'long') {
              setLongPeriodWithdrawDaysLeft(days);
            }

            setLongTermLocked(true);
          } else {
            setLongTermLocked(false);
          }

          setEpanStakingPeriod(period);
          setDefiStakingAction(action);
        } else {
          setLongTermLocked(false);
          setDefiStakingAction(action);
          setEpanStakingPeriod(period);
        }
        amountFieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        amountFieldRef.current?.focus({ preventScroll: true });
      },
    [currency, amountFieldRef],
  );

  const handleSelectEpanStakingPeriod = useCallback(
    (period: EpanStakingPeriod) => () => {
      setEpanStakingPeriod(period);
    },
    [],
  );

  const { stakingApy, stakingApyDisplayValue } = usePaypolitanStakingApy(
    currency,
    epanStakingPeriod === 'long',
  );

  return {
    defiStakingAction,
    handleSelectDefiStakingAction,
    epanStakingPeriod,
    stakingApyDisplayValue,
    handleSelectEpanStakingPeriod,
    longPeriodWithdrawDaysLeft,
    closeStackingPeriodModal,
    processingToastVisible,
    stakingApy,
    longTermSelected,
    longTermLocked,
  };
};
