import React from 'react';
import './style.scss';
import cx from 'classnames';
import { CommonButton, FormTextField, PageContentContainer } from '../../components';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentNetwork } from '../../redux/blockchain/selectors';
import { networkTitles, networkToChainIdMap } from '../../constants';
import { formatAmount, formatTokenBalance, mapNetworkToSwitchChainId } from './helper';
import { Formik } from 'formik';
import { isEthNetwork } from '../../helpers';
import { ConfirmModal, NetworksBridge } from './components';
import { Divider } from '@mui/material';
import {
  useConnectionWalletModal,
  useCurrentUserWalletsBalances,
  useResetFormOnWalletDisconnection,
  useWalletConnector,
} from '../../hooks';
import { useScreen, useForm, useBridgeSwapAllowance } from './hooks';
import { getBridgeSwapProcessToast } from '../../redux/toast/selectors';

export const CrossChainBridgePage = () => {
  const network = useSelector(getCurrentNetwork);
  const { t } = useTranslation(['cross-chain-bridge']);
  const { walletsBalances } = useCurrentUserWalletsBalances();
  const { visible: processingToastVisible } = useSelector(getBridgeSwapProcessToast, shallowEqual);
  const { walletConnected, connectedAddress, isChainSupported } = useWalletConnector();
  const { formRef } = useResetFormOnWalletDisconnection<ICrossChainBridgeFormData>();
  const { tokenBalance, selectedWallet, handleChangeNetwork } = useScreen({
    connectedAddress,
    walletsBalances,
  });
  const { openModal: openConnectionWalletModal } = useConnectionWalletModal();
  const { isApproveVisible } = useBridgeSwapAllowance('EPAN_BEPAN', selectedWallet);
  const {
    initialValues,
    validationSchema,
    onSubmitForm,
    handleApprove,
    approveLoading,
    confirmModalValues,
    confirmModalVisible,
    onDismissConfirmModal,
  } = useForm({
    tokenBalance,
    selectedWallet,
  });

  return (
    <>
      <PageContentContainer className="ccb">
        <span className={cx('ccb-main-text', 'ccb-balance-label')}>
          {t('staking:available-balance')}
        </span>
        <span className={cx('ccb-balance-value')}>
          {formatTokenBalance(tokenBalance, walletConnected, network)}
        </span>
        <span className={cx('ccb-main-text', 'ccb-networks-transfer')}>
          {t('transfer-between-networks', {
            networkFrom: t(networkTitles[networkToChainIdMap[network]]),
            networkTo: t(networkTitles[mapNetworkToSwitchChainId[network]]),
          })}
        </span>
        <Formik
          validateOnMount
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, isValid, isSubmitting, submitForm }) => {
            return (
              <>
                <span className={cx('ccb-secondary-text')}>{t('common:amount')}</span>
                <FormTextField<ICrossChainBridgeFormData>
                  name="amount"
                  placeholder={'0.00'}
                  className="ccb-input"
                  disabled={!walletConnected}
                  inputContainerClassName="ccb-input-container"
                  rightComponent={
                    <span className={cx('ccb-main-text', 'ccb-currency')}>
                      {isEthNetwork(network) ? 'EPAN' : 'BEPAN'}
                    </span>
                  }
                />
                <NetworksBridge
                  walletConnected={walletConnected}
                  network={network}
                  handleChangeNetwork={handleChangeNetwork}
                />
                {walletConnected && (
                  <>
                    <span className={cx('ccb-main-text', 'ccb-receive-token')}>
                      {t('receive-after-swap', {
                        amount: formatAmount(values.amount),
                        currency: isEthNetwork(network) ? 'BEPAN' : 'EPAN',
                        network: t(networkTitles[mapNetworkToSwitchChainId[network]]),
                      })}
                    </span>
                    <Divider className={cx('ccb-divider')} />
                    {isApproveVisible(values.amount) && walletConnected && (
                      <span className="ccb-unlimited-approval-info">
                        {t('common:unlimited-approval-info')}
                      </span>
                    )}
                  </>
                )}
                {isChainSupported && (
                  <div className="ccb-submit-buttons">
                    {!walletConnected ? (
                      <CommonButton
                        type={'button'}
                        label={t('common:connect-wallet')}
                        onClick={openConnectionWalletModal}
                      />
                    ) : (
                      <>
                        <CommonButton
                          type={'submit'}
                          fullWidth
                          onClick={submitForm}
                          label={t('common:transfer')}
                          disabled={
                            !isValid ||
                            isSubmitting ||
                            processingToastVisible ||
                            isApproveVisible(values.amount)
                          }
                          loading={isSubmitting}
                        />
                        {isApproveVisible(values.amount) && (
                          <>
                            <div className="ccb-spacer" />
                            <CommonButton
                              fullWidth
                              type={'button'}
                              label={t('common:approve')}
                              design="negative"
                              onClick={handleApprove()}
                              disabled={!isValid || isSubmitting || processingToastVisible}
                              loading={approveLoading}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </PageContentContainer>
      {!!confirmModalValues && (
        <ConfirmModal
          onDismiss={onDismissConfirmModal}
          visible={confirmModalVisible}
          {...confirmModalValues}
        />
      )}
    </>
  );
};
