export const blocks = [
  {
    title: 'definition-title',
    description: 'definition-description',
  },
  {
    title: 'genprovisions-title',
    description: 'genprovisions-description',
  },
  {
    title: 'registration-title',
    description: 'registration-description',
  },
  {
    title: 'taxes-title',
    description: 'taxes-description',
  },
  {
    title: 'intellectual-rights-title',
    description: 'intellectual-rights-description',
  },
  {
    title: 'third-party-title',
    description: 'third-party-description',
  },
  {
    title: 'purchase-limitations-title',
    description: 'purchase-limitations-description',
  },
  {
    title: 'disclaimers-title',
    description: 'disclaimers-description',
  },
  {
    title: 'limitations-title',
    description: 'limitations-description',
  },
  {
    title: 'indemnity-title',
    description: 'indemnity-description',
  },
  {
    title: 'applicable-law-title',
    description: 'applicable-law-description',
  },
  {
    title: 'waiver-title',
    description: 'waiver-description',
  },
  {
    title: 'termination-title',
    description: 'termination-description',
  },
  {
    title: 'miscllaneous-title',
    description: 'miscllaneous-description',
  },
  {
    title: 'contact-title',
    description: 'contact-description',
  },
];
