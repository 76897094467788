import { SnackbarProvider } from 'notistack';
import React from 'react';
import { PendingTransactionAction, DismissAction, ApprovalTransactionAction } from '../components';

class SnackbarService {
  maxSnack = 3;
  snackbar: Maybe<SnackbarProvider> = null;

  assignRef = (ref: Maybe<SnackbarProvider>) => {
    this.snackbar = ref;
  };

  closeSnackbar = (key: string) => {
    this.snackbar?.closeSnackbar(key);
  };

  showErrorSnackbar = (message: string) => {
    this.snackbar?.enqueueSnackbar(message, {
      variant: 'error',
      key: message,
      action: <DismissAction snackbarKey={message} />,
    });
  };

  showSuccessSnackbar = (message: string) => {
    this.snackbar?.enqueueSnackbar(message, {
      variant: 'success',
      key: message,
      action: <DismissAction snackbarKey={message} />,
    });
  };

  showTransactionPendingSnackbar = (message: string, key: string, txHash: string) => {
    this.snackbar?.enqueueSnackbar(message, {
      variant: 'info',
      key,
      persist: true,
      action: <PendingTransactionAction snackbarKey={key} txHash={txHash} />,
    });
  };

  showWatingForApprovalTxSnackbar = (message: string, key: string, onCancelPress: () => void) => {
    this.snackbar?.enqueueSnackbar(message, {
      variant: 'info',
      key,
      persist: true,
      action: <ApprovalTransactionAction key={key} onCancelPress={onCancelPress} />,
    });
  };
}

export const snackbarService = new SnackbarService();
