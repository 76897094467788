import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { translate } from '../../../../api';
import {
  MAX_NAME_LENGTH,
  MIN_NAME_LENGTH,
  NAME_REGEX,
  ONLY_PHONE_NUM_REGEX,
  PHONE_NUMBER_MIN_LENGTH,
} from '../../../../constants';
import { isValidPhoneNumber } from '../../../../helpers';
import { IUpdateProfileData } from './types';
import { isEmail } from 'class-validator';

export const getValidationSchema = (): SchemaOf<IUpdateProfileData> =>
  Yup.object({
    email: Yup.string()
      .email(translate('incorrect-email', 'errors'))
      .required(translate('required', 'errors'))
      .test('additional-email-validations', translate('incorrect-email', 'errors'), (value) => {
        return isEmail(value);
      }),
    firstName: Yup.string()
      .trim()
      .required(translate('required', 'errors'))
      .matches(NAME_REGEX, translate('name-start-with-digit', 'errors'))
      .min(MIN_NAME_LENGTH, translate('name-length', 'errors'))
      .max(MAX_NAME_LENGTH, translate('name-length', 'errors')),
    lastName: Yup.string()
      .trim()
      .required(translate('required', 'errors'))
      .matches(NAME_REGEX, translate('name-start-with-digit', 'errors'))
      .min(MIN_NAME_LENGTH, translate('name-length', 'errors'))
      .max(MAX_NAME_LENGTH, translate('name-length', 'errors')),
    phoneNumber: Yup.string()
      .trim()
      .required(translate('required', 'errors'))
      .min(PHONE_NUMBER_MIN_LENGTH, translate('phone-number-short', 'errors'))
      .test('isValid phone', translate('invalid-phone-number', 'errors'), (value) => {
        try {
          if (!value) {
            return true;
          }

          const arr = value.split('-');
          const phone = arr[0] ?? '';
          const extractedPhone = phone.replace(ONLY_PHONE_NUM_REGEX, '');

          if (Number.isNaN(Number(extractedPhone))) {
            return false;
          }

          return isValidPhoneNumber(`+${extractedPhone}`);
        } catch {
          return false;
        }
      }),
  });
