import { createAction } from 'typesafe-actions';
import {
  IShowClubDealSwapApprovalToastPayload,
  IShowClubDealSwappingToastPayload,
  IShowCustomToastPayload,
  IShowSwappingTokenProcessToastPayload,
  IShowToastPayload,
  IShowTransactionProcessToastPayload,
  IShowUniswapLiquidityApprovalToastPayload,
  IShowUniswapRemoveLiquidityToastPayload,
} from './types';

export const SHOW_SENT_TRANSACTION_TOAST = '@TOAST/SHOW_SENT_TRANSACTION_TOAST';
export const SHOW_CONFIRMED_TRANSACTION_TOAST = '@TOAST/SHOW_CONFIRMED_TRANSACTION_TOAST';
export const SHOW_SUCCESS_COMPOUND_MINT_TOAST = '@TOAST/SHOW_SUCCESS_COMPOUND_MINT_TOAST';
export const SHOW_SUCCESS_COMPOUND_REDEEM_TOAST = '@TOAST/SHOW_SUCCESS_COMPOUND_REDEEM_TOAST';
export const RESET_TOAST = '@TOAST/RESET_TOAST';
export const SHOW_SUCCESS_STAKE_EPAN_TOAST = '@TOAST/SHOW_SUCCESS_STAKE_EPAN_TOAST';
export const SHOW_SUCCESS_REDEEM_TOAST = '@TOAST/SHOW_SUCCESS_REDEEM_TOAST';
export const SHOW_SUCCESS_APPROVE_EPAN_TOAST = '@TOAST/SHOW_SUCCESS_APPROVE_EPAN_TOAST';
export const SHOW_SUCCESS_BRIDGE_SWAP_TOAST = '@TOAST/SHOW_SUCCESS_BRIDGE_SWAP_TOAST';
export const SHOW_SUCCESS_WITHDRAW_EPAN_TOAST = '@TOAST/SHOW_SUCCESS_WITHDRAW_EPAN_TOAST';
export const SHOW_TRANSACTION_PROCESS_TOAST = '@TOAST/SHOW_TRANSACTION_PROCESS_TOAST';
export const RESET_TRANSACTION_PROCESS_TOAST = '@TOAST/RESET_TRANSACTION_PROCESS_TOAST';
export const SHOW_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST =
  '@TOAST/SHOW_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST';
export const RESET_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST =
  '@TOAST/RESET_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST';
export const SHOW_COMPOUND_PROCESS_TOAST = '@TOAST/SHOW_COMPOUND_PROCESS_TOAST';
export const RESET_COMPOUND_PROCESS_TOAST = '@TOAST/RESET_COMPOUND_PROCESS_TOAST';
export const SHOW_EPAN_STAKING_PROCESS_TOAST = '@TOAST/SHOW_EPAN_STAKING_PROCESS_TOAST';
export const RESET_EPAN_STAKING_PROCESS_TOAST = '@TOAST/RESET_EPAN_STAKING_PROCESS_TOAST';
export const SHOW_BRIDGE_SWAP_PROCESS_TOAST = '@TOAST/SHOW_BRIDGE_SWAP_PROCESS_TOAST';
export const RESET_BRIDGE_SWAP_PROCESS_TOAST = '@TOAST/RESET_BRIDGE_SWAP_PROCESS_TOAST';
export const TOGGLE_NETWORK_SELECTOR = '@TOAST/TOGGLE_NETWORK_SELECTOR';
export const TOGGLE_CUSTOM_TOKENS_MODAL = '@TOAST/TOGGLE_CUSTOM_TOKENS_MODAL';
export const SHOW_SWAPPING_TOKEN_PROCESS_TOAST = '@TOAST/SHOW_SWAPPING_TOKEN_PROCESS_TOAST';
export const RESET_SWAPPING_TOKEN_PROCESS_TOAST = '@TOAST/SWAPPING_TOKEN_PROCESS_TOAST';
export const SHOW_CUSTOM_PROCESS_TOAST = '@TOAST/SHOW_CUSTOM_PROCESS_TOAST';
export const RESET_CUSTOM_PROCESS_TOAST = '@TOAST/RESET_CUSTOM_PROCESS_TOAST';
export const SHOW_UNISWAP_LIQUIDITY_APPROVAL_PROCESS_TOAST =
  '@TOAST/SHOW_UNISWAP_LIQUIDITY_APPROVAL_PROCESS_TOAST';
export const RESET_UNISWAP_LIQUIDITY_APPROVAL_PROCESS_TOAST =
  '@TOAST/RESET_UNISWAP_LIQUIDITY_APPROVAL_PROCESS_TOAST';
export const SHOW_UNISWAP_LIQUIDITY_SUPPLYING_PROCESS_TOAST =
  '@TOAST/SHOW_UNISWAP_LIQUIDITY_SUPPLYING_PROCESS_TOAST';
export const RESET_UNISWAP_LIQUIDITY_SUPPLYING_PROCESS_TOAST =
  '@TOAST/RESET_UNISWAP_LIQUIDITY_SUPPLYING_PROCESS_TOAST';
export const SHOW_UNISWAP_REMOVE_LIQUIDITY_PROCESS_TOAST =
  '@TOAST/SHOW_UNISWAP_REMOVE_LIQUIDITY_PROCESS_TOAST';
export const RESET_UNISWAP_REMOVE_LIQUIDITY_PROCESS_TOAST =
  '@TOAST/RESET_UNISWAP_REMOVE_LIQUIDITY_PROCESS_TOAST';
export const SHOW_REMOVING_LIQUIDITY_APPROVAL_PROCESS_TOAST =
  '@TOAST/SHOW_REMOVING_LIQUIDITY_APPROVAL_PROCESS_TOAST';
export const RESET_REMOVING_LIQUIDITY_APPROVAL_PROCESS_TOAST =
  '@TOAST/RESET_REMOVING_LIQUIDITY_APPROVAL_PROCESS_TOAST';
export const SHOW_CLUB_DEAL_SWAP_APPROVAL_PROCESS_TOAST =
  '@TOAST/SHOW_CLUB_DEAL_SWAP_APPROVAL_PROCESS_TOAST';
export const RESET_CLUB_DEAL_SWAP_APPROVAL_PROCESS_TOAST =
  '@TOAST/RESET_CLUB_DEAL_SWAP_APPROVAL_PROCESS_TOAST';
export const RESET_ALL_PROCESS_TOAST = '@TOAST/RESET_ALL_PROCESS_TOAST';
export const SHOW_CLUB_DEAL_SWAPPING_PROCESS_TOAST = '@TOAST/SHOW_CLUB_DEAL_SWAPPING_PROCESS_TOAST';
export const RESET_CLUB_DEAL_SWAPPING_PROCESS_TOAST =
  '@TOAST/RESET_CLUB_DEAL_SWAPPING_PROCESS_TOAST';
export const SHOW_SPOT_TOP_UP_PROCESS_TOAST = '@TOAST/SHOW_SPOT_TOP_UP_PROCESS_TOAST';
export const RESET_SPOT_TOP_UP_PROCESS_TOAST = '@TOAST/RESET_SPOT_TOP_UP_PROCESS_TOAST';

export const showSentTransactionToast = createAction(
  SHOW_SENT_TRANSACTION_TOAST,
)<IShowToastPayload>();
export const showConfirmedTransactionToast = createAction(
  SHOW_CONFIRMED_TRANSACTION_TOAST,
)<IShowToastPayload>();
export const showSuccessCompoundMintToast = createAction(
  SHOW_SUCCESS_COMPOUND_MINT_TOAST,
)<IShowToastPayload>();
export const showSuccessCompoundRedeemToast = createAction(
  SHOW_SUCCESS_COMPOUND_REDEEM_TOAST,
)<IShowToastPayload>();
export const showSuccessRedeemToast = createAction(SHOW_SUCCESS_REDEEM_TOAST)<IShowToastPayload>();
export const resetToast = createAction(RESET_TOAST)();
export const showSuccessStakeEpanToast = createAction(
  SHOW_SUCCESS_STAKE_EPAN_TOAST,
)<IShowToastPayload>();
export const showSuccessApproveEpanToast = createAction(
  SHOW_SUCCESS_APPROVE_EPAN_TOAST,
)<IShowToastPayload>();
export const showSuccessBridgeSwapToast = createAction(
  SHOW_SUCCESS_BRIDGE_SWAP_TOAST,
)<IShowToastPayload>();
export const showSuccessWithdrawEpanToast = createAction(
  SHOW_SUCCESS_WITHDRAW_EPAN_TOAST,
)<IShowToastPayload>();
export const showTransactionProcessToast = createAction(
  SHOW_TRANSACTION_PROCESS_TOAST,
)<IShowTransactionProcessToastPayload>();
export const resetTransactionProcessToast = createAction(RESET_TRANSACTION_PROCESS_TOAST)();
export const showRedeemSpotAccountProcessToast = createAction(
  SHOW_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST,
)<IShowTransactionProcessToastPayload>();
export const resetRedeemSpotAccountProcessToast = createAction(
  RESET_REDEEM_SPOT_ACCOUNT_PROCESS_TOAST,
)();
export const showCompoundProcessToast = createAction(
  SHOW_COMPOUND_PROCESS_TOAST,
)<IShowTransactionProcessToastPayload>();
export const resetCompoundProcessToast = createAction(RESET_COMPOUND_PROCESS_TOAST)();
export const showEpanStakingProcessToast = createAction(
  SHOW_EPAN_STAKING_PROCESS_TOAST,
)<IShowTransactionProcessToastPayload>();
export const resetEpanStakingProcessToast = createAction(RESET_EPAN_STAKING_PROCESS_TOAST)();
export const showBridgeSwapProcessToast = createAction(
  SHOW_BRIDGE_SWAP_PROCESS_TOAST,
)<IShowSwappingTokenProcessToastPayload>();
export const resetBridgeSwapProcessToast = createAction(RESET_BRIDGE_SWAP_PROCESS_TOAST)();
export const toggleNetworkSelector = createAction(TOGGLE_NETWORK_SELECTOR)();
export const toggleCustomTokensModal = createAction(TOGGLE_CUSTOM_TOKENS_MODAL)();
export const showSwappingTokenProcessToast = createAction(
  SHOW_SWAPPING_TOKEN_PROCESS_TOAST,
)<IShowSwappingTokenProcessToastPayload>();
export const resetSwappingTokenProcessToast = createAction(RESET_SWAPPING_TOKEN_PROCESS_TOAST)();
export const showCustomProcessToast =
  createAction(SHOW_CUSTOM_PROCESS_TOAST)<IShowCustomToastPayload>();
export const resetCustomProcessToast = createAction(RESET_CUSTOM_PROCESS_TOAST)();
export const showUniswapLiquidityApprovalProcessToast = createAction(
  SHOW_UNISWAP_LIQUIDITY_APPROVAL_PROCESS_TOAST,
)<IShowUniswapLiquidityApprovalToastPayload>();
export const resetUniswapLiquidityApprovalProcessToast = createAction(
  RESET_UNISWAP_LIQUIDITY_APPROVAL_PROCESS_TOAST,
)();
export const showUniswapLiquiditySupplyingProcessToast = createAction(
  SHOW_UNISWAP_LIQUIDITY_SUPPLYING_PROCESS_TOAST,
)<IShowUniswapLiquidityApprovalToastPayload>();
export const resetUniswapLiquiditySupplyingProcessToast = createAction(
  RESET_UNISWAP_LIQUIDITY_SUPPLYING_PROCESS_TOAST,
)();
export const showUniswapRemoveLiquidityProcessToast = createAction(
  SHOW_UNISWAP_REMOVE_LIQUIDITY_PROCESS_TOAST,
)<IShowUniswapRemoveLiquidityToastPayload>();
export const resetUniswapRemoveLiquidityProcessToast = createAction(
  RESET_UNISWAP_REMOVE_LIQUIDITY_PROCESS_TOAST,
)();
export const showRemovingLiquidityApprovalProcessToast = createAction(
  SHOW_REMOVING_LIQUIDITY_APPROVAL_PROCESS_TOAST,
)<IShowUniswapLiquidityApprovalToastPayload>();
export const resetRemovingLiquidityApprovalProcessToast = createAction(
  RESET_REMOVING_LIQUIDITY_APPROVAL_PROCESS_TOAST,
)();
export const showClubDealSwapApprovalProcessToast = createAction(
  SHOW_CLUB_DEAL_SWAP_APPROVAL_PROCESS_TOAST,
)<IShowClubDealSwapApprovalToastPayload>();
export const resetClubDealSwapApprovalProcessToast = createAction(
  RESET_CLUB_DEAL_SWAP_APPROVAL_PROCESS_TOAST,
)();
export const showClubDealSwappingProcessToast = createAction(
  SHOW_CLUB_DEAL_SWAPPING_PROCESS_TOAST,
)<IShowClubDealSwappingToastPayload>();
export const resetClubDealSwappingProcessToast = createAction(
  RESET_CLUB_DEAL_SWAPPING_PROCESS_TOAST,
)();
export const resetAllProcessToast = createAction(RESET_ALL_PROCESS_TOAST)();
export const showSpotTopUpProcessToast = createAction(
  SHOW_SPOT_TOP_UP_PROCESS_TOAST,
)<IShowTransactionProcessToastPayload>();
export const resetSpotTopUpProcessToast = createAction(RESET_SPOT_TOP_UP_PROCESS_TOAST)();

export const toastActions = {
  showSentTransactionToast,
  showConfirmedTransactionToast,
  showSuccessCompoundMintToast,
  showSuccessCompoundRedeemToast,
  resetToast,
  showSuccessStakeEpanToast,
  showSuccessWithdrawEpanToast,
  showTransactionProcessToast,
  resetTransactionProcessToast,
  showCompoundProcessToast,
  resetCompoundProcessToast,
  showEpanStakingProcessToast,
  resetEpanStakingProcessToast,
  toggleNetworkSelector,
  toggleCustomTokensModal,
  showSwappingTokenProcessToast,
  resetSwappingTokenProcessToast,
  showCustomProcessToast,
  resetCustomProcessToast,
  showBridgeSwapProcessToast,
  resetBridgeSwapProcessToast,
  showUniswapLiquidityApprovalProcessToast,
  resetUniswapLiquidityApprovalProcessToast,
  showUniswapLiquiditySupplyingProcessToast,
  resetUniswapLiquiditySupplyingProcessToast,
  showUniswapRemoveLiquidityProcessToast,
  resetUniswapRemoveLiquidityProcessToast,
  showRemovingLiquidityApprovalProcessToast,
  resetRemovingLiquidityApprovalProcessToast,
  showClubDealSwapApprovalProcessToast,
  resetClubDealSwapApprovalProcessToast,
  showSuccessBridgeSwapToast,
  showClubDealSwappingProcessToast,
  resetClubDealSwappingProcessToast,
  resetAllProcessToast,
  showRedeemSpotAccountProcessToast,
  resetRedeemSpotAccountProcessToast,
  showSuccessApproveEpanToast,
  showSpotTopUpProcessToast,
  resetSpotTopUpProcessToast,
} as const;
