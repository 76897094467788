import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { paypolitanStakingService } from '../services/blockchain';
import { getEpanStakingApyBlockchainError } from '../redux/blockchain/actions';
import { useWalletConnector } from './use-wallet-connector';

export const GET_PAYPOLITAN_STAKING_APY_CACHE_KEY = 'GET_PAYPOLITAN_STAKING_APY_CACHE_KEY';

export const usePaypolitanStakingApy = (type: PaypolitanStakingType, isLongTerm: boolean) => {
  const dispatch = useDispatch();
  const { walletEnabled } = useWalletConnector();

  const {
    data: stakingApy,
    isLoading: isLoadingStakingApy,
    refetch: refetchStakingApy,
  } = useQuery<number, Error>(
    [GET_PAYPOLITAN_STAKING_APY_CACHE_KEY, isLongTerm, type],
    () => paypolitanStakingService.getApy(type, isLongTerm),
    {
      enabled: walletEnabled,
      staleTime: 60 * 1000,
      cacheTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      retry: 2,
      onError: (e) => {
        dispatch(getEpanStakingApyBlockchainError(e));
      },
    },
  );

  const stakingApyDisplayValue = stakingApy && walletEnabled ? `${stakingApy}%` : '0%';

  return {
    stakingApy: stakingApy ?? 0,
    stakingApyDisplayValue,
    isLoadingStakingApy,
    refetchStakingApy,
  };
};
