import { authService, httpService } from '../../../services';

export function protectedApi(): any {
  return function (_: any, __: any, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      const token = authService.getAccessToken('api');

      if (token) {
        httpService.setAuthHeader(token);
      }

      return original.apply(this, args);
    };

    return descriptor;
  };
}
