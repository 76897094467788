import React, { useCallback, useEffect } from 'react';

export const useSegmentedControl = ({ tabs, onChange }: IUseSegmentedControlParams) => {
  const [value, setValue] = React.useState<Maybe<string | number>>(null);

  const handleChange = useCallback((_, value) => {
    if (value !== null) {
      setValue(value);
      onChange && onChange(value);
    }
  }, []);

  useEffect(() => {
    if (!value) {
      setValue(tabs[0]?.value ?? null);
    }
  }, [tabs, value]);

  return {
    value,
    handleChange,
  };
};
