import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { web3Service } from '../services/blockchain';
import { getEstimatedGasBlockchainError } from '../redux/blockchain/actions';
import { getCurrentChainId } from '../redux/blockchain/selectors';
import { DEFAULT_REFETCH_INTERVAL, ESTIMATE_GAS_MULTIPLIER } from '../constants';
import { useWalletConnector } from './use-wallet-connector';

interface UseEstimateGasParams {
  selectedToken?: ICustomToken;
  selectedWallet: Maybe<IWalletWithBalance>;
  toAddress: Maybe<string>;
  amount: string | number;
  gasPriceInEth: string;
  onSuccess?: (gas: number) => void;
}

export const GET_ESTIMATED_GAS_CACHE_KEY = 'GET_ESTIMATED_GAS_CACHE_KEY';

export const useEstimatedGas = ({
  selectedWallet,
  toAddress,
  selectedToken,
  amount,
  gasPriceInEth,
  onSuccess,
}: UseEstimateGasParams) => {
  const dispatch = useDispatch();
  const currentChainId = useSelector(getCurrentChainId);
  const { walletEnabled } = useWalletConnector();

  const {
    data: estimatedGas,
    isLoading: isLoadingGetEstimatedGas,
    refetch: refetchGetEstimatedGas,
    error,
  } = useQuery<Maybe<IGetEstimatedGasResponse>, Error>(
    [GET_ESTIMATED_GAS_CACHE_KEY, selectedWallet, toAddress, amount, selectedToken, currentChainId],
    async () => {
      if (!selectedWallet || !toAddress) {
        return null;
      }

      return web3Service.calculateTransferGas(
        {
          fromWallet: selectedWallet,
          amount: Number(amount),
          toAddress,
          gasPriceInEth,
          chainId: currentChainId,
        },
        selectedToken,
      );
    },
    {
      enabled: Boolean(
        toAddress &&
          walletEnabled &&
          selectedWallet &&
          Boolean(selectedWallet.balance) &&
          !Number.isNaN(Number(amount)),
      ),
      staleTime: DEFAULT_REFETCH_INTERVAL,
      cacheTime: DEFAULT_REFETCH_INTERVAL,
      refetchInterval: DEFAULT_REFETCH_INTERVAL,
      retry: 2,
      onSuccess: (resp) => {
        if (onSuccess && resp) {
          onSuccess(Math.floor(resp.gas * ESTIMATE_GAS_MULTIPLIER));
        }
      },
      onError: (e) => {
        dispatch(getEstimatedGasBlockchainError(e));
      },
    },
  );

  const gas = estimatedGas ? Math.floor(estimatedGas.gas * ESTIMATE_GAS_MULTIPLIER) : 0;

  return {
    gas,
    gasPrice: estimatedGas?.gasPrice,
    isLoadingGetEstimatedGas,
    refetchGetEstimatedGas,
    estimatedGasHasError: Boolean(error),
  };
};
