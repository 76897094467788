import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { AmountField, BaseModal, CommonButton } from '../../../../components';

export const ReviewModal: React.FC<IApproveDefiStakingActionModalProps> = ({
  visible,
  action,
  currency,
  amount,
  period,
  onDismiss,
  onApprove,
}) => {
  const { t } = useTranslation(['common']);

  const getTitle = () => {
    return action === 'withdraw'
      ? t('epan-staking:review-withdrawal')
      : t('epan-staking:review-investment');
  };

  return (
    <BaseModal visible={visible} onDismiss={onDismiss} title={getTitle()}>
      <AmountField label={t('amount')} amount={amount} />
      <AmountField label={t('currency')} amount={currency} />
      <AmountField
        label={t('period')}
        amount={
          period === 'long'
            ? t('epan-staking:staking-period-long')
            : t('epan-staking:staking-period-flexible')
        }
      />
      <CommonButton className="review-staking-modal-button" label={t('ok')} onClick={onApprove} />
    </BaseModal>
  );
};
