import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useForm } from './use-form';
import { CommonButton, FormTextField, PasswordStrengthHint } from '../../../../components';
import { usePasswordVisibility } from '../../../../hooks';
import { IconButton, InputAdornment } from '@material-ui/core';
import { ReactComponent as EyeIcon } from '../../../../assets/images/icons/eye-icon.svg';
import { ReactComponent as CrossedOutEyeIcon } from '../../../../assets/images/icons/crossed-out-eye-icon.svg';
import './style.scss';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { getAppInitializing, getAppLoading } from '../../../../redux/app/selectors';

export const EmailStep: React.FC = () => {
  const { t } = useTranslation(['common', 'sign-up']);
  const { onSubmitForm, initialValues, validationSchema } = useForm();
  const appInitializing = useSelector(getAppInitializing);
  const appLoading = useSelector(getAppLoading);
  const [createPasswordVisible, toggleCreatePasswordVisibility] = usePasswordVisibility();
  const [confirmPasswordVisible, toggleConfirmPasswordVisibility] = usePasswordVisibility();

  return (
    <Formik<ISignUpEmailFormData>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
      validateOnMount={true}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form>
          <span className={cx('sign-up-main-text', 'sign-up-input-title')}>{t('email')}</span>
          <FormTextField<ISignUpEmailFormData>
            inputContainerClassName={cx('sign-up-input')}
            name={'email'}
            type="email"
            autoFocus
            disabled={appInitializing}
            label={t('email')}
          />
          <span className={cx('sign-up-main-text', 'sign-up-input-title')}>
            {t('sign-up:create-password')}
          </span>
          <FormTextField<ISignUpEmailFormData>
            name="password"
            disabled={appInitializing}
            inputContainerClassName={cx('sign-up-input')}
            type={createPasswordVisible ? 'text' : 'password'}
            label={t('sign-up:create-password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleCreatePasswordVisibility}
                    edge="end"
                  >
                    {createPasswordVisible ? <EyeIcon /> : <CrossedOutEyeIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <span className={cx('sign-up-main-text', 'sign-up-input-title')}>
            {t('sign-up:re-type-password')}
          </span>
          <FormTextField<ISignUpEmailFormData>
            name="confirmPassword"
            disabled={appInitializing}
            inputContainerClassName={cx('sign-up-re-type-password-input')}
            type={confirmPasswordVisible ? 'text' : 'password'}
            label={t('sign-up:re-type')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {confirmPasswordVisible ? <EyeIcon /> : <CrossedOutEyeIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <PasswordStrengthHint password={values.password} />
          <CommonButton
            fill
            fullWidth
            type="submit"
            size="large"
            label={t('next')}
            className="sign-up-submit-button"
            containerClassName={cx('sign-up-next-button')}
            loading={isSubmitting || appLoading}
            disabled={isSubmitting || !isValid || appInitializing || appLoading}
          />
        </Form>
      )}
    </Formik>
  );
};
