import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { paypolitanStakingService } from '../services/blockchain';
import { getEpanStakingAllowanceBlockchainError } from '../redux/blockchain/actions';
import { getCurrentNetwork } from '../redux/blockchain/selectors';
import { DEFAULT_REFETCH_INTERVAL } from '../constants';
import { useWalletConnector } from './use-wallet-connector';

export const GET_PAYPOLITAN_STAKING_ALLOWANCE_CACHE_KEY =
  'GET_PAYPOLITAN_STAKING_ALLOWANCE_CACHE_KEY';

export const usePaypolitanStakingAllowance = (
  type: PaypolitanStakingType,
  fromWallet: Maybe<IWallet>,
) => {
  const dispatch = useDispatch();
  const network = useSelector(getCurrentNetwork);
  const { walletEnabled } = useWalletConnector();

  const {
    data: stakingAllowance,
    isLoading: isLoadingStakingAllowance,
    refetch: refetchStakingAllowance,
  } = useQuery<Maybe<string>, Error>(
    [GET_PAYPOLITAN_STAKING_ALLOWANCE_CACHE_KEY, fromWallet, network, type],
    () =>
      fromWallet ? paypolitanStakingService.getStakeAllowance(type, fromWallet, network) : null,
    {
      enabled: Boolean(fromWallet) && walletEnabled,
      staleTime: DEFAULT_REFETCH_INTERVAL,
      cacheTime: DEFAULT_REFETCH_INTERVAL,
      refetchInterval: DEFAULT_REFETCH_INTERVAL,
      retry: 2,
      onError: (e) => {
        dispatch(getEpanStakingAllowanceBlockchainError(e));
      },
    },
  );

  return {
    stakingAllowance,
    isLoadingStakingAllowance,
    refetchStakingAllowance,
  };
};
