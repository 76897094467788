import { createReducer } from 'typesafe-actions';
import {
  IClubDealSwappingToastState,
  ICustomToastState,
  IRemovingLiquidityApprovalToastState,
  ISwappingTokenProcessToastState,
  IToastState,
  ITransactionProcessToastState,
  IUniswapLiquidityApprovalToastState,
  IUniswapLiquiditySupplyingToastState,
  IUniswapRemoveLiquidityToastState,
} from './types';
import {
  showSentTransactionToast,
  showConfirmedTransactionToast,
  resetToast,
  showSuccessCompoundMintToast,
  showSuccessCompoundRedeemToast,
  showSuccessStakeEpanToast,
  showSuccessWithdrawEpanToast,
  showTransactionProcessToast,
  resetTransactionProcessToast,
  showCompoundProcessToast,
  resetCompoundProcessToast,
  showEpanStakingProcessToast,
  resetEpanStakingProcessToast,
  showSwappingTokenProcessToast,
  resetSwappingTokenProcessToast,
  showCustomProcessToast,
  resetCustomProcessToast,
  showBridgeSwapProcessToast,
  resetBridgeSwapProcessToast,
  showUniswapLiquidityApprovalProcessToast,
  resetUniswapLiquidityApprovalProcessToast,
  showUniswapLiquiditySupplyingProcessToast,
  resetUniswapLiquiditySupplyingProcessToast,
  showSuccessBridgeSwapToast,
  resetAllProcessToast,
  showUniswapRemoveLiquidityProcessToast,
  resetUniswapRemoveLiquidityProcessToast,
  showRemovingLiquidityApprovalProcessToast,
  resetRemovingLiquidityApprovalProcessToast,
  showClubDealSwapApprovalProcessToast,
  resetClubDealSwapApprovalProcessToast,
  showClubDealSwappingProcessToast,
  resetClubDealSwappingProcessToast,
  showRedeemSpotAccountProcessToast,
  resetRedeemSpotAccountProcessToast,
  showSpotTopUpProcessToast,
  resetSpotTopUpProcessToast,
} from './actions';
import { resetStore } from '../app/actions';

const transactionProcessInitState: ITransactionProcessToastState = {
  visible: false,
  amount: '',
  fee: '',
  txHash: null,
  wallet: null,
  title: '',
  message: '',
};

const swappingTokenProcessInitState: ISwappingTokenProcessToastState = {
  ...transactionProcessInitState,
  inputToken: {
    amount: '',
    symbol: '',
  },
  outputToken: {
    amount: '',
    symbol: '',
  },
};

const customToastState: ICustomToastState = {
  ...transactionProcessInitState,
  values: [],
};

const uniswapLiquidityApprovalToastState: IUniswapLiquidityApprovalToastState = {
  ...transactionProcessInitState,
  values: [],
};

const uniswapLiquiditySupplyingToastState: IUniswapLiquiditySupplyingToastState = {
  ...transactionProcessInitState,
  values: [],
};

const uniswapRemoveLiquidityToastState: IUniswapRemoveLiquidityToastState = {
  ...transactionProcessInitState,
  values: [],
};

const removingLiquidityApprovalToastState: IRemovingLiquidityApprovalToastState = {
  ...transactionProcessInitState,
  values: [],
};

const clubDealSwapApprovalToastState: IUniswapLiquidityApprovalToastState = {
  ...transactionProcessInitState,
  values: [],
};

const clubDealSwappingToastState: IClubDealSwappingToastState = {
  ...transactionProcessInitState,
  values: [],
};

const initialState: IToastState = {
  message: null,
  type: 'transactionProcess',
  transactionProcess: transactionProcessInitState,
  spotTopUpAccountProcess: transactionProcessInitState,
  redeemSpotAccountProcess: transactionProcessInitState,
  compoundProcess: transactionProcessInitState,
  epanStakingProcess: transactionProcessInitState,
  swappingToken: swappingTokenProcessInitState,
  custom: customToastState,
  uniswapLiquidityApprovalProcess: uniswapLiquidityApprovalToastState,
  uniswapLiquiditySupplyingProcess: uniswapLiquiditySupplyingToastState,
  uniswapRemoveLiquidityProcess: uniswapRemoveLiquidityToastState,
  removingLiquidityApprovalProcess: removingLiquidityApprovalToastState,
  clubDealSwapApprovalProcess: clubDealSwapApprovalToastState,
  clubDealSwappingProcess: clubDealSwappingToastState,
  bridgeSwapProcess: swappingTokenProcessInitState,
  spotTopUpProcess: transactionProcessInitState,
};

export const toast = createReducer(initialState)
  .handleAction(showSentTransactionToast, (state, { type, payload }) => ({
    ...state,
    title: payload.title,
    message: payload.message,
    action: payload.withAction ?? true ? type : undefined,
  }))
  .handleAction(showConfirmedTransactionToast, (state, { type, payload }) => ({
    ...state,
    title: payload.title,
    message: payload.message,
    action: payload.withAction ? type : undefined,
    selectedWallet: payload.selectedWallet,
  }))
  .handleAction(showSuccessCompoundMintToast, (state, { type, payload }) => ({
    ...state,
    title: payload.title,
    message: payload.message,
    action: payload.withAction ? type : undefined,
  }))
  .handleAction(showSuccessCompoundRedeemToast, (state, { type, payload }) => ({
    ...state,
    title: payload.title,
    message: payload.message,
    action: payload.withAction ? type : undefined,
  }))
  .handleAction(showSuccessStakeEpanToast, (state, { type, payload }) => ({
    ...state,
    title: payload.title,
    message: payload.message,
    action: payload.withAction ? type : undefined,
  }))
  .handleAction(showSuccessWithdrawEpanToast, (state, { type, payload }) => ({
    ...state,
    title: payload.title,
    message: payload.message,
    action: payload.withAction ? type : undefined,
  }))
  .handleAction(showSuccessBridgeSwapToast, (state, { type, payload }) => ({
    ...state,
    title: payload.title,
    message: payload.message,
    action: payload.withAction ? type : undefined,
  }))
  .handleAction(showTransactionProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    transactionProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetTransactionProcessToast, (state) => ({
    ...state,
    transactionProcess: transactionProcessInitState,
  }))
  .handleAction(showRedeemSpotAccountProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    redeemSpotAccountProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetRedeemSpotAccountProcessToast, (state) => ({
    ...state,
    redeemSpotAccountProcess: transactionProcessInitState,
  }))
  .handleAction(showSpotTopUpProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    spotTopUpAccountProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetSpotTopUpProcessToast, (state) => ({
    ...state,
    spotTopUpAccountProcess: transactionProcessInitState,
  }))
  .handleAction(showCompoundProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    compoundProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetCompoundProcessToast, (state) => ({
    ...state,
    compoundProcess: transactionProcessInitState,
  }))
  .handleAction(showEpanStakingProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    epanStakingProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetEpanStakingProcessToast, (state) => ({
    ...state,
    epanStakingProcess: transactionProcessInitState,
  }))
  .handleAction(resetToast, (state) => ({
    ...initialState,
    type: state.type,
    compoundProcess: state.compoundProcess,
    transactionProcess: state.transactionProcess,
    epanStakingProcess: state.epanStakingProcess,
    swappingToken: state.swappingToken,
    uniswapLiquidityApprovalProcess: state.uniswapLiquidityApprovalProcess,
    uniswapLiquiditySupplyingProcess: state.uniswapLiquiditySupplyingProcess,
    uniswapRemoveLiquidityProcess: state.uniswapRemoveLiquidityProcess,
    removingLiquidityApprovalProcess: state.removingLiquidityApprovalProcess,
    clubDealSwapApprovalProcess: state.clubDealSwapApprovalProcess,
    clubDealSwappingProcess: state.clubDealSwappingProcess,
    custom: state.custom,
    spotTopUpProcess: state.spotTopUpProcess,
  }))
  .handleAction(showSwappingTokenProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    swappingToken: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetSwappingTokenProcessToast, (state) => ({
    ...state,
    swappingToken: swappingTokenProcessInitState,
  }))
  .handleAction(showCustomProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    custom: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetCustomProcessToast, (state) => ({
    ...state,
    custom: customToastState,
  }))
  .handleAction(showUniswapLiquidityApprovalProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    uniswapLiquidityApprovalProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetUniswapLiquidityApprovalProcessToast, (state) => ({
    ...state,
    uniswapLiquidityApprovalProcess: uniswapLiquidityApprovalToastState,
  }))
  .handleAction(showUniswapLiquiditySupplyingProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    uniswapLiquiditySupplyingProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetUniswapLiquiditySupplyingProcessToast, (state) => ({
    ...state,
    uniswapLiquiditySupplyingProcess: uniswapLiquiditySupplyingToastState,
  }))
  .handleAction(showUniswapRemoveLiquidityProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    uniswapRemoveLiquidityProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetUniswapRemoveLiquidityProcessToast, (state) => ({
    ...state,
    uniswapRemoveLiquidityProcess: uniswapRemoveLiquidityToastState,
  }))
  .handleAction(showBridgeSwapProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    bridgeSwapProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(showRemovingLiquidityApprovalProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    removingLiquidityApprovalProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetRemovingLiquidityApprovalProcessToast, (state) => ({
    ...state,
    removingLiquidityApprovalProcess: removingLiquidityApprovalToastState,
  }))
  .handleAction(showClubDealSwapApprovalProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    clubDealSwapApprovalProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetClubDealSwapApprovalProcessToast, (state) => ({
    ...state,
    clubDealSwapApprovalProcess: clubDealSwapApprovalToastState,
  }))
  .handleAction(showClubDealSwappingProcessToast, (state, { payload }) => ({
    ...state,
    type: payload.type,
    clubDealSwappingProcess: {
      ...payload,
      visible: true,
    },
  }))
  .handleAction(resetClubDealSwappingProcessToast, (state) => ({
    ...state,
    clubDealSwappingProcess: clubDealSwappingToastState,
  }))
  .handleAction(resetBridgeSwapProcessToast, (state) => ({
    ...state,
    bridgeSwapProcess: swappingTokenProcessInitState,
  }))
  .handleAction(resetAllProcessToast, (state) => ({
    ...state,
    transactionProcess: transactionProcessInitState,
    compoundProcess: transactionProcessInitState,
    epanStakingProcess: transactionProcessInitState,
    swappingToken: swappingTokenProcessInitState,
    custom: customToastState,
    bridgeSwapProcess: swappingTokenProcessInitState,
    spotTopUpProcess: transactionProcessInitState,
  }))
  .handleAction(resetStore, () => initialState);
