import React from 'react';
import './styles.scss';
import { QRCode } from 'react-qrcode-logo';
import QRCodeLogo from '../../assets/images/qr-code-logo.png';
import { ReactComponent as MainIcon } from '../../assets/images/icons/main.svg';
import WalletBrokenIcon from '../../assets/images/icons/wallet-broken-icon.svg';
import { BaseModal } from '../base-modal';
import { useModal } from './use-modal';
import { useTranslation } from 'react-i18next';
import { CommonButton } from '../common-button';

export const CustomWalletConnectQrModal = () => {
  const { t } = useTranslation(['wallet-connect']);
  const { visible, onDismiss, url, err } = useModal();

  return (
    <BaseModal visible={visible} onDismiss={onDismiss}>
      <div className="custom-wc-qr-modal flex-container">
        {err ? (
          <div className="flex-container">
            <div className="flex-container custom-wc-qr-modal-error-content">
              <img className="custom-wc-qr-modal-wallet-icon" src={WalletBrokenIcon} alt="wallet" />
              <h1 className="custom-wc-qr-modal-title">{t('connection-failed')}</h1>
              <span className="custom-wc-qr-modal-info">{t('connection-failed-error')}</span>
            </div>
            <CommonButton fullWidth label={t('common:ok')} onClick={onDismiss} />
          </div>
        ) : (
          <>
            <div className="custom-wc-qr-modal-wallet-icon-wrapper">
              <MainIcon className="custom-wc-qr-modal-wallet-icon" />
            </div>
            <h1 className="custom-wc-qr-modal-title">{t('connect-paypolitan-wallet')}</h1>
            <span className="custom-wc-qr-modal-info">{t('connect-paypolitan-wallet-info')}</span>
            <QRCode
              size={400}
              value={url}
              logoImage={QRCodeLogo}
              logoHeight={120}
              logoWidth={120}
            />
          </>
        )}
      </div>
    </BaseModal>
  );
};
