import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { CommonTileContainer } from '../../../../components';
import { thousandSeparator } from '../../../../helpers/thousandSeparator';
import positive from '../../../../assets/images/icons/positive.svg';
import negative from '../../../../assets/images/icons/negative.svg';
import { isUndefined } from '../../../../utils/isUndefined';
import { useTranslation } from 'react-i18next';

const subsAmount = (str: any) => {
  if (typeof str === 'number') {
    return Number(str).toFixed(4);
  }
  return Number(str.replace(/\s/g, '')).toFixed(4);
};

// TODO completely rework all logic
export const EpanPriceStatistics: React.FC = () => {
  const { t } = useTranslation(['price-stats']);
  const [epanStatistic, setEpanStatistic] = useState<any>({});
  const isEpanStatiticsEmpty =
    epanStatistic &&
    Object.keys(epanStatistic).length === 0 &&
    Object.getPrototypeOf(epanStatistic) === Object.prototype;

  useEffect(() => {
    (async () => {
      try {
        if (isEpanStatiticsEmpty) {
          const response = await fetch('https://api.coingecko.com/api/v3/coins/paypolitan-token');
          if (response.ok) {
            const json = await response.json();
            setEpanStatistic(json);
          }
          if (!response.ok) {
            return null;
          }
        }
      } catch {}
    })();
  }, [isEpanStatiticsEmpty]);

  const renderPriceToken = useCallback(() => {
    if (isEpanStatiticsEmpty || isUndefined(epanStatistic)) {
      return `$ 0.00`;
    }
    if (!isEpanStatiticsEmpty) {
      const usd = epanStatistic?.market_data?.current_price?.usd ?? '0';
      return `$ ${subsAmount(thousandSeparator(usd))}`;
    }
  }, [epanStatistic, isEpanStatiticsEmpty]);

  const renderPriceChange24 = useCallback(() => {
    if (isEpanStatiticsEmpty || isUndefined(epanStatistic)) {
      return `$ 0.00`;
    }
    if (!isEpanStatiticsEmpty) {
      const price_change_24h = epanStatistic?.market_data?.price_change_24h ?? '0';
      return `$ ${Number(thousandSeparator(price_change_24h)).toFixed(5)}`;
    }
  }, [epanStatistic, isEpanStatiticsEmpty]);

  const renderPriceChange24Percentage = useCallback(() => {
    if (isEpanStatiticsEmpty || isUndefined(epanStatistic)) {
      return `0.00`;
    }
    if (!isEpanStatiticsEmpty) {
      const usd = epanStatistic?.market_data?.price_change_24h_in_currency?.usd ?? '0';
      return (
        <span className={`epanPrice-item-value-percentage ${usd > 0.0 ? 'positive' : 'negative'}`}>
          <img src={usd > 0.0 ? positive : negative} alt="icon" />
          <span>{`${Number(usd).toFixed(2)}%`}</span>
        </span>
      );
    }
  }, [epanStatistic, isEpanStatiticsEmpty]);

  const renderLowHighPrice = useCallback(() => {
    if (isEpanStatiticsEmpty || isUndefined(epanStatistic)) {
      return `0.00`;
    }
    if (!isEpanStatiticsEmpty) {
      const low_24h = epanStatistic?.market_data?.low_24h ?? '0';
      const high_24h = epanStatistic?.market_data?.high_24h ?? '0';
      return `$ ${subsAmount(low_24h.usd)} / $ ${subsAmount(high_24h.usd)} `;
    }
  }, [epanStatistic, isEpanStatiticsEmpty]);

  const renderTradingVolume = useCallback(() => {
    if (isEpanStatiticsEmpty || isUndefined(epanStatistic)) {
      return `0.00`;
    }
    if (!isEpanStatiticsEmpty) {
      const usd = epanStatistic?.market_data?.total_volume?.usd ?? '0';
      return `$ ${thousandSeparator(usd)}`;
    }
  }, [epanStatistic, isEpanStatiticsEmpty]);

  const renderMarketCap = useCallback(() => {
    if (isEpanStatiticsEmpty || isUndefined(epanStatistic)) {
      return `0.00`;
    }
    if (!isEpanStatiticsEmpty) {
      const usd = epanStatistic?.market_data?.market_cap?.usd ?? '0';
      return `$ ${thousandSeparator(usd)}`;
    }
  }, [epanStatistic, isEpanStatiticsEmpty]);

  const renderMarketCapPercentage = useCallback(() => {
    if (isEpanStatiticsEmpty || isUndefined(epanStatistic)) {
      return `0.00`;
    }
    if (!isEpanStatiticsEmpty) {
      const market_cap_change_percentage_24h =
        epanStatistic?.market_data?.market_cap_change_percentage_24h ?? '0';
      return (
        <span
          className={`epanPrice-item-value-percentage ${
            market_cap_change_percentage_24h > 0 ? 'positive' : 'negative'
          }`}
        >
          <img src={market_cap_change_percentage_24h > 0 ? positive : negative} alt="icon" />
          <span>{`${Number(market_cap_change_percentage_24h).toFixed(2)}%`}</span>
        </span>
      );
    }
  }, [epanStatistic, isEpanStatiticsEmpty]);

  return (
    <CommonTileContainer className="epanPrice-container">
      <div className="epanPrice">
        <div className="epanPrice-item">
          <div className="epanPrice-item-header">
            <span className="epanPrice-item-header-title">{t('token-price')}</span>
          </div>
          <div className="epanPrice-item-value">
            <span className="epanPrice-item-value-amount">{renderPriceToken()}</span>
          </div>
        </div>
        <div className="epanPrice-item">
          <div className="epanPrice-item-header">
            <span className="epanPrice-item-header-title">{t('price-change')}</span>
            <span className="epanPrice-item-header-chip">{t('price-change-range')}</span>
          </div>
          <div className="epanPrice-item-value">
            <span className="epanPrice-item-value-amount">{renderPriceChange24()}</span>
            {renderPriceChange24Percentage()}
          </div>
        </div>
        <div className="epanPrice-item">
          <div className="epanPrice-item-header">
            <span className="epanPrice-item-header-title">{t('price-change-low-high')}</span>
          </div>
          <div className="epanPrice-item-value">
            <span className="epanPrice-item-value-amount">{renderLowHighPrice()}</span>
          </div>
        </div>
        <div className="epanPrice-item">
          <div className="epanPrice-item-header">
            <span className="epanPrice-item-header-title">{t('trading-volume')}</span>
            <span className="epanPrice-item-header-chip">{t('trading-volume-range')}</span>
          </div>
          <div className="epanPrice-item-value">
            <span className="epanPrice-item-value-amount">{renderTradingVolume()}</span>
          </div>
        </div>
        <div className="epanPrice-item">
          <div className="epanPrice-item-header">
            <span className="epanPrice-item-header-title">{t('market-cap')}</span>
          </div>
          <div className="epanPrice-item-value">
            <span className="epanPrice-item-value-amount">{renderMarketCap()}</span>
            {renderMarketCapPercentage()}
          </div>
        </div>
      </div>
    </CommonTileContainer>
  );
};
