import React from 'react';
import cx from 'classnames';
import './style.scss';
import { Check } from '@material-ui/icons';
import { IPasswordStrengthHintProps } from './types';
import {
  passwordLengthPolicy,
  passwordNumberSpecialCharactersPolicy,
  passwordUpperLowerCasePolicy,
} from '../../constants';
import { useTranslation } from 'react-i18next';

export const PasswordStrengthHint: React.FC<IPasswordStrengthHintProps> = ({ password }) => {
  const { t } = useTranslation('validation-info');

  return (
    <>
      <div className={cx('hint-row', 'hint-row-value')}>
        <span className={cx('hint-main-text')}>{t('password-character-long')}</span>
        {passwordLengthPolicy.check(password) && (
          <Check className={cx('hint-check')} fontSize={'small'} />
        )}
      </div>
      <div className={cx('hint-row', 'hint-row-value')}>
        <span className={cx('hint-main-text')}>{t('password-upper-lower-letters')}</span>
        {passwordUpperLowerCasePolicy.check(password) && (
          <Check className={cx('hint-check')} fontSize={'small'} />
        )}
      </div>
      <div className={cx('hint-row')}>
        <span className={cx('hint-main-text')}>{t('password-special-characters')}</span>
        {passwordNumberSpecialCharactersPolicy.check(password) && (
          <Check className={cx('hint-check')} fontSize={'small'} />
        )}
      </div>
    </>
  );
};
