import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { translate } from '../../api';
import { isEmail } from 'class-validator';

export const getValidationSchema = (): SchemaOf<ISignInFormData> =>
  Yup.object({
    email: Yup.string()
      .trim()
      .email(translate('incorrect-email', 'errors'))
      .required(translate('required', 'errors'))
      .test('additional-email-validations', translate('incorrect-email', 'errors'), (value) => {
        return isEmail(value);
      }),
    password: Yup.string().required(translate('required', 'errors')),
  });
