import { createAction } from 'typesafe-actions';
import { IUser } from './types';

export const SET_CURRENT_USER = '@USERS/SET_CURRENT';
export const DELETE_ACCOUNT = '@USERS/DELETE_ACCOUNT';
export const UPDATE_CURRENT_USER_METADATA = '@USERS/UPDATE_CURRENT_USER_METADATA';
export const UPDATE_CURRENT_USER = '@USERS/UPDATE_CURRENT_USER';
export const SET_CURRENT_FIAT_CURRENCY = '@USERS/SET_CURRENT_FIAT_CURRENCY';
export const SET_NOTIFICATIONS_SETTINGS = '@USERS/SET_NOTIFICATIONS_SETTINGS';
export const SET_USER_API_WALLETS = '@USERS/SET_USER_API_WALLETS';
export const ADD_WALLET_REQUEST = '@USERS/ADD_WALLET_REQUEST';
export const EDIT_WALLET_REQUEST = '@USERS/EDIT_WALLET_REQUEST';
export const IMPORT_WALLET_REQUEST = '@USERS/IMPORT_WALLET_REQUEST';
export const POPULATE_CURRENT_USER_DATA = '@USERS/POPULATE_CURRENT_USER_DATA';
export const EDIT_PROFILE_PICTURE_REQUEST = '@USERS/EDIT_PROFILE_PICTURE_REQUEST';
export const DELETE_PROFILE_PICTURE_REQUEST = '@USERS/DELETE_PROFILE_PICTURE_REQUEST';
export const ADD_WALLET_FAILURE = '@USERS/ADD_WALLET_FAILURE';
export const EDIT_PROFILE_REQUEST = '@USERS/EDIT_PROFILE_REQUEST';
export const EDIT_WALLET_FAILURE = '@USERS/EDIT_WALLET_FAILURE';
export const EDIT_PROFILE_FAILURE = '@USERS/EDIT_PROFILE_FAILURE';
export const EDIT_PROFILE_PICTURE_FAILURE = '@USERS/EDIT_PROFILE_PICTURE_FAILURE';
export const REFER_FRIEND_FAILURE = '@USERS/REFER_FRIEND_FAILURE';
export const CHANGE_EMAIL_FAILURE = '@USERS/CHANGE_EMAIL_FAILURE';
export const POPULATE_CURRENT_USER_DATA_FAILURE = '@USERS/POPULATE_CURRENT_USER_DATA_FAILURE';
export const CHANGE_PASSWORD_FAILURE = '@USERS/CHANGE_PASSWORD_FAILURE';
export const ADD_CUSTOM_TOKEN_FAILURE = '@USERS/ADD_CUSTOM_TOKEN_FAILURE';
export const DELETE_CUSTOM_TOKEN_FAILURE = '@USERS/DELETE_CUSTOM_TOKEN_FAILURE';
export const SET_PREFERENCES_FAILURE = '@USERS/SET_PREFERENCES_FAILURE';
export const GET_CURRENT_API_USER_FAILURE = '@USERS/GET_CURRENT_API_USER_FAILURE';
export const SET_CURRENT_API_USER = '@USERS/SET_CURRENT_API_USER';
export const IMPORT_WALLET_FAILURE = '@USERS/IMPORT_WALLET_FAILURE';
export const GET_INTERNAL_WALLETS_MAP_FAILURE = '@USERS/GET_INTERNAL_WALLETS_MAP_FAILURE';
export const SELECT_INTERNAL_WALLET_FAILURE = '@USERS/SELECT_INTERNAL_WALLET_FAILURE';
export const GET_NOTIFICATIONS_FAILURE = '@USERS/GET_NOTIFICATIONS_FAILURE';
export const SPOT_ACCOUNT_FAILURE = '@USERS/SPOT_ACCOUNT_FAILURE';
export const ACTIVATE_PROMO_CODE_FAILURE = '@USERS/ACTIVATE_PROMO_CODE_FAILURE';
export const MANAGE_INTERNAL_WALLET_FAILURE = '@USERS/MANAGE_INTERNAL_WALLET_FAILURE';
export const CREATE_NEW_WALLET_FAILURE = '@USERS/CREATE_NEW_WALLET_FAILURE';
export const DELETE_PROFILE_PICTURE_FAILURE = '@USERS/DELETE_PROFILE_PICTURE_FAILURE';

export const setCurrentUser = createAction(SET_CURRENT_USER)<IUser>();
export const deleteAccount = createAction(DELETE_ACCOUNT)();
export const updateCurrentUserMetadata = createAction(UPDATE_CURRENT_USER_METADATA)<
  Partial<IUser['user_metadata']>
>();
export const updateCurrentUser = createAction(UPDATE_CURRENT_USER)<Partial<IUser>>();
export const setCurrentFiatCurrency = createAction(SET_CURRENT_FIAT_CURRENCY)<IFiatCurrency>();
export const setCurrentApiUser = createAction(SET_CURRENT_API_USER)<IApiUser>();
export const setNotificationsSettings = createAction(SET_NOTIFICATIONS_SETTINGS)<
  Partial<INotificationSettings>
>();
export const setUserApiWallets = createAction(SET_USER_API_WALLETS)<IApiWallet[]>();
export const addWalletRequest = createAction(ADD_WALLET_REQUEST)<IAddWalletsParams>();
export const editWalletRequest = createAction(EDIT_WALLET_REQUEST)<IEditWalletsParams>();
export const importWalletRequest = createAction(IMPORT_WALLET_REQUEST)<IImportWalletsParams>();
export const editProfileRequest = createAction(EDIT_PROFILE_REQUEST)<Partial<EditUserParams>>();
export const populateCurrentUserData = createAction(POPULATE_CURRENT_USER_DATA)();
export const editProfilePictureRequest = createAction(EDIT_PROFILE_PICTURE_REQUEST)<{
  picture: IFile;
}>();
export const deleteProfilePictureRequest = createAction(DELETE_PROFILE_PICTURE_REQUEST)();
export const addWalletFailure = createAction(ADD_WALLET_FAILURE)<Error>();
export const editWalletFailure = createAction(EDIT_WALLET_FAILURE)<Error>();
export const editProfileFailure = createAction(EDIT_PROFILE_FAILURE)<Error>();
export const editProfilePictureFailure = createAction(EDIT_PROFILE_PICTURE_FAILURE)<Error>();
export const referFriendFailure = createAction(REFER_FRIEND_FAILURE)<Error>();
export const changeEmailFailure = createAction(CHANGE_EMAIL_FAILURE)<Error>();
export const populateCurrentUserDataFailure = createAction(
  POPULATE_CURRENT_USER_DATA_FAILURE,
)<Error>();
export const changePasswordFailure = createAction(CHANGE_PASSWORD_FAILURE)<Error>();
export const addCustomTokenFailure = createAction(ADD_CUSTOM_TOKEN_FAILURE)<Error>();
export const deleteCustomTokenFailure = createAction(DELETE_CUSTOM_TOKEN_FAILURE)<Error>();
export const getCurrentApiUserFailure = createAction(GET_CURRENT_API_USER_FAILURE)<Error>();
export const setPreferencesFailure = createAction(SET_PREFERENCES_FAILURE)<Error>();
export const importWalletFailure = createAction(IMPORT_WALLET_FAILURE)<Error>();
export const getInternalWalletsMapFailure = createAction(GET_INTERNAL_WALLETS_MAP_FAILURE)<Error>();
export const selectInternalWalletFailure = createAction(SELECT_INTERNAL_WALLET_FAILURE)<Error>();
export const getNotificationsFailure = createAction(GET_NOTIFICATIONS_FAILURE)<Error>();
export const spotAccountFailure = createAction(SPOT_ACCOUNT_FAILURE)<Error>();
export const activatePromoCodeFailure = createAction(ACTIVATE_PROMO_CODE_FAILURE)<Error>();
export const manageInternalWalletFailure = createAction(MANAGE_INTERNAL_WALLET_FAILURE)<Error>();
export const createNewWalletFailure = createAction(CREATE_NEW_WALLET_FAILURE)<Error>();
export const deleteProfilePictureFailure = createAction(DELETE_PROFILE_PICTURE_FAILURE)<Error>();

export const usersActions = {
  setCurrentUser,
  deleteAccount,
  updateCurrentUserMetadata,
  updateCurrentUser,
  setCurrentFiatCurrency,
  setNotificationsSettings,
  editProfilePictureRequest,
  addWalletFailure,
  editProfileFailure,
  editProfilePictureFailure,
  referFriendFailure,
  changeEmailFailure,
  editProfileRequest,
  changePasswordFailure,
  populateCurrentUserDataFailure,
  addCustomTokenFailure,
  setCurrentApiUser,
  deleteCustomTokenFailure,
  getCurrentApiUserFailure,
  setPreferencesFailure,
  importWalletFailure,
  getInternalWalletsMapFailure,
  selectInternalWalletFailure,
  setUserApiWallets,
  importWalletRequest,
  getNotificationsFailure,
  spotAccountFailure,
  activatePromoCodeFailure,
  manageInternalWalletFailure,
  createNewWalletFailure,
  populateCurrentUserData,
  deleteProfilePictureFailure,
} as const;
