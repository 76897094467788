import React from 'react';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';
import cx from 'classnames';
import './style.scss';
import { IFormTextFieldProps } from './types';

export const FormTextField = <FormFields extends object>({
  name,
  className,
  onChangeText,
  maxLength,
  errorTextClassName,
  isForbiddenCharacter,
  inputContainerClassName,
  disabled = false,
  transform,
  onBlur,
  highlightOnFocus,
  placeholder,
  rightComponent,
  ...rest
}: IFormTextFieldProps<FormFields>) => {
  return (
    <div className={cx('form-text-filed-wrapper', inputContainerClassName)}>
      <Field name={name}>
        {(props: FieldProps<string>) => (
          <>
            <TextField
              {...props}
              onChange={(evt) => {
                const isForbidden = isForbiddenCharacter
                  ? isForbiddenCharacter(evt.target.value)
                  : false;

                if (!isForbidden) {
                  if (!maxLength) {
                    props.form.setFieldValue(
                      String(name),
                      transform ? transform(props.field.value, evt.target.value) : evt.target.value,
                    );

                    if (onChangeText) {
                      onChangeText(
                        transform
                          ? transform(props.field.value, evt.target.value)
                          : evt.target.value,
                      );
                    }
                  } else if (evt.target.value.length <= maxLength) {
                    props.form.setFieldValue(
                      String(name),
                      transform ? transform(props.field.value, evt.target.value) : evt.target.value,
                    );

                    if (onChangeText) {
                      onChangeText(
                        transform
                          ? transform(props.field.value, evt.target.value)
                          : evt.target.value,
                      );
                    }
                  }
                }
              }}
              onBlur={(evt) => {
                if (onBlur) {
                  onBlur(evt);
                }

                props.field.onBlur(evt);
              }}
              variant="filled"
              placeholder={placeholder}
              FormHelperTextProps={{
                className: cx('form-text-filed-error-text', errorTextClassName),
              }}
              className={cx(
                'form-text-filed-root',
                { 'form-text-filed-focused': !!highlightOnFocus },
                { 'form-text-filed-placeholder-shown': !!placeholder },
                { 'form-text-filed-right-component-exist': !!rightComponent },
                className,
              )}
              {...rest}
              disabled={disabled}
            />
            {!!rightComponent && (
              <div
                className={cx('form-text-filed-right-component-wrapper', {
                  'form-text-filed-right-component-wrapper-error':
                    props.form.errors[name] && props.form.touched[name],
                })}
              >
                {rightComponent}
              </div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};
