import React from 'react';
import './style.scss';
import mobile from '../../assets/images/icons/mobile.svg';
import googlePlay from '../../assets/images/icons/GooglePlay.svg';
import appleStore from '../../assets/images/icons/AppStore.svg';
import { useTranslation } from 'react-i18next';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '../../constants';

export const MobileDevice = () => {
  const { t } = useTranslation(['common']);
  return (
    <div className="mobile">
      <div className="mobile-wrapper">
        <img className="mobile-wrapper-image" src={mobile} alt="icon" />
        <p className="mobile-wrapper-title">{t('common:download-app')}</p>
        <p className="mobile-wrapper-subTitle">{t('common:download-app-overview')}</p>
        <div className="mobile-wrapper-download">
          <div>
            <a target="_blank" rel="noreferrer" href={GOOGLE_PLAY_LINK}>
              <img src={googlePlay} alt="download from google play" />
            </a>
          </div>
          <div>
            <a target="_blank" rel="noreferrer" href={APP_STORE_LINK}>
              <img src={appleStore} alt="download from apple store" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
