import React from 'react';
import './style.scss';
import cx from 'classnames';
import { Modal, Portal } from '@material-ui/core';
import { IBaseModalProps } from './types';
import { Close } from '@material-ui/icons';

export const BaseModal: React.FC<IBaseModalProps> = ({
  hideCloseButton,
  visible,
  onDismiss,
  children,
  title,
  icon,
}) => {
  return (
    <Portal>
      <Modal open={visible} onClose={onDismiss} className="base-modal">
        <div className="base-modal-content">
          <div
            className={cx('base-modal-header', { 'base-modal-header-centered': hideCloseButton })}
          >
            <div className="base-modal-header-head">
              {icon && <div className="base-modal-header-icon">{icon}</div>}
              <span className="base-modal-header-title">{title}</span>
            </div>
            {!hideCloseButton && (
              <button className="base-modal-header-close-button" onClick={onDismiss}>
                <Close />
              </button>
            )}
          </div>
          {children}
        </div>
      </Modal>
    </Portal>
  );
};
