import {
  Auth0CreateUserError,
  Auth0Error,
  Auth0InvalidEmailError,
  auth0InvalidEmailErrorCode,
  AuthUnauthorizedError,
  IAuth0Error,
} from './errors';
import { envService } from '../env.service';
import Auth0, { Auth0Result, Auth0UserProfile, DbSignUpOptions, DbSignUpResults } from 'auth0-js';
import {
  getFromStorage,
  removeFromStorage,
  saveToStorage,
  StorageEntities,
} from '../../api/secure-storage';
import { getTokenExpirationDate, isTokenExpired } from '../../helpers';
import { httpService } from '../http';
import { __DEV__ } from '../../constants';

const AUTH0_SCOPE = 'openid';
const AUTH0_CONNECTION = 'Username-Password-Authentication';

class AuthService {
  // TODO develop for web
  constructor() {
    this.auth0 = this.init();
  }

  private auth0: Auth0.Authentication;
  private auth0Tokens: Maybe<StorageEntities> = null;

  init() {
    this.auth0 = new Auth0.Authentication({
      clientID: envService.envConfig.AUTH0_CLIENT_ID,
      domain: envService.envConfig.AUTH0_DOMAIN,
    });
    return this.auth0;
  }

  /** @throws {Auth0CreateUserError} */

  createUser = async (user: Omit<DbSignUpOptions, 'connection'>) => {
    try {
      return await new Promise<DbSignUpResults>((resolve, reject) => {
        this.auth0.dbConnection.signup(
          {
            ...user,
            connection: AUTH0_CONNECTION,
          },
          (err, resp) => {
            if (err) {
              reject(err);
            }

            resolve(resp);
          },
        );
      });
    } catch (e) {
      if ((e as IAuth0Error).code === 'invalid_signup') {
        throw new Auth0CreateUserError();
      } else if ((e as IAuth0Error).code.startsWith(auth0InvalidEmailErrorCode)) {
        throw new Auth0InvalidEmailError();
      } else {
        throw new Auth0Error(e as IAuth0Error);
      }
    }
  };

  /** @throws {Auth0Error} */
  updateUser = async (metadata: Partial<Auth0UserMetadata>) => {
    try {
      const token = this.getAccessToken('auth0');

      if (token) {
        const { user_metadata } = await this.getUser(token);
        const { sub } = await new Promise<Auth0UserProfile>((resolve, reject) => {
          this.auth0.userInfo(token, (err, resp) => {
            if (err) {
              reject(err);
            }

            resolve(resp);
          });
        });

        return new Promise((resolve, reject) => {
          new Auth0.Management({
            audience: envService.auth0ApiAudience,
            token,
            scope: AUTH0_SCOPE,
            clientId: envService.envConfig.AUTH0_CLIENT_ID,
            domain: envService.envConfig.AUTH0_DOMAIN,
          }).patchUserMetadata(
            sub,
            {
              ...(user_metadata ?? {}),
              ...metadata,
            },
            (err, resp) => {
              if (err) {
                reject(err);
              }

              resolve(resp);
            },
          );
        });
      } else {
        throw new AuthUnauthorizedError();
      }
    } catch (e) {
      if (e instanceof AuthUnauthorizedError) {
        throw e;
      } else {
        throw new Auth0Error(e as IAuth0Error);
      }
    }
  };

  /** @throws {Auth0Error} */
  getUser = async (accessToken?: string) => {
    try {
      const token = accessToken ? accessToken : this.getAccessToken('auth0');

      if (token) {
        const { sub } = await new Promise<Auth0UserProfile>((resolve, reject) => {
          this.auth0.userInfo(token, (err, resp) => {
            if (err) {
              reject(err);
            }

            resolve(resp);
          });
        });

        return new Promise<Auth0UserProfile>((resolve, reject) => {
          new Auth0.Management({
            audience: envService.auth0ApiAudience,
            token,
            scope: AUTH0_SCOPE,
            clientId: envService.envConfig.AUTH0_CLIENT_ID,
            domain: envService.envConfig.AUTH0_DOMAIN,
          }).getUser(sub, (err, resp) => {
            if (err) {
              reject(err);
            }
            console.log(resp);
            resolve(resp);
          });
        });
      } else {
        throw new AuthUnauthorizedError();
      }
    } catch (e) {
      if (e instanceof AuthUnauthorizedError) {
        throw e;
      } else {
        throw new Auth0Error(e as IAuth0Error);
      }
    }
  };

  /** @throws {Auth0Error} */
  loginByEmailAndPassword = async (username: string, password: string /*, rememberMe = false*/) => {
    try {
      const auth0Tokens = await new Promise<Required<Auth0Result>>((resolve, reject) => {
        this.auth0.login(
          {
            username,
            password,
            realm: AUTH0_CONNECTION,
            scope: AUTH0_SCOPE,
            audience: envService.auth0ManagementApiAudience,
          },
          (err, resp) => {
            if (err) {
              reject(err);
            }

            resolve(resp);
          },
        );
      });
      __DEV__ && console.log('-------- auth0Tokens', auth0Tokens);

      const apiTokens = await new Promise<Required<Auth0Result>>((resolve, reject) => {
        this.auth0.login(
          {
            username,
            password,
            realm: AUTH0_CONNECTION,
            scope: AUTH0_SCOPE,
            audience: envService.auth0ApiAudience,
          },
          (err, resp) => {
            if (err) {
              reject(err);
            }

            resolve(resp);
          },
        );
      });

      const entities: StorageEntities = {
        auth0AccessToken: auth0Tokens.accessToken,
        auth0ExpiresIn: getTokenExpirationDate(auth0Tokens.expiresIn),
        apiAccessToken: apiTokens.accessToken,
        apiExpiresIn: getTokenExpirationDate(apiTokens.expiresIn),
      };

      await saveToStorage(entities);

      return this.getUser(auth0Tokens.accessToken);
    } catch (e) {
      throw new Auth0Error(e as IAuth0Error);
    }
  };

  /** @throws {AuthUnauthorizedError} */
  getAccessToken = (type: TokesType) => {
    try {
      let accessToken = this.auth0Tokens ? this.auth0Tokens[`${type}AccessToken`] : null;

      if (!accessToken) {
        accessToken = getFromStorage(`${type}AccessToken` as keyof AuthStorageEntities);
      }

      let expiredIn = this.auth0Tokens ? this.auth0Tokens[`${type}ExpiresIn`] : null;

      if (!expiredIn) {
        expiredIn = getFromStorage(`${type}ExpiresIn` as keyof AuthStorageEntities);
      }

      if (!accessToken || !expiredIn) {
        return null;
      }

      if (!isTokenExpired(Number(expiredIn))) {
        return accessToken;
      }

      return null;
    } catch (e) {
      throw new AuthUnauthorizedError();
    }
  };

  /** @throws {Auth0Error} */
  resetPassword = async (email: string) => {
    try {
      return new Promise((resolve, reject) => {
        this.auth0.dbConnection.changePassword(
          { email, connection: AUTH0_CONNECTION },
          (err, resp) => {
            if (err) {
              reject(err);
            }

            resolve(resp);
          },
        );
      });
    } catch (e) {
      throw new Auth0Error(e as IAuth0Error);
    }
  };

  /** @throws {Auth0Error} */
  logout = async () => {
    try {
      this.auth0Tokens = null;
      const url = this.auth0.buildLogoutUrl({ federated: true });
      fetch(url, {
        method: 'GET',
        mode: 'no-cors',
      }).catch((err) => {
        // not needed to process this error - logout any way
        __DEV__ && console.log('-------- auth0 logout err', err);
      });
      await removeFromStorage('apiAccessToken');
      await removeFromStorage('apiExpiresIn');
      await removeFromStorage('auth0AccessToken');
      await removeFromStorage('auth0ExpiresIn');
    } catch (e) {
      throw new Auth0Error(e as IAuth0Error);
    }
  };

  // /** @throws {Error} */
  // checkCurrentPassword = async (username: string, password: string) => {
  //   try {
  //     await this.auth0.auth.passwordRealm({
  //       username,
  //       password,
  //       realm: AUTH0_CONNECTION,
  //       scope: AUTH0_SCOPE,
  //       audience: envService.auth0ManagementApiAudience,
  //     });
  //   } catch (error) {
  //     throw new Error(translate('current-password-invalid', 'errors'));
  //   }
  // };
}

export const authService = new AuthService();
