import React, { useCallback } from 'react';
import cx from 'classnames';
import './style.scss';
import BankIcon from '../../../../assets/images/icons/bank-icon.svg';
import BankEmptyIcon from '../../../../assets/images/icons/bank-empty-icon.svg';
import { CommonTileContainer } from '../../../../components';
import { IBankAccountProps } from './types';
import { useTranslation } from 'react-i18next';
import { thousandSeparator } from '../../../../helpers/thousandSeparator';

export const BankAccount: React.FC<IBankAccountProps> = ({
  bankName,
  icon,
  balance,
  accountNumber,
  connected,
}) => {
  const { t } = useTranslation(['dashboard']);

  const getAccountNumber = useCallback(() => {
    return (
      !!accountNumber && (
        <span className={cx('bank-account-main-text', 'bank-account-account-number')}>
          <div className={cx('bank-account-bullet-wrapper')}>
            {Array(4)
              .fill(null)
              .map((_, idx) => (
                <span key={idx} className={cx('bank-account-bullet')}>
                  {'\u2022'}
                </span>
              ))}
          </div>
          {accountNumber?.slice(-4)}
        </span>
      )
    );
  }, [accountNumber]);

  return (
    <CommonTileContainer className="bank-account">
      {connected ? (
        <>
          <div className={cx('bank-account-row', 'bank-account-row-space', 'bank-account-header')}>
            <div className={cx('bank-account-row')}>
              <div className={cx('bank-account-icon-rounded')}>
                <img src={BankIcon} alt="Bank" />
              </div>
              <div className={cx('bank-account-bank-name')}>
                <span className={cx('bank-account-title')}>{t('bank-account')}</span>
                <span className={cx('bank-account-main-text')}>{bankName}</span>
              </div>
            </div>
            {!!icon && <img width={30} alt="icon" src={icon} />}
          </div>
          <div className={cx('bank-account-row', 'bank-account-row-space')}>
            <span className={cx('bank-account-balance')}>{`$ ${thousandSeparator(balance)}`}</span>
            {getAccountNumber()}
          </div>
        </>
      ) : (
        <div className={cx('bank-account-row')}>
          <div className={cx('bank-account-icon-rounded', 'bank-account-empty-icon-rounded')}>
            <img src={BankEmptyIcon} alt="Bank" className={cx('bank-account-empty-bank-icon')} />
          </div>
          <div className={cx('bank-account-disconnected')}>
            <span className={cx('bank-account-disconnected-title')}>{t('bank-account')}</span>
            <span className={cx('bank-account-main-text')}>{t('bank-account-not-connected')}</span>
          </div>
        </div>
      )}
    </CommonTileContainer>
  );
};
