// import crashlytics from '@react-native-firebase/crashlytics';
import { noop } from '../helpers';
import { envService } from './env.service';

interface IErrorsReporterConfig {
  userId: string;
}

class RemoteLogger {
  async init({ userId }: IErrorsReporterConfig) {
    // await Promise.all([crashlytics().setUserId(userId)]).catch(noop);
  }

  async reset() {
    // await Promise.all([crashlytics().setUserId('')]).catch(noop);
  }

  reportError(error: Error, message?: string) {
    if (message) {
      // crashlytics().log(`${envService.envConfig.ENV} - ${message}`);
    }

    // crashlytics().recordError(error);
  }
}

export const remoteLogger = new RemoteLogger();
