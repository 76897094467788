import React from 'react';
import cx from 'classnames';
import './style.scss';
import { IPageContentContainerProps } from './types';

export const PageContentContainer: React.FC<IPageContentContainerProps> = ({
  children,
  className,
}) => {
  return <div className={cx('page-content-container', className)}>{children}</div>;
};
