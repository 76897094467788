const styles = `.theme-light .card {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

* {
  font-family: "Futura PT", sans-serif !important;
}

button:focus {
  box-shadow: none !important;
}

.select-options {
  border-radius: 8px !important;
}

button.info {
  background: #CF46B9 !important;
  color: white !important;
  border-color: transparent !important;
}
button.info:hover {
  background: #CF46B9 !important;
  color: white !important;
}
button.info:disabled {
  background: #ebebeb !important;
  color: #b6b5c2 !important;
}

button.basic {
  background: #CF46B9 !important;
  color: white !important;
  border-color: transparent !important;
}
button.basic:disabled {
  background: #ebebeb !important;
  color: #b6b5c2 !important;
}

button.info-outline {
  color: #CF46B9 !important;
  border-color: #CF46B9 !important;
  background: transparent !important;
}

.spinner > div {
  background-color: #CF46B9 !important;
}

.back-btn {
  color: #CF46B9 !important;
}

.loan-info {
  border: none !important;
}

button.select {
  background: #F1F2F4 !important;
  border-radius: 8px !important;
}
button.select > div > span:last-of-type {
  color: #B6B5C2 !important;
}
button.select .select-value, button.select .select-symbol {
  font-weight: 450 !important;
}

input {
  border: none !important;
  background: #F1F2F4 !important;
}
input:focus {
  box-shadow: none !important;
}

.credit-line {
  background: none !important;
  border: none !important;
}
.credit-line > div:nth-of-type(2) > .deposit-credit-line {
  background: #F1F2F4 !important;
}
.credit-line > .card-fund {
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.06);
}
.credit-line > .card-fund:nth-of-type(3) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.credit-line > .card-fund:last-of-type {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.fund-details > .addresses {
  background: #F8F7FA !important;
  border-radius: 8px;
}
.fund-details > .addresses > .address-info > div > div {
  color: #CF46B9 !important;
}

#collateralForm input {
  border: 1px solid #CF46B9 !important;
  border-right-color: transparent !important;
  background: transparent !important;
}
#collateralForm input:focus {
  box-shadow: 0 0 4px #CF46B9 !important;
}`;
export default styles;
