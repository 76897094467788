const filtersData = [
  {
    type: 'currency',
    title: '',
    filters: [
      { value: 'ETH', label: 'ETH' },
      { value: 'USDT', label: 'USDT' },
    ],
  },
];
export const useScreen = () => {
  return {
    filtersData,
  };
};
