import { useDispatch, useSelector } from 'react-redux';
import {
  getWalletConnectQrCodeUrl,
  getWalletConnectQrModalError,
} from '../../redux/blockchain/selectors';
import { useCallback } from 'react';
import {
  resetWalletConnectQrCodeUrl,
  resetWalletConnectQrModalError,
} from '../../redux/blockchain/actions';

export const useModal = () => {
  const dispatch = useDispatch();
  const url = useSelector(getWalletConnectQrCodeUrl);
  const err = useSelector(getWalletConnectQrModalError);
  const visible = !!url || (!!url && !!err);

  const onDismiss = useCallback(() => {
    dispatch(resetWalletConnectQrCodeUrl());
    dispatch(resetWalletConnectQrModalError());
  }, [dispatch]);

  return {
    url,
    err,
    onDismiss,
    visible,
  };
};
