import React, { useCallback } from 'react';
import './style.scss';
import cx from 'classnames';
import ArrowViceVersaIcon from '../../../../assets/images/icons/arrow-vice-versa.svg';
import { useTranslation } from 'react-i18next';
import { TokenIconRounded } from '../../../../components';
import { isEthNetwork } from '../../../../helpers';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { getExternalWalletType } from '../../../../redux/blockchain/selectors';

export const NetworksBridge: React.FC<ICrossChainNetworksBridgeProps> = ({
  walletConnected,
  network,
  handleChangeNetwork,
}) => {
  const externalWalletType = useSelector(getExternalWalletType);
  const { t } = useTranslation(['cross-chain-bridge']);

  const renderItemNetwork = useCallback((isEthereum: boolean) => {
    return (
      <div className={cx('networks-bridge-item', 'networks-bridge-row')}>
        <TokenIconRounded tokenName={isEthereum ? 'ETH' : 'BNB'} />
        <span className={cx('ccb-main-text', 'networks-bridge-network-label')}>
          {t(isEthereum ? 'ethereum' : 'smart-chain')}
        </span>
      </div>
    );
  }, []);

  return (
    <>
      <div className="networks-bridge">
        <div className={cx('networks-bridge-row', 'networks-bridge-blockchains')}>
          <div className="networks-bridge-blockchain-label-wrapper">
            <span className={cx('ccb-secondary-text')}>{t('current-blockchain')}</span>
          </div>
          <div className="ccb-spacer" />
          <div className="networks-bridge-blockchain-label-wrapper">
            <span className="ccb-secondary-text">{t('target-blockchain')}</span>
          </div>
        </div>
        <div className="networks-bridge-row">
          {renderItemNetwork(isEthNetwork(network))}
          <button
            data-tip="changing-network"
            data-for="changing-network"
            className={cx('networks-bridge-change-network-button', {
              ['networks-bridge-change-network-button-disabled']:
                externalWalletType !== 'metamaskExtension' && externalWalletType !== 'coinbase',
            })}
            onClick={handleChangeNetwork}
          >
            <div className="ccb-spacer">
              <img src={ArrowViceVersaIcon} className="networks-bridge-arrows" alt={'arrow'} />
            </div>
          </button>
          {renderItemNetwork(!isEthNetwork(network))}
        </div>
      </div>
      {externalWalletType !== 'metamaskExtension' &&
        externalWalletType !== 'coinbase' &&
        walletConnected && (
          <ReactTooltip backgroundColor={'black'} id="changing-network" type="dark">
            <span className="networks-bridge-tooltip-text">{t('changing-networks-tooltip')}</span>
          </ReactTooltip>
        )}
    </>
  );
};
