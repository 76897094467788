import { call, put, takeLatest } from 'redux-saga/effects';
import { logoutSaga } from '../auth/saga';
import {
  ADD_WALLET_REQUEST,
  addWalletRequest,
  DELETE_ACCOUNT,
  EDIT_WALLET_REQUEST,
  editWalletFailure,
  editWalletRequest,
  IMPORT_WALLET_REQUEST,
  importWalletFailure,
  importWalletRequest,
  setUserApiWallets,
  editProfilePictureFailure,
  EDIT_PROFILE_PICTURE_REQUEST,
  updateCurrentUser,
  updateCurrentUserMetadata,
  DELETE_PROFILE_PICTURE_REQUEST,
  editProfileFailure,
  EDIT_PROFILE_REQUEST,
  deleteProfilePictureFailure,
} from './actions';
import { usersResource, walletsResource } from '../../api';
import { addWalletFailure } from './actions';
import { authService, HttpClientValidationError } from '../../services';
import { __DEV__ } from '../../constants';
import { PayloadAction } from 'typesafe-actions';
import { IChangeProfilePictureResponse } from './types';

export function* deleteAccountSaga() {
  try {
    yield call(logoutSaga);
  } catch (e) {
    __DEV__ && console.log('-------- e', e);
  }
}

/** @throws {Error} */
function* refetchApiWallets() {
  const userApiWallets: IApiWallet[] = yield walletsResource.getWallets();
  yield put(setUserApiWallets(userApiWallets));
}

export function* addWalletRequestSaga(action?: ReturnType<typeof addWalletRequest> | undefined) {
  try {
    if (action) {
      yield call([walletsResource, walletsResource.addWallet], action.payload);
    } else {
      // const user: IUser = yield select(getCurrentUser);
      //
      // if (user) {
      //   const storedWallet: IStoredWallet = yield call(getInternalWalletFromStorage, user.userId);
      //
      //   yield call([walletsResource, walletsResource.addWallet], {
      //     address: storedWallet.address,
      //     currency: 'ETH',
      //     type: 'internal',
      //   });
      // }
    }

    yield call(refetchApiWallets);
  } catch (e) {
    if (e instanceof HttpClientValidationError && e.message === 'Wallet already exists') {
      __DEV__ && console.log('-------- e', e); // TODO need error code from BE
    } else {
      yield put(addWalletFailure(e as Error));
    }
  }
}

export function* editWalletRequestSaga({ payload }: ReturnType<typeof editWalletRequest>) {
  try {
    yield call([walletsResource, walletsResource.editWallet], payload);
    yield call(refetchApiWallets);
  } catch (e) {
    yield put(editWalletFailure(e as Error));
  }
}

export function* importWalletRequestSaga({ payload }: ReturnType<typeof importWalletRequest>) {
  try {
    yield call([walletsResource, walletsResource.importWallet], payload);
    yield call(refetchApiWallets);
  } catch (e) {
    yield put(importWalletFailure(e as Error));
  }
}

export function* changeProfilePictureSaga({ payload }: PayloadAction<string, { picture: IFile }>) {
  try {
    const res: IChangeProfilePictureResponse = yield call(
      [usersResource, usersResource.uploadProfilePicture],
      payload,
    );
    yield put(
      updateCurrentUser({
        picture: res.pictureUrl,
      }),
    );
    yield put(
      updateCurrentUserMetadata({
        pictureUrl: res.pictureUrl,
      }),
    );
    yield authService.updateUser({
      pictureUrl: res.pictureUrl,
    });
  } catch (e) {
    yield put(editProfilePictureFailure(e as Error));
  }
}

export function* removeProfilePictureSaga() {
  try {
    yield call([usersResource, usersResource.deleteProfilePicture]);
    yield put(
      updateCurrentUser({
        picture: undefined,
      }),
    );
    yield put(
      updateCurrentUserMetadata({
        pictureUrl: undefined,
      }),
    );
    yield authService.updateUser({
      pictureUrl: null,
    });
  } catch (e) {
    yield put(deleteProfilePictureFailure(e as Error));
  }
}

export function* updateProfileDataSaga({ payload }: PayloadAction<string, EditUserParams>) {
  try {
    yield call([usersResource, usersResource.editUser], payload);
    yield put(updateCurrentUserMetadata(payload));
    yield authService.updateUser(payload);
  } catch (e) {
    yield put(editProfileFailure(e as Error));
  }
}

export function* watchUsersSaga() {
  yield takeLatest(DELETE_ACCOUNT, deleteAccountSaga);
  yield takeLatest(ADD_WALLET_REQUEST, addWalletRequestSaga);
  yield takeLatest(EDIT_WALLET_REQUEST, editWalletRequestSaga);
  yield takeLatest(IMPORT_WALLET_REQUEST, importWalletRequestSaga);
  yield takeLatest(EDIT_PROFILE_PICTURE_REQUEST, changeProfilePictureSaga);
  yield takeLatest(DELETE_PROFILE_PICTURE_REQUEST, removeProfilePictureSaga);
  yield takeLatest(EDIT_PROFILE_REQUEST, updateProfileDataSaga);
}
