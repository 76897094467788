import { createReducer } from 'typesafe-actions';
import { ISignUpState, SignUpStep } from './types';
import { resetSignUpState, setConfirmationCodeTimeoutTimestamp, setSignUpState } from './actions';
import { resetStore } from '../app/actions';

const initialState: ISignUpState = {
  step: SignUpStep.EmailPassword,
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  profilePicture: null,
  areTermsAccepted: false,
  isPhoneNumberVerified: false,
  confirmationCodeTimeoutTimestamp: null,
};

export const signUp = createReducer(initialState)
  .handleAction(setSignUpState, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(setConfirmationCodeTimeoutTimestamp, (state, { payload }) => ({
    ...state,
    confirmationCodeTimeoutTimestamp: payload,
  }))
  .handleAction(resetSignUpState, () => initialState)
  .handleAction(resetStore, () => initialState);
