import { __DEV__ } from '../constants';

export type StorageEntities = AuthStorageEntities;

export const getFromStorage = <Response = string>(
  entity: keyof StorageEntities,
  onError?: ValueCallback<Error>,
): Response | null => {
  try {
    const item = localStorage.getItem(entity);

    return item ? JSON.parse(item) : null;
  } catch (err) {
    if (onError) {
      onError(err as Error);
    }
    return null;
  }
};

export const saveToStorage = async (entities: Partial<StorageEntities>) => {
  Object.entries(entities).forEach(([key, value]) => {
    if (value !== undefined) {
      return localStorage.setItem(key, JSON.stringify(value));
    }
  });
};

export const removeFromStorage = async (entity: keyof StorageEntities) => {
  try {
    localStorage.removeItem(`${entity}`);
  } catch (e) {
    __DEV__ && console.log(e);
  }
};
