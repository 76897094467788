export const blocks = [
  {
    title: 'introduction-title',
    description: 'introduction-description',
  },
  {
    title: 'reasons-title',
    description: 'reasons-description',
  },
  {
    title: 'receive-info-title',
    description: 'receive-info-description',
  },
  {
    title: 'info-types-title',
    description: 'info-types-description',
  },
  {
    title: 'cookies-title',
    description: 'cookies-description',
  },
  {
    title: 'usage-title',
    description: 'usage-description',
  },
  {
    title: 'share-title',
    description: 'share-description',
  },
  {
    title: 'protect-title',
    description: 'protect-description',
  },
  {
    title: 'retention-title',
    description: 'retention-description',
  },
  {
    title: 'secure-title',
    description: 'secure-description',
  },
  {
    title: 'rights-title',
    description: 'rights-description',
  },
  {
    title: 'updates-title',
    description: 'updates-description',
  },
  {
    title: 'contact-title',
    description: 'contact-description',
  },
];
