import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import notFound from '../../assets/images/not-found.svg';

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation('not-found-page');

  return (
    <div className="notFound">
      <div className="notFound-wrapper">
        <img src={notFound} alt="icon" className="notFound-image" />
        <p className="notFound-wrapper-title">{t('title')}</p>
        <p className="notFound-wrapper-subTitle">{t('description')}</p>
        <NavLink to="/">{t('back-button-label')}</NavLink>
      </div>
    </div>
  );
};
