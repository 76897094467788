import React, { useCallback } from 'react';
import './styles.scss';
import { ReactComponent as ArrowLeft } from './../../assets/images/icons/arrow.svg';
import { EmailStep, FullNameStep, OTPConfirmationStep, StepProgressBar } from './steps';
import { SignUpStep } from '../../redux/auth/types';
import { useSignUpScreen } from './use-sign-up-screen';
import { UserAuthHeader } from '../../components';
import { useTranslation } from 'react-i18next';

export const SignUpPage: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { step, handleClickBack, handleClickCancel, config } = useSignUpScreen();

  const renderTopNavigation = useCallback(() => {
    return (
      <div className="top-navigation">
        <div>
          {step > 3 && (
            <button onClick={handleClickBack}>
              <ArrowLeft className="top-navigation-arrow-left" />
              <span>{t('sign-up:back')}</span>
            </button>
          )}
        </div>
        <button onClick={handleClickCancel}>
          <span className="top-navigation-cancel-registration">
            {t('sign-up:cancel-registration')}
          </span>
        </button>
      </div>
    );
  }, [t, step, handleClickBack, handleClickCancel]);

  return (
    <div className="signUp">
      {renderTopNavigation()}
      <div className="signUp-form">
        <UserAuthHeader title={t('common:sign-up')} subTitle={t('common:almost-there')} />
        <StepProgressBar step={step} config={config} />
        {step === SignUpStep.EmailPassword && <EmailStep />}
        {step === SignUpStep.OTPConfirmationEmail && <OTPConfirmationStep type={'email'} />}
        {step === SignUpStep.FullNamePhoneNumber && <FullNameStep />}
        {step === SignUpStep.OTPConfirmationPhone && <OTPConfirmationStep type={'phone'} />}
      </div>
    </div>
  );
};
