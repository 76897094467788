import { useCallback, useEffect, useMemo } from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { getValidationSchema } from './validation-schema';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  finishSignUp,
  sendVerificationSmsFailure,
  setConfirmationCodeTimeoutTimestamp,
  setSignUpState,
} from '../../../../redux/auth/actions';
import { SignUpStep } from '../../../../redux/auth/types';
import { getSignUpData } from '../../../../redux/auth/selectors';
import { useMutation } from 'react-query';
import { configurationResource, usersResource } from '../../../../api';
import { ONLY_PHONE_NUM_REGEX } from '../../../../constants';
import { useAppLoading } from '../../../../hooks';
import { getResendConfirmationCodeTimeoutTimestamp, noop } from '../../../../helpers';
import { authService } from '../../../../services';
import { getApiConfig } from '../../../../redux/app/selectors';
import { setApiConfig } from '../../../../redux/app/actions';

export const useForm = () => {
  const { firstName, lastName, phoneNumber, areTermsAccepted, isPhoneNumberVerified } = useSelector(
    getSignUpData,
    shallowEqual,
  );
  const config = useSelector(getApiConfig, shallowEqual);
  const shouldVerifyPhone = config?.isPhoneEnabled ?? true;
  const appLoading = useAppLoading();
  const initialValues: ISignUpFullNameFormData = {
    firstName,
    lastName,
    phoneNumber,
    areTermsAccepted,
  };
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const dispatch = useDispatch();

  const { mutateAsync: sendVerificationSms } = useMutation<
    ISendVerificationSmsResponse | undefined,
    Error,
    ISendVerificationSmsParams
  >(usersResource.sendVerificationSms);

  const { mutateAsync: updateUser } = useMutation<
    EditUserResponse | undefined,
    Error,
    EditUserParams
  >(usersResource.editUser);

  useEffect(() => {
    configurationResource
      .getConfiguration()
      .then((conf) => {
        if (conf) {
          dispatch(setApiConfig(conf));
        }
      })
      .catch(noop);
  }, [dispatch]);

  const onSubmitForm = useCallback(
    async (
      values: ISignUpFullNameFormData,
      { setSubmitting }: FormikHelpers<ISignUpFullNameFormData>,
    ) => {
      try {
        const phoneNumberForApi = `+${values.phoneNumber.replace(ONLY_PHONE_NUM_REGEX, '')}`;

        if (shouldVerifyPhone) {
          if (isPhoneNumberVerified) {
            await updateUser({ areTermsAccepted: true });
            dispatch(finishSignUp());
            return;
          }

          await sendVerificationSms({ phoneNumber: phoneNumberForApi });
          await updateUser({ firstName: values.firstName, lastName: values.lastName });
          dispatch(
            setSignUpState({
              step: SignUpStep.OTPConfirmationPhone,
              firstName: values.firstName,
              lastName: values.lastName,
              areTermsAccepted: values.areTermsAccepted,
              phoneNumber: phoneNumberForApi,
            }),
          );
          dispatch(
            setConfirmationCodeTimeoutTimestamp(getResendConfirmationCodeTimeoutTimestamp()),
          );
        } else {
          await updateUser({
            phoneNumber: phoneNumberForApi,
            firstName: values.firstName,
            lastName: values.lastName,
            areTermsAccepted: true,
          });
          await authService.updateUser({
            phoneNumber: phoneNumberForApi,
            firstName: values.firstName,
            lastName: values.lastName,
          });
          dispatch(finishSignUp());
        }
      } catch (e) {
        dispatch(sendVerificationSmsFailure(e as Error));
      } finally {
        setSubmitting(false);
      }
    },
    [dispatch, sendVerificationSms, updateUser, isPhoneNumberVerified, shouldVerifyPhone],
  );

  return {
    initialValues,
    onSubmitForm,
    validationSchema,
    appLoading,
    isPhoneNumberVerified,
    lastStep: !shouldVerifyPhone,
  };
};
