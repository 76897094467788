import { DefaultOptions } from 'react-query/types/core/types';
import { Config } from './env.config';
import { IClientMeta } from '@walletconnect/types';
import Decimal from 'decimal.js';

export const __DEV__ = process.env.NODE_ENV === 'development';

export const DEFAULT_REFETCH_INTERVAL = 20 * 1000;

export const APP_VERSION = '2.0.0';
export const IDLE_TIMEOUT = 15 * 60 * 1000;
export const REQUEST_TIMEOUT = 10 * 1000;
export const WALLET_CONNECT_TRANSACTION_TIMEOUT = 90 * 1000;
export const CRYPTO_BALANCE_PRECISION = 5;
export const EPAN_BALANCE_PRECISION = 2;
export const TRANSACTION_AMOUNT_PRECISION = 9;
export const TRANSACTION_GAS_PRECISION = 6;
export const EARNING_AMOUNT_PRECISION = 7;
export const RESEND_CONFIRMATION_CODE_TIMOUT = 180 * 1000;
export const LONG_TERM_EPAN_STAKING_MONTH_DURATION = 6;
export const TOKEN_DECIMAL_LENGTH = 5;
export const FIAT_EXCHANGE_PRECISION = 2;
export const ESTIMATE_GAS_MULTIPLIER = 1.3;
export const SWAPPING_TOKEN_PRECISION = 6;
export const CURRENCIES_TO_ROUND_MAP = ['EPAN', 'BEPAN'];
export const DEFAULT_CURRENCY_AMOUNT_PRECISION = 2;
export const SWAPPING_GAS_MULTIPLIER = 2;
export const UNISWAP_LIQUIDITY_GAS_MULTIPLIER = 1.5;
export const UNISWAP_LIQUIDITY_INVESTMENT_GAS = 200000;
export const CLUB_DEAL_SWAP_GAS_APPROVAL_MULTIPLIER = 1.5;
export const CLUB_DEAL_SWAP_GAS_SWAPPING_MULTIPLIER = 1.5;
export const UNISWAP_LIQUIDITY_APPROVAL_GAS = 50000;
export const SWAP_TOKEN_GAS = 200000;
export const CLUB_DEAL_SWAP_GAS = 160000;
export const SPOT_ACCOUNT_CALL_CENTER_PHONE = '+49 172 456 23 12';
export const LIQUIDITY_PROVIDER_FEE_FOR_ETH_PERCENTAGE = 0.003;
export const LIQUIDITY_PROVIDER_FEE_FOR_TOKENS_PERCENTAGE = 0.005991;
export const DEFAULT_PRECISION = 2;
export const TOKEN_APPROVAL_AMOUNT = new Decimal(2).pow(256).minus(1);

export const DEFAULT_ETH_WALLET_CURRENCIES: DefaultEthWalletCurrencies[] = [
  'EPAN',
  'POLVEN',
  'ETH',
  'USDT',
];
export const DEFAULT_BSC_WALLET_CURRENCIES: DefaultBscWalletCurrencies[] = ['BEPAN', 'BNB'];
export const DEFAULT_WALLET_CURRENCIES: Array<
  DefaultEthWalletCurrencies | DefaultBscWalletCurrencies
> = [...DEFAULT_ETH_WALLET_CURRENCIES, ...DEFAULT_BSC_WALLET_CURRENCIES];
export const NETWORK_NATIVE_CURRENCIES: NetworkNativeCurrency[] = ['ETH', 'BNB'];
export const SUPPORTED_FIAT_CURRENCIES: Record<TSupportedFiatCurrencies, IFiatCurrency> = {
  USD: { currency: 'USD', symbol: '$' },
  EUR: { currency: 'EUR', symbol: '€' },
};

export const uniswapTransactionSettingsDefaultValues: ITopUpSpotAccountSettingsData = {
  slippageTolerance: 0.1,
  deadline: 30,
};

export const uniswapAddLiquidityTransactionSettingsValues: ITopUpSpotAccountSettingsData = {
  slippageTolerance: 0.5,
  deadline: 30,
};

export const uniswapRemoveLiquidityTransactionSettingsValues: ITopUpSpotAccountSettingsData = {
  slippageTolerance: 5,
  deadline: 30,
};

export const pancakeSwapTransactionSettingsDefaultValues: ITopUpSpotAccountSettingsData = {
  slippageTolerance: 10,
  deadline: 20,
};

export const uniswapTransactionSettingsValidationValues = {
  slippage: {
    valid: 50,
    maxExecution: 1,
    minExecution: 0.05,
  },
  deadline: {
    max: 180,
  },
};

export const DEFAULT_ACTIVE_TOKEN_LISTS = ['Compound', 'UniswapDefaultList', 'PancakeSwapTop100'];

export const blockchainErrors = {
  insufficientOutputAmount: 'INSUFFICIENT_OUTPUT_AMOUNT',
};

export const envConfig: Record<IEnv, IEnvConfig> = {
  DEV: {
    ...Config,
    ENV: 'DEV',
  },
  STAGE: {
    ...Config,
    ENV: 'STAGE',
  },
  PRODUCTION: {
    ...Config,
    ENV: 'PRODUCTION',
  },
} as const;

export const reactQueryDefaultOptions: DefaultOptions = {
  queries: {
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    retry: false,
  },
  mutations: {
    retry: false,
  },
};

export const AVAILABLE_LANGUAGE_TAG: Array<AvailableLanguageTags> = ['en'];

export const WALLET_CONNECT_CLIENT_META: IClientMeta = {
  name: 'Paypolitan',
  url: 'https://paypolitan.li/',
  description: 'Paypolitan - Metaverse revolution!',
  icons: ['https://media-web-app-prod.s3.eu-central-1.amazonaws.com/epan-token-icon.svg'],
};

export const INTERNAL_WALLET_STORAGE_KEY = 'INTERNAL_WALLET';
export const INTERNAL_WALLETS_MAP_STORAGE_KEY = 'INTERNAL_WALLETS_LIST';
export const INTERNAL_WALLETS_NAME_MAP_STORAGE_KEY = 'INTERNAL_WALLETS_NAME_STORAGE_KEY';
export const CURRENT_CHAIN_ID_STORAGE_KEY = 'currentChainId' as const;
export const ENV_STORAGE_KEY = 'ENV' as const;

export const blockExplorerApiBaseUrl: Record<Network, string> = {
  mainnet: 'https://api.etherscan.io/api',
  ropsten: 'https://api-ropsten.etherscan.io/api',
  bscMainnet: 'https://api.bscscan.com/api',
  bscTestnet: 'https://api-testnet.bscscan.com/api',
};

export const blockExplorerType: Record<BlockExplorer, Array<Network>> = {
  bscScan: ['bscMainnet', 'bscTestnet'],
  etherScan: ['mainnet', 'ropsten'],
};

export const blockExplorerUrl: Record<BlockExplorer, string> = {
  bscScan: 'https://bscscan.com',
  etherScan: 'https://etherscan.io',
};

export const TERMS_AND_CONDITION_URL = 'https://paypolitan.io/terms';
export const PRIVACY_POLICY_URL = 'https://paypolitan.io/privacy_policy';
