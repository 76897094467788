import { useCallback, useState } from 'react';

export const useModal = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const toggleModalVisibility = useCallback(() => {
    setModalVisible((prevState) => !prevState);
  }, []);

  const closeModal = useCallback(() => setModalVisible(false), []);

  return {
    modalVisible,
    toggleModalVisibility,
    closeModal,
  };
};
