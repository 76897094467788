import { createAction } from 'typesafe-actions';

export const ACTIVATE_SPOT_ACCOUNT_FAILURE = '@SPOT/ACTIVATE_SPOT_ACCOUNT_FAILURE';
export const FETCH_SPOT_ACCOUNT_FAILURE = '@SPOT/FETCH_SPOT_ACCOUNT_FAILURE';
export const TOP_UP_SPOT_ACCOUNT_FAILURE = '@SPOT/TOP_UP_SPOT_ACCOUNT_FAILURE';
export const REDEEM_SPOT_ACCOUNT_FUNDS_FAILURE = '@SPOT/REDEEM_SPOT_ACCOUNT_FUNDS_FAILURE';
export const CALCULATE_TOP_UP_RATE_FAILURE = '@SPOT/CALCULATE_TOP_UP_RATE_FAILURE';
export const FETCH_TOP_UP_WALLET_ADDRESSES_FAILURE = '@SPOT/FETCH_TOP_UP_WALLET_ADDRESSES_FAILURE';

export const activateSpotAccountFailure = createAction(ACTIVATE_SPOT_ACCOUNT_FAILURE)<Error>();
export const fetchSpotAccountFailure = createAction(FETCH_SPOT_ACCOUNT_FAILURE)<Error>();
export const topUpSpotAccountFailure = createAction(TOP_UP_SPOT_ACCOUNT_FAILURE)<Error>();
export const redeemSpotAccountFundsFailure = createAction(
  REDEEM_SPOT_ACCOUNT_FUNDS_FAILURE,
)<Error>();
export const calculateTopUpRateFailure = createAction(CALCULATE_TOP_UP_RATE_FAILURE)<Error>();
export const fetchTopUpWalletAddressesFailure = createAction(
  FETCH_TOP_UP_WALLET_ADDRESSES_FAILURE,
)<Error>();

export const spotAccountActions = {
  activateSpotAccountFailure,
  fetchSpotAccountFailure,
  topUpSpotAccountFailure,
  redeemSpotAccountFundsFailure,
  calculateTopUpRateFailure,
  fetchTopUpWalletAddressesFailure,
} as const;
