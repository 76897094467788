import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { getValidationSchema } from './validation-schema';
import { useDispatch, useSelector } from 'react-redux';
import { getSignUpEmail } from '../../../../redux/auth/selectors';
import {
  createUser,
  setConfirmationCodeTimeoutTimestamp,
  setSignUpState,
} from '../../../../redux/auth/actions';
import { SignUpStep } from '../../../../redux/auth/types';
import { getResendConfirmationCodeTimeoutTimestamp } from '../../../../helpers';

export const useForm = () => {
  const email = useSelector(getSignUpEmail);
  const initialValues: ISignUpEmailFormData = { email, password: '', confirmPassword: '' };
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const dispatch = useDispatch();

  const onSubmitForm = useCallback(
    async (
      values: ISignUpEmailFormData,
      { setSubmitting }: FormikHelpers<ISignUpEmailFormData>,
    ) => {
      dispatch(
        createUser({
          email: values.email,
          password: values.password,
          onCreated: () => {
            setSubmitting(false);
            dispatch(
              setSignUpState({ step: SignUpStep.OTPConfirmationEmail, email: values.email }),
            );
            dispatch(
              setConfirmationCodeTimeoutTimestamp(getResendConfirmationCodeTimeoutTimestamp()),
            );
          },
        }),
      );
    },
    [dispatch],
  );

  return {
    initialValues,
    onSubmitForm,
    validationSchema,
  };
};
