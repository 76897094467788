export const paypolitanEarningToCurrencyMap: Record<PaypolitanEarningType, PaypolitanStakingType> =
  {
    bepanStakingFlexible: 'BEPAN',
    bepanStakingLong: 'BEPAN',
    epanStakingFlexible: 'EPAN',
    epanStakingLong: 'EPAN',
    polvenStakingFlexible: 'POLVEN',
    polvenStakingLong: 'POLVEN',
  };

export const earningTypeToCurrencyMap: Record<EarningType, EarningCurrencies> = {
  epanStakingFlexible: 'EPAN',
  epanStakingLong: 'EPAN',
  polvenStakingFlexible: 'POLVEN',
  polvenStakingLong: 'POLVEN',
  bepanStakingLong: 'BEPAN',
  bepanStakingFlexible: 'BEPAN',
} as const;

export const earningsApy: Record<EarningType, number> = {
  bepanStakingFlexible: 8.45,
  bepanStakingLong: 16.9,
  epanStakingFlexible: 8.45,
  epanStakingLong: 16.9,
  polvenStakingFlexible: 8.5,
  polvenStakingLong: 19.8,
};
