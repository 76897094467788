import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { getValidationSchema } from './validation-schema';
import { useDispatch } from 'react-redux';
import { signInPassword } from '../../redux/auth/actions';

export const useForm = () => {
  const initialValues: ISignInFormData = { email: '', password: '' };
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const dispatch = useDispatch();

  const onSubmitForm = useCallback(
    async (values: ISignInFormData, { setSubmitting }: FormikHelpers<ISignInFormData>) => {
      dispatch(signInPassword(values));
      setSubmitting(false);
    },
    [dispatch],
  );

  return {
    initialValues,
    onSubmitForm,
    validationSchema,
  };
};
