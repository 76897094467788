import React, { useCallback, useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { authService } from '../../services';
import { UnauthenticatedModal } from '../../components';
import { useModal } from '../../hooks';
import { useSelector } from 'react-redux';
import { getAuthenticated } from '../../redux/app/selectors';
import { IDLE_TIMEOUT, routes } from '../../constants';
import { useIdleTimer } from 'react-idle-timer';

export const ProtectedRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const token = authService.getAccessToken('auth0');
  const { modalVisible, toggleModalVisibility, closeModal } = useModal();
  const history = useHistory();
  const authenticated = useSelector(getAuthenticated);

  const processMissingToken = useCallback(() => {
    if (authenticated) {
      toggleModalVisibility();
    } else {
      history.push({
        pathname: routes.SIGN_IN,
        state: { from: location.pathname },
      });
    }
  }, [toggleModalVisibility, history, authenticated]);

  useEffect(() => {
    if (!token) {
      processMissingToken();
    }
  }, [token, processMissingToken]);

  useIdleTimer({
    onIdle: processMissingToken,
    timeout: IDLE_TIMEOUT,
    crossTab: true,
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <React.Fragment>
            {/*@ts-ignore*/}
            <Component {...props} />
            <UnauthenticatedModal isOpen={modalVisible} onCloseModal={closeModal} />
          </React.Fragment>
        );
      }}
    />
  );
};
