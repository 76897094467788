import React from 'react';
import { Avatar } from '@mui/material';
import { IUserAvatar } from './types';

export const AvatarUser: React.FC<IUserAvatar> = ({ userInfo, customClass }) => {
  const { lastName, firstName, photoUrl } = userInfo;

  if (photoUrl) {
    return <Avatar className={customClass} alt="user photo" src={photoUrl} />;
  }

  return (
    <Avatar className={customClass}>{`${firstName.substring(0, 1)}${lastName.substring(
      0,
      1,
    )}`}</Avatar>
  );
};
