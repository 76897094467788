import { TransactionReceipt, TransactionConfig } from 'web3-core';
import { Contract } from 'web3-eth-contract';
import WalletConnectProvider from '@walletconnect/web3-provider';

export interface ISendTransactionParams {
  userId: string;
  toAddress: string;
  fromWallet: IWallet;
  amount: number;
  gasPriceInEth?: string;
  estimatedGas?: number;
  connector?: /*ReturnType<typeof useWalletConnect>*/ {};
  description?: string;
  executionAbortControllerName: string;
  onReceipt?: (receipt?: TransactionReceipt) => void;
  onError?: (e: Error) => void;
  customToken?: ICustomToken;
  chainId: SupportedChainId;
}

export interface IApproveErc20TokenTransactionParams {
  spenderAddress: string;
  fromWallet: IWallet;
  amount?: number;
  gasPrice?: string;
  gas?: string;
  connector?: /*ReturnType<typeof useWalletConnect>*/ {};
  customToken: ICustomToken;
  userId?: string;
  chainId: SupportedChainId;
  executionAbortControllerName: string;
  onReceipt?: (receipt?: TransactionReceipt) => void;
}

// export interface ISendWalletConnectTransactionParams extends ITxDataWalletConnect {
//   connector: ReturnType<typeof useWalletConnect>;
//   chainId: SupportedChainId;
// }

export interface ISendInternalWalletTransactionParams extends TransactionConfig {
  userId?: string;
  executionAbortControllerName: string;
  onReceipt?: (receipt?: TransactionReceipt) => void;
  onError?: (err: Error) => void;
}

export interface IPaypolitanStakingTransactionParams {
  type: PaypolitanStakingType;
  isTimeFrame: boolean;
  value: string;
  fromWallet: IWallet;
  gasLimit?: string;
  gasPrice?: string;
  gasPriceInEth?: string;
  network: Network;
  userId?: string;
  gas?: number;
  connector?: /*ReturnType<typeof useWalletConnect>*/ {};
  onReceipt?: (receipt?: TransactionReceipt) => void;
}

export interface IGetTokenAllowanceParams {
  walletAddress: string;
  tokenAddress: string;
  chainId: SupportedChainId;
}

export interface ITxData {
  from: string;
  to: string;
  gasPrice: string;
  gas: string;
  value: string | number;
  data: string;
}

export interface ISendFromInternalWalletParams {
  onReceipt?: () => void;
  onError?: (err: Error) => void;
  txData: ITxData;
  userId?: string;
}

export interface IApproveERC20TokenParams {
  userId?: string;
  inputAmount: string;
  fromWallet: IWallet;
  gasPriceInEth: string;
  gas: number;
  connector: /*ReturnType<typeof useWalletConnect>*/ {};
  chainId: SupportedChainId;
  onReceipt?: () => void;
  onError?: (err: Error) => void;
}

export interface IEstimateApprovalGasParams {
  amount: string;
  fromWallet: IWallet;
  gasPriceInEth: string;
  chainId: SupportedChainId;
}

export interface ISendInternalWalletTransactionParams extends TransactionConfig {
  chainId: SupportedChainId;
  userId?: string;
  onReceipt?: (receipt?: TransactionReceipt) => void;
}

export interface IEthBscPairsData {
  eth: Maybe<Contract>;
  bsc: Maybe<Contract>;
}

export interface IEthBscBridgeTransactionParams {
  type: EthBscBridgePairs;
  value: string;
  fromWallet: IWallet;
  network: Network;
  connector?: /*ReturnType<typeof useWalletConnect>*/ {};
  onReceipt?: (receipt?: TransactionReceipt) => void;
  onError?: (e: Error) => void;
}

export interface IPrepareEthBscBridgeSwapTrxDataParams {
  type: EthBscBridgePairs;
  value: string;
  fromWallet: IWallet;
  network: Network;
  feeValue: string;
}

export interface ISupplyInvestmentParams extends IGetSupplyInvestmentTxDataParams {
  wallet: IWallet;
  userId: string;
  onError?: (err: Error) => void;
  onReceipt?: () => void;
  connector: /*ReturnType<typeof useWalletConnect>*/ {};
}

export interface IInitWalletConnectProviderParams extends IEthProviderSubscriptionParams {
  provider: WalletConnectProvider;
  addresses: string[];
}

export enum EAbortControllers {
  STAKING = 'STAKING',
  CROSS_CHAIN_BRIDGE = 'CROSS_CHAIN_BRIDGE',
  TOP_UP_SPOT_ACCOUNT = 'TOP_UP_SPOT_ACCOUNT',
}
