import { call, takeLatest, delay, put, select } from 'redux-saga/effects';
import { Action, PayloadAction } from 'typesafe-actions';
import { translate } from '../../api';
import {
  Auth0Error,
  AuthUnauthorizedError,
  HttpClientUnauthorizedError,
  HttpNetworkError,
  HttpServerError,
  snackbarService,
} from '../../services';
import {
  GET_COMPOUND_SUPPLY_APY_BC_ERROR,
  GET_EARNINGS_BALANCES_BC_ERROR,
  GET_ESTIMATED_GAS_BC_ERROR,
  GET_WALLETS_BALANCES_BC_ERROR,
} from '../blockchain/actions';
import { logout } from '../auth/actions';
import { WalletConnectTimeOutError } from '../../services/blockchain';
import { capitalize } from '../../helpers';
import { getCurrentNetwork } from '../blockchain/selectors';
import {
  __DEV__,
  networkNativeCurrency,
  networkTitles,
  networkToChainIdMap,
} from '../../constants';
import { GET_GAS_ORACLE_FAILURE } from '../transactions/actions';

function* processGeneralErrorsSaga({ type, payload }: PayloadAction<string, Error>) {
  yield delay(500);

  if (payload instanceof HttpClientUnauthorizedError || payload instanceof AuthUnauthorizedError) {
    yield put(logout());
  }

  if (
    type === GET_GAS_ORACLE_FAILURE &&
    (payload instanceof HttpServerError || payload instanceof HttpNetworkError)
  ) {
    __DEV__ && console.log('-------- GET_GAS_ORACLE_FAILURE', payload);
  } else if (payload.message?.includes('Waiting for approval tx error')) {
    return;
  } else if (payload.message) {
    snackbarService.showErrorSnackbar(payload.message);
  } else {
    snackbarService.showErrorSnackbar(translate('unexpected', 'errors'));
  }
}

function* processAuth0ErrorsSaga({ type, payload }: PayloadAction<string, Error>) {
  yield delay(500);

  if (payload instanceof Auth0Error) {
    snackbarService.showErrorSnackbar(payload.message);
  } else {
    yield call(processGeneralErrorsSaga, { type, payload });
  }
}

function* processBlockChainErrorsSaga({ type, payload }: PayloadAction<string, Error>) {
  const network: Network = yield select(getCurrentNetwork);

  yield delay(500);

  if (payload instanceof WalletConnectTimeOutError) {
    snackbarService.showErrorSnackbar(
      translate('wallet-connect-transaction-timeout', 'errors', {
        wallet: capitalize(payload.message),
      }),
    );
  } else if (
    type === GET_EARNINGS_BALANCES_BC_ERROR ||
    type === GET_WALLETS_BALANCES_BC_ERROR ||
    type === GET_COMPOUND_SUPPLY_APY_BC_ERROR ||
    type === GET_ESTIMATED_GAS_BC_ERROR
  ) {
    // currently, don't show to users - seems technical
  } else if (payload instanceof HttpServerError) {
    // currently, don't show to users
  } else if (payload?.message?.includes('insufficient funds')) {
    snackbarService.showErrorSnackbar(
      translate('insufficient-funds', 'errors', { currency: networkNativeCurrency[network] }),
    );
  } else if (payload?.message?.toLowerCase().includes('bad gateway')) {
    snackbarService.showErrorSnackbar(
      translate('blockchain-network-error', 'errors', {
        network: translate(networkTitles[networkToChainIdMap[network]], 'networks'),
      }),
    );
  } else if (
    payload?.message?.toLowerCase().includes('"code": 1001') ||
    payload?.message?.includes('transaction receipt')
  ) {
    // currently, don't show to users
  } else if (payload?.message?.includes('Transaction has been reverted by the EVM')) {
    snackbarService.showErrorSnackbar(translate('transaction-reverted', 'errors'));
  } else if (payload.message?.includes('Waiting for approval tx error')) {
    return;
  } else if (payload.message) {
    snackbarService.showErrorSnackbar(payload.message);
  } else {
    snackbarService.showErrorSnackbar(translate('unexpected', 'errors'));
  }
}

export function* watchErrorsSaga() {
  yield takeLatest((action: Action) => action.type.endsWith('FAILURE'), processGeneralErrorsSaga);
  yield takeLatest((action: Action) => action.type.endsWith('AUTH0_ERROR'), processAuth0ErrorsSaga);
  yield takeLatest(
    (action: Action) => action.type.endsWith('BC_ERROR'),
    processBlockChainErrorsSaga,
  );
}
