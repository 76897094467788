export const ETH_NETWORKS: Record<EthNetwork, IEthNetworkData> = {
  mainnet: {
    name: 'networks:ethereum-main-network',
    network: 'mainnet',
    chain_id: 1,
    infuraId: '',
    native_currency: {
      symbol: 'ETH',
      decimals: '18',
    },
    epanStakingAddress: '0x626D8eb5B818a712a9D50b74cdD8e205BD00BaE1',
    polvenStakingAddress: '0x9b887877F99D7696e0ca1d2dA3B7e75FA11Fd81E',
    uniswapRouterV2Address: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    uniswapV2FactoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    epanToBepanBridgeAddress: '0x4cA4D69246670Dd0e38b10329e58455031Df9Edb',
    clubDealSwapAddress: '0x1fb3bC482b26891939bC116Ba7ee81605753683c',
    polvenGovernanceAddress: '0x407cdbff18f65e25D6eE133f67cb339bADeC4c65',
  },
  ropsten: {
    name: 'networks:ropsten-test-network',
    network: 'ropsten',
    chain_id: 3,
    infuraId: '',
    native_currency: {
      symbol: 'ETH',
      decimals: '18',
    },
    epanStakingAddress: '0xC05083cd3bBaA31dDc3870E98762C38C067cAB9b',
    polvenStakingAddress: '0x904dc0F37e09560366c3934c2E3f552c745Cf948',
    uniswapRouterV2Address: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    uniswapV2FactoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    epanToBepanBridgeAddress: '0x0ce14A33275df220aDb7f115dc11e260a1832C85',
    clubDealSwapAddress: '0xEf2cF1CC0bd265B0597536498248fc77b3a3a95e',
    polvenGovernanceAddress: '0xEe7fcb73b08Ea580396967440F400b6428Acee85',
  },
} as const;

export const STAGE_ETH_NETWORKS: Record<EthNetwork, IEthNetworkData> = {
  mainnet: ETH_NETWORKS.mainnet,
  ropsten: {
    ...ETH_NETWORKS.ropsten,
    epanStakingAddress: '0xC05083cd3bBaA31dDc3870E98762C38C067cAB9b',
    polvenStakingAddress: '0x904dc0F37e09560366c3934c2E3f552c745Cf948',
    epanToBepanBridgeAddress: '0xebCae9511239CBA28C6AEA54355113158F7abEE3',
    polvenGovernanceAddress: '0xEe7fcb73b08Ea580396967440F400b6428Acee85',
  },
} as const;

export const BSC_NETWORKS: Record<BscNetwork, IBscNetworkData> = {
  bscMainnet: {
    name: 'networks:bsc-main-network',
    network: 'bscMainnet',
    chain_id: 56,
    rpc: 'https://bsc-dataseed.binance.org/',
    native_currency: {
      symbol: 'BNB',
      decimals: '18',
    },
    bepanStakingAddress: '0x672e54656A2619De7B773214f241Db6c481ce5df',
    bepanToEpanBridgeAddress: '0x985E2a89c2e0C1AFB56f699cf320Da0782FE0e51',
    pancakeSwapRouterV2Address: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    pancakeSwapFactoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  },
  bscTestnet: {
    name: 'networks:bsc-test-network',
    network: 'bscTestnet',
    chain_id: 97,
    rpc: 'https://data-seed-prebsc-2-s2.binance.org:8545/',
    native_currency: {
      symbol: 'BNB',
      decimals: '18',
    },
    bepanStakingAddress: '0x3058817E38F68B232aa94cce8dABFd1271094890',
    bepanToEpanBridgeAddress: '0x584885E251253CB9670dFDC6B40Ef45b91db9eC2',
    pancakeSwapRouterV2Address: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
    pancakeSwapFactoryAddress: '0x6725F303b657a9451d8BA641348b6761A6CC7a17',
  },
} as const;

export const STAGE_BSC_NETWORKS: Record<BscNetwork, IBscNetworkData> = {
  bscMainnet: BSC_NETWORKS.bscMainnet,
  bscTestnet: {
    ...BSC_NETWORKS.bscTestnet,
    bepanStakingAddress: '0x573543A08fFeB21c806e26A92E16BbaE3D03042F',
    bepanToEpanBridgeAddress: '0x51c149cbdbd25C034a05D83ce04CC9F697f99590',
    pancakeSwapRouterV2Address: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    pancakeSwapFactoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  },
} as const;

export const networkTitles: Record<number, string> = {
  [ETH_NETWORKS.mainnet.chain_id]: ETH_NETWORKS.mainnet.name,
  [ETH_NETWORKS.ropsten.chain_id]: ETH_NETWORKS.ropsten.name,
  [BSC_NETWORKS.bscMainnet.chain_id]: BSC_NETWORKS.bscMainnet.name,
  [BSC_NETWORKS.bscTestnet.chain_id]: BSC_NETWORKS.bscTestnet.name,
};

export const chainIdToBscNetworkMap: Record<SupportedBscChainId, BscNetwork> = {
  56: 'bscMainnet',
  97: 'bscTestnet',
};

export const chainIdToEthNetworkMap: Record<SupportedEthChainId, EthNetwork> = {
  1: 'mainnet',
  3: 'ropsten',
};

export const chainIdToNetworkMap: Record<SupportedChainId, Network> = {
  ...chainIdToBscNetworkMap,
  ...chainIdToEthNetworkMap,
};

export const networkToChainIdMap: Record<Network, SupportedChainId> = {
  mainnet: 1,
  ropsten: 3,
  bscMainnet: 56,
  bscTestnet: 97,
};

export const networkNativeCurrency: Record<Network, NetworkNativeCurrency> = {
  bscMainnet: 'BNB',
  bscTestnet: 'BNB',
  mainnet: 'ETH',
  ropsten: 'ETH',
};

export const appNetworkToApiNetwork: Record<Network, IApiNetwork> = {
  mainnet: 'ethereum_mainnet',
  ropsten: 'ethereum_testnet',
  bscMainnet: 'bsc_mainnet',
  bscTestnet: 'bsc_testnet',
};

export const apiNetworkToAppNetwork: Record<IApiNetwork, Network> = {
  ethereum_mainnet: 'mainnet',
  ethereum_testnet: 'ropsten',
  bsc_mainnet: 'bscMainnet',
  bsc_testnet: 'bscTestnet',
};

export const notificationNetworksIds: Record<IApiNetwork, SupportedChainId> = {
  ethereum_mainnet: 1,
  ethereum_testnet: 3,
  bsc_mainnet: 56,
  bsc_testnet: 97,
};

export const supportedChainIds: SupportedChainId[] = [1, 3, 56, 97];

export const addChainToMetamaskParams: Record<SupportedChainId, AddEthereumChainParameter> = {
  1: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      decimals: 18,
      symbol: 'ETH',
    },
    blockExplorerUrls: ['https://etherscan.io'],
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  },
  3: {
    chainId: `0x${Number(3).toString(16)}`,
    chainName: 'Ropsten',
    nativeCurrency: {
      name: 'ETH',
      decimals: 18,
      symbol: 'ETH',
    },
    blockExplorerUrls: ['https://ropsten.etherscan.io'],
    rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  },
  56: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: 'Binance Smart Chain MainNet',
    nativeCurrency: {
      name: 'BNB',
      decimals: 18,
      symbol: 'BNB',
    },
    blockExplorerUrls: ['https://bscscan.com'],
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
  },
  97: {
    chainId: `0x${Number(97).toString(16)}`,
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'BNB',
      decimals: 18,
      symbol: 'BNB',
    },
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    rpcUrls: ['https://data-seed-prebsc-2-s2.binance.org:8545/'],
  },
};
