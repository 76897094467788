import React, { useEffect, useState } from 'react';
import './style.scss';
import cx from 'classnames';
import { getTransactionProcessDuration } from '../../helpers';

export const Timer: React.FC<ITimerProps> = ({ getLabel, timeoutTimestamp, textClassName }) => {
  const [secondsLeft, setSecondsLeft] = useState(getTransactionProcessDuration(timeoutTimestamp));

  useEffect(() => {
    const clear = setInterval(
      () =>
        setSecondsLeft((prevSec) => {
          if (!prevSec) {
            clearInterval(clear);
          }

          return getTransactionProcessDuration(timeoutTimestamp);
        }),
      1000,
    );

    return () => clearInterval(clear);
  }, [timeoutTimestamp]);

  return <span className={cx('timer-value', textClassName)}>{getLabel(secondsLeft)}</span>;
};
