import React, { useCallback } from 'react';
import { FormControl } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import { FieldProps } from 'formik';
import './style.scss';
import cx from 'classnames';
import 'react-phone-input-2/lib/material.css';

export interface IPhoneNumberInputProps extends FieldProps {
  containerClass?: string;
  inputClass?: string;
  placeholder?: string;
  valid?: boolean;
  setValid?: (val: boolean) => void;
  onChangeCapture?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
  highlightOnFocus?: boolean;
}

export const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = (props) => {
  const {
    containerClass = '',
    placeholder,
    form: { setFieldValue, errors, touched },
    field: { name, value, onBlur },
    autoFocus = false,
    disabled,
    inputClass,
    highlightOnFocus,
  } = props;

  const onChange = useCallback(
    (phone: string, countyData: CountryData, __: unknown) => {
      if (props.onChangeCapture) {
        props.onChangeCapture();
      }
      const countryCode = countyData.countryCode.toUpperCase();
      const phoneString = `${phone}-${countryCode}`;
      setFieldValue(name, phoneString);
    },
    [setFieldValue, name],
  );

  const isValid = useCallback(() => {
    if (!touched[name]) {
      return true;
    }

    const fieldError = errors[name] as string | undefined;

    return fieldError ?? true;
  }, [errors, name, touched]);

  return (
    <FormControl fullWidth>
      <PhoneInput
        inputProps={{ id: name, autoFocus }}
        isValid={isValid}
        country="de"
        disabled={disabled}
        placeholder={placeholder}
        containerClass={cx('phone-number-input', containerClass, {
          'phone-number-input-focusable': !!highlightOnFocus,
        })}
        inputClass={inputClass}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </FormControl>
  );
};
