import { createAction } from 'typesafe-actions';

export const RESET_STORE = '@APP/RESET_STORE';
export const START_APP_LOADING = '@APP/START_APP_LOADING';
export const FINISH_APP_LOADING = '@APP/FINISH_APP_LOADING';
export const SET_AUTHENTICATED = '@APP/SET_AUTHENTICATED';
export const SET_APP_INITIALIZING = '@APP/SET_APP_INITIALIZING';
export const INIT_APP = '@APP/INIT_APP';
export const RECOVER_FROM_ERROR_BOUNDARY = '@APP/RECOVER_FROM_ERROR_BOUNDARY';
export const SET_CONNECTION_WALLET_MODAL_VISIBLE = '@APP/SET_CONNECTION_WALLET_MODAL_VISIBLE';
export const SET_API_CONFIG = '@APP/SET_API_CONFIG';

export const resetStore = createAction(RESET_STORE)();
export const startAppLoading = createAction(START_APP_LOADING)();
export const finishAppLoading = createAction(FINISH_APP_LOADING)();
export const setAuthenticated = createAction(SET_AUTHENTICATED)<boolean>();
export const setAppInitializing = createAction(SET_APP_INITIALIZING)<boolean>();
export const initApp = createAction(INIT_APP)();
export const recoverFromErrorBoundary = createAction(RECOVER_FROM_ERROR_BOUNDARY)<() => void>();
export const setConnectionWalletModalVisible = createAction(
  SET_CONNECTION_WALLET_MODAL_VISIBLE,
)<boolean>();
export const setApiConfig = createAction(SET_API_CONFIG)<IApiConfiguration>();

export const appActions = {
  resetStore,
  startAppLoading,
  finishAppLoading,
  setAuthenticated,
  setAppInitializing,
  initApp,
  recoverFromErrorBoundary,
  setConnectionWalletModalVisible,
  setApiConfig,
} as const;
