import React from 'react';
import OtpInput from 'react-otp-input';
import './style.scss';
import cx from 'classnames';

export const CommonOtpInput: React.FC<ICommonOtpInputProps> = ({
  code,
  onChange,
  length = 4,
  errorText,
  onlyNumbers = false,
  disabled = false,
  placeholder,
  inputStyles,
}) => {
  return (
    <OtpInput
      value={code}
      onChange={onChange}
      numInputs={length}
      className={cx('otp-input-root')}
      inputStyle={inputStyles}
      hasErrored={Boolean(errorText)}
      shouldAutoFocus
      placeholder={placeholder}
      separator={<div className={cx('otp-input-spacer')} />}
      isInputNum={onlyNumbers}
      isDisabled={disabled}
    />
  );
};
