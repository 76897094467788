import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, CommonButton } from '../../../../components';
import warning from '../../../../assets/images/icons/warning.svg';
import cx from 'classnames';
import './style.scss';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getEllipsizeString } from '../../../../helpers';
import ClipboardIcon from '../../../../assets/images/icons/clipboard-icon.svg';
import { snackbarService } from '../../../../services';
import { useDispatch } from 'react-redux';
import { showRedeemSpotAccountProcessToast } from '../../../../redux/toast/actions';

export const ConfirmModal = ({
  isVisible,
  closeConfirmModal,
  ...rest
}: Maybe<Partial<IConfirmSpotWithdrawModalData>> & {
  isVisible: boolean;
  closeConfirmModal: () => void;
}) => {
  const { t } = useTranslation(['spot-account', 'common']);
  const isFinalAmountExceedFee = Number(rest.finalAmount ?? 0) > 0;
  const dispatch = useDispatch();
  const onClipboardCopied = () => {
    snackbarService.showSuccessSnackbar(t('copied', { ns: 'common' }));
  };

  return (
    <BaseModal onDismiss={closeConfirmModal} title={t('review-redeem')} visible={isVisible}>
      <div className="spot-account-redeem-modal">
        <div className="spot-account-redeem-modal-value-container">
          <span className="spot-account-redeem-modal-label">{t('amount-to-redeem')}</span>
          <span className="spot-account-redeem-modal-value">
            {rest.amount} {rest.currency}
          </span>
        </div>
        <div className="spot-account-redeem-modal-value-container">
          <span className="spot-account-redeem-modal-label">{t('max-network-fee')}</span>
          <span className="spot-account-redeem-modal-value">{rest.fee}</span>
        </div>
        {isFinalAmountExceedFee && (
          <div className="spot-account-redeem-modal-value-container">
            <span className="spot-account-redeem-modal-label">{t('final-redeem-amount')}</span>
            <span className="spot-account-redeem-modal-value">
              {rest.finalAmount} {rest.currency}
            </span>
          </div>
        )}
        <div className="spot-account-redeem-modal-value-container">
          <span className="spot-account-redeem-modal-label">{t('wallet-address')}</span>
          <span className="spot-account-redeem-modal-value">
            <CopyToClipboard onCopy={onClipboardCopied} text={rest.address ?? ''}>
              <button className="spot-account-redeem-modal-clipboard-button">
                <span>{getEllipsizeString(rest.address)}</span>
                <img src={ClipboardIcon} alt="clipboard" />
              </button>
            </CopyToClipboard>
          </span>
        </div>
        <div
          className={cx('redeem-spot-account-action-buttons', {
            'redeem-spot-account-action-buttons-spread': !isFinalAmountExceedFee,
          })}
        >
          {!isFinalAmountExceedFee && (
            <div className="spot-account-redeem-modal-insufficient-epan-container">
              <img
                className="spot-account-redeem-modal-insufficient-epan-container-img"
                src={warning}
              />
              <span>{t('insufficient-epan')}</span>
            </div>
          )}
          <CommonButton
            containerClassName={cx('redeem-spot-account-action-button-cancel-container', {
              'spot-account-redeem-modal-close-button-container': !isFinalAmountExceedFee,
            })}
            labelClassName={cx('redeem-spot-account-action-button-label', {
              'redeem-spot-account-action-button-cancel-label': isFinalAmountExceedFee,
            })}
            className={cx('redeem-spot-account-action-button', {
              'redeem-spot-account-action-button-cancel': isFinalAmountExceedFee,
              'spot-account-redeem-modal-close-button': !isFinalAmountExceedFee,
            })}
            label={t(isFinalAmountExceedFee ? 'cancel' : 'close', { ns: 'common' })}
            onClick={closeConfirmModal}
          />
          {isFinalAmountExceedFee && (
            <CommonButton
              labelClassName="redeem-spot-account-action-button-label"
              className="redeem-spot-account-action-button"
              label={t('confirm', { ns: 'common' })}
              onClick={rest.onProceedPress}
            />
          )}
        </div>
      </div>
    </BaseModal>
  );
};
