import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';
import { logout } from '../../redux/auth/actions';
import {
  Box,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { drawerWidth, routes } from '../../constants';
import Logo from './../../assets/images/paypolitan-logo-colored.svg';
import staking from './../../assets/images/icons/staking.svg';
import dashboard from './../../assets/images/icons/dashboard.svg';
import cross from './../../assets/images/icons/cross_chain.svg';
import req_transfer from './../../assets/images/icons/req_transfer.svg';
import t_history from './../../assets/images/icons/t_history.svg';
import spot_account from './../../assets/images/icons/spot-account-drawer.svg';
import settings from './../../assets/images/icons/settings.svg';
import notifi from './../../assets/images/icons/notifi.svg';
import logoutIcon from './../../assets/images/icons/logout.svg';
import smart_credit_icon from './../../assets/images/icons/smart-credit-icon.svg';
import { Menu } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QRIcon from '../../assets/images/icons/qr.svg';

export const DrawerComponent: React.FC = () => {
  const { t } = useTranslation(['drawer']);
  const [open, setOpen] = useState(true);
  const [variant, setVariant] = useState<DrawerProps['variant']>('temporary');
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const greaterThan900 = useMediaQuery('(min-width:900px)');

  const onClickLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const toggleDrawer = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  useEffect(() => {
    if (greaterThan900) {
      setOpen(true);
      setVariant('permanent');
    } else {
      setOpen(false);
      setVariant('temporary');
    }
  }, [greaterThan900]);

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <button className="drawer-burger-button" onClick={toggleDrawer}>
          <Menu className="drawer-burger-icon" fontSize="large" />
        </button>
        <Drawer
          open={open}
          variant={variant}
          onClose={toggleDrawer}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <div className="drawer">
            <img className="drawer-logo" src={Logo} alt="logo" />
            <List className="drawer-list">
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                component={Link}
                to={routes.DASHBOARD}
                className={classNames('drawer-list-item', {
                  activeItem: pathname === ('/dashboard/' || '/dashboard'),
                })}
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={dashboard} alt="icon" />
                </ListItemIcon>
                <ListItemText className="drawer-list-item-text" primary={t('dashboard')} />
              </ListItem>
              <ListItem
                button
                onClick={toggleDrawer}
                disableGutters
                component={Link}
                to={routes.STAKING}
                className={classNames('drawer-list-item', {
                  activeItem: pathname === '/dashboard/staking',
                })}
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={staking} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('staking')} />
              </ListItem>
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                button
                component={Link}
                to={routes.CROSS_CHAIN_BRIDGE}
                className={classNames('drawer-list-item', {
                  activeItem: pathname === '/dashboard/cross-chain-bridge',
                })}
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={cross} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('cross-chain-bridge')} />
              </ListItem>
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                button
                disabled
                className="drawer-list-item"
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={req_transfer} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('request-transfer')} />
              </ListItem>
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                button
                disabled
                component={Link}
                to={routes.TRANSACTION_HISTORY}
                className={classNames('drawer-list-item', {
                  activeItem: pathname === '/dashboard/transaction-history',
                })}
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={t_history} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('transaction-history')} />
              </ListItem>
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                button
                disabled
                component={Link}
                to={routes.SPOT_ACCOUNT}
                className={classNames('drawer-list-item', {
                  activeItem: pathname === '/dashboard/spot-account',
                })}
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={spot_account} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('spot-account')} />
              </ListItem>
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                button
                disabled
                /*                 component={Link}
                to={routes.TRANSACTION_HISTORY} */
                className={classNames('drawer-list-item', {
                  activeItem: pathname === '/dashboard/transaction-history', // TODO add new screen
                })}
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={QRIcon} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('settings:receive-funds')} />
              </ListItem>
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                button
                component={Link}
                to={routes.SMART_CREDIT}
                className={classNames('drawer-list-item', {
                  activeItem: pathname === '/dashboard/smart-credit',
                })}
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={smart_credit_icon} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('smart-credit')} />
              </ListItem>
            </List>
            <List>
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                button
                disabled
                className="drawer-list-item"
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={notifi} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('notifications')} />
              </ListItem>
              <ListItem
                onClick={toggleDrawer}
                disableGutters
                button
                component={Link}
                to={routes.SETTINGS}
                className={classNames('drawer-list-item', {
                  activeItem: pathname === routes.SETTINGS,
                })}
              >
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={settings} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('settings')} />
              </ListItem>
              <ListItem button disableGutters onClick={onClickLogout} className="drawer-list-item">
                <ListItemIcon className="drawer-list-item-icon">
                  <img src={logoutIcon} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={t('logout')} />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </Box>
    </>
  );
};
