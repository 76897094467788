import './style.scss';
import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useSegmentedControl } from './use-segmented-control';

export const SegmentedControl: React.FC<ISegmentedControlProps> = ({ onChange, tabs = [] }) => {
  const { value, handleChange } = useSegmentedControl({ onChange, tabs });

  if (!tabs.length) {
    return null;
  }

  return (
    <ToggleButtonGroup
      className="segmented-control"
      value={value}
      color="primary"
      exclusive
      onChange={handleChange}
    >
      {tabs.map((item, idx) => (
        <ToggleButton
          key={`${item.value}${idx}`}
          className="segmented-control-button"
          value={item.value}
        >
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
