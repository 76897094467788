import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConnectionWalletModalVisible } from '../redux/app/actions';
import { getConnectionWalletModalVisible } from '../redux/app/selectors';

export const useConnectionWalletModal = () => {
  const [type, setType] = useState<IWalletType | ''>('');
  const [isChangingProvider, setIsChangingProvider] = useState(false);
  const visible = useSelector(getConnectionWalletModalVisible);
  const dispatch = useDispatch();

  const clearModalState = useCallback(() => {
    setType('');
    setIsChangingProvider(false);
  }, []);

  const openModal = useCallback(() => {
    dispatch(setConnectionWalletModalVisible(true));
  }, [dispatch]);

  const closeModal = useCallback(() => {
    dispatch(setConnectionWalletModalVisible(false));
    clearModalState();
  }, [dispatch, clearModalState]);

  const handleTypeClick = useCallback(
    (type: IWalletType) => () => {
      setType(type);
    },
    [],
  );

  const toggleChangingProvider = useCallback(() => {
    setIsChangingProvider((changed) => !changed);
  }, []);

  return {
    type,
    visible,
    openModal,
    closeModal,
    handleTypeClick,
    isChangingProvider,
    toggleChangingProvider,
  };
};
