import { charsets, PasswordPolicy } from 'password-sheriff';

export const EXTRACTED_PHONE_NUMBER_REGEXP = /[^0-9+]/g;
export const NAME_REGEX = /^[^0-9].*$/;
export const MIN_NAME_LENGTH = 2;
export const MAX_NAME_LENGTH = 255;
export const PHONE_NUMBER_MIN_LENGTH = 6;
export const PHONE_NUMBER_MAX_LENGTH = 15;
export const ONLY_NUM_REGEX = /^[0-9]*$/;
export const ONLY_PHONE_NUM_REGEX = /[^0-9]/g;
export const PHONE_NUMBER_REGEXP = /^[\d+]+$/;
export const MIN_TRANSFER_AMOUNT = 0.01;
export const MIN_COMPOUND_AMOUNT = 0.0001;
export const MIN_SWAPPING_AMOUNT = 0.00001;
export const DEFAULT_MAX_INPUT_SYMBOLS = 20;
export const PASSWORD_MAX_LENGTH = 128;
export const TRANSACTION_ID_REGEXP = /^[a-zA-Z0-9]*$/g;
export const TRANSACTION_ID_MIN_LENGTH = 12;
export const TRANSACTION_ID_MAX_LENGTH = 18;
export const PROMO_CODE_REGEXP = /^[a-zA-Z0-9]*$/g;
export const PROMO_CODE_LENGTH = 6;
export const MIN_NATIVE_TOKEN_AMOUNT = 0.01;
export const MIN_DEFAULT_AMOUNT = 0.00001;

export const passwordPolicy: IPasswordPolicy = new PasswordPolicy({
  length: { minLength: 8 },
  contains: {
    expressions: [
      charsets.lowerCase,
      charsets.upperCase,
      charsets.numbers,
      charsets.specialCharacters,
    ],
  },
});

export const passwordLengthPolicy: IPasswordPolicy = new PasswordPolicy({
  length: { minLength: 8 },
});

export const passwordUpperLowerCasePolicy: IPasswordPolicy = new PasswordPolicy({
  contains: {
    expressions: [charsets.lowerCase, charsets.upperCase],
  },
});

export const passwordNumberSpecialCharactersPolicy: IPasswordPolicy = new PasswordPolicy({
  contains: {
    expressions: [charsets.numbers, charsets.specialCharacters],
  },
});

interface IPasswordPolicy {
  check: (password?: string) => boolean;
}
