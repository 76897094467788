import { FIAT_EXCHANGE_PRECISION, __DEV__ } from '../../../constants';
import { isEthNetwork } from '../../../helpers';
import i18next from 'i18next';

// @ts-ignore
const checkSmartContract = (
  transaction: IApiTransaction,
  network: Network,
  isFee?: boolean,
): ITransactionCheckSmartContractResponse => {
  const feePostfix = isFee ? ` ${i18next.t('fee', { ns: 'common' })}` : '';
  const addFeeSuffix = (label: string) => {
    return `${label}${feePostfix}`;
  };
  let transactionType = isFee ? (isEthNetwork(network) ? 'ETH' : 'BNB') : transaction.type;
  return {
    type: transactionType,
    label: addFeeSuffix(i18next.t(transaction.type, { ns: 'transaction-types' })),
  };
};

export const getMatchedNativeTokenTransactions = ({
  res,
  type,
  address,
  fiatCurrency,
  network,
}: IHandleGetTransactions<IApiTransaction[]>): IMatchedTransaction[] => {
  const transactionsResult = res[0];
  const transactionsApi = Array.isArray(transactionsResult) ? transactionsResult : [];
  return (
    transactionsApi
      ?.filter((item) =>
        type === 'sent' ? item.from === address.toLowerCase() : item.to === address.toLowerCase(),
      )
      // @ts-ignore TODO refactor
      .map((transactionItem) => {
        const isFee = Boolean(
          transactionItem.fee ||
            (!Number(transactionItem.value) && Number(transactionItem.value) !== 0),
        );
        const balance = Number((transactionItem.fee || transactionItem.amount) ?? 0);
        const currencyRate = parseFloat(transactionItem.quotes[fiatCurrency]);
        const fiatValue = (balance * currencyRate).toFixed(FIAT_EXCHANGE_PRECISION);

        return {
          hash: transactionItem.hash ?? '',
          date: transactionItem.timeStamp ?? '',
          sender: {
            firstName: transactionItem?.sender?.firstName,
            lastName: transactionItem?.sender?.lastName,
            photo: transactionItem?.sender?.photo,
          },
          recepient: {
            firstName: transactionItem?.recipient?.firstName,
            lastName: transactionItem?.recipient?.lastName,
            photo: transactionItem?.recipient?.photo,
          },
          currency: transactionItem.currency,
          description: transactionItem?.desc ?? '',
          from: transactionItem.from,
          to: transactionItem.to,
          smartContract: checkSmartContract(transactionItem, network, isFee),
          fee: transactionItem.fee,
          amount: transactionItem.amount,
          fiatValue,
          fiatCurrency,
          contractAddress: transactionItem.contractAddress,
        };
      })
  );
};
