import { createAction } from 'typesafe-actions';
import { GasOracleSpeed } from '../../hooks';
import {
  ISetClubDealSwapGasOracleSpeedActionParams,
  ISetUniswapLiquidityGasOracleSpeedActionParams,
  ITransaction,
} from './types';

export const GET_TRANSACTIONS_HISTORY_FAILURE = '@TRANSACTIONS/GET_TRANSACTIONS_HISTORY_FAILURE';
export const ADD_TRANSACTION_FAILURE = '@TRANSACTIONS/ADD_TRANSACTION_FAILURE';
export const SET_TRANSACTION_FIELDS = '@TRANSACTIONS/SET_TRANSACTION_FIELDS';
export const RESET_TRANSACTION_FIELDS = '@TRANSACTIONS/RESET_TRANSACTION_FIELDS';
export const GET_GAS_ORACLE_FAILURE = '@TRANSACTIONS/GET_GAS_ORACLE_FAILURE';
export const GET_CONFIRMATION_TIME_FAILURE = '@TRANSACTIONS/GET_CONFIRMATION_TIME_FAILURE';
export const SET_SELECTED_CUSTOM_TOKEN = '@TRANSACTIONS/SET_SELECTED_CUSTOM_TOKEN';
export const RESET_SELECTED_CUSTOM_TOKEN = '@TRANSACTIONS/RESET_SELECTED_CUSTOM_TOKEN';
export const SET_SWAPPING_SELECTED_WALLET = '@TRANSACTIONS/SET_SWAPPING_SELECTED_WALLET';
export const SET_SWAPPING_GAS_ORACLE_SPEED = '@TRANSACTIONS/SET_SWAPPING_GAS_ORACLE_SPEED';
export const TRANSFER_REQUEST_FAILURE = '@TRANSACTIONS/TRANSFER_REQUEST_FAILURE';
export const RESET_SELECTED_CURRENCY = '@TRANSACTIONS/RESET_SELECTED_CURRENCY';
export const SET_UNISWAP_LIQUIDITY_GAS_ORACLE_SPEED =
  '@TRANSACTIONS/SET_UNISWAP_LIQUIDITY_GAS_ORACLE_SPEED';
export const SET_CLUB_DEAL_SWAP_GAS_ORACLE_SPEED =
  '@TRANSACTIONS/SET_CLUB_DEAL_SWAP_GAS_ORACLE_SPEED';
export const SET_POLVEN_GOVERNANCE_VOTING_GAS_ORACLE_SPEED =
  '@TRANSACTIONS/SET_POLVEN_GOVERNANCE_VOTING_GAS_ORACLE_SPEED';

export const getTransactionsHistoryFailure = createAction(
  GET_TRANSACTIONS_HISTORY_FAILURE,
)<Error>();
export const addTransactionFailure = createAction(ADD_TRANSACTION_FAILURE)<Error>();
export const setTransactionFields = createAction(SET_TRANSACTION_FIELDS)<Partial<ITransaction>>();
export const resetTransactionFields = createAction(RESET_TRANSACTION_FIELDS)();
export const getGasOracleFailure = createAction(GET_GAS_ORACLE_FAILURE)<Error>();
export const getConfirmationTimeFailure = createAction(GET_CONFIRMATION_TIME_FAILURE)<Error>();
export const setSelectedCustomToken = createAction(SET_SELECTED_CUSTOM_TOKEN)<ICustomToken>();
export const resetSelectedCustomToken = createAction(RESET_SELECTED_CUSTOM_TOKEN)();
export const setSwappingSelectedWallet = createAction(SET_SWAPPING_SELECTED_WALLET)<
  Maybe<IWalletWithBalance>
>();
export const setSwappingGasOracleSpeed = createAction(
  SET_SWAPPING_GAS_ORACLE_SPEED,
)<GasOracleSpeed>();
export const setUniswapLiquidityGasOracleSpeed = createAction(
  SET_UNISWAP_LIQUIDITY_GAS_ORACLE_SPEED,
)<ISetUniswapLiquidityGasOracleSpeedActionParams>();
export const setClubDealSwapGasOracleSpeed = createAction(
  SET_CLUB_DEAL_SWAP_GAS_ORACLE_SPEED,
)<ISetClubDealSwapGasOracleSpeedActionParams>();
export const transferRequestFailure = createAction(TRANSFER_REQUEST_FAILURE)<Error>();
export const resetSelectedCurrency = createAction(RESET_SELECTED_CURRENCY)<Network>();
export const setPolvenGovernanceVotingGasOracleSpeed = createAction(
  SET_POLVEN_GOVERNANCE_VOTING_GAS_ORACLE_SPEED,
)<GasOracleSpeed>();

export const transactionsActions = {
  getTransactionsHistoryFailure,
  addTransactionFailure,
  setTransactionFields,
  resetTransactionFields,
  getGasOracleFailure,
  getConfirmationTimeFailure,
  setSelectedCustomToken,
  resetSelectedCustomToken,
  setSwappingSelectedWallet,
  setSwappingGasOracleSpeed,
  transferRequestFailure,
  resetSelectedCurrency,
  setUniswapLiquidityGasOracleSpeed,
  setClubDealSwapGasOracleSpeed,
  setPolvenGovernanceVotingGasOracleSpeed,
} as const;
