import { createReducer } from 'typesafe-actions';
import { IUsersState } from './types';
import {
  setCurrentApiUser,
  setCurrentFiatCurrency,
  setCurrentUser,
  setNotificationsSettings,
  setUserApiWallets,
  updateCurrentUser,
  updateCurrentUserMetadata,
} from './actions';
import { resetStore } from '../app/actions';
import { SUPPORTED_FIAT_CURRENCIES } from '../../constants';

const initialState: IUsersState = {
  current: null,
  currentFiatCurrency: SUPPORTED_FIAT_CURRENCIES.USD,
  apiUser: null,
  notificationsSettings: { isEnabled: false },
  apiWallets: [],
};

export const users = createReducer(initialState)
  .handleAction(setCurrentUser, (state, { payload }) => ({
    ...state,
    current: payload,
  }))
  .handleAction(updateCurrentUserMetadata, (state, { payload }) => ({
    ...state,
    current: state.current
      ? {
          ...state.current,
          user_metadata: state.current.user_metadata
            ? {
                ...state.current.user_metadata,
                ...payload,
              }
            : undefined,
        }
      : null,
  }))
  .handleAction(updateCurrentUser, (state, { payload }) => ({
    ...state,
    current: state.current
      ? {
          ...state.current,
          ...(payload || {}),
        }
      : null,
  }))
  .handleAction(setCurrentFiatCurrency, (state, { payload }) => ({
    ...state,
    currentFiatCurrency: payload,
  }))
  .handleAction(setCurrentApiUser, (state, { payload }) => ({
    ...state,
    apiUser: payload,
  }))
  .handleAction(setUserApiWallets, (state, { payload }) => ({
    ...state,
    apiWallets: payload,
  }))
  .handleAction(setNotificationsSettings, (state, { payload }) => ({
    ...state,
    notificationsSettings: {
      ...state.notificationsSettings,
      ...payload,
    },
  }))
  .handleAction(resetStore, () => initialState);
