import React from 'react';
import './style.scss';
import cx from 'classnames';
import { IUserAuthHeaderProps } from './types';

export const UserAuthHeader: React.FC<IUserAuthHeaderProps> = ({
  title,
  subTitle,
  containerClassName,
}) => (
  <div className={cx('userAuth-head', containerClassName)}>
    <p className="userAuth-head-title">{title}</p>
    <p className="userAuth-head-subTitle">{subTitle}</p>
  </div>
);
