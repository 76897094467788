import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { transactionsResource } from '../api';
import { getGasOracleFailure } from '../redux/transactions/actions';
import { web3Service } from '../services/blockchain';
import { getCurrentNetwork } from '../redux/blockchain/selectors';

export enum GasOracleSpeed {
  Low,
  Medium,
  Fast,
}

export const useGasOracle = () => {
  const dispatch = useDispatch();
  const network = useSelector(getCurrentNetwork);

  const {
    data: gasOracle,
    isLoading: isLoadingGasOracle,
    refetch: refetchGasOracle,
  } = useQuery<IEstimateGasResponse | undefined, Error, IEstimateGasResponse | undefined>(
    [transactionsResource.estimateGasCacheKey, network],
    () => transactionsResource.estimateGas(network),
    {
      staleTime: 15 * 1000,
      cacheTime: 15 * 1000,
      refetchInterval: 15 * 1000,
      retry: false,
      onError: (e) => {
        dispatch(getGasOracleFailure(e));
      },
    },
  );

  const gasOracleArray = gasOracle
    ? [
        web3Service.web3.utils.fromWei(gasOracle.SafeGasPrice, 'ether'),
        web3Service.web3.utils.fromWei(gasOracle.ProposeGasPrice, 'ether'),
        web3Service.web3.utils.fromWei(gasOracle.FastGasPrice, 'ether'),
      ]
    : [];

  return {
    gasOracle,
    gasOracleArray,
    isLoadingGasOracle,
    refetchGasOracle,
    currentBaseFee: gasOracle?.currentBaseFee ?? '',
    recommendedBaseFee: gasOracle?.recommendedBaseFee ?? '',
  };
};
