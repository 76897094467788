import React from 'react';
import './styles.scss';
import cx from 'classnames';
import { ISignStepProgressBarProps } from './types';
import { SignUpStep } from '../../../../redux/auth/types';
import { useTranslation } from 'react-i18next';

export const StepProgressBar: React.FC<ISignStepProgressBarProps> = ({ step, config }) => {
  const { t } = useTranslation(['sign-up']);
  const shouldVerifyPhone = config?.isPhoneEnabled ?? true;
  console.log('-------- config', config);

  return (
    <div className="step-progress-bar">
      {Object.values(SignUpStep)
        .filter((item) => Number.isNaN(Number(item)))
        .filter((item) => (shouldVerifyPhone ? true : item !== 'OTPConfirmationPhone'))
        .map((item, idx, arr) => {
          const isLastItem = idx === arr.length - 1;
          const isActive = step === idx + 1;
          const isCompleted = step > idx + 1;

          return (
            <div
              key={item}
              className={cx('step-progress-bar-step', {
                'step-progress-bar-step-margin': !isLastItem,
                'step-progress-bar-step-active': isActive,
                'step-progress-bar-step-completed': isCompleted,
              })}
            >
              <span
                className={cx('step-progress-bar-step-label', {
                  'step-progress-bar-step-label-active': isActive,
                  'step-progress-bar-step-label-completed': isCompleted,
                })}
              >
                {t('step', { step: idx + 1 })}
              </span>
            </div>
          );
        })}
    </div>
  );
};
