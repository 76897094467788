import React from 'react';
import cx from 'classnames';
import './style.scss';
import { providersIconMap } from '../../use-modal';
import { CommonButton } from '../../../common-button';
import WalletWhiteIcon from '../../../../assets/images/icons/wallet-white-icon.svg';
import { getEllipsizeString, isEthNetwork } from '../../../../helpers';
import ClipboardIcon from '../../../../assets/images/icons/clipboard-icon.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EtherscanIcon from '../../../../assets/images/icons/etherscan-icon.svg';
import { useSelector } from 'react-redux';
import { getCurrentNetwork } from '../../../../redux/blockchain/selectors';
import { useTranslation } from 'react-i18next';
import { IConnectedWalletModalAppearanceProps } from './types';

export const ConnectedWalletModalAppearance: React.FC<IConnectedWalletModalAppearanceProps> = ({
  externalWallet,
  connectedAddress,
  toggleChangingProvider,
  onClipboardCopied,
  viewOnEtherscanRef,
}) => {
  const { t } = useTranslation(['common']);
  const network = useSelector(getCurrentNetwork);

  return (
    <>
      <div className="connection-wallet-modal-row">
        <span
          className={cx(
            'connection-wallet-modal-main-text',
            'connection-wallet-modal-connected-with',
          )}
        >
          {t('connected-with')}
        </span>
        <img src={providersIconMap[externalWallet?.type] ?? ''} alt="icon" width={18} />
        <span className={cx('connection-wallet-modal-main-text', 'connection-wallet-modal-wallet')}>
          {externalWallet?.name ?? ''}
        </span>
        <CommonButton label={t('change')} onClick={toggleChangingProvider} />
      </div>
      <div
        className={cx(
          'connection-wallet-modal-row',
          'connection-wallet-modal-wallet-address-container',
        )}
      >
        <div className="connection-wallet-modal-wallet-icon-rounded">
          <img src={WalletWhiteIcon} alt="wallet" />
        </div>
        <span className="connection-wallet-modal-address">
          {getEllipsizeString(connectedAddress ?? '')}
        </span>
      </div>
      <div className={cx('connection-wallet-modal-row')}>
        <img src={ClipboardIcon} alt="clipboard" />
        <CopyToClipboard text={connectedAddress ?? ''} onCopy={onClipboardCopied}>
          <button className="connection-wallet-modal-clipboard-button">
            <span className="connection-wallet-modal-underline-text">{t('copy-address')}</span>
          </button>
        </CopyToClipboard>
        <img src={EtherscanIcon} alt="etherscan" />
        <a
          target={'_blank'}
          rel={'noreferrer'}
          className={cx(
            'connection-wallet-modal-underline-text',
            'connection-wallet-modal-etherscan-ref',
          )}
          href={viewOnEtherscanRef}
        >
          {t(isEthNetwork(network) ? 'view-on-eth-scan' : 'view-on-bsc-scan')}
        </a>
      </div>
    </>
  );
};
