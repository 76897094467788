import Jimp from 'jimp';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dataURLtoFile } from '../../../../helpers';
import { useUserInfo } from '../../../../hooks';
import {
  deleteProfilePictureRequest,
  editProfilePictureRequest,
} from '../../../../redux/users/actions';

export const useWebcamForm = (closeUploadMenu: () => void) => {
  const dispatch = useDispatch();
  const { photo } = useUserInfo();
  const [hasWebcam, setHasWebcam] = useState(false);

  const handleImgUpload: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (!e.target.files) {
      closeUploadMenu();
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = async () => {
      if (reader.result) {
        const jimpImage = await Jimp.read(
          Buffer.from((reader.result as string).substring(22), 'base64'),
        );
        jimpImage.crop(jimpImage.bitmap.width / 2 - 90, jimpImage.bitmap.height / 2 - 90, 180, 180);
        const jimpImgB64 = await jimpImage.getBase64Async(Jimp.MIME_JPEG);
        const modifiedFile = dataURLtoFile(jimpImgB64, `profile-photo-${Date.now()}`);
        dispatch(editProfilePictureRequest({ picture: modifiedFile as unknown as IFile }));
      }
    };
    closeUploadMenu();
    e.target.value = '';
  };

  const handleImgRemove = () => {
    if (photo) {
      dispatch(deleteProfilePictureRequest());
      closeUploadMenu();
    }
  };

  useEffect(() => {
    (async () => {
      const md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices) {
        return;
      }
      const devices = await md.enumerateDevices();
      setHasWebcam(devices.some((device) => 'videoinput' === device.kind));
    })();
  }, [navigator.mediaDevices]);

  return {
    handleImgRemove,
    handleImgUpload,
    hasWebcam,
  };
};
