import React, { useCallback } from 'react';
import './main-style.scss';
import widgetStyles from './widget-styles/widget-style.scss.js';

export const SmartCreditPage = () => {
  const onRef = useCallback((host: any) => {
    if (host) {
      const style = document.createElement('style');
      style.innerHTML = widgetStyles;
      host.shadowRoot.appendChild(style);
    }
  }, []);

  return (
    <div className="smart-credit">
      {/*@ts-ignore*/}
      <borrowing-widget theme={'light'} ref={onRef} />
    </div>
  );
};
