import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../constants';

export const useHandlers = () => {
  const history = useHistory();

  const handlePressGoToLogin = useCallback(() => {
    history.push(routes.SIGN_IN);
  }, [history]);

  const handlePressCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return {
    handlePressGoToLogin,
    handlePressCancel,
  };
};
