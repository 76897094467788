import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, CommonButton } from '../../../../components';
import { getPaypolitanStakingTimeframeLastDate } from '../../../../helpers';

export const StackingPeriodInfoModal: React.FC<IStakingPeriodInfoModalProps> = ({
  visible,
  onClose,
  daysLeft,
}) => {
  const { t } = useTranslation(['epan-staking']);
  const endsStackingDate = getPaypolitanStakingTimeframeLastDate(daysLeft);

  return (
    <BaseModal
      visible={visible}
      onDismiss={onClose}
      hideCloseButton
      title={`${t('staking-period-not-over')} ${endsStackingDate}`}
    >
      <CommonButton label={t('common:ok')} onClick={onClose} />
    </BaseModal>
  );
};
