import { translate } from '../../api';

export const authUnauthorizedErrorCode = 'unauthorized';
export const auth0CreateUserErrorCode = 'invalid_signup';
export const auth0InvalidEmailErrorCode = 'error in email - email format validation failed';

export interface IAuth0Error extends Partial<Error> {
  code: string;
  description: string;
  statusCode: number;
}

export class Auth0Error extends Error {
  code: string;
  statusCode: number;

  constructor(error: IAuth0Error) {
    super(error.description ?? error.message);
    this.name = error.name ?? error.code;
    this.code = error.code;
    this.statusCode = error.statusCode;
  }
}

export class AuthUnauthorizedError extends Auth0Error {
  constructor() {
    super({
      name: 'AuthUnauthorizedError',
      description: translate('token-expired', 'errors'),
      code: authUnauthorizedErrorCode,
      statusCode: 401,
    });
  }
}

export class Auth0CreateUserError extends Auth0Error {
  constructor() {
    super({
      name: 'Auth0CreateUserError',
      description: translate('email-already-used', 'errors'),
      statusCode: 400,
      code: auth0CreateUserErrorCode,
    });
  }
}

export class Auth0InvalidEmailError extends Auth0Error {
  constructor() {
    super({
      name: 'Auth0InvalidEmailError',
      description: translate('incorrect-email', 'errors'),
      statusCode: 400,
      code: auth0InvalidEmailErrorCode,
    });
  }
}
