import React, { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { getValidationSchema } from './validation-schema';
import { useDispatch, useSelector } from 'react-redux';
import { useUserInfo } from '../../../../hooks';
import { IUpdateProfileData } from './types';
import { editProfileRequest } from '../../../../redux/users/actions';
import { useTranslation } from 'react-i18next';
import { getAppLoading } from '../../../../redux/app/selectors';
import { CommonButton } from '../../../../components';
import cx from 'classnames';
import { useMutation } from 'react-query';
import { usersResource } from '../../../../api';
import { checkVerificationCodeFailure } from '../../../../redux/auth/actions';
import { ONLY_PHONE_NUM_REGEX } from '../../../../constants';

export const useForm = (activeField: keyof IUpdateProfileData) => {
  const userData = useUserInfo();
  const { mutateAsync: sendVerificationSms, isLoading: isLoadingSendVerificationSms } = useMutation<
    ISendVerificationSmsResponse | undefined,
    Error,
    ISendVerificationSmsParams
  >(usersResource.sendVerificationSms);
  const initialValues = {
    email: userData.email,
    phoneNumber: userData.phoneNumber,
    firstName: userData.firstName,
    lastName: userData.lastName,
  };
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const dispatch = useDispatch();
  const appLoading = useSelector(getAppLoading);
  const { t } = useTranslation(['common']);

  const onSubmitForm = useCallback(
    async (
      data: IUpdateProfileData,
      onFinally: () => void,
      { setSubmitting }: FormikHelpers<IUpdateProfileData>,
    ) => {
      try {
        switch (activeField) {
          case 'email':
            setSubmitting(true);
            await usersResource.sendVerificationEmail({ email: data.email });
            setSubmitting(false);
            onFinally();
            break;
          case 'phoneNumber':
            const phoneNumberForApi = `+${data.phoneNumber.replace(ONLY_PHONE_NUM_REGEX, '')}`;
            setSubmitting(true);
            await sendVerificationSms({ phoneNumber: phoneNumberForApi });
            setSubmitting(false);
            onFinally();
            break;
          default:
            setSubmitting(true);
            dispatch(editProfileRequest({ [activeField]: data[activeField] }));
            setSubmitting(false);
            onFinally();
        }
      } catch (e) {
        dispatch(checkVerificationCodeFailure(e as Error));
      }
    },
    [dispatch, activeField],
  );

  const ActionButtons = (
    activeField: keyof IUpdateProfileData | null,
    field: keyof IUpdateProfileData,
    isSubmitting: boolean,
    isValid: boolean,
    onCancel: () => void,
  ) => {
    if (activeField !== field) {
      return null;
    }
    return (
      <div className="edit-profile-action-buttons">
        <CommonButton
          size="small"
          type="submit"
          label={t('cancel')}
          className={cx('edit-profile-action-button', 'edit-profile-action-button-cancel')}
          labelClassName={cx(
            'edit-profile-action-button-label',
            'edit-profile-action-button-cancel-label',
          )}
          onClick={onCancel}
          disabled={isSubmitting || appLoading}
        />
        <CommonButton
          size="small"
          type="submit"
          label={t('save')}
          className="edit-profile-action-button"
          labelClassName="edit-profile-action-button-label"
          loading={isSubmitting || appLoading}
          disabled={isSubmitting || appLoading || !isValid}
        />
      </div>
    );
  };

  return {
    initialValues,
    onSubmitForm,
    validationSchema,
    ActionButtons,
  };
};
