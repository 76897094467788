import React, { useMemo } from 'react';
import './style.scss';
import { IProps } from './types';
import disabledOverlay from '../../../../assets/images/icons/disabled-overlay.svg';
import cx from 'classnames';

export const Block = (props: IProps) => {
  const action = useMemo(() => (props.action ? props.action : () => void 0), [props.action]);
  return (
    <div
      onClick={action}
      className={cx('settings-item-block', { 'settings-item-block-disabled': !props.action })}
    >
      {!props.action && <img className="settings-item-block-overlay" src={disabledOverlay} />}
      <div
        className={cx('settings-item-block-icon', {
          'settings-item-block-icon-disabled': !props.action,
        })}
      >
        <props.icon />
      </div>
      <span
        className={cx('settings-item-block-text', {
          'settings-item-block-text-disabled': !props.action,
        })}
      >
        {props.text}
      </span>
    </div>
  );
};
