import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useForm } from './use-form';
import { useHandlers } from './use-handlers';
import cx from 'classnames';
import { UserAuthHeader, CommonButton, FormTextField } from '../../components';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';

export const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation(['common', 'restore-password', 'sign-up']);
  const { initialValues, validationSchema, onSubmitForm, emailSent } = useForm();
  const { handlePressGoToLogin } = useHandlers();

  return (
    <div className="resetPassword">
      <UserAuthHeader
        containerClassName="resetPassword-auth-header"
        title={t('restore-password:title')}
      />
      {emailSent && (
        <span className={cx('resetPassword-success-description')}>
          {t('restore-password:success-description')}
        </span>
      )}
      {!emailSent && (
        <>
          <Formik<IResetPasswordFormData>
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitForm}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <span className={cx('resetPassword-main-text', 'resetPassword-input-title')}>
                  {t('email')}
                </span>
                <FormTextField<IResetPasswordFormData>
                  autoFocus
                  name={'email'}
                  inputContainerClassName={cx('resetPassword-input')}
                  label={t('restore-password:email-placeholder')}
                />
                <CommonButton
                  type={'submit'}
                  size="large"
                  className="resetPassword-submit-button"
                  containerClassName="resetPassword-submit-button-container"
                  label={t('restore-password:title')}
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                />
              </Form>
            )}
          </Formik>
          <Link className={cx('resetPassword-main-text', 'resetPassword-link')} to={routes.SIGN_IN}>
            {t('common:cancel')}
          </Link>
        </>
      )}
      {emailSent && (
        <CommonButton
          size="large"
          className="resetPassword-submit-button"
          containerClassName="resetPassword-submit-button-container"
          label={t('restore-password:redirect-to-login-label')}
          onClick={handlePressGoToLogin}
        />
      )}
    </div>
  );
};
