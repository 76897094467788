import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, CommonButton } from '../../../../components';
import cx from 'classnames';
import './style.scss';

export const ConfirmModal = (props: Partial<IConfirmTopUpSpotModalProps>) => {
  const { t } = useTranslation(['spot-account', 'common']);
  return (
    <BaseModal onDismiss={props.onClose!} title={t('buy-epan')} visible={!!props.visible}>
      <div className="spot-account-top-up-modal">
        <div className="spot-account-top-up-modal-value-container">
          <span className="spot-account-top-up-modal-label">{t('pay')}</span>
          <span className="spot-account-top-up-modal-value">{props.pay}</span>
        </div>
        <div className="spot-account-top-up-modal-value-container">
          <span className="spot-account-top-up-modal-label">{t('receive')}</span>
          <span className="spot-account-top-up-modal-value">{props.receive}</span>
        </div>
        <div className="spot-account-top-up-modal-value-container">
          <span className="spot-account-top-up-modal-label">{t('rate')}</span>
          <span className="spot-account-top-up-modal-value">{props.rate}</span>
        </div>
        {props.discount && (
          <React.Fragment>
            <div className="spot-account-top-up-modal-divider"></div>
            <div className="spot-account-top-up-modal-value-container">
              <span className="spot-account-top-up-modal-label">{t('enteredPromo')}</span>
              <span className="spot-account-top-up-modal-value">{props.promoCode}</span>
            </div>
            <div className="spot-account-top-up-modal-value-container">
              <span className="spot-account-top-up-modal-label">{t('discount')}</span>
              <span className="spot-account-top-up-modal-value">{props.discount}</span>
            </div>
          </React.Fragment>
        )}
        <div className="spot-account-top-up-modal-value-container">
          <span className="spot-account-top-up-modal-label">{t('lockPeriod')}</span>
          <span className="spot-account-top-up-modal-value">{props.lockPeriod}</span>
        </div>
        <div className={cx('redeem-spot-account-action-buttons')}>
          <CommonButton
            containerClassName={cx('redeem-spot-account-action-button-cancel-container')}
            labelClassName={cx(
              'redeem-spot-account-action-button-label',
              'redeem-spot-account-action-button-cancel-label',
            )}
            className={cx(
              'redeem-spot-account-action-button',
              'redeem-spot-account-action-button-cancel',
            )}
            label={t('cancel', { ns: 'common' })}
            onClick={props.onClose}
          />
          <CommonButton
            labelClassName="redeem-spot-account-action-button-label"
            className="redeem-spot-account-action-button"
            label={t('confirm', { ns: 'common' })}
            onClick={props.onProceedPress}
          />
        </div>
      </div>
    </BaseModal>
  );
};
