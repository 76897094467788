import { takeLatest, call, put, fork, delay } from 'redux-saga/effects';
import { usersResource, tokensResource } from '../../api';
import {
  CANCEL_SIGN_UP_PROCESS,
  CREATE_USER,
  createUser,
  resetSignUpState,
  createUserAuth0Error,
  createUserFailure,
  FINISH_SIGN_UP,
  finishSignUpFailure,
  LOG_OUT,
  setConfirmationCodeTimeoutTimestamp,
  setSignUpState,
  SIGN_IN_PASSWORD,
  signInPassword,
  signInPasswordAuth0Error,
} from './actions';
import {
  Auth0Error,
  authService,
  AuthUnauthorizedError,
  historyService,
  httpService,
  reactQueryService,
} from '../../services';
import { finishAppLoading, resetStore, setAuthenticated, startAppLoading } from '../app/actions';
import { SignUpStep } from './types';
import { __DEV__, routes, SUPPORTED_FIAT_CURRENCIES } from '../../constants';
import {
  populateCurrentUserDataFailure,
  setCurrentFiatCurrency,
  setCurrentApiUser,
  setCurrentUser,
  setNotificationsSettings,
  POPULATE_CURRENT_USER_DATA,
  populateCurrentUserData,
} from '../users/actions';
import {
  setTryAutoConnectWallet,
  updateCustomErc20Tokens,
  updateErc20Tokens,
} from '../blockchain/actions';
import { Auth0User } from '../users/types';
import { Auth0UserProfile } from 'auth0-js';
import { getResendConfirmationCodeTimeoutTimestamp, noop } from '../../helpers';
import { removeFromStorage } from '../../api/secure-storage';

function* populateCurrentUserFromUsersResource() {
  // TODO redesign
  const currentUser: Maybe<IApiUser> = yield usersResource.getCurrentUser().catch(noop);

  yield put(updateCustomErc20Tokens(currentUser?.customTokens ?? []));
  yield put(
    setNotificationsSettings({
      isEnabled: currentUser?.notificationsSettings?.isEnabled || false,
    }),
  );
  yield put(
    setCurrentFiatCurrency(
      SUPPORTED_FIAT_CURRENCIES[
        currentUser?.preferredCurrency ?? SUPPORTED_FIAT_CURRENCIES.USD.currency
      ],
    ),
  );

  if (currentUser) {
    yield put(setCurrentApiUser(currentUser));
  }
}

function* prepareBlockchainServices(auth0User: Auth0User<Auth0UserMetadata>) {
  // const network: Network = chainIdToNetworkMap[chainId];
  // yield call([paypolitanStakingService, paypolitanStakingService.init],
  //   web3Service, network);
  // yield call([ethBscBridgeService, ethBscBridgeService.init], web3Service,
  //   network);
}

function* populateSignUpData(step: SignUpStep, currentUser?: IApiUser) {
  if (currentUser) {
    yield put(
      setSignUpState({
        step,
        email: currentUser?.email ?? '',
        firstName: currentUser?.firstName ?? '',
        lastName: currentUser?.lastName ?? '',
        phoneNumber: currentUser?.phoneNumber ?? '',
        isPhoneNumberVerified: !!currentUser?.phoneNumber,
      }),
    );
    historyService.history.push(routes.SIGN_UP);
  }
}

export function* populateCurrentUserSaga(
  action: ReturnType<typeof populateCurrentUserData>,
  auth0User?: Auth0User<Auth0UserMetadata>,
) {
  try {
    const currentUser: IApiUser | undefined = yield usersResource.getCurrentUser();

    if (!currentUser?.areTermsAccepted) {
      if (!currentUser?.isEmailVerified) {
        yield call([usersResource, usersResource.sendVerificationEmail], {
          email: currentUser?.email ?? '',
        });
        yield put(setConfirmationCodeTimeoutTimestamp(getResendConfirmationCodeTimeoutTimestamp()));
        yield call(populateSignUpData, SignUpStep.OTPConfirmationEmail, currentUser);
      } else if (
        !currentUser?.firstName ||
        !currentUser?.lastName ||
        !currentUser?.phoneNumber ||
        !currentUser.areTermsAccepted
      ) {
        yield call(populateSignUpData, SignUpStep.FullNamePhoneNumber, currentUser);
      }
    } else {
      if (auth0User) {
        yield put(setCurrentUser(auth0User));
      } else {
        const user: Required<Auth0UserProfile> = yield authService.getUser();
        yield put(setCurrentUser(user));
      }

      const apiErc20Tokens: IGetDefaultErc20TokesResponse =
        yield tokensResource.getDefaultErc20Tokens();
      yield put(updateErc20Tokens(apiErc20Tokens.tokens));

      yield call(populateCurrentUserFromUsersResource);
      yield put(setAuthenticated(true));
    }
  } catch (e) {
    yield put(populateCurrentUserDataFailure(e as Error));
    throw e;
  }
}

function* clearData() {
  yield fork([usersResource, usersResource.logout]);
  yield fork([authService, authService.logout]);
  yield fork([httpService, httpService.removeAuthHeader]);
  yield fork([reactQueryService, reactQueryService.clearCache]);
  // yield fork([web3Service, web3Service.disconnect]);
  // yield fork([remoteLogger, remoteLogger.reset]);
}

export function* logoutSaga() {
  try {
    yield put(resetStore());
    historyService.history.replace(routes.SIGN_IN);
    yield delay(500);
    yield call(clearData);
  } catch (e) {
    if (__DEV__) {
      console.log('-------- logout error', e);
    }
  }
}

export function* cancelSignUpProcess() {
  try {
    yield put(resetSignUpState());
    yield call([httpService, httpService.removeAuthHeader]);
    yield call(removeFromStorage, 'apiAccessToken');
    yield call(removeFromStorage, 'auth0AccessToken');
    historyService.history.replace(routes.SIGN_IN);
  } catch (e) {
    __DEV__ && console.log('-------- logout error', e);
  }
}

function* signInPasswordSaga({ payload: { email, password } }: ReturnType<typeof signInPassword>) {
  try {
    yield put(startAppLoading());
    yield put(setTryAutoConnectWallet(false));
    const auth0User: Auth0User<Auth0UserMetadata> = yield call(
      [authService, authService.loginByEmailAndPassword],
      email,
      password,
    );
    yield call(populateCurrentUserSaga, populateCurrentUserData(), auth0User);
  } catch (e) {
    yield put(signInPasswordAuth0Error(e as Auth0Error));
  } finally {
    yield put(finishAppLoading());
  }
}

function* createUserRequestSaga() {
  try {
    yield call([usersResource, usersResource.createUser]);
  } catch (e) {
    yield put(createUserFailure(e as Error));
  }
}

function* createUserSaga({
  payload: { onCreated, password, email },
}: ReturnType<typeof createUser>) {
  try {
    yield put(startAppLoading());
    yield call([authService, authService.createUser], {
      email,
      password,
    });
    yield call([authService, authService.loginByEmailAndPassword], email, password);
    yield call(createUserRequestSaga);
    yield call([usersResource, usersResource.sendVerificationEmail], { email });
    yield call(onCreated);
  } catch (e) {
    yield put(createUserAuth0Error(e as Auth0Error));
  } finally {
    yield put(finishAppLoading());
  }
}

function* finishSignUpSaga() {
  try {
    yield put(startAppLoading());

    const auth0Token: Maybe<string> = yield call(
      [authService, authService.getAccessToken],
      'auth0',
    );

    if (auth0Token) {
      const auth0User: Auth0User<Auth0UserMetadata> = yield call(
        [authService, authService.getUser],
        auth0Token,
      );

      yield call(populateCurrentUserSaga, populateCurrentUserData(), auth0User);
    } else {
      yield put(finishSignUpFailure(new AuthUnauthorizedError()));
    }
  } catch (e) {
    yield put(finishSignUpFailure(e as Error));
  } finally {
    yield put(finishAppLoading());
  }
}

export function* watchAuthSaga() {
  yield takeLatest(LOG_OUT, logoutSaga);
  yield takeLatest(CREATE_USER, createUserSaga);
  yield takeLatest(SIGN_IN_PASSWORD, signInPasswordSaga);
  yield takeLatest(POPULATE_CURRENT_USER_DATA, populateCurrentUserSaga);
  yield takeLatest(FINISH_SIGN_UP, finishSignUpSaga);
  yield takeLatest(CANCEL_SIGN_UP_PROCESS, cancelSignUpProcess);
}
