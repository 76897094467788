import { takeLatest, call, put } from 'redux-saga/effects';
import {
  INIT_APP,
  setAppInitializing,
  RECOVER_FROM_ERROR_BOUNDARY,
  recoverFromErrorBoundary,
  setAuthenticated,
  setApiConfig,
} from './actions';
import { logoutSaga, populateCurrentUserSaga } from '../auth/saga';
import { __DEV__ } from '../../constants';
import { configurationResource, usersResource } from '../../api';
import { authService } from '../../services';
import { Auth0User } from '../users/types';
import { populateCurrentUserData } from '../users/actions';

function* checkUserAuthProcess() {
  try {
    const auth0Token: string = yield call([authService, authService.getAccessToken], 'auth0');

    if (auth0Token) {
      const auth0User: Auth0User<Auth0UserMetadata> = yield call(
        [authService, authService.getUser],
        auth0Token,
      );
      yield call(populateCurrentUserSaga, populateCurrentUserData(), auth0User);
    } else {
      yield put(setAppInitializing(false));
    }
  } catch (e) {
    __DEV__ && console.log('-------- e', e);
  }
}

function* setUpApiConfigSaga() {
  try {
    const config: Maybe<IApiConfiguration> = yield configurationResource.getConfiguration();

    if (config) {
      yield put(setApiConfig(config));
    }
  } catch (e) {
    __DEV__ && console.log('-------- e', e);
  }
}

function* initAppSaga() {
  try {
    yield call(setUpApiConfigSaga);
    const currentUser: IApiUser | undefined = yield usersResource.getCurrentUser();

    if (currentUser?.areTermsAccepted) {
      yield put(setAuthenticated(true));
    } else {
      yield call(checkUserAuthProcess);
    }
  } catch (e) {
    if (__DEV__) {
      console.log('-------- e', e);
    }
  } finally {
    yield put(setAppInitializing(false));
  }
}

function* recoverFromErrorBoundarySaga({
  payload: doneCallback,
}: ReturnType<typeof recoverFromErrorBoundary>) {
  yield call(logoutSaga);
  yield call(doneCallback);
}

export function* watchAppSaga() {
  yield takeLatest(INIT_APP, initAppSaga);
  yield takeLatest(RECOVER_FROM_ERROR_BOUNDARY, recoverFromErrorBoundarySaga);
}
