import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BaseModal } from '../../../../components';
import { blocks } from './helpers';
import './styles.scss';
import { IProps } from './types';

export const Terms = (props: IProps) => {
  const { t } = useTranslation(['terms']);

  return (
    <BaseModal title={t('title')} visible={props.isVisible} onDismiss={props.onDismiss}>
      <div>
        <span className="terms-description-text">
          <Trans
            t={t}
            components={{
              br: <br />,
            }}
            i18nKey={'main-description'}
          />
        </span>
        {blocks.map((val, idx) => {
          return (
            <div className="terms-block">
              <span className="terms-block-title">
                <span className="terms-block-number">{idx + 1}.</span>
                {t(val.title)}
              </span>
              <div className="terms-block-description">
                <span>
                  <Trans
                    t={t}
                    components={{
                      br: <br />,
                    }}
                    i18nKey={val.description}
                  />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </BaseModal>
  );
};
