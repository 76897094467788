import { Query, QueryClient } from 'react-query';
import { contactsResource, tokensResource } from '../api';
import { reactQueryDefaultOptions } from '../constants';
import { isEthNetwork } from '../helpers';

class ReactQueryService {
  readonly queryClient = new QueryClient({
    defaultOptions: reactQueryDefaultOptions,
  });

  clearCache() {
    this.queryClient.clear();
  }

  invalidateQueries(key: string) {
    return this.queryClient.invalidateQueries(key, { refetchInactive: true });
  }

  invalidateQueriesByPredicate(predicate: (query: Query) => boolean) {
    return this.queryClient.invalidateQueries({
      predicate,
      refetchInactive: true,
    });
  }

  updateContactsStatus = (contactId: string, status: string) => {
    this.queryClient.setQueryData<IGetContactsListResponse | undefined>(
      [contactsResource.cacheKey, { limit: 25 }],
      (oldData: IGetContactsListResponse | undefined) => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          docs: oldData?.docs?.map((contact) =>
            contactId === contact.userId ? { ...contact, status } : contact,
          ),
        };
      },
    );
  };

  getTokenLists = (network: Network) => {
    return this.queryClient
      .getQueryCache()
      .getAll()
      .filter((cacheKey) =>
        isEthNetwork(network)
          ? cacheKey.queryKey[0] === tokensResource.compoundTokenListCacheKey ||
            cacheKey.queryKey[0] === tokensResource.uniswapDefaultListTokenListCacheKey
          : cacheKey.queryKey[0] === tokensResource.pancakeSwapTop100TokenListCacheKey,
      )
      .map((list) => list.state.data);
  };
}

export const reactQueryService = new ReactQueryService();
