import { createAction } from 'typesafe-actions';
import { ISignInPasswordPayload, ISignUpPasswordPayload, ISignUpState } from './types';
import { Auth0Error } from '../../services';

export const SET_STATE = '@AUTH/SET_STATE';
export const RESET_STATE = '@AUTH/RESET_STATE';
export const CREATE_USER_FAILURE = '@AUTH/CREATE_USER_FAILURE';
export const CREATE_USER_AUTH0_ERROR = '@AUTH/CREATE_USER_AUTH0_ERROR';
export const CREATE_USER = '@AUTH/CREATE_USER';
export const FINISH_SIGN_UP = '@AUTH/FINISH_SIGN_UP';
export const FINISH_SIGN_UP_FAILURE = '@AUTH/FINISH_SIGN_UP_FAILURE';
export const RESET_PASSWORD_AUTH0_ERROR = '@AUTH/RESET_PASSWORD_AUTH0_ERROR';
export const SIGN_IN_PASSWORD = '@AUTH/SIGN_IN_PASSWORD';
export const SIGN_IN_PASSWORD_AUTH0_ERROR = '@AUTH/SIGN_IN_PASSWORD_AUTH0_ERROR';
export const LOG_OUT = '@AUTH/LOG_OUT';
export const SEND_VERIFICATION_SMS_FAILURE = '@AUTH/SEND_VERIFICATION_SMS_FAILURE';
export const CHECK_VERIFICATION_CODE_FAILURE = '@AUTH/CHECK_VERIFICATION_CODE_FAILURE';
export const SET_CONFIRMATION_CODE_TIMEOUT_TIMESTAMP =
  '@AUTH/SET_CONFIRMATION_CODE_TIMEOUT_TIMESTAMP';
export const RESEND_CONFIRMATION_CODE_FAILURE = '@AUTH/RESEND_CONFIRMATION_CODE_FAILURE';
export const CANCEL_SIGN_UP_PROCESS = '@AUTH/CANCEL_SIGN_UP_PROCESS';

export const setSignUpState = createAction(SET_STATE)<Partial<ISignUpState>>();
export const resetSignUpState = createAction(RESET_STATE)();
export const createUserFailure = createAction(CREATE_USER_FAILURE)<Error>();
export const createUserAuth0Error = createAction(CREATE_USER_AUTH0_ERROR)<Auth0Error>();
export const createUser = createAction(CREATE_USER)<ISignUpPasswordPayload>();
export const finishSignUp = createAction(FINISH_SIGN_UP)();
export const finishSignUpFailure = createAction(FINISH_SIGN_UP_FAILURE)<Error>();
export const resetPasswordAuth0Error = createAction(RESET_PASSWORD_AUTH0_ERROR)<Auth0Error>();
export const signInPassword = createAction(SIGN_IN_PASSWORD)<ISignInPasswordPayload>();
export const signInPasswordAuth0Error = createAction(SIGN_IN_PASSWORD_AUTH0_ERROR)<Auth0Error>();
export const logout = createAction(LOG_OUT)();
export const sendVerificationSmsFailure = createAction(SEND_VERIFICATION_SMS_FAILURE)<Error>();
export const checkVerificationCodeFailure = createAction(CHECK_VERIFICATION_CODE_FAILURE)<Error>();
export const setConfirmationCodeTimeoutTimestamp = createAction(
  SET_CONFIRMATION_CODE_TIMEOUT_TIMESTAMP,
)<Maybe<number>>();
export const resendConfirmationCodeFailure = createAction(
  RESEND_CONFIRMATION_CODE_FAILURE,
)<Error>();
export const cancelSignUpProcess = createAction(CANCEL_SIGN_UP_PROCESS)();

export const authActions = {
  setSignUpState,
  createUserFailure,
  createUser,
  createUserAuth0Error,
  resetPasswordAuth0Error,
  signInPassword,
  signInPasswordAuth0Error,
  logout,
  resetSignUpState,
  sendVerificationSmsFailure,
  checkVerificationCodeFailure,
  resendConfirmationCodeFailure,
  cancelSignUpProcess,
  setConfirmationCodeTimeoutTimestamp,
} as const;
