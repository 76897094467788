import { ApiResponse, Monitor } from 'apisauce';

export const getTimestamp = () => {
  const now = new Date();
  return `${now.getHours().toString().padStart(2, '0')}:${now
    .getMinutes()
    .toString()
    .padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
};

const displayLoggerGroup = (title: string, fields: unknown[], collapsed = true) => {
  collapsed && console.groupCollapsed(`%c ${title}`, 'font-weight: normal;');
  fields.forEach(console.log);
  collapsed && console.groupEnd();
};

const logRequestResult = (
  { config, data, status, originalError, ok }: ApiResponse<unknown>,
  { style, collapsed }: { style: string; collapsed: boolean },
) => {
  if (config) {
    const { method, url, headers, params, baseURL } = config;

    collapsed &&
      console.groupCollapsed(
        `%c ${getTimestamp()} ${status} ${method} ${url || baseURL}`,
        `${style};`,
      );
    if (params && Object.keys(params).length) {
      displayLoggerGroup('Params', [params], collapsed);
    }
    if (headers && Object.keys(headers).length) {
      displayLoggerGroup('Headers', [headers], collapsed);
    }
    if (config.data) {
      displayLoggerGroup('Request body', [config?.data], collapsed);
    }
    if (data) {
      displayLoggerGroup('Response data', [data], collapsed);
    }
    if (!ok) {
      displayLoggerGroup('Error', [originalError], collapsed);
    }
    collapsed && console.groupEnd();
  }
};

export const makeLogRequestMonitor =
  (collapsed = true): Monitor =>
  (response) => {
    if (response.config?.method === 'get') {
      logRequestResult(response, { style: 'color: #3E8DCF', collapsed });
    } else {
      logRequestResult(response, { style: 'color: red', collapsed });
    }
  };
