import React from 'react';
import cx from 'classnames';
import './style.scss';
import { IAmountFieldProps } from './types';

export const AmountField: React.FC<IAmountFieldProps> = ({
  label,
  amount,
  containerClassName,
  amountClassName,
}) => {
  return (
    <div className={cx('amount-field', containerClassName)}>
      <span className={cx('amount-field-main-text', 'amount-field-label')}>{label}</span>
      <span className={cx('amount-field-main-text', amountClassName)}>{amount}</span>
    </div>
  );
};
