import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from './use-form';
import { CommonButton, CommonOtpInput, Timer } from '../../../../components';
import './style.scss';
import cx from 'classnames';
import { getConfirmationCodeTimeoutTimestamp } from '../../../../redux/auth/selectors';
import { useSelector } from 'react-redux';

const cellCount = 4;

const info = {
  phone: {
    main: 'phone-confirmation-info',
    enterCode: 'enter-code-to-verify-phone',
  },
  email: {
    main: 'email-confirmation-info',
    enterCode: 'enter-code-to-verify-email',
  },
};

export const OTPConfirmationStep: React.FC<IOTPConfirmationStepProps> = ({ type }) => {
  const { t } = useTranslation(['sign-up']);
  const confirmationCodeTimeoutTimestamp = useSelector(getConfirmationCodeTimeoutTimestamp);
  const {
    code,
    setCode,
    onSubmitForm,
    submitting,
    appLoading,
    getTimerLabel,
    resendCodeClick,
    resendingCode,
    phoneNumber,
    email,
  } = useForm(type);

  const getResendingCodeTimer = useCallback(() => {
    if (confirmationCodeTimeoutTimestamp === null) {
      return;
    }
    return (
      <div className="resend-timer">
        {confirmationCodeTimeoutTimestamp ? (
          <Timer timeoutTimestamp={confirmationCodeTimeoutTimestamp} getLabel={getTimerLabel} />
        ) : (
          <button disabled={resendingCode} onClick={resendCodeClick}>
            <span>{t('resend-code')}</span>
          </button>
        )}
      </div>
    );
  }, [t, resendingCode, getTimerLabel, confirmationCodeTimeoutTimestamp, resendCodeClick]);

  return (
    <div>
      <div className="signUp-head">
        <p className="signUp-head-info">
          <span>{t(info[type].main)}</span>
          <span className="verification-data">{` ${phoneNumber || email}. `}</span>
          <span>{t(info[type].enterCode)}</span>
        </p>
      </div>
      <div className="signUp-codeInput">
        <CommonOtpInput
          onlyNumbers
          code={code}
          onChange={setCode}
          disabled={submitting}
          placeholder="____"
        />
      </div>
      <CommonButton
        fill
        fullWidth
        size="large"
        onClick={onSubmitForm}
        label={type === 'phone' ? t('sign-up:finish-registration') : t('common:next')}
        loading={submitting || appLoading}
        className="sign-up-submit-button"
        containerClassName={cx('verification-next-button')}
        disabled={code.length !== cellCount || submitting || appLoading}
      />
      {getResendingCodeTimer()}
    </div>
  );
};
