import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getCurrentNetwork } from '../../../redux/blockchain/selectors';
import { ethBscBridgeService } from '../../../services/blockchain';
import { DEFAULT_REFETCH_INTERVAL, ETH_DECIMALS } from '../../../constants';
import { getBridgeSwapAllowanceBlockchainError } from '../../../redux/blockchain/actions';
import { useCallback } from 'react';
import { isEthNetwork, isTokenApprovalMandatory } from '../../../helpers';

export const GET_BRIDGE_SWAP_ALLOWANCE_CACHE_KEY = 'GET_BRIDGE_SWAP_ALLOWANCE_CACHE_KEY';

export const useBridgeSwapAllowance = (type: EthBscBridgePairs, fromWallet?: IWallet) => {
  const dispatch = useDispatch();
  const network = useSelector(getCurrentNetwork);

  const { data: bridgeSwapAllowance, isLoading: isLoadingBridgeSwapAllowance } = useQuery<
    Maybe<string>,
    Error
  >(
    [GET_BRIDGE_SWAP_ALLOWANCE_CACHE_KEY, fromWallet, network, type],
    () =>
      fromWallet ? ethBscBridgeService.getBridgeSwapAllowance(type, fromWallet, network) : null,
    {
      enabled: Boolean(fromWallet),
      staleTime: DEFAULT_REFETCH_INTERVAL,
      cacheTime: DEFAULT_REFETCH_INTERVAL,
      refetchInterval: DEFAULT_REFETCH_INTERVAL,
      retry: 2,
      onError: (e) => {
        dispatch(getBridgeSwapAllowanceBlockchainError(e));
      },
    },
  );

  const isApproveVisible = useCallback(
    (amount: number | string) => {
      return (
        isEthNetwork(network) &&
        !Number.isNaN(Number(amount)) &&
        isTokenApprovalMandatory(Number(bridgeSwapAllowance ?? 0), amount, ETH_DECIMALS)
      );
    },
    [bridgeSwapAllowance],
  );

  return {
    isApproveVisible,
    bridgeSwapAllowance,
    isLoadingBridgeSwapAllowance,
  };
};
