import { Action, applyMiddleware, combineReducers, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { Task } from '@redux-saga/types';
import { all, spawn, call } from 'redux-saga/effects';
import { configureLogger } from './logger';
import { signUp } from './auth/reducer';
import { app } from './app/reducer';
import { watchAppSaga } from './app/saga';
import { watchAuthSaga } from './auth/saga';
import { watchErrorsSaga } from './errors/saga';
import { users } from './users/reducer';
import { watchUsersSaga } from './users/saga';
import { toast } from './toast/reducer';
import { blockChain } from './blockchain/reducer';
import { watchBlockchainSaga } from './blockchain/saga';
import { watchToastSaga } from './toast/saga';

export const rootReducer = combineReducers({
  signUp,
  app,
  users,
  toast,
  blockChain,
});

function* rootSaga() {
  const sagas = [
    watchAppSaga,
    watchAuthSaga,
    watchErrorsSaga,
    watchUsersSaga,
    watchBlockchainSaga,
    watchToastSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log('------- root saga error', e);
          }
        }
      }),
    ),
  );
}

export type RootStateType = ReturnType<typeof rootReducer>;

// Cached task for fixing issue with duplication run saga
let rootSagaTask: Maybe<Task>;

const sagaMiddleware = createSagaMiddleware();
const middleware: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = configureLogger();
  middleware.push(loggerMiddleware);
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export const dispatch = (action: Action) => {
  store.dispatch(action);
};

export function configureStore() {
  if (!rootSagaTask) {
    rootSagaTask = sagaMiddleware.run(rootSaga);
  } else if (rootSagaTask.isRunning()) {
    rootSagaTask.cancel();
    rootSagaTask = sagaMiddleware.run(rootSaga);
  }

  return store;
}
