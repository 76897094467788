import React from 'react';
import './style.scss';
// import { envService } from '../../services/env.service';
import { VERSION, VERSION_CHANGELOG } from '../../constants';

export const VersionPage: React.FC = () => {
  // if (envService.envConfig.ENV === 'PRODUCTION') return null;

  return (
    <div className="version-page-root">
      <h1>{`Version: ${VERSION}`}</h1>
      <h3>{VERSION_CHANGELOG}</h3>
    </div>
  );
};
