import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AVAILABLE_LANGUAGE_TAG } from './constants';
import { noop } from './helpers';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
  en: require('./assets/translations/en.json'),
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: AVAILABLE_LANGUAGE_TAG[0],
    fallbackLng: AVAILABLE_LANGUAGE_TAG[0],
    ns: Object.keys(resources.en),
    defaultNS: 'common',
    keySeparator: '>',
    nsSeparator: ':',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'language',
      lookupLocalStorage: 'language',
      htmlTag: document.documentElement,
    },
  })
  .catch(noop);
