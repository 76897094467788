export const TWITTER_COMMUNITY_LINK = 'https://twitter.com/paypolitan';
export const TELEGRAM_COMMUNITY_NEWS_LINK = 'https://t.me/paypolitan_ann';
export const TELEGRAM_COMMUNITY_ENGLISH_LINK = 'https://t.me/paypolitan_chat';
export const TELEGRAM_COMMUNITY_VIETNAM_LINK = 'https://t.me/paypolitan_viet';
export const TELEGRAM_COMMUNITY_RUSSIAN_LINK = 'https://t.me/paypolitan_ru_chat';
export const LINKEDIN_COMMUNITY_LINK = 'https://www.linkedin.com/company/paypolitan';
export const MAIN_WEB_LINK = 'https://paypolitan.io';
export const DYNAMIC_LINKS_DOMAIN_URI_PREFIX = 'https://paypolitandapp.page.link';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.paypolitan.app';
export const APP_STORE_LINK = 'https://apps.apple.com/us/app/paypolitan/id1562351292';
