import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import cx from 'classnames';
import './style.scss';
import { ICommonButtonProps } from './types';

export const CommonButton: React.FC<ICommonButtonProps> = ({
  label,
  fullWidth,
  className,
  children,
  design = 'primary',
  loading,
  size = 'medium',
  type = 'button',
  containerClassName,
  labelClassName,
  startIcon,
  fill,
  ...rest
}) => {
  const getLabel = () =>
    label ? (
      <span
        className={cx(
          'common-button-label',
          {
            'common-button-label-negative': design === 'negative',
            'common-button-label-large': size === 'large',
            'common-button-label-small': size === 'small',
            'common-button-label-greyish-negative': design === 'greyish-negative',
          },
          labelClassName,
        )}
      >
        {label}
      </span>
    ) : (
      children
    );

  return (
    <div className={cx(containerClassName, { 'common-button-full-width': fullWidth })}>
      <Button
        variant={design === 'negative' ? 'outlined' : 'contained'}
        type={type}
        startIcon={
          startIcon ||
          (loading && (
            <CircularProgress
              className={cx('common-button-spinner', {
                'common-button-spinner-negative': design === 'negative',
              })}
              size={20}
            />
          ))
        }
        className={cx(
          'common-button-root',
          { 'common-button-secondary': design === 'secondary' },
          { 'common-button-greyish': design === 'greyish' },
          { 'common-button-large': size === 'large' },
          { 'common-button-small': size === 'small' },
          { 'common-button-greyish-negative': design === 'greyish-negative' },
          className,
        )}
        {...rest}
      >
        {getLabel()}
      </Button>
    </div>
  );
};
