import moment from 'moment';
import {
  CRYPTO_BALANCE_PRECISION,
  LONG_TERM_EPAN_STAKING_MONTH_DURATION,
  RESEND_CONFIRMATION_CODE_TIMOUT,
  WALLET_CONNECT_TRANSACTION_TIMEOUT,
} from '../constants';
import Decimal from 'decimal.js';

export const isTokenExpired = (expiredDate: number) => {
  return moment().isAfter(moment(expiredDate));
};

export const getTokenExpirationDate = (expiredIn: number) => {
  return moment().add(expiredIn, 'seconds').valueOf();
};

export const convertTransactionHistoryDate = (value?: string) => {
  if (!value) {
    return '';
  }
  return moment.unix(Number(value)).format('HH:mm DD/MM/YY');
};

export const secondsToMinutes = (seconds?: number) => {
  if (!seconds) {
    return '';
  }

  const minutes = Math.floor(seconds / 60);
  const sec = seconds % 60;

  return `${minutes}m ${sec}s`;
};

export const getPaypolitanStakingTimeframeDaysLeft = (date: string) => {
  const stakeData = moment(new Date(Number(date) * 1000)).startOf('day');
  const now = moment().startOf('day');

  return stakeData.add(LONG_TERM_EPAN_STAKING_MONTH_DURATION, 'month').diff(now, 'days');
};

export const getPaypolitanStakingTimeframeLastDate = (daysLeft: number) => {
  return moment().add(daysLeft, 'd').format('DD.MM.YYYY');
};

export const getTransactionTimeoutTimestamp = (timeout = WALLET_CONNECT_TRANSACTION_TIMEOUT) => {
  return moment().add(timeout, 'milliseconds').valueOf();
};

export const getTransactionProcessDuration = (timestamp?: number) => {
  if (!timestamp) {
    return 0;
  }

  const diff = moment(timestamp).diff(moment(), 'seconds');

  return diff > 0 ? diff : 0;
};
export const formatResendCodeTimer = (value: number) => {
  return moment().startOf('day').seconds(value).format('mm:ss');
};

export const getNotificationDate = (value: string) => {
  return moment(value).format('HH:mm MM/DD/YY');
};

export const getSpotAccountInvestmentDate = (value: string) => {
  return moment(value).format('DD.MM.YYYY');
};

export const isSpotInvestmentRedeemable = (timestamp: string) => {
  if (!timestamp) {
    return true;
  }

  return moment(timestamp).isBefore(moment());
};

export const getResendConfirmationCodeTimeoutTimestamp = (
  timeout = RESEND_CONFIRMATION_CODE_TIMOUT,
) => {
  return moment().add(timeout, 'milliseconds').valueOf();
};

export const getSpotAccountDetailsData = (balances: IApiSpotAccountBalance[]) => {
  const now = moment();
  const data = balances
    .map((bal) => {
      const date = moment(bal.redeemDate);

      return {
        date,
        diff: now.diff(date, 'seconds'),
        amount: bal.amount,
      };
    })
    .sort((a, b) => a.diff - b.diff);

  const availableRedeem = data
    .filter((i) => i.diff >= 0)
    .reduce((acc, cur) => acc + Number(cur.amount), 0);

  return {
    nearestRedeemableDate: data.length ? data[data.length - 1].date.toISOString() : null,
    availableRedeem: new Decimal(availableRedeem)
      .toDecimalPlaces(CRYPTO_BALANCE_PRECISION, Decimal.ROUND_HALF_FLOOR)
      .toNumber(),
  };
};

export const getRewardStakeDate = (value: number) => {
  return moment.unix(value).format('DD.MM.YYYY');
};

export const getTopUpSpotAccountLockDate = (days?: number) => {
  if (!days || days === 0) {
    return '';
  }

  return moment().add(days, 'days').format('DD.MM.YYYY');
};
